<template>
  <v-form ref="form" @submit.prevent="submit">
    <v-container v-if="loading === true">
      <v-card>
        <v-card-text class="text-center">
          <v-progress-circular
            :size="35"
            :width="3"
            indeterminate
          />
        </v-card-text>
      </v-card>
    </v-container>
    <v-container v-else>
      <v-card>
        <v-card-title class="title">
          <template v-if="id === 0">Add Route Group</template>
          <template v-else>Update {{ formData.name }}</template>
        </v-card-title>
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                v-model="formData.name"
                :rules="[rules.required]"
                required
                validate-on-blur
                label="Name"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-card elevation="0">
                <v-card-title class="subheading">
                  Available Routes
                  <v-spacer />
                  <v-text-field
                    v-model="search"
                    append-icon="filter_list"
                    label="Filter"
                    single-line
                    hide-details
                    clearable
                  />
                </v-card-title>
                <v-data-table
                  ref="sourceTable"
                  :items="routes"
                  :headers="headers"
                  :loading="routesLoading"
                  :footer-props="{ itemsPerPageOptions: [10,50,{'text':'All',value:-1}] }"
                  :search="search"
                  hide-default-header
                  :custom-filter="searchFilter"
                  @click:row="addRoute"
                >
                  <template v-slot:item.name="row">
                    {{ translateName(row.item) }}
                  </template>
                  <template v-slot:item.actions="row">
                    <td class="text-right">
                      <v-btn color="primary" small fab>
                        <v-icon>add</v-icon>
                      </v-btn>
                    </td>
                  </template>
                  <template v-slot:no-data>
                    <v-alert v-if="!sourceLoading" :value="true" color="error" icon="warning">
                      No routes available
                    </v-alert>
                    <div v-if="sourceLoading" class="text-center">
                      <v-progress-circular indeterminate />
                    </div>
                  </template>
                  <template v-slot:no-results>
                    <v-alert :value="true" color="error" icon="warning">
                      Your search for "{{ search }}" found no results.
                    </v-alert>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-card elevation="0">
                <v-card-title class="subheading">
                  Selected Routes ({{ routesSelected.length }})
                  <v-spacer />
                  <v-text-field
                    v-model="selectedSearch"
                    append-icon="filter_list"
                    label="Filter"
                    single-line
                    hide-details
                    clearable
                  />
                </v-card-title>
                <v-data-table
                  ref="sourceSelectedTable"
                  :items="routesSelected"
                  :headers="headers"
                  :footer-props="{ itemsPerPageOptions: [10,50,{'text':'All',value:-1}] }"
                  :search="selectedSearch"
                  :loading="routesLoading"
                  hide-default-header
                  no-data-text="No numbers added"
                  :custom-filter="searchFilter"
                  @click:row="removeRoute"
                >
                  <template v-slot:item.name="row">
                    {{ translateName(row.item) }}
                  </template>
                  <template v-slot:item.actions="row">
                    <td class="text-right">
                      <v-btn color="primary" small fab>
                        <v-icon>remove</v-icon>
                      </v-btn>
                    </td>
                  </template>
                  <template v-slot:no-results>
                    <v-alert :value="true" color="error" icon="warning">
                      Your search for "{{ selectedSearch }}" found no results.
                    </v-alert>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-right">
              <v-btn
                color="primary"
                :disabled="formLoading"
                :loading="formLoading"
                type="submit"
              >
                <template v-if="id === 0">Add Route Group</template>
                <template v-else>Update Route Group</template>
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="formErrors.length > 0">
            <v-col>
              <v-alert type="error">
                <div v-for="(error, k) in formErrors" :key="k">
                  {{ error }}
                </div>
              </v-alert>
            </v-col>
          </v-row>

        </v-container>
      </v-card>
    </v-container>
  </v-form>
</template>

<script>
  import * as rules from '@/plugins/rules'
  import { authFetch } from '@/plugins/authFetch'
  import { translateRoute } from '@/plugins/common'

  export default {
    props: {
      id: { type: Number, default: 0 },
    },
    data () {
      return {
        rules: rules,
        formData: {
          name: null,
        },
        search: '',
        selectedSearch: '',
        headers: [
          { text: 'Name', value: 'name' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        routes: [],
        routesLoading: false,
        routesSelected: [],
        formLoading: false,
        formErrors: [],
        loading: false,
      }
    },
    created () {
      this.getRoutes()
      if (this.id > 0) {
        this.getGroup(this.id)
      }
    },
    methods: {
      searchFilter (value, search, item) {
        const searchLower = search.toString().toLowerCase().trim()
        return `${item.label} ${item.ip} ${item.port} ${item.prefix}`.toLowerCase().includes(searchLower)
      },
      addRoute (item) {
        if (this.routesSelected.map(x => x.id).includes(item.id)) {
          return
        }
        this.routesSelected.push(item)
      },
      removeRoute (item) {
        const k = this.routesSelected.map(x => x.id).indexOf(item.id)
        if (k === -1) {
          return
        }
        this.routesSelected.splice(k, 1)
      },
      translateName: translateRoute,
      getRoutes () {
        this.routes = []
        this.routesLoading = true
        authFetch(`${process.env.VUE_APP_API_ROOT}routes/`)
          .then(async response => {
            this.routes = await response.json()
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.routesLoading = false
          })
      },
      getGroup (id) {
        this.loading = true
        authFetch(`${process.env.VUE_APP_API_ROOT}route-group/${id}/`)
          .then(async response => {
            const data = await response.json()
            this.formData.name = data.name
            this.routesSelected = data.routes
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.loading = false
          })
      },
      submit () {
        this.formErrors = []
        if (this.routesSelected.length === 0) {
          this.formErrors.push('You must add at least one route')
        }
        if (!this.$refs.form.validate()) {
          this.formLoading = false
          return false
        }
        if (this.formErrors.length > 0) {
          return false
        }
        this.formLoading = true
        const postData = {
          name: this.formData.name,
          routes: this.routesSelected.map(x => x.id),
        }
        let method = 'POST'
        let uri = `${process.env.VUE_APP_API_ROOT}route-groups/`
        if (this.id > 0) {
          method = 'PUT'
          uri = `${process.env.VUE_APP_API_ROOT}route-group/${this.id}/`
        }
        authFetch(uri, { method: method, body: JSON.stringify(postData) })
          .then(async response => {
            const data = await response.json()
            this.routesSelected = []
            this.formData.name = null
            this.$emit('completed', data)
          })
          .catch(async error => {
            console.log(error)
            const data = await error.json()
            if (typeof data === 'object' && Object.prototype.hasOwnProperty.call(data, 'message')) {
              // Is it an object we can loop through for feedback?
              if (typeof data.message === 'object') {
                Object.values(data.message).forEach(msg => {
                  this.formErrors.push(msg)
                })
                // String returned, setup dialog
              } else {
                this.formErrors = [data.message]
              }
              // Generic error if we didn't get a proper response
            } else {
              this.formErrors = [`Server returned a ${error.status} status code`]
            }
          })
          .finally(() => {
            this.formLoading = false
          })
      },
    },
  }
</script>

<style>

</style>

<template>
  <layout-panel>
    <v-container>
      <v-card>
        <v-card-text class="display-2 text-center mt-5 primary white--text">
          New Revenue Boost Task
        </v-card-text>
      </v-card>
    </v-container>
    <v-container v-if="loading">
      <v-row>
        <v-col class="text-center">
          <v-progress-circular
            :size="70"
            :width="7"
            indeterminate
          />
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else-if="noServers">
      <v-row>
        <v-col>
          <v-alert type="error" text outlined>
            You don't have any servers assigned with this feature enabled. Please contact the
            admin to request this feature.
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
    <v-form v-else ref="form" v-model="formValid" @submit.prevent="submit">
      <v-container>
        <v-card>
          <v-card-title>
            Peer Settings
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="formData.source"
                    required
                    label="Source Peer IP"
                    :rules="[rules.ip]"
                    validate-on-blur
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="formData.destination"
                    required
                    :rules="[rules.ipAndPort]"
                    label="Destination Peer IP:Port"
                    validate-on-blur
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="formData.outgoingPrefix"
                    label="Outgoing Dialing Prefix"
                  />
                </v-col>
                <v-col>
                  <v-slider
                    v-model="formData.percentage"
                    min="0"
                    max="100"
                    label="Percentage"
                  >
                    <template v-slot:append>
                      <v-text-field
                        v-model="formData.percentage"
                        class="mt-0 pt-0"
                        hide-details
                        single-line
                        type="number"
                        style="width: 60px"
                      />
                    </template>
                  </v-slider>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-title>
            Pair Selection
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="2">
                  <v-text-field
                    ref="lookbackValue"
                    v-model="lookbackValue"
                    label="CDR Lookback"
                    type="number"
                    :rules="[rules.positiveNumber]"
                    validate-on-blur
                  />
                </v-col>
                <v-col cols="2">
                  <v-select
                    v-model="lookbackPeriod"
                    :items="lookbackOptions"
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    ref="lookupPrefixes"
                    v-model="formData.lookupPrefix"
                    label="Prefxies to Check"
                    hint="Comma separated"
                    :rules="[rules.positiveCommaSepNumbers]"
                    required
                    persistent-hint
                    validate-on-blur
                  />
                </v-col>
                <v-col cols="2">
                  <v-btn
                    color="primary"
                    :disabled="pairsLoading"
                    :loading="pairsLoading"
                    @click="getPairs"
                  >Get Pairs
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="pairsLoaded && pairsError" />
              <v-row v-else-if="pairsLoaded && pairs.length == 0">
                <v-col>
                  <v-alert type="info" text outlined>
                    No results found
                  </v-alert>
                </v-col>
              </v-row>
              <template v-else-if="pairsLoaded && pairs.length > 0">
                <v-data-table
                  :headers="headers"
                  :items="pairs"
                  item-key="id"
                  hide-default-footer
                  :items-per-page="-1"
                >
                  <template v-slot:item.select="row">
                    <v-checkbox
                      v-model="formData.pairs"
                      :value="pairsValues[row.item.id]"
                      class="short"
                    />
                  </template>
                </v-data-table>
                <v-row>
                  <v-col class="text-right">
                    <v-btn
                      color="primary"
                      @click="toggleSelect"
                    >{{ selectText }}</v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-btn
                      color="primary"
                      type="submit"
                      block
                    >Launch Task
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </v-container>
          </v-card-text>
        </v-card>
      </v-container>
    </v-form>

    <v-dialog v-model="errorDialog" max-width="500">
      <v-card>
        <v-card-title class="headline" color="error">Form Error</v-card-title>
        <v-card-text>
          <p v-for="(err, key) in formErrors" :key="key">{{ err }}</p>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="errorDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </layout-panel>
</template>

<script>
  import { authFetch, objectToQueryString } from '@/plugins/authFetch'
  import * as rules from '@/plugins/rules'

  export default {
    data () {
      return {
        rules: rules,
        formData: {
          destination: '',
          source: '',
          lookupPrefix: '',
          outgoingPrefix: '',
          percentage: 0,
          pairs: [],
        },
        formValid: true,
        formErrors: [],
        loading: false,
        pairsLoading: false,
        pairsLoaded: false,
        pairs: [],
        pairsError: false,
        noServers: false,
        pairsValues: {},
        headers: [
          { text: 'Retail Carrier', value: 'retailCarrier' },
          { text: 'Destination Carrier', value: 'destinationCarrier' },
          { text: 'Total Calls', value: 'count' },
          { text: 'Retails Assigned', value: 'retailCount' },
          { text: 'Use Pair', value: 'select' },
        ],
        lookbackOptions: [
          { text: 'Hours', value: 'hour' },
          { text: 'Days', value: 'day' },
          { text: 'Months', value: 'month' },
          { text: 'Years', value: 'year' },
        ],
        lookbackValue: 6,
        lookbackPeriod: 'hour',
        errorDialog: false,
      }
    },
    computed: {
      selectText () {
        if (this.formData.pairs.length === Object.keys(this.pairsValues).length) {
          return 'Select None'
        }
        return 'Select All'
      },
    },
    created () {
      this.getServers()
    },
    methods: {
      toggleSelect () {
        if (this.formData.pairs.length === Object.keys(this.pairsValues).length) {
          this.formData.pairs = []
        } else {
          this.formData.pairs = []
          Object.values(this.pairsValues).forEach(r => {
            this.formData.pairs.push(r)
          })
        }
      },
      getServers () {
        this.loading = true
        authFetch(`${process.env.VUE_APP_API_ROOT}tasks/rev-boost/servers/`)
          .then(async response => {
          })
          .catch(error => {
            this.noServers = true
            console.log(error)
          })
          .finally(() => {
            this.loading = false
          })
      },
      getPairs () {
        let error = false
        if (this.$refs.lookupPrefixes.validate() === false) {
          this.$refs.lookupPrefixes.focus()
          this.$refs.lookupPrefixes.blur()
          error = true
        }
        if (this.$refs.lookbackValue.validate() === false) {
          this.$refs.lookbackValue.focus()
          this.$refs.lookbackValue.blur()
          error = true
        }
        if (error === true) {
          return false
        }

        this.pairsLoading = true
        this.pairsLoaded = false
        this.formData.pairs = []
        const query = objectToQueryString({
          prefixes: this.formData.lookupPrefix,
          lookbackValue: this.lookbackValue,
          lookbackPeriod: this.lookbackPeriod,
        })

        authFetch(`${process.env.VUE_APP_API_ROOT}tasks/rev-boost/pairs/?${query}`)
          .then(async response => {
            const r = await response.json()
            let id = 0
            this.pairs = []
            this.pairsValues = {}
            this.pairsError = false
            r.forEach(item => {
              item.id = id
              this.pairs.push(item)
              this.pairsValues[id] = {
                source: item.retailCarrierPrefix,
                destination: item.destinationCarrierPrefix,
              }
              id++
            })
          })
          .catch(error => {
            this.pairsError = true
            console.log(error)
          })
          .finally(() => {
            this.pairsLoading = false
            this.pairsLoaded = true
          })
      },
      submit () {
        this.formErrors = []
        this.errorDialog = false

        let errors = false
        if (this.formData.pairs.length === 0) {
          this.formErrors.push('You must select at least one pair to run')
        }

        if (!this.$refs.form.validate()) {
          errors = true
        }

        if (this.formErrors.length > 0) {
          this.errorDialog = true
          errors = true
        }

        if (errors === true) {
          return false
        }

        this.formLoading = true
        authFetch(`${process.env.VUE_APP_API_ROOT}tasks/rev-boost/`, {
          method: 'POST',
          body: JSON.stringify(this.formData),
        })
          .then(async response => {
            await response.json()
            this.$router.push({ name: 'revboost' })
          })
          .catch(async error => {
            console.log(error)
            const data = await error.json()
            if (typeof data === 'object' && Object.prototype.hasOwnProperty.call(data, 'message')) {
              // Is it an object we can loop through for feedback?
              if (typeof data.message === 'object') {
                Object.values(data.message).forEach(msg => {
                  this.formErrors.push(msg)
                })
                // String returned, setup dialog
              } else {
                this.formErrors = [data.message]
              }
              // Generic error if we didn't get a proper response
            } else {
              this.formErrors = [`Server returned a ${error.status} status code`]
            }
            if (this.formErrors.length > 0) {
              this.errorDialog = true
            }
          })
          .finally(() => {
            this.formLoading = false
          })
      },
    },
  }
</script>

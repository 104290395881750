<template>
  <layout-panel>
    <v-container>
      <v-card>
        <v-card-text class="display-2 text-center mt-5 primary white--text">
          Last 1000 Calls
        </v-card-text>
      </v-card>
    </v-container>
    <v-card class="mt-5">
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :footer-props="{
            itemsPerPageOptions: [50, 100, 500]
          }"
          :options.sync="options"
          :server-items-length="total"
          disable-sort
        >
          <template v-slot:no-data>
            <div v-if="!loading && !error" class="text-center">
              No IPRN Rates found
            </div>
            <v-alert
              v-if="!loading && error"
              :value="true"
              color="error"
              icon="warning"
            >
              Error loading nubmers
            </v-alert>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </layout-panel>
</template>

<script>
  import { authFetch, objectToQueryString } from '@/plugins/authFetch'
  export default {
    props: {
      taskId: { type: Number, default: 0 },
    },
    data () {
      return {
        headers: [
          { text: 'Call Date', value: 'calldate' },
          { text: 'Source', value: 'src' },
          { text: 'Source Carrier', value: 'carrier' },
          { text: 'Destination', value: 'dst' },
          { text: 'Destination Carrier', value: 'dstCarrier' },
        ],
        items: [],
        loading: false,
        error: false,
        total: 0,
        options: {},
      }
    },
    watch: {
      options: {
        handler () {
          this.getData()
        },
        deep: true,
      },
    },
    methods: {
      applyFilter () {
        if (!this.$refs.form.validate()) {
          this.submitting = false
          return false
        }
        this.filterCarrier = this.filterCarrierField
        this.filterCountry = this.filterCountryField
        this.options.page = 1
        this.getData()
      },
      getData () {
        this.loading = true
        const query = objectToQueryString({
          itemsPerPage: this.options.itemsPerPage,
          page: this.options.page,
        })
        authFetch(process.env.VUE_APP_API_ROOT + `cdr/recent/?${query}`)
          .then(async response => {
            const r = await response.json()
            this.total = r.total
            this.items = r.items
          })
          .catch(error => {
            console.log(error)
            this.error = true
          })
          .finally(() => {
            this.loading = false
          })
      },
    },
  }
</script>

<template>
  <v-form ref="form" @submit.prevent="submit">
    <v-container v-if="loading === true">
      <v-card>
        <v-card-text class="text-center">
          <v-progress-circular
            :size="35"
            :width="3"
            indeterminate
          />
        </v-card-text>
      </v-card>
    </v-container>
    <v-container v-else>
      <v-card>
        <v-card-title class="title">
          <template v-if="id === 0">Add Route</template>
          <template v-else>Update {{ translateName(formData) }}</template>
        </v-card-title>
        <v-container>
          <v-row>
            <v-col cols="6" md="4">
              <v-text-field
                v-model="formData.prefix"
                required
                label="Route Prefix"
              />
            </v-col>
            <v-col cols="6" md="4">
              <v-text-field
                v-model="formData.ip"
                :rules="[rules.required, rules.ip]"
                validate-on-blur
                required
                label="Route IP"
              />
            </v-col>
            <v-col cols="6" md="4">
              <v-text-field
                v-model="formData.port"
                :rules="[rules.required, rules.port]"
                required
                label="Route Port"
                type="number"
              />
            </v-col>
            <template v-if="id === 0">
              <v-col cols="6" md="4">
                <v-combobox
                  v-model="formData.label"
                  :rules="[rules.required]"
                  required
                  :items="labels"
                  label="Route Provider"
                />
              </v-col>
            </template>
            <v-col cols="3" md="2">
              <v-text-field
                v-model="formData.callsPerInterval"
                :rules="[rules.required, rules.positiveRange]"
                required
                label="Route Group CPS"
                hint="Range"
              />
            </v-col>
            <v-col cols="3" md="2">
              <v-text-field
                v-model="formData.maxSimultaneousCalls"
                :rules="[rules.required, rules.numberGT0]"
                required
                label="Route Group Channels"
                type="number"
              />
            </v-col>
            <v-col cols="3" md="2">
              <v-text-field
                v-model="formData.pdds"
                :rules="[rules.required, rules.positiveCommaSepNumbers]"
                required
                label="Route Group PDDs"
              />
            </v-col>
            <v-col cols="3" md="2">
              <v-text-field
                v-model="formData.acd"
                :rules="[rules.required, rules.positiveRange]"
                required
                label="ACD Required"
                hint="Range"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-right">
              <v-btn
                color="primary"
                :disabled="formLoading"
                :loading="formLoading"
                type="submit"
              >
                <template v-if="id === 0">Add Route</template>
                <template v-else>Update Route</template>
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="formErrors.length > 0">
            <v-col>
              <v-alert type="error">
                <div v-for="(error, k) in formErrors" :key="k">
                  {{ error }}
                </div>
              </v-alert>
            </v-col>
          </v-row>

        </v-container>
      </v-card>
    </v-container>
  </v-form>
</template>

<script>
  import { authFetch } from '@/plugins/authFetch'
  import * as rules from '@/plugins/rules'
  import { numberRangeToList, commaSeparatedNumbersToList, translateRoute } from '@/plugins/common'

  export default {
    props: {
      id: { type: Number, default: 0 },
    },
    data () {
      return {
        rules: rules,
        formData: {
          prefix: null,
          ip: null,
          port: null,
          label: null,
          callsPerInterval: null,
          maxSimultaneousCalls: null,
          pdds: null,
          acd: null,
        },
        formErrors: [],
        formLoading: false,
        loading: false,
        labels: [],
      }
    },
    created () {
      if (this.id > 0) {
        this.getRoute(this.id)
      }
      this.getLabels()
    },
    methods: {
      translateName: translateRoute,
      apiToFormData (data) {
        if (Object.prototype.hasOwnProperty.call(data, 'callsPerInterval') &&
          Array.isArray(data.callsPerInterval)) {
          data.callsPerInterval = data.callsPerInterval.join('-')
        }
        if (Object.prototype.hasOwnProperty.call(data, 'callsInterval') &&
          Array.isArray(data.callsInterval)) {
          data.callsInterval = data.callsInterval.join('-')
        }
        if (Object.prototype.hasOwnProperty.call(data, 'pdds') &&
          Array.isArray(data.pdds)) {
          data.pdds = data.pdds.join(', ')
        }
        if (Object.prototype.hasOwnProperty.call(data, 'acd') &&
          Array.isArray(data.acd)) {
          data.acd = data.acd.join('-')
        }
        return data
      },
      getRoute (id) {
        this.loading = true
        authFetch(`${process.env.VUE_APP_API_ROOT}route/${id}/`)
          .then(async response => {
            this.formData = this.apiToFormData(await response.json())
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.loading = false
          })
      },
      getLabels () {
        authFetch(`${process.env.VUE_APP_API_ROOT}routes/labels/`)
          .then(async response => {
            this.labels = await response.json()
          })
          .catch(error => {
            console.log(error)
          })
      },
      submit () {
        this.formLoading = true
        this.formErrors = []
        if (!this.$refs.form.validate()) {
          this.formLoading = false
          return false
        }
        const postData = {
          callsPerInterval: numberRangeToList(this.formData.callsPerInterval),
          callsInterval: [1],
          maxSimultaneousCalls: parseInt(this.formData.maxSimultaneousCalls, 10),
          pdds: commaSeparatedNumbersToList(this.formData.pdds),
          acd: numberRangeToList(this.formData.acd),
          prefix: this.formData.prefix,
          ip: this.formData.ip,
          port: parseInt(this.formData.port, 10),
        }
        let method = 'PUT'
        let uri = `${process.env.VUE_APP_API_ROOT}route/${this.id}/`
        if (this.id === 0) {
          postData.label = this.formData.label
          method = 'POST'
          uri = `${process.env.VUE_APP_API_ROOT}routes/`
        }
        authFetch(uri, { method: method, body: JSON.stringify(postData) })
          .then(async response => {
            const data = await response.json()
            this.$emit('completed', data)
            if (this.id === 0) {
              this.$refs.form.reset()
            }
          })
          .catch(async error => {
            console.log(error)
            const data = await error.json()
            if (typeof data === 'object' && Object.prototype.hasOwnProperty.call(data, 'message')) {
              // Is it an object we can loop through for feedback?
              if (typeof data.message === 'object') {
                Object.values(data.message).forEach(msg => {
                  this.formErrors.push(msg)
                })
                // String returned, setup dialog
              } else {
                this.formErrors = [data.message]
              }
              // Generic error if we didn't get a proper response
            } else {
              this.formErrors = [`Server returned a ${error.status} status code`]
            }
          })
          .finally(() => {
            this.formLoading = false
          })
      },
    },
  }
</script>

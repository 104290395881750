<template>
  <layout-panel>
    <v-container><v-card>
      <v-card-text class="display-2 text-center mt-5 primary white--text">
        Automatic Access Test
        <div class="grey--text text--lighten-1 body-1">
          This tool will look back at access over the amount of history that you select,
          and look for working access for each of the destinations that you select.
        </div>
      </v-card-text>
    </v-card></v-container>
    <v-form ref="form" v-model="formValid" @submit.prevent="submit">
      <v-container>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title>Destination Options</v-card-title>
              <v-container class="m-3">
                <v-row>
                  <v-col>
                    <v-switch v-model="useCSV" label="Upload CSV File" />
                  </v-col>
                </v-row>
                <v-row v-if="useCSV">
                  <v-col cols="12">
                    <v-file-input
                      accepts=".csv,text/csv"
                      label="CSV Rate File"
                      required
                      :error="csvError.length > 0"
                      :error-messages="csvError"
                      @change="onFileChange"
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="formData.csvPrefixColumn"
                      :rules="[rules.required, rules.numberGT0]"
                      required
                      label="Prefix Column Number"
                      type="number"
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="formData.csvRateColumn"
                      :rules="[rules.required, rules.numberGT0]"
                      required
                      label="Rate Column Number"
                      type="number"
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="formData.csvNameColumn"
                      label="Carrier Name Column Number (optional)"
                      type="number"
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="formData.csvMaximumRate"
                      :rules="[rules.required]"
                      required
                      label="Maximum Rate"
                    >
                      <template v-slot:append>
                        <v-tooltip bottom max-width="400">
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">help</v-icon>
                          </template>
                          Select the MAX rate from the rate sheet uploaded of any
                          destination that will be tested.
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col>
                    <v-textarea
                      v-model="formData.csvString"
                      :placeholder="`34,0.05,daytime\nCanada,\t0.01,\tevening\n44, 0.03, both`"
                      outlined
                      :rules="[rules.required]"
                    />
                  </v-col>
                </v-row>
              </v-container>

              <v-card-title>Task Options</v-card-title>
              <v-container class="m-3">
                <v-row>
                  <template v-if="routesLoading === false">
                    <v-col cols="10">
                      <v-select
                        v-model="formData.routeId"
                        label="Route"
                        :items="routesItems"
                        :rules="[rules.required, rules.positiveNumber]"
                      />
                    </v-col>
                    <v-col cols="2">
                      <v-btn color="primary" @click="routeForm = true">
                        New Route
                      </v-btn>
                    </v-col>
                  </template>
                  <v-col v-else cols="12" style="text-align: center">
                    <v-progress-circular
                      :size="35"
                      :width="3"
                      indeterminate
                    />
                  </v-col>
                  <v-col v-if="useCSV" cols="6">
                    <v-text-field
                      v-model="formData.prefixes"
                      label="Destination Prefixes (Daytime)"
                      :rules="[rules.positiveCommaSepNumbers]"
                    >
                      <template v-slot:append>
                        <v-tooltip bottom max-width="400">
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">help</v-icon>
                          </template>
                          Enter country codes in this box that are applicable to daytime
                          destinations (GMT), tasks will automatically turn off when it
                          is time to run the evening time prefixes.
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col v-if="useCSV" cols="6">
                    <v-text-field
                      v-model="formData.altPrefixes"
                      label="Destination Prefixes (Evening)"
                      :rules="[rules.positiveCommaSepNumbers]"
                    >
                      <template v-slot:append>
                        <v-tooltip bottom max-width="400">
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">help</v-icon>
                          </template>
                          Enter country codes in this box that are applicable to evening
                          destinations (GMT), tasks will automatically turn off when it
                          is time to run the daytime prefixes.
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3">
                    <v-text-field
                      v-model="formData.maxSimultaneousCalls"
                      :rules="[rules.required, rules.positiveNumber]"
                      required
                      label="Max Ports"
                      type="number"
                      hint="Maximum 800"
                      min="1"
                      max="800"
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="formData.minimumRetail"
                      :rules="[rules.required]"
                      required
                      label="Minimum Retail Rate"
                      validate-on-blur=""
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="formData.cliPrefix"
                      label="CLI Prefix"
                    >
                      <template v-slot:append>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">help</v-icon>
                          </template>
                          Add a prefix to the CallerID when the call is delivered.
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="formData.taskACD"
                      :rules="[rules.required, rules.positiveRange]"
                      required
                      label="ACD Required"
                      hint="Range"
                    >
                      <template v-slot:append>
                        <v-tooltip bottom max-width="400">
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">help</v-icon>
                          </template>
                          Setting the ACD is setting the average duration of the
                          outgoing calls, this is a requirement that will change
                          between routes.
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      v-model="lookbackTime"
                      reverse
                      label="CDR Pair Lookback"
                      type="number"
                    />
                  </v-col>
                  <v-col cols="2">
                    <v-select
                      v-model="lookbackTimePeriod"
                      :items="lookbackPeriods"
                    >
                      <template v-slot:append-outer>
                        <v-tooltip bottom max-width="400">
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">help</v-icon>
                          </template>
                          Amount of time to look back in history for Access relating
                          to the destinations entered.
                        </v-tooltip>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="2">
                    <v-switch
                      v-model="formData.testMode"
                      label="Test Mode"
                    >
                      <template v-slot:append>
                        <v-tooltip bottom max-width="400">
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">help</v-icon>
                          </template>
                          Uses a very small amount of capacity and CPS to test,
                          therefore testing a lot more access pairs at the same
                          time.
                        </v-tooltip>
                      </template>
                    </v-switch>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="formData.taskPDDs"
                      :rules="[rules.required, rules.positiveCommaSepNumbers]"
                      validate-on-blur
                      required
                      label="PDDs (comma separated)"
                    />
                  </v-col>
                  <v-col>
                    <v-radio-group
                      v-model="formData.sourceLastSeenCheck"
                      label="Source Filtering"
                    >
                      <v-radio label="None" value="none" />
                      <v-radio label="Verified Only" value="verified" />
                      <v-radio label="Exploration" value="exploration" />
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-card-title>Dial Servers
                      <v-checkbox
                        v-model="dialServerAll"
                        label="All / None"
                        class="pl-5"
                      />
                    </v-card-title>
                  </v-col>
                  <v-col v-for="(ds, k) in dialServers" :key="k" cols="4">
                    <v-checkbox
                      v-model="formData.dialServers"
                      :label="ds.text"
                      :value="ds.value"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-row>
          <v-col>
            <v-card class="pa-3">
              <v-btn
                color="primary"
                large
                block
                type="submit"
                :disabled="submitting"
                :loading="submitting"
              >
                <template>Start Task</template>
              </v-btn>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-dialog v-model="errorDialog" max-width="500">
        <v-card>
          <v-card-title class="headline" color="error">Validation Error</v-card-title>
          <v-card-text>
            <p>You made mistakes on the form that must be fixed.</p>
            <p v-for="(error, key) in errorDialogText" :key="key">{{ error }}</p>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" @click="errorDialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <v-dialog v-model="routeForm" max-width="500">
      <new-route-form @completed="handleNewRoute" @cancel="routeForm = false" />
    </v-dialog>
  </layout-panel>
</template>

<script>
  import { authFetch } from '@/plugins/authFetch'
  import * as rules from '@/plugins/rules'
  import { commaSeparatedNumbersToList, numberRangeToList } from '@/plugins/common'
  import NewRouteForm from '@/components/ModalRouteForm.vue'

  export default {
    components: { NewRouteForm },
    props: {
      previousFormData: {
        type: Object,
        default () {
          return {
            routeId: '',
            prefixes: '',
            maxSimultaneousCalls: '',
            maximumRate: '',
            minimumRetail: '',
            altPrefixes: '',
            testMode: false,
            dialServers: [],
            csvPrefixColumn: null,
            csvRateColumn: null,
            csvNameColumn: null,
            csvMaximumRate: null,
            lastSeenCheck: false,
            cliPrefix: '',
            taskACD: '',
            csvString: '',
            taskPDDs: '',
          }
        },
      },
      pageHeader: { type: String, default: 'New Auto Exploration Task' },
    },
    data () {
      return {
        submitting: false,
        errorDialog: false,
        errorDialogText: '',
        rules: rules,
        formValid: true,
        formData: this.previousFormData,
        routesItems: [],
        routesLoading: true,
        routeForm: false,
        dialServers: [],
        dialServerAll: true,
        lookbackTime: 1,
        lookbackTimePeriod: 'hour',
        lookbackPeriods: [
          { text: 'Minutes', value: 'minute' },
          { text: 'Hours', value: 'hour' },
          { text: 'Days', value: 'day' },
          { text: 'Months', value: 'month' },
          { text: 'Years', value: 'year' },
        ],
        csvFile: false,
        csvError: [],
        useCSV: true,
      }
    },
    watch: {
      dialServerAll () {
        if (this.dialServerAll === true) {
          this.formData.dialServers = []
          this.dialServers.forEach(server => {
            this.formData.dialServers.push(server.value)
          })
        } else {
          this.formData.dialServers = []
        }
      },
    },
    mounted () {
      this.loadRoutes(this.formData.routeId)
      this.loadServers()
      if (Array.isArray(this.formData.taskACD)) {
        this.formData.taskACD = this.formData.taskACD.join('-')
      }
    },
    methods: {
      handleNewRoute (id) {
        this.loadRoutes(id)
        this.routeForm = false
      },
      onFileChange (f) {
        this.csvFile = f
        if (!this.csvFile) {
          this.csvFile = false
        }
      },
      toBase64 (file) {
        if (this.useCSV === false) {
          return new Promise((resolve) => {
            resolve('')
          })
        }
        return new Promise((resolve, reject) => {
          var reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = () => resolve(reader.result)
          reader.onerror = error => reject(error)
        })
      },
      loadRoutes (setTo) {
        this.routesLoading = true
        authFetch(`${process.env.VUE_APP_API_ROOT}routes/`)
          .then(async response => {
            const items = await response.json()
            this.routesItems = []
            items.forEach(r => {
              let prefix = 'No Prefix'
              if (r.prefix !== '') {
                prefix = `Prefix: ${r.prefix}`
              }
              let labelText = ''
              if (r.label) {
                labelText = `${r.label} - ${r.ip}:${r.port} ${prefix}`
              } else {
                labelText = `${r.ip}:${r.port} ${prefix}`
              }
              this.routesItems.push({
                text: labelText,
                value: r.id,
              })
            })
          })
          .finally(() => {
            this.routesLoading = false
            if (typeof setTo !== 'undefined') {
              this.formData.routeId = setTo
            }
          })
      },
      loadServers () {
        authFetch(`${process.env.VUE_APP_API_ROOT}servers/`)
          .then(async response => {
            const items = await response.json()
            if (typeof items === 'object' && Object.prototype.hasOwnProperty.call(items, 'servers')) {
              items.servers.forEach(server => {
                this.dialServers.push({
                  text: `${server.ip} - ${server.maxCPS} CPS/` +
                    `${server.maxChannels} Ports`,
                  value: server.ip,
                })
                this.formData.dialServers.push(server.ip)
              })
            }
          })
      },
      submit () {
        this.errorDialogText = []
        let valid = true

        let maxSimultaneousCalls = parseInt(this.formData.maxSimultaneousCalls, 10)
        if (maxSimultaneousCalls > 800) {
          maxSimultaneousCalls = 800
          this.formData.maxSimultaneousCalls = 800
        }

        if (this.useCSV === true && this.csvFile === false) {
          this.csvError.push('File is required')
          valid = false
        }

        // Built-in validation
        if (!this.$refs.form.validate() || !valid) {
          this.submitting = false
          return false
        }

        let multiplier = 60
        switch (this.lookbackTimePeriod) {
          case 'hour':
            multiplier = 3600
            break
          case 'day':
            multiplier = 86400
            break
          case 'month':
            multiplier = 2592000
            break
          case 'year':
            multiplier = 31536000
            break
        }

        const postData = {
          routeId: parseInt(this.formData.routeId, 10),
          prefixes: commaSeparatedNumbersToList(this.formData.prefixes),
          altPrefixes: commaSeparatedNumbersToList(this.formData.altPrefixes),
          maxSimultaneousCalls: maxSimultaneousCalls,
          maximumRate: this.formData.maximumRate,
          minimumRetail: this.formData.minimumRetail,
          testMode: this.formData.testMode,
          dialServers: this.formData.dialServers,
          lookbackSeconds: this.lookbackTime * multiplier,
          csvPrefixColumn: this.formData.csvPrefixColumn,
          csvRateColumn: this.formData.csvRateColumn,
          csvNameColumn: this.formData.csvNameColumn,
          csvMaximumRate: this.formData.csvMaximumRate,
          lastSeenCheck: this.formData.lastSeenCheck,
          cliPrefix: this.formData.cliPrefix,
          taskACD: numberRangeToList(this.formData.taskACD),
          csvString: this.formData.csvString,
          taskPDDs: commaSeparatedNumbersToList(this.formData.taskPDDs),
        }

        this.submitting = true
        const method = 'POST'

        this.toBase64(this.csvFile).then(async b64File => {
          postData.csvFile = b64File
          if (postData.csvFile !== '') {
            postData.csvString = ''
          } else {
            postData.maximumRate = 0
          }
          await authFetch(process.env.VUE_APP_API_ROOT + 'tasks/route/', {
            method: method,
            body: JSON.stringify(postData),
          })
            .then(async response => {
              const data = await response.json()
              if (Object.prototype.hasOwnProperty.call(data, 'result') && data.result === 'success') {
                this.$router.push({ name: 'tasks' })
              } else {
                this.errorDialogText = ['Received an unexpected response from the server. Not sure what to do']
                this.valid = true
              }
            })
            .catch(async error => {
              console.log(error)
              try {
                const data = await error.json()
                if (typeof data === 'object' && Object.prototype.hasOwnProperty.call(data, 'message')) {
                  if (typeof data.message === 'object') {
                    this.errorDialogText = data.message
                  } else {
                    this.errorDialogText = [data.message]
                  }
                } else {
                  this.errorDialogText = [`Server returned a ${error.status} status code`]
                }
              } catch (e) {
                if (error.status === undefined) {
                  this.errorDialogText = ['Request blocked by CORS policy']
                } else {
                  this.errorDialogText = [`Server returned a ${error.status} status code`]
                }
              }
              if (this.errorDialogText) {
                this.errorDialog = true
              }
            })
            .finally(() => {
              this.submitting = false
            })
        })
      },
    },
  }
</script>

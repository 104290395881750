<template>
  <layout-panel>
    <v-container>
      <v-card>
        <v-card-text class="display-2 text-center mt-5 primary white--text">
          My Profile
        </v-card-text>
      </v-card>
    </v-container>
    <v-container>
      <v-card>
        <v-card-title>User Settings</v-card-title>
        <v-card-text v-if="userSettingsLoaded">
          <v-switch
            v-model="userSettings.dark"
            label="Dark Theme"
            :loading="userSettingsLoading.dark"
            :disabled="userSettingsLoading.dark"
          />
          <v-switch
            v-model="userSettings.autoStart"
            label="Enable Rubix Robot"
            :loading="userSettingsLoading.autoStart"
            :disabled="userSettingsLoading.autoStart"
          />
        </v-card-text>
        <v-card-text v-else class="text-center">
          <v-progress-circular
            indeterminate
            size="25"
            width="2"
          />
        </v-card-text>
      </v-card>
    </v-container>
    <v-container>
      <v-card>
        <v-card-title>Payment Details</v-card-title>
        <template v-if="userPaymentDetailsLoaded">
          <v-form ref="paymentDetailsForm" @submit.prevent="updatePaymentDetails">
            <v-card-text>
              <v-radio-group v-model="userPaymentDetails.method" row>
                <v-radio label="Wire" value="wire" />
                <v-radio label="Bitcoin" value="btc" />
                <v-radio label="PayPal" value="paypal" />
              </v-radio-group>
              <v-divider class="pb-3" />

              <!-- WIRE -->
              <template v-if="userPaymentDetails.method === 'wire'">
                <v-autocomplete
                  v-model="userPaymentDetails.country"
                  label="Country"
                  :items="allCountries"
                  required
                  :rules="[rules.required]"
                  :loading="countriesLoading"
                  dense
                  validate-on-blur
                  class="pt-3"
                />
                <template v-if="userPaymentDetailsCountrySelected">

                  <!-- SEPA FORM -->
                  <template v-if="userPaymentDetailsIsSEPA">
                    <v-text-field
                      v-model="userPaymentDetails.details.iban"
                      label="IBAN"
                      :rules="[rules.required]"
                      validate-on-blur
                    />
                    <v-text-field
                      v-model="userPaymentDetails.details.recipientName"
                      label="Name on Account"
                      :rules="[rules.required]"
                      validate-on-blur
                    />
                    <v-text-field
                      v-model="userPaymentDetails.details.recipientAddress"
                      label="Address on Account"
                      :rules="[rules.required]"
                      validate-on-blur
                    />
                  </template>
                  <!-- END SEPA FORM -->
                  <!-- US WIRE FORM -->
                  <template v-else-if="userPaymentDetailsIsUS">
                    <v-text-field
                      v-model="userPaymentDetails.details.bankAddress"
                      label="Bank Address"
                      :rules="[rules.required]"
                      validate-on-blur
                    />
                    <v-text-field
                      v-model="userPaymentDetails.details.bankInstituteNumber"
                      label="Bank Institute Number"
                      :rules="[rules.required]"
                      validate-on-blur
                    />
                    <v-text-field
                      v-model="userPaymentDetails.details.transitNumber"
                      label="Transit Number"
                      :rules="[rules.required]"
                      validate-on-blur
                    />
                    <v-text-field
                      v-model="userPaymentDetails.details.accountNumber"
                      label="Account Number"
                      :rules="[rules.required]"
                      validate-on-blur
                    />
                    <v-text-field
                      v-model="userPaymentDetails.details.swift"
                      label="SWIFT Code/BIC"
                      :rules="[rules.required]"
                      validate-on-blur
                    />
                    <v-text-field
                      v-model="userPaymentDetails.details.accountName"
                      label="Account Name"
                      validate-on-blur
                      hint="Optional"
                      persistent-hint
                    />
                    <v-text-field
                      v-model="userPaymentDetails.details.recipientName"
                      label="Recipient Name"
                      :rules="[rules.required]"
                      validate-on-blur
                    />
                    <v-text-field
                      v-model="userPaymentDetails.details.recipientAddress"
                      label="Recipient Address"
                      :rules="[rules.required]"
                      validate-on-blur
                    />
                  </template>
                  <!-- END US WIRE FORM -->
                  <template v-else>
                    Please enter your banking details in the box below
                  </template>
                </template>
              </template>
              <!-- END WIRE -->

              <!-- BITCOIN -->
              <template v-if="userPaymentDetails.method === 'btc'">
                <v-autocomplete
                  v-model="userPaymentDetails.country"
                  label="Country"
                  :items="allCountries"
                  :loading="countriesLoading"
                  dense
                  validate-on-blur
                  class="pt-3"
                />
                <v-alert type="warning">
                  <strong>Please ensure your address is accurate!</strong> Funds
                  <strong>CANNOT</strong> be recovered if sent to an incorrect
                  address, and we will not be able to refund or correct this mistake.
                  You will lose your payment if you enter an incorrect address.
                </v-alert>
                <v-text-field
                  v-model="userPaymentDetails.details.address"
                  label="Bitcoin Address"
                  :rules="[rules.required]"
                  validate-on-blur
                />
              </template>
              <!-- END BITCOIN -->

              <!-- PAYPAL -->
              <template v-if="userPaymentDetails.method === 'paypal'">
                <v-autocomplete
                  v-model="userPaymentDetails.country"
                  label="Country"
                  :items="allCountries"
                  :loading="countriesLoading"
                  dense
                  validate-on-blur
                  class="pt-3"
                />
                <v-text-field
                  v-model="userPaymentDetails.details.email"
                  label="PayPal E-Mail Address"
                  :rules="[rules.required]"
                  validate-on-blur
                />
              </template>
              <!-- END BITCOIN -->

              <v-textarea
                v-model="userPaymentDetails.notes"
                :label="userPaymentDetailsNotesLabel"
                auto-grow
              />
            </v-card-text>
            <v-card-actions>
              <v-btn type="submit" color="primary" block>Save</v-btn>
            </v-card-actions>
          </v-form>
        </template>
        <v-card-text v-else class="text-center">
          <v-progress-circular
            indeterminate
            size="25"
            width="2"
          />
        </v-card-text>
      </v-card>
    </v-container>
    <v-container>
      <v-card>
        <v-card-title>Update Password</v-card-title>
        <v-form ref="form" v-model="formValid" @submit.prevent="submit">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="currentPassword"
                    type="password"
                    label="Current Password"
                    :rules="[rules.required]"
                    validate-on-blur
                    :error-messages="formErrors.currentPassword"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="newPassword"
                    type="password"
                    label="New Password"
                    :rules="[rules.required]"
                    validate-on-blur
                    :error-messages="formErrors.newPassword"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="newPasswordAgain"
                    type="password"
                    label="Repeat New Password"
                    :rules="[rules.required]"
                    validate-on-blur
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn
                    color="primary"
                    type="submit"
                    block
                    :disabled="submitting"
                    :loading="submitting"
                  >Submit</v-btn>
                </v-col>
              </v-row>
              <v-row v-if="changedMessage">
                <v-col>
                  <v-alert
                    v-model="changedMessage"
                    type="success"
                    dismissible
                  >Changed Successfully</v-alert>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-form>
      </v-card>
    </v-container>

  </layout-panel>
</template>

<script>
  import { authFetch } from '@/plugins/authFetch'
  import * as rules from '@/plugins/rules'

  export default {
    data () {
      return {
        rules: rules,
        formValid: false,
        submitting: false,
        formErrors: {},
        currentPassword: null,
        newPassword: null,
        newPasswordAgain: null,
        changedMessage: false,
        userSettings: {},
        userSettingsLoading: {},
        userSettingsLoaded: false,
        userPaymentDetailsLoaded: false,
        userPaymentDetailsSubmitting: false,
        userPaymentDetails: {},
        countriesLoading: false,
        allCountries: [],
      }
    },
    computed: {
      userPaymentDetailsCountry () {
        if (Object.prototype.hasOwnProperty.call(this.userPaymentDetails, 'country') === false) {
          return null
        }
        return this.userPaymentDetails.country
      },
      userPaymentDetailsIsUS () {
        return this.userPaymentDetailsCountry === 'United States/Canada'
      },
      userPaymentDetailsIsSEPA () {
        return ['Albania',
                'Andorra',
                'Austria',
                'Azerbaijan',
                'Bahrain',
                'Belgium',
                'Bosnia and Herzegovina',
                'Brazil',
                'Bulgaria',
                'Costa Rica',
                'Croatia',
                'Cyprus',
                'Czech Republic',
                'Denmark',
                'Dominican Republic',
                'Estonia',
                'Faroe Islands',
                'Finland/Aland Islands',
                'France',
                'French Guiana',
                'French Polynesia',
                'Georgia',
                'Germany',
                'Gibraltar',
                'Greece',
                'Greenland',
                'Guadeloupe',
                'Guatemala',
                'Hungary',
                'Iceland',
                'Ireland',
                'Israel',
                'Italy',
                'Jordan',
                'Kosovo',
                'Kuwait',
                'Latvia',
                'Lebanon',
                'Liechtenstein',
                'Lithuania',
                'Luxembourg',
                'Macedonia',
                'Malta',
                'Martinique',
                'Mauritania',
                'Mauritius',
                'Mayotte/Reunion',
                'Moldova/Transnistria',
                'Monaco',
                'Montenegro',
                'New Caledonia',
                'Norway/Svalbard and Jan Mayen',
                'Pakistan',
                'Palestine',
                'Poland',
                'Portugal',
                'Qatar',
                'Romania',
                'Saint Lucia',
                'Saint Martin/Saint Barthelemy',
                'Saint Pierre and Miquelon',
                'San Marino',
                'Sao Tome and Principe',
                'Saudi Arabia',
                'Serbia',
                'Slovakia',
                'Slovenia',
                'Spain',
                'Sweden',
                'Switzerland',
                'Netherlands',
                'East Timor',
                'Tunisia',
                'Turkey',
                'United Arab Emirates',
                'United Kingdom',
                'British Virgin Islands',
                'Wallis and Futuna'].includes(this.userPaymentDetailsCountry)
      },
      userPaymentDetailsCountrySelected () {
        return this.userPaymentDetailsCountry !== null &&
          this.userPaymentDetailsCountry !== ''
      },
      userPaymentDetailsNotesLabel () {
        if (this.userPaymentDetailsIsUS !== false || this.userPaymentDetailsIsSEPA !== false) {
          return 'Additional Notes'
        }
        return 'Banking Details'
      },
    },
    watch: {
      newPassword () { this.clearNewPasswordError() },
      newPasswordAgain () { this.clearNewPasswordError() },
      'userSettings.dark' (v) {
        if (this.userSettingsLoaded === false) { return }
        this.updateSettings({ dark: v })
          .then(() => {
            this.$store.state.dark = v
          })
      },
      'userSettings.autoStart' (v) {
        if (this.userSettingsLoaded === false) { return }
        this.updateSettings({ autoStart: v })
      },
      userPaymentDetailsCountry () {
        if (this.userPaymentDetails.details === null) {
          this.userPaymentDetails.details = {}
        }
      },
    },
    created () {
      this.getSettings()
      this.getPaymentDetails()
      this.getCountries()
    },
    methods: {
      clearNewPasswordError () {
        if (Object.prototype.hasOwnProperty.call(this.formErrors, 'newPassword')) {
          this.formErrors.newPassword = ''
        }
      },
      getCountries (search) {
        this.countriesLoading = true
        authFetch(`${process.env.VUE_APP_API_ROOT}countries/`)
          .then(async response => {
            this.allCountries = await response.json()
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            this.countriesLoading = false
          })
      },
      getSettings () {
        authFetch(`${process.env.VUE_APP_API_ROOT}user/settings/`)
          .then(async response => {
            const data = await response.json()
            for (const [k, v] of Object.entries(data)) {
              this.$set(this.userSettings, k, v)
              this.$set(this.userSettingsLoading, k, false)
            }
            this.$nextTick(() => {
              this.userSettingsLoaded = true
            })
          })
          .catch(async error => {
            console.log(error)
          })
      },
      updateSettings (obj) {
        return new Promise((resolve, reject) => {
          const postData = {}
          for (const [k, v] of Object.entries(obj)) {
            if (Object.prototype.hasOwnProperty.call(this.userSettings, k)) {
              postData[k] = v
              this.$set(this.userSettingsLoading, k, true)
            }
          }
          if (Object.keys(postData).length === 0) { resolve() }
          authFetch(`${process.env.VUE_APP_API_ROOT}user/settings/`, {
            method: 'PUT',
            body: JSON.stringify({ settings: postData }),
          })
            .then(async response => {
              await response.json()
              resolve()
            })
            .catch(async error => {
              console.log(error)
              reject(error)
            })
            .finally(() => {
              Object.keys(postData).forEach(k => {
                this.$set(this.userSettingsLoading, k, false)
              })
            })
        })
      },
      getPaymentDetails () {
        authFetch(`${process.env.VUE_APP_API_ROOT}user/payment-details/`)
          .then(async response => {
            this.userPaymentDetails = await response.json()
            if (this.userPaymentDetails.method === null) {
              this.userPaymentDetails.method = 'wire'
            }
            this.$nextTick(() => {
              this.userPaymentDetailsLoaded = true
            })
          })
          .catch(async error => {
            console.log(error)
          })
      },
      updatePaymentDetails () {
        if (!this.$refs.paymentDetailsForm.validate()) {
          return false
        }
        this.userPaymentDetailsSubmitting = true
        authFetch(`${process.env.VUE_APP_API_ROOT}user/payment-details/`, {
          method: 'PUT',
          body: JSON.stringify(this.userPaymentDetails),
        })
          .then(async response => {
            await response.json()
          })
          .catch(async error => {
            console.log(error)
          })
          .finally(() => {
            this.userPaymentDetailsSubmitting = false
          })
      },
      submit () {
        this.changedMessage = false
        this.submitting = true
        this.formErrors = {}
        let error = false
        if (!this.$refs.form.validate()) {
          error = true
        }

        if (this.newPassword && this.newPasswordAgain && this.newPassword !== this.newPasswordAgain) {
          this.formErrors.newPassword = 'Passwords don\'t match'
          error = true
        } else if (this.newPassword === this.currentPassword) {
          this.formErrors.newPassword = 'Must be different than current password'
          error = true
        }

        if (error === true) {
          this.submitting = false
          return
        }

        const postData = {
          currentPassword: this.currentPassword,
          newPassword: this.newPassword,
        }
        authFetch(`${process.env.VUE_APP_API_ROOT}user/settings/password/`, {
          method: 'PUT',
          body: JSON.stringify(postData),
        })
          .then(async response => {
            await response.json()
            this.currentPassword = null
            this.newPasswordAgain = null
            this.newPassword = null
            this.changedMessage = true
          })
          .catch(async error => {
            console.log(error)
            const data = await error.json()
            if (typeof data === 'object' && Object.prototype.hasOwnProperty.call(data, 'message')) {
              if (typeof data.message === 'object') {
                for (const [k, v] of Object.entries(data.message)) {
                  this.formErrors[k] = v
                }
              }
            }
          })
          .finally(() => {
            this.submitting = false
          })
      },
    },
  }
</script>

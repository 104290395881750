<template>
  <layout-panel>
    <v-container>
      <v-card>
        <v-card-text class="display-2 text-center mt-5 primary white--text">
          Inbound CDR
        </v-card-text>
      </v-card>
    </v-container>
    <v-container>
      <v-card>
        <v-container>
          <v-row>
            <v-col cols="3">
              <v-menu
                v-model="startMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="start"
                    label="Start"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="start"
                  no-title
                  scrollable=""
                  @input="startMenu = false"
                />
              </v-menu>
            </v-col>
            <v-col cols="3" offset="1">
              <v-menu
                v-model="endMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="end"
                    label="End"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="end"
                  no-title
                  scrollable=""
                  @input="endMenu = false"
                />
              </v-menu>
            </v-col>
            <v-col cols="4" offset="1">
              <v-btn color="primary" :loading="loading" @click="getData()">
                Get Records
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-container>
    <v-container>
      <v-alert
        v-if="initial === true && loading === false && items.length === 0"
        :value="true"
        color="error"
        icon="warning"
      >
        No data avaialable
      </v-alert>
    </v-container>
    <v-container v-if="items.length > 0">
      <v-card>
        <v-data-table
          :items="items"
          :headers="headers"
          :footer-props="{ itemsPerPageOptions: [50,100,{'text':'All',value:-1}] }"
          :items-per-page="50"
        >
          <template v-slot:item="row">
            <tr>
              <td>{{ row.item.calldate }}</td>
              <td>{{ row.item.src }}</td>
              <td>{{ row.item.carrier }}</td>
              <td>{{ row.item.dst }}</td>
              <td>{{ row.item.dstCarrier }}</td>
              <td>{{ row.item.disposition }}</td>
              <td>{{ row.item.billsec }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </layout-panel>
</template>

<script>
  import { authFetch, objectToQueryString } from '@/plugins/authFetch'
  import { validateDate } from '@/plugins/validators'

  export default {
    props: {
      startProp: { type: String, required: true },
      endProp: { type: String, required: true },
    },
    data () {
      return {
        start: this.startProp,
        startMenu: false,
        end: this.endProp,
        endMenu: false,
        items: [],
        loading: false,
        initial: false,
        headers: [
          { text: 'Date', value: 'calldate' },
          { text: 'Source', value: 'src' },
          { text: 'Source Carrier', value: 'carrier' },
          { text: 'Destination', value: 'dst' },
          { text: 'Destination Carrier', value: 'dstCarrier' },
          { text: 'Disposition', value: 'disposition' },
          { text: 'Duration', value: 'billsec' },
        ],
      }
    },
    created () {
      if (!validateDate(this.start)) {
        this.start = new Date().toISOString().substr(0, 10)
      }
      if (!validateDate(this.end)) {
        const tomorrow = new Date()
        tomorrow.setDate(tomorrow.getDate() + 1)
        this.end = tomorrow.toISOString().substr(0, 10)
      }
    },
    methods: {
      getData () {
        const queryString = objectToQueryString({
          start: this.start,
          end: this.end,
        })
        this.initial = true
        this.loading = true
        this.items = []
        authFetch(process.env.VUE_APP_API_ROOT + `cdr/incoming/?${queryString}`)
          .then(async response => {
            this.items = await response.json()
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.loading = false
          })
      },
    },
  }
</script>

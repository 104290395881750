<template>
  <layout-panel>
    <v-container>
      <v-card>
        <v-card-text class="display-2 text-center mt-5 primary white--text">
          Manage Sessions
        </v-card-text>
      </v-card>
    </v-container>
    <v-container>
      <v-card>
        <v-card-title class="pt-5">Current Session</v-card-title>
        <v-card-text>
          <v-data-table
            :items="currentArray"
            :headers="currentHeaders"
            :loading="currentLoading"
            hide-default-footer
            :items-per-page="-1"
          >
            <template v-slot:item.id="row">
              {{ truncateId(row.item.id) }}
            </template>
            <template v-slot:item.persist="row">
              {{ translatePersist(row.item.persist) }}
            </template>
            <template v-slot:item.actions="row">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="primary"
                    icon
                    small
                    :disabled="row.item.loading"
                    :loading="row.item.loading"
                    v-on="on"
                    @click.stop="submit(row.item.id)"
                  >
                    <v-icon>clear</v-icon>
                  </v-btn>
                </template>
                <span>Destroy Session</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-title class="pt-5">Valid Sessions</v-card-title>
        <v-card-text>
          <v-btn
            color="primary"
            :disabled="itemsLoading"
            @click="submit('all')"
          >Clear All</v-btn>
        </v-card-text>
        <v-card-text>
          <v-data-table
            :items="itemsArray"
            :headers="headers"
            :footer-props="{
              itemsPerPageOptions: [10, 50, 100]
            }"
            :options.sync="options"
            :server-items-length="total"
            :sort-by="sortBy"
            :sort-desc="descending"
          >
            <template v-slot:item.id="row">
              {{ truncateId(row.item.id) }}
            </template>
            <template v-slot:item.persist="row">
              {{ translatePersist(row.item.persist) }}
            </template>
            <template v-slot:item.actions="row">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="primary"
                    icon
                    small
                    :disabled="row.item.loading"
                    :loading="row.item.loading"
                    v-on="on"
                    @click.stop="submit(row.item.id)"
                  >
                    <v-icon>clear</v-icon>
                  </v-btn>
                </template>
                <span>Destroy Session</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </layout-panel>
</template>

<script>
  import { authFetch, objectToQueryString } from '@/plugins/authFetch'
  export default {
    data: () => ({
      current: {},
      currentLoading: false,
      currentHeaders: [
        { text: 'ID', value: 'id' },
        { text: 'Last Used', value: 'lastRequest' },
        { text: 'Last IP', value: 'ip' },
        { text: 'Persistent', value: 'persist' },
        { text: 'Actions', value: 'actions' },
      ],
      items: {},
      itemsLoading: false,
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Last Used', value: 'lastRequest' },
        { text: 'Last IP', value: 'ip' },
        { text: 'Persistent', value: 'persist' },
        { text: 'Actions', value: 'actions' },
      ],
      options: {},
      sortBy: 'lastRequest',
      descending: true,
      total: 0,
    }),
    computed: {
      itemsArray () {
        const result = []
        Object.values(this.items).forEach(r => {
          result.push(r)
        })
        return result
      },
      currentArray () {
        const result = []
        Object.values(this.current).forEach(r => {
          result.push(r)
        })
        return result
      },
    },
    mounted () {
      this.getCurrent()
      this.getData()
    },
    methods: {
      truncateId (id) {
        return `${id.substring(0, 10)}...`
      },
      translatePersist (bool) {
        return bool === true ? 'Yes' : 'No'
      },
      getCurrent () {
        this.currentLoading = true
        authFetch(process.env.VUE_APP_API_ROOT + 'sessions/current/')
          .then(async response => {
            const items = await response.json()
            items.forEach(r => {
              r.loading = false
              this.$set(this.current, r.id, r)
            })
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            this.currentLoading = false
          })
      },
      getData () {
        this.loading = true
        const query = objectToQueryString({
          itemsPerPage: this.options.itemsPerPage,
          page: this.options.page,
          sortBy:
            this.options.sortBy.length === 0
              ? this.sortBy
              : this.options.sortBy[0],
          sortDesc:
            this.options.sortDesc.length === 0
              ? this.descending === true
                ? 1
                : 0
              : this.options.sortDesc[0] === true
                ? 1
                : 0,
        })
        authFetch(process.env.VUE_APP_API_ROOT + `sessions/?${query}`)
          .then(async response => {
            const data = await response.json()
            this.total = data.total
            data.items.forEach(r => {
              r.loading = false
              this.$set(this.items, r.id, r)
            })
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            this.paidLoading = false
          })
      },
      submit (id) {
        const isCurrent = false
        const postData = {}
        if (id === 'all') {
          postData.id = 0
          postData.all = true
        } else {
          postData.id = id
          if (Object.prototype.hasOwnProperty.call(this.current, id) === true) {
            this.current[id].loading = true
            this.isCurrent = true
          } else {
            this.items[id].loading = true
          }
        }
        authFetch(process.env.VUE_APP_API_ROOT + 'sessions/', {
          method: 'DELETE',
          body: JSON.stringify(postData),
        })
          .then(async response => {
            await response.json()
            if (isCurrent === true) {
              this.$router.push({ name: 'login', params: { logout: true } })
            } else {
              this.items = {}
              this.getData()
            }
          })
          .catch(async error => {
            console.log(error)
            this.pending[id].loading = false
          })
      },
    },
  }
</script>

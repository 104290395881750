<template>
  <v-form ref="form" @submit.prevent="">
    <v-row>
      <v-col>
        <v-autocomplete
          v-model="rateListValue"
          :items="rateList"
          label="Country / Carrier Code"
          :error-messages="prefixErrorSelect"
          :rules="[notNull]"
        />
      </v-col>
      <v-col v-if="showCustomValues" cols="2">
        <v-text-field
          v-model="prefix"
          label="Prefix"
          :error-messages="prefixErrorText"
        />
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="routeId"
          :items="routes"
          label="Route"
          :error-messages="errors.route"
          :rules="[rules.required]"
        />
      </v-col>
      <v-col class="d-flex align-center" cols="2">
        <v-text-field
          v-if="showCustomValues"
          v-model="rate"
          label="Rate"
          :error-messages="errors.rate"
        />
        <span v-else>
          Rate:
          <span v-if="rateListValue !== null">
            ${{ rates[rateListValue].rate }}
          </span>
          <em v-else>Select Coutry Code for rate</em>
        </span>
      </v-col>
      <v-col cols="1">
        <v-btn color="primary" fab @click="remove()">
          <v-icon>clear</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2">
        <v-text-field
          ref="acd"
          v-model="acd"
          :rules="[rules.required, rules.positiveRange]"
          required
          label="ACD Required"
          hint="Range"
        >
          <template v-slot:append>
            <v-tooltip bottom max-width="400">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">help</v-icon>
              </template>
              Setting the ACD is setting the average duration of the
              outgoing calls, this is a requirement that will change
              between routes.
            </v-tooltip>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field
          v-model="channels"
          :rules="[rules.required, rules.positiveNumber]"
          required
          label="Max Channels"
          type="number"
          :hint="`Max ${dialServerMax.channels}`"
          min="1"
          :max="dialServerMax.channels"
        />
      </v-col>
      <v-col cols="2">
        <v-text-field
          v-model="cps"
          :rules="[rules.required, rules.positiveRange]"
          required
          label="Max Calls per Interval"
          :hint="`Max ${dialServerMax.cps}`"
        />
      </v-col>
      <v-col cols="2">
        <v-text-field
          v-model="interval"
          :rules="[rules.required, rules.positiveRange]"
          required
          label="Max Calls Interval"
          hint="Range"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-switch
          v-model="autoPDD"
          label="Automatic ASR Adjust"
        >
          <template v-slot:append>
            <v-tooltip bottom max-width="400">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">help</v-icon>
              </template>
              Enter the minimum and maximum ASR that you want
              to run on this route and the platform will try
              its best to acheive a comfortable number between
              the entered values. If it cannot reach a number
              between the values entered, the task will eventually
              be automatically stopped.
            </v-tooltip>
          </template>
        </v-switch>
      </v-col>
      <template v-if="autoPDD === true">
        <v-col cols="2">
          <v-text-field
            v-model="minASR"
            :rules="[rules.required, rules.positiveNumber]"
            required
            label="Min ASR"
            type="number"
          />
        </v-col>
        <v-col cols="2">
          <v-text-field
            v-model="maxASR"
            :rules="[rules.required, rules.positiveNumber]"
            required
            label="Max ASR"
            type="number"
          />
        </v-col>
      </template>
      <template v-else>
        <v-col cols="2">
          <v-text-field
            v-model="pdds"
            :rules="[rules.required, rules.positiveCommaSepNumbers]"
            validate-on-blur
            required
            label="PDDs (comma separated)"
          />
        </v-col>
      </template>
    </v-row>
    <v-divider />
  </v-form>
</template>

<script>
  import * as rules from '@/plugins/rules'
  import {
    defaultDialServerMaximums,
    commaSeparatedNumbersToList,
    numberRangeToList,
  } from '@/plugins/common'

  export default {
    props: {
      index: { type: String, default: null },
      value: {
        type: Object,
        default () {
          return {
            routeId: null,
            prefix: null,
            rate: null,
            pddLock: false,
            cps: null,
            interval: null,
            channels: null,
            minASR: null,
            maxASR: null,
            pdds: null,
            acd: null,
          }
        },

      },
      errors: {
        type: Object,
        default () {
          return {
            route: '',
            prefix: '',
            rate: '',
            cps: '',
            interval: '',
            channels: '',
            minASR: '',
            maxASR: '',
            pdds: '',
            acd: '',
          }
        },
      },
      routes: { type: Array, required: true },
      rates: { type: Object, required: true },
    },
    data () {
      return {
        routeId: this.value.routeId,
        prefix: this.prefix,
        rate: this.value.rate,
        cps: this.value.cps,
        interval: this.value.interval,
        channels: this.value.channels,
        minASR: this.value.minASR,
        maxASR: this.value.maxASR,
        pdds: this.value.pdds,
        acd: this.value.acd,
        rateListValue: null,
        rules: rules,
        dialServerMax: defaultDialServerMaximums,
        mounted: false,
      }
    },
    computed: {
      showCustomValues () {
        return this.rateListValue === false
      },
      rateList () {
        const result = []
        Object.keys(this.rates).forEach(prefix => {
          result.push({
            text: `${prefix} - ${this.rates[prefix].name}`,
            value: prefix,
          })
        })
        result.push({
          text: 'Custom Country Code',
          value: false,
        })
        return result
      },
      prefixErrorSelect () {
        if (!this.errors.prefix) {
          return ''
        } else if (this.showCustomValues === true) {
          return ''
        }
        return this.errors.prefix
      },
      prefixErrorText () {
        if (!this.errors.prefix) {
          return ''
        } else if (this.showCustomValues === false) {
          return ''
        }
        return this.errors.prefix
      },
      autoPDD: {
        get () {
          return !this.value.pddLock
        },
        set (value) {
          this.value.pddLock = !value
        },
      },
    },
    watch: {
      routeId (val) {
        this.value.routeId = val
        this.$emit('input', this.value)
      },
      rateListValue (val) {
        if (this.showCustomValues === true) {
          if (this.mounted === true) {
            this.prefix = null
            this.rate = null
          }
        } else {
          this.prefix = this.rateListValue
          this.rate = this.rates[this.prefix].rate
        }
      },
      prefix (val) {
        this.errors.prefix = ''
        this.value.prefix = val
        this.$emit('input', this.value)
      },
      rate (val) {
        this.errors.rate = ''
        this.value.rate = val
        this.$emit('input', this.value)
      },
      cps (val) {
        this.errors.cps = ''
        this.value.cps = numberRangeToList(val)
        this.$emit('input', this.value)
      },
      interval (val) {
        this.errors.interval = ''
        this.value.interval = numberRangeToList(val)
        console.log(this.value)
        this.$emit('input', this.value)
      },
      channels (val) {
        this.errors.channels = ''
        this.value.channels = val
        this.$emit('input', this.value)
      },
      minASR (val) {
        this.errors.minASR = ''
        this.value.minASR = val
        this.$emit('input', this.value)
      },
      maxASR (val) {
        this.errors.maxASR = ''
        this.value.maxASR = val
        this.$emit('input', this.value)
      },
      pdds (val) {
        this.errors.pdds = ''
        this.value.pdds = commaSeparatedNumbersToList(val)
        this.$emit('input', this.value)
      },
      acd (val) {
        this.errors.acd = ''
        this.value.acd = numberRangeToList(val)
        this.$emit('input', this.value)
      },
    },
    mounted () {
      if (this.value.prefix === null) {
        return
      }
      if (Object.prototype.hasOwnProperty.call(this.rates, this.value.prefix)) {
        this.prefix = this.value.prefix
        this.rateListValue = this.value.prefix
      } else {
        this.prefix = this.value.prefix
        this.rateListValue = false
      }
      if (Array.isArray(this.pdds)) {
        this.pdds = this.pdds.join(', ')
      }
      if (Array.isArray(this.acd)) {
        this.acd = this.acd.join('-')
      }
      if (Array.isArray(this.interval)) {
        this.interval = this.interval.join('-')
      }
      if (Array.isArray(this.cps)) {
        this.cps = this.cps.join('-')
      }
      this.$nextTick(() => {
        this.mounted = true
      })
    },
    methods: {
      remove () {
        this.$emit('remove', this.index)
      },
      notNull (val) {
        return val !== null || 'Required'
      },
      validate () {
        let valid = true
        if (this.$refs.form.validate() === false) {
          valid = false
        }

        if (this.rate) {
          this.rate = parseFloat(this.rate)
          if (this.rate <= 0) {
            this.errors.rate = 'Must be greater than 0'
            valid = false
          }
        } else {
          this.errors.rate = 'Required'
          valid = false
        }

        if (this.showCustomValues && !this.prefix) {
          this.errors.prefix = 'Required'
        }

        if (valid === true) {
          if (this.autoPDD === true) {
            this.value.pdds = [18, 19, 20]
          } else {
            this.value.minASR = 7
            this.value.maxASR = 14
          }
        }

        if (this.maxChannels > this.dialServerMax.channels) {
          this.maxChannels = this.dialServerMax.channels
        }
        if (this.maxCPS > this.dialServerMax.cps) {
          this.maxCPS = this.dialServerMax.cps
        }

        return valid
      },
    },
  }

</script>

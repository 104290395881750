<template>
  <layout-panel>
    <v-container>
      <v-card>
        <v-card-text class="display-2 text-center mt-5 primary white--text">
          Custom SIP Server
        </v-card-text>
      </v-card>
    </v-container>
    <v-container>
      <v-card>
        <v-card-title>Request SIP Server Change</v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="formValid" @submit.prevent="submit">
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="ip"
                    label="IP"
                    :rules="[rules.required, rules.ip]"
                    validate-on-blur
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="port"
                    label="Port"
                    type="number"
                    :rules="[rules.required, rules.port]"
                    validate-on-blur
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn
                    color="primary"
                    type="submit"
                    block
                    :disabled="submitting"
                    :loading="submitting"
                  >Submit</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>

      <v-card>
        <v-card-title>Most Recent Approvals</v-card-title>
        <v-card-text v-if="recentLoading === true">
          <v-progress-circular
            :size="25"
            :width="3"
            indeterminate
          />
        </v-card-text>
        <v-card-text v-else-if="recent.length === 0">
          <em>Nothing to display</em>
        </v-card-text>
        <v-card-text v-else>
          <v-list-item v-for="(i, k) in recent" :key="`recent_${k}`">
            {{ i.date }} - {{ i.ip }}:{{ i.port }}
          </v-list-item>
        </v-card-text>

        <v-card-title>Pending Requests</v-card-title>
        <v-card-text v-if="pendingLoading === true">
          <v-progress-circular
            :size="25"
            :width="3"
            indeterminate
          />
        </v-card-text>
        <v-card-text v-else-if="pending.length === 0">
          <em>Nothing to display</em>
        </v-card-text>
        <v-card-text v-else>
          <v-list-item v-for="(i, k) in pending" :key="`pending_${k}`">
            {{ i.date }} - {{ i.ip }}:{{ i.port }}
          </v-list-item>
        </v-card-text>
      </v-card>
    </v-container>
  </layout-panel>
</template>

<script>
  import { authFetch } from '@/plugins/authFetch'
  import * as rules from '@/plugins/rules'

  export default {
    data () {
      return {
        rules: rules,
        formValid: false,
        ip: null,
        port: null,
        recent: [],
        recentLoading: false,
        pending: [],
        pendingLoading: false,
        submitting: false,
        formErrors: [],
      }
    },
    created () {
      this.getData()
    },
    methods: {
      getData () {
        this.pendingLoading = true
        this.recentLoading = true
        authFetch(process.env.VUE_APP_API_ROOT + 'ivr/sip/')
          .then(async response => {
            const items = await response.json()
            this.pending = items.pending
            this.recent = items.completed
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.pendingLoading = false
            this.recentLoading = false
          })
      },
      submit () {
        this.formErrors = []
        if (!this.$refs.form.validate()) {
          return false
        }
        this.submitting = true
        const postData = {
          ip: this.ip,
          port: parseInt(this.port, 10),
        }
        authFetch(process.env.VUE_APP_API_ROOT + 'ivr/sip/', {
          method: 'POST',
          body: JSON.stringify(postData),
        })
          .then(async response => {
            await response.json()
            this.ip = null
            this.port = null
            this.getData()
          })
          .catch(async error => {
            console.log(error)
            const data = await error.json()
            if (typeof data === 'object' && Object.prototype.hasOwnProperty.call(data, 'message')) {
              // Is it an object we can loop through for feedback?
              if (typeof data.message === 'object') {
                Object.values(data.message).forEach(msg => {
                  this.formErrors.push(msg)
                })
                // String returned, setup dialog
              } else {
                this.formErrors = [data.message]
              }
              // Generic error if we didn't get a proper response
            } else {
              this.formErrors = [`Server returned a ${error.status} status code`]
            }
          })
          .finally(() => {
            this.submitting = false
          })
      },
    },
  }
</script>

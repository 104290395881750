<template>
  <layout-panel>
    <v-container>
      <v-card>
        <v-card-text class="display-2 text-center mt-5 primary white--text">
          Request Payment
        </v-card-text>
      </v-card>
    </v-container>
    <v-container>
      <v-card>
        <v-card-title>
          Make Payment Request
        </v-card-title>
        <v-card-text class="text-center">
          <v-file-input
            v-if="submitDisabled === false"
            label="Invoice"
            required
            :error="fileError.length > 0"
            :error-messages="fileError"
            accept=".pdf"
            show-size
            hint="PDF Only, Max file size: 20MB"
            persistent-hint
            @change="onFileChange"
          />
          <v-btn
            color="primary"
            :disabled="submitDisabled"
            :loading="submitting"
            class="mb-5"
            x-large=""
            @click="submit"
          >Make Request</v-btn>
          <div v-if="submitError" class="pb-5 red--text">
            Error making request: {{ submitError }}
          </div>
          <div v-if="nextPayment">
            Your next payment request can be made on {{ nextPayment }} UTC
          </div>
          <div v-else-if="pendingLoading === true">
            Balance loading...
          </div>
          <v-container v-else fluid>
            <v-row justify="center">
              <v-col class="xs" sm="10" md="6" lg="4" xl="2">
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                      <template v-if="balance.fees && Object.keys(balance.fees).length > 0">
                        <tr>
                          <td class="text-right">Base Amount:</td>
                          <td class="text-left font-weight-bold">
                            {{ toMoney(balance.totalBefore) }}
                          </td>
                        </tr>
                        <tr v-for="(v, k) in balance.fees" :key="k">
                          <td class="text-right">{{ k }}</td>
                          <td class="text-left font-weight-bold">-{{ toMoney(v) }}</td>
                        </tr>
                      </template>
                      <tr>
                        <td class="text-right">Your current balance for this period:</td>
                        <td class="text-left font-weight-bold">
                          {{ toMoney(balance.total) }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-title class="pt-5">Pending Payments</v-card-title>
        <v-card-text>
          <v-data-table
            :items="pending"
            :headers="pendingHeaders"
            :loading="pendingLoading"
            hide-default-footer
            :items-per-page="-1"
          >
            <template v-slot:item.amount="row">
              {{ toMoney(row.item.amount) }}
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-title class="pt-5">Previous Payments</v-card-title>
        <v-card-text>
          <v-data-table
            :items="paid"
            :headers="paidHeaders"
            :footer-props="{
              itemsPerPageOptions: [10, 50, 100]
            }"
            :options.sync="paidOptions"
            :server-items-length="paidTotal"
            :sort-by="paidSortBy"
            :sort-desc="paidDescending"
          >
            <template v-slot:item.amount="row">
              {{ toMoney(row.item.amount) }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </layout-panel>
</template>

<script>
  import { authFetch, objectToQueryString } from '@/plugins/authFetch'
  import { toMoney } from '@/plugins/common'
  export default {
    data: () => ({
      paid: [],
      paidLoading: false,
      paidHeaders: [
        { text: 'Date Requested', value: 'added' },
        { text: 'Period Start', value: 'start' },
        { text: 'Period End', value: 'end' },
        { text: 'Amount Paid', value: 'amount' },
        { text: 'Date Paid', value: 'paid' },
      ],
      paidOptions: {},
      paidTotal: 0,
      paidSortBy: 'end',
      paidDescending: true,
      pending: [],
      pendingLoading: false,
      pendingHeaders: [
        { text: 'Date Requested', value: 'added' },
        { text: 'Period Start', value: 'start' },
        { text: 'Period End', value: 'end' },
        { text: 'Amount Due', value: 'amount' },
      ],
      nextPayment: false,
      submitting: false,
      balance: { total: 0 },
      submitError: false,
      file: false,
      fileError: [],
    }),
    computed: {
      submitDisabled () {
        return this.nextPayment !== false || this.submitting === true || this.balance.total === 0
      },
    },
    mounted () {
      this.getPending()
      this.getPaid()
    },
    methods: {
      onFileChange (f) {
        this.file = f
        if (!this.file) {
          this.file = false
          return
        }
        if (this.file !== false && this.file.size > 20000000) {
          this.fileError.push('File cannot be larger than 20MB')
          this.file = false
          return
        }
        this.fileError = []
      },
      toBase64 (file) {
        return new Promise((resolve, reject) => {
          var reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = () => resolve(reader.result)
          reader.onerror = error => reject(error)
        })
      },
      toMoney (v) {
        return toMoney(v)
      },
      getPending () {
        this.pendingLoading = true
        authFetch(process.env.VUE_APP_API_ROOT + 'payment-requests/pending/')
          .then(async response => {
            const data = await response.json()
            this.pending = data.items
            this.nextPayment = data.nextSubmit
            this.balance = data.balance
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            this.pendingLoading = false
          })
      },
      getPaid () {
        this.paidLoading = true
        const query = objectToQueryString({
          itemsPerPage: this.paidOptions.itemsPerPage,
          page: this.paidOptions.page,
          sortBy:
            this.paidOptions.sortBy.length === 0
              ? this.paidSortBy
              : this.paidOptions.sortBy[0],
          sortDesc:
            this.paidOptions.sortDesc.length === 0
              ? this.paidDescending === true
                ? 1
                : 0
              : this.paidOptions.sortDesc[0] === true
                ? 1
                : 0,
        })
        authFetch(process.env.VUE_APP_API_ROOT + `payment-requests/?${query}`)
          .then(async response => {
            const data = await response.json()
            this.paidTotal = data.total
            this.paid = data.items
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            this.paidLoading = false
          })
      },
      submit () {
        this.submitError = false
        this.fileError = []
        if (this.file === false) {
          this.fileError.push('Invoice is required to submit payment')
          return false
        }
        this.submitting = true

        this.toBase64(this.file).then(async b64file => {
          await authFetch(`${process.env.VUE_APP_API_ROOT}payment-requests/`, {
            method: 'POST',
            body: JSON.stringify({
              invoice: b64file,
            }),
          })
            .then(async response => {
              await response.json()
              this.file = false
              this.getPending()
            })
            .catch(async error => {
              console.log(error)
              const data = await error.json()
              if (Object.prototype.hasOwnProperty.call(data, 'message')) {
                this.submitError = data.message
              }
            })
            .finally(() => {
              this.submitting = false
            })
        })
      },
    },
  }
</script>

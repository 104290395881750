<template>
  <v-container>
    <v-row>
      <v-col cols="2">
        <v-select
          v-model="type"
          :items="types"
          :error-messages="typeError"
          label="Filter On"
          @change="string = null"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-if="type === 'prefix'"
          v-model="string"
          :error-messages="errors.stringError"
          label="Prefix"
        />
        <v-autocomplete
          v-else-if="type === 'carrier'"
          v-model="string"
          :error-messages="errors.stringError"
          :items="carriers"
          :loading="carriersLoading.length > 0"
          :search-input.sync="carriersSearch"
          label="Carrier"
        />
        <v-autocomplete
          v-else-if="type === 'country'"
          v-model="string"
          :error-messages="errors.stringError"
          :items="countries"
          :loading="countriesLoading.length > 0"
          :search-input.sync="countriesSearch"
          label="Country"
        />
        <v-autocomplete
          v-else-if="type === 'carrierAll'"
          v-model="string"
          :error-messages="errors.stringError"
          :items="carriersAll"
          :loading="carriersAllLoading.length > 0"
          :search-input.sync="carriersAllSearch"
          label="Carrier"
        />
      </v-col>
      <v-col cols="1">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              fab
              :disabled="loading"
              :loading="loading"
              v-on="on"
              @click="getCounts()"
            >
              <v-icon>sync</v-icon>
            </v-btn>
          </template>
          Get Number Count
        </v-tooltip>
      </v-col>
      <v-col cols="3">
        <div class="mt-5">
          <span v-if="count !== null">Available Numbers: {{ count }}</span>
          <span v-if="countError !== false">Error when grabbing counts</span>
        </div>
      </v-col>
      <v-col v-if="useRates" cols="1">
        <v-text-field
          v-model="rate"
          :error-messages="errors.rateError"
          label="$ Rate"
        />
      </v-col>
      <v-col cols="1">
        <v-btn fab color="primary" @click="remove()">
          <v-icon>clear</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { authFetch, objectToQueryString } from '@/plugins/authFetch'
  export default {
    props: {
      index: { type: String, default: null },
      value: {
        type: Object,
        default () {
          return {
            type: 'country',
            string: '',
            rate: '',
          }
        },
      },
      errors: {
        type: Object,
        default () {
          return {
            stringError: '',
            rateError: '',
          }
        },
      },
      lookbackType: { type: String, default: 'time' },
      lookbackData: { type: String, default: '1 day' },
      useRates: { type: Boolean, default: true },
    },
    data () {
      return {
        type: this.value.type,
        string: this.value.string,
        rate: this.value.rate,
        types: [
          { text: 'Carrier', value: 'carrier' },
          // { text: 'Carrier Prefix', value: 'prefix' },
          { text: 'Country', value: 'country' },
          { text: 'Carrier ALL', value: 'carrierAll' },
        ],
        loading: false,
        typeError: '',
        carriers: [],
        carriersLoading: [],
        carriersSearch: null,
        carriersLoaded: [],
        countries: [],
        countriesLoading: [],
        countriesSearch: null,
        countriesLoaded: [],
        count: null,
        countError: false,
        carriersAll: [],
        carriersAllLoading: [],
        carriersAllSearch: null,
        carriersAllLoaded: [],
      }
    },
    watch: {
      type (val) {
        this.typeError = ''
        this.value.type = val
        this.$emit('input', this.value)
      },
      string (val) {
        this.errors.stringError = ''
        this.value.string = val
        this.$emit('input', this.value)
      },
      rate (val) {
        this.errors.rate = ''
        this.value.rate = val
        this.$emit('input', this.value)
      },
      carriersSearch (val) {
        if (val === null || val.length < 2) {
          return
        }
        const lower = val.substring(0, 2).toLowerCase()
        if (this.carriersLoaded.indexOf(lower) !== -1) {
          return
        }
        this.carriersLoaded.push(lower)
        this.getCarriers(lower)
      },
      countriesSearch (val) {
        if (val === null || val.length < 2) {
          return
        }
        const lower = val.substring(0, 2).toLowerCase()
        if (this.countriesLoaded.indexOf(lower) !== -1) {
          return
        }
        this.countriesLoaded.push(lower)
        this.getCountries(lower)
      },
      carriersAllSearch (val) {
        if (val === null || val.length < 2) {
          return
        }
        const lower = val.substring(0, 2).toLowerCase()
        if (this.carriersAllLoaded.indexOf(lower) !== -1) {
          return
        }
        this.carriersAllLoaded.push(lower)
        this.getCarriersAll(lower)
      },
    },
    mounted () {
      if (this.value.string) {
        switch (this.value.type) {
          case 'carrier':
            this.carriers.push(this.value.string)
            break
          case 'country':
            this.countries.push(this.value.string)
            break
          case 'carrierAll':
            this.carriersAll.push(this.value.string)
            break
        }
      }
    },
    methods: {
      remove () {
        this.$emit('removeWhitelist', this.index)
      },
      getCounts () {
        if (!this.types.some(type => type.value === this.type)) {
          this.typeError = 'Invalid selection'
          return
        }
        if (!this.string) {
          this.errors.stringError = 'Required'
          return
        }
        if (!this.lookbackData) {
          if (this.lookbackType === 'time') {
            this.lookbackData = '1 day'
          } else {
            this.lookbackData = 1000
          }
        }
        this.loading = true
        this.count = null
        this.countError = false
        const queryString = objectToQueryString({
          string: this.string,
          lookbackType: this.lookbackType,
          data: this.lookbackData,
        })
        authFetch(process.env.VUE_APP_API_ROOT + `hlr/counts/${this.type}/?${queryString}`)
          .then(async response => {
            const data = await response.json()
            this.count = data.count
          })
          .catch((error) => {
            console.log(error)
            this.countError = 'Error grabbing from server'
          })
          .finally(() => {
            this.loading = false
          })
      },
      getCarriers (search) {
        const queryString = objectToQueryString({
          search: search,
        })
        this.carriersLoading.push(search)
        authFetch(process.env.VUE_APP_API_ROOT + `carriers/?${queryString}`)
          .then(async response => {
            const data = await response.json()
            if (!Array.isArray(data)) {
              return
            }
            data.forEach(v => {
              this.carriers.push(v)
            })
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            this.carriersLoading = this.carriersLoading.filter(v => v !== search)
          })
      },
      getCarriersAll (search) {
        const queryString = objectToQueryString({
          search: search,
          base: 1,
        })
        this.carriersAllLoading.push(search)
        authFetch(process.env.VUE_APP_API_ROOT + `carriers/?${queryString}`)
          .then(async response => {
            const data = await response.json()
            if (!Array.isArray(data)) {
              return
            }
            data.forEach(v => {
              this.carriersAll.push(v)
            })
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            this.carriersAllLoading = this.carriersAllLoading.filter(v => v !== search)
          })
      },
      getCountries (search) {
        const queryString = objectToQueryString({
          search: search,
        })
        this.countriesLoading.push(search)
        authFetch(process.env.VUE_APP_API_ROOT + `countries/?${queryString}`)
          .then(async response => {
            const data = await response.json()
            if (!Array.isArray(data)) {
              return
            }
            data.forEach(v => {
              this.countries.push(v)
            })
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            this.countriesLoading = this.countriesLoading.filter(v => v !== search)
          })
      },
    },
  }

</script>

import {
  validateIPV4,
  validatePort,
  validateCommaSeparatedPositiveNumbers,
  validatePositiveRange,
  validateCommaSeparatedString,
  validateIPAndPort,
} from '@/plugins/validators'

export function required (value) {
  return !!value || 'Required'
}

export function ip (value) {
  return validateIPV4(value) || 'Invalid IP'
}

export function port (value) {
  return validatePort(value) || 'Invalid Port'
}

export function ipAndPort (value) {
  return validateIPAndPort(value) || 'Must be in IP:PORT format'
}

export function numberGT0 (value) {
  return (!isNaN(value) && parseInt(value, 10) > 0) || 'Must be number > 0'
}

export function positiveNumber (value) {
  if (value === undefined || value === '') {
    return true
  }
  return (
    (!isNaN(value) && parseInt(value, 10) >= 0) || 'Must be positive number'
  )
}

export function positiveFloat (value) {
  if (value === undefined || value === '') {
    return true
  }
  return (
    (!isNaN(value) && parseFloat(value, 10) >= 0) || 'Must be positive number'
  )
}

export function positiveCommaSepNumbers (value) {
  return (
    validateCommaSeparatedPositiveNumbers(value) ||
    'Must be comma-separated numbers'
  )
}

export function commaSepString (value) {
  return validateCommaSeparatedString(value) || 'Must be comma-separated values'
}

export function positiveRange (value) {
  return (
    validatePositiveRange(value) ||
    'Must be postive number, or range of numbers'
  )
}

export function moreThanTwoCharacters (value) {
  if (!value) {
    return true
  }
  return value.length > 2 || 'Must be three or more characters'
}

export function moreThanOneCharacter (value) {
  if (!value) {
    return true
  }
  return value.length > 1 || 'Must be two or more characters'
}

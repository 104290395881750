<template>
  <layout-panel>
    <v-container>
      <v-card>
        <v-card-text class="display-2 text-center mt-5 primary white--text">
          Download Task CDRs
        </v-card-text>
      </v-card>
    </v-container>
    <v-container v-if="loading === true" class="text-center mt-5">
      <v-progress-circular
        :size="50"
        :width="5"
        indeterminate
      />
    </v-container>
    <v-container>
      <v-alert
        v-if="loading === false && items.length === 0"
        :value="true"
        color="error"
        icon="warning"
        class="mt-5"
      >
        No visible completed tasks found
      </v-alert>
      <v-card v-else-if="loading === false">
        <v-container>
          <v-row>
            <v-col>
              <v-select v-model="task" label="Task" :items="items" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-radio-group v-model="outgoing">
                <v-radio label="Outgoing" :value="true" />
                <v-radio label="Incoming" :value="false" />
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                color="primary"
                :disabled="processing"
                :loading="processing"
                block
                @click="downloadCSV()"
              >Download</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-container>
  </layout-panel>
</template>

<script>
  import { authFetch, authDownload, objectToQueryString } from '@/plugins/authFetch'

  export default {
    data () {
      return {
        items: [],
        loading: true,
        task: null,
        outgoing: true,
        outgoingOptions: [
          { text: 'Outgoing', value: true },
          { text: 'Incoming', value: false },
        ],
        processing: false,
      }
    },
    created () {
      this.getData()
    },
    methods: {
      downloadCSV () {
        const tid = parseInt(this.task)
        if (isNaN(tid)) {
          return
        }
        this.processing = true
        const query = { task: tid, outgoing: 0 }
        if (this.outgoing === true) {
          query.outgoing = 1
        }
        authDownload(`${process.env.VUE_APP_API_ROOT}cdr/download/?${objectToQueryString(query)}`)
          .finally(() => {
            this.processing = false
          })
      },
      getData () {
        authFetch(process.env.VUE_APP_API_ROOT + 'tasks/list/')
          .then(async response => {
            const items = await response.json()
            items.forEach(r => {
              this.items.push({ text: `${r[1]} (${r[0]})`, value: r[0] })
            })
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.loading = false
          })
      },
    },
  }
</script>

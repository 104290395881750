import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import { authFetch } from '@/plugins/authFetch'

Vue.use(Vuex)

// TODO - Use the persis flag to either use sessionStorage or localStorage for added security
const vuexSession = new VuexPersistence({
  key: 'auth',
  storage: window.localStorage,
  reducer: state => ({
    sessionId: state.sessionId,
    dark: state.dark,
  }),
})

export default new Vuex.Store({
  state: {
    sessionId: null,
    username: null,
    permission: null,
    dark: false,
  },
  mutations: {
    SET_SESSION_ID (state, id) {
      state.sessionId = id
    },
    CLEAR_SESSION_ID (state) {
      state.sessionId = null
    },
    SET_USER_DETAILS (state, data) {
      state.username = data.username
      state.permission = data.permission
      state.dark = data.dark
    },
    CLEAR_USER_DETAILS (state) {
      state.username = null
      state.permission = null
      state.dark = false
    },
    SET_DARK (state, data) {
      state.dark = data
    },
  },
  actions: {
    setSessionId ({ commit }, id) {
      commit('SET_SESSION_ID', id)
    },
    clearSessionId ({ commit }) {
      commit('CLEAR_SESSION_ID')
    },
    clearSession ({ commit }) {
      commit('CLEAR_SESSION_ID')
      commit('CLEAR_USER_DETAILS')
    },
    populateUserDetails ({ commit }, sessionId) {
      return new Promise((resolve, reject) => {
        authFetch(process.env.VUE_APP_API_ROOT + 'session/details/')
        .then(async response => {
          if (response.ok) {
            const data = await response.json()
            commit('SET_USER_DETAILS', data)
            resolve(true)
          } else {
            commit('CLEAR_USER_DETAILS')
            resolve(false)
          }
        })
        .catch(error => {
          commit('CLEAR_USER_DETAILS')
          reject(error)
        })
      })
    },
    setDark ({ commit }, bool) {
      commit('SET_DARK', bool)
    },
  },
  plugins: [vuexSession.plugin],
})

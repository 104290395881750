import store from '@/store'
import router from '@/router'
import download from 'downloadjs'

export function authFetch (uri, options) {
  if (options === undefined) {
    options = {}
  }
  if (!Object.prototype.hasOwnProperty.call(options, 'headers')) {
    options.headers = {
      'Content-Type': 'application/json',
      Authorization: store.state.sessionId,
    }
  } else {
    options.headers.Authorization = store.state.sessionId
  }
  return new Promise((resolve, reject) => {
    fetch(uri, options)
      .then(response => {
        if (response.status === 401) {
          store.commit('CLEAR_SESSION_ID')
          router.push({ name: 'login' })
          reject(response)
        } else if (!response.ok) {
          reject(response)
        }
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function authDownload (uri, mime = 'text/csv') {
  let filename = 'download'
  return new Promise((resolve, reject) => {
    authFetch(uri)
    .then(response => {
        if (response.status === 200) {
          filename = response.headers.get('content-disposition')
          filename = filename.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1]
            ? filename.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1] : 'download'
          return response.blob()
      }
    })
    .then(blob => {
      download(blob, filename, mime)
      resolve(true)
    })
    .catch(error => {
      reject(error)
    })
  })
}

export function objectToQueryString (obj) {
  return Object.keys(obj)
    .map(key => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(obj[key])
    })
    .join('&')
}

<template>
  <layout-panel>
    <v-container>
      <v-card>
        <v-card-text class="display-2 text-center mt-5 primary white--text">
          Global Message
        </v-card-text>
      </v-card>
    </v-container>
    <v-container>
      <v-form ref="form" @submit.prevent="submit">
        <v-card>
          <v-card-text>
            <v-textarea
              v-model="body"
              label="Message"
              auto-grow
            />
          </v-card-text>
          <v-card-actions>
            <v-btn
              type="submit"
              color="primary"
              block
              :disabled="loading"
              :loading="loading"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-container>
  </layout-panel>
</template>

<script>
  import { authFetch } from '@/plugins/authFetch'
  export default {
    data: () => ({
      body: '',
      loading: false,
    }),
    methods: {
      submit () {
        this.loading = true
        authFetch(process.env.VUE_APP_API_ROOT + 'admin/users/messages/', {
          method: 'POST',
          body: JSON.stringify({ body: this.body }),
        })
          .then(async response => {
            await response.json()
            this.body = ''
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            this.loading = false
          })
      },
    },
  }
</script>

<style scoped>
.pre { white-space: pre-wrap; }
</style>

<template>
  <layout-panel>
    <v-container>
      <v-card>
        <v-card-text class="display-2 text-center mt-5 primary white--text">
          SIP Servers for IVR
        </v-card-text>
      </v-card>
    </v-container>
    <v-container>
      <v-card>
        <v-card-title>Pending IVR SIP Requests</v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="itemsArray"
            hide-default-footer
            :items-per-page="-1"
          >
            <template v-slot:item.ipport="row">
              {{ row.item.ip }}:{{ row.item.port }}
            </template>
            <template v-slot:item.actions="row">
              <v-btn
                color="primary"
                :disabled="row.item.loading"
                :loading="row.item.loading"
                small
                @click="submit(row.item.id)"
              >Mark Complete</v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </layout-panel>
</template>

<script>
  import { authFetch } from '@/plugins/authFetch'

  export default {
    data () {
      return {
        items: {},
        headers: [
          { text: 'Added', value: 'date' },
          { text: 'Username', value: 'user' },
          { text: 'IP:Port', value: 'ipport' },
          { text: 'Actions', value: 'actions' },
        ],
        loading: true,
      }
    },
    computed: {
      itemsArray () {
        const result = []
        Object.values(this.items).forEach(r => {
          result.push(r)
        })
        return result
      },
    },
    created () {
      this.getData()
    },
    methods: {
      getData () {
        this.loading = true
        authFetch(process.env.VUE_APP_API_ROOT + 'admin/ivr/sip/')
          .then(async response => {
            const items = await response.json()
            items.forEach(r => {
              r.loading = false
              this.$set(this.items, r.id, r)
            })
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.loading = false
          })
      },
      submit (id) {
        this.items[id].loading = true
        const postData = {
          id: id,
        }
        authFetch(process.env.VUE_APP_API_ROOT + 'admin/ivr/sip/', {
          method: 'PUT',
          body: JSON.stringify(postData),
        })
          .then(async response => {
            await response.json()
            this.$delete(this.items, id)
          })
          .catch(async error => {
            console.log(error)
            this.items[id].loading = false
          })
      },
    },
  }
</script>

<template>
  <layout-panel>
    <v-container>
      <v-card>
        <v-card-text class="display-2 text-center mt-5 primary white--text">
          Daily Stats
        </v-card-text>
      </v-card>
    </v-container>
    <v-container>
      <v-card>
        <v-container>
          <v-row>
            <v-col cols="7">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="date"
                    label="Date"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="date"
                  no-title
                  scrollable=""
                  @input="menu = false"
                />
              </v-menu>
            </v-col>
            <v-col cols="4" offset="1">
              <v-btn color="primary" @click="submit()">Get Stats</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-container>

    <v-container v-if="loading">
      <v-row>
        <v-col class="text-center">
          <v-progress-circular
            :size="70"
            :width="7"
            indeterminate
          />
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="loading === false && requested === true && items.length === 0">
      <v-alert
        type="info"
        text
      >
        No stats for selected date
      </v-alert>
    </v-container>

    <template v-if="items.length > 0">
      <v-container>
        <v-card>
          <v-card-title class="display-1">Totals</v-card-title>
          <v-data-table
            :items="totals"
            :headers="headersTotal"
            hide-default-footer
            :items-per-page="-1"
            disable-sort
          >
            <template v-slot:item.totalRevenue="row">
              ${{ floorToTwo(row.item.totalRevenue) }}
            </template>
            <template v-slot:item.totalCost="row">
              ${{ floorToTwo(row.item.totalCost) }}
            </template>
            <template v-slot:item.totalProfit="row">
              ${{ floorToTwo(row.item.totalProfit) }}
            </template>
            <template v-slot:item.ourProfit="row">
              ${{ floorToTwo(row.item.ourProfit) }}
            </template>
            <template v-slot:item.userProfit="row">
              ${{ floorToTwo(owedToUser(row)) }}
            </template>
            <template v-slot:item.roi="row">
              {{ calculateRoi(row.item) }}
            </template>
          </v-data-table>
        </v-card>
      </v-container>
      <v-container>
        <v-card>
          <v-card-title class="display-1">{{ dateProp }}</v-card-title>
          <v-data-table
            :items="items"
            :headers="headers"
            hide-default-footer
            :items-per-page="-1"
          >
            <template v-slot:item.totalRevenue="row">
              ${{ floorToTwo(row.item.totalRevenue) }}
            </template>
            <template v-slot:item.totalCost="row">
              ${{ floorToTwo(row.item.totalCost) }}
            </template>
            <template v-slot:item.totalProfit="row">
              ${{ floorToTwo(row.item.totalProfit) }}
            </template>
            <template v-slot:item.ourProfit="row">
              ${{ floorToTwo(row.item.ourProfit) }}
            </template>
            <template v-slot:item.userProfit="row">
              ${{ floorToTwo(owedToUser(row)) }}
            </template>
            <template v-slot:item.roi="row">
              {{ calculateRoi(row.item) }}
            </template>
          </v-data-table>
        </v-card>
      </v-container>
    </template>
  </layout-panel>
</template>

<script>
  import { authFetch, objectToQueryString } from '@/plugins/authFetch'
  import { floorToTwo } from '@/plugins/common'

  export default {
    props: {
      dateProp: { type: String, required: true },
    },
    data () {
      return {
        date: this.dateProp,
        items: [],
        totals: [],
        loading: false,
        headers: [
          { text: 'User', value: 'user' },
          { text: 'Total Revenue', value: 'totalRevenue' },
          { text: 'Total Cost', value: 'totalCost' },
          { text: 'Total Profit', value: 'totalProfit' },
          { text: 'Our Profit', value: 'ourProfit' },
          { text: 'Owed to User', value: 'userProfit' },
          { text: 'RoI', value: 'roi' },
        ],
        headersTotal: [
          { text: 'Total Revenue', value: 'totalRevenue' },
          { text: 'Total Cost', value: 'totalCost' },
          { text: 'Total Profit', value: 'totalProfit' },
          { text: 'Our Profit', value: 'ourProfit' },
          { text: 'Owed to User', value: 'userProfit' },
          { text: 'RoI', value: 'roi' },
        ],
        menu: false,
        requested: false,
      }
    },
    watch: {
      dateProp () {
        this.date = this.dateProp
        if (this.requested === true) {
          this.requested = false
        }
        this.getData(this.date)
      },
    },
    mounted () {
      if (this.dateProp) {
        this.getData(this.dateProp)
      } else {
        this.date = new Date().toISOString().substr(0, 10)
      }
    },
    methods: {
      floorToTwo (v) {
        return floorToTwo(v)
      },
      owedToUser (row) {
        if (row.item.totalCost > row.item.totalRevenue) {
          return row.item.totalRevenue
        }
        return row.item.userProfit + row.item.totalCost
      },
      calculateRoi (item) {
        if (item.totalCost === 0) {
          return 'n/a'
        }
        return `${floorToTwo(
          ((item.totalRevenue - item.totalCost) / item.totalCost) * 100
        )}%`
      },
      submit () {
        this.$router.push({
          name: 'admin-stats',
          query: {
            date: this.date,
          },
        })
      },
      getData (date) {
        const queryString = objectToQueryString({
          date: date,
        })
        this.loading = true
        this.requested = true
        this.totals = []
        this.items = []
        authFetch(process.env.VUE_APP_API_ROOT + `admin/stats/daily/?${queryString}`)
          .then(async response => {
            const items = await response.json()
            this.items = items.items
            this.totals = items.totals
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.loading = false
          })
      },
    },
  }
</script>

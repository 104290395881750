<template>
  <v-container>
    <v-row>
      <v-col cols="2">
        <v-select
          v-model="type"
          :items="types"
          :error-messages="typeError"
          label="Filter On"
          @change="string = null"
        />
      </v-col>
      <v-col v-if="type === 'prefix'" cols="3">
        <v-text-field
          v-model="string"
          :error-messages="errors.stringError"
          label="Prefix"
        />
      </v-col>
      <template v-else-if="type === 'carrier'">
        <v-col cols="10">
          <v-autocomplete
            ref="carrierAutocomplete"
            v-model="string"
            :error-messages="errors.stringError"
            :items="carriers"
            :loading="carriersLoading.length > 0"
            :search-input.sync="carriersSearch"
            label="Carrier"
            multiple
          >
            <v-list-item
              slot="prepend-item"
              ripple
              @click="toggleAllCarriers"
            >
              <v-list-item-action>
                <v-icon>{{ carrierSelectAllIcon() }}</v-icon>
              </v-list-item-action>
              <v-list-item-title>Select All</v-list-item-title>
            </v-list-item>
            <v-divider
              slot="prepend-item"
              class="mt-2"
            />
            <template v-slot:selection="data">
              <v-chip
                close
                small
                @click:close="removeItem(data.item)"
              >
                {{ data.item }}
              </v-chip>
            </template>
          </v-autocomplete>
        </v-col>
      </template>
      <v-col v-else-if="type === 'carrierAll'" cols="3">
        <v-autocomplete
          v-model="string"
          :error-messages="errors.stringError"
          :items="carriersAll"
          :loading="carriersAllLoading.length > 0"
          :search-input.sync="carriersAllSearch"
          label="Carrier"
        />
      </v-col>
      <v-col v-else-if="type === 'country'" cols="3">
        <v-autocomplete
          v-model="string"
          :error-messages="errors.stringError"
          :items="countries"
          :loading="countriesLoading.length > 0"
          :search-input.sync="countriesSearch"
          label="Country"
        />
      </v-col>
      <v-col v-else-if="type === 'country-mobile'" cols="3">
        <v-autocomplete
          v-model="string"
          :error-messages="errors.stringError"
          :items="countries"
          :loading="countriesLoading.length > 0"
          :search-input.sync="countriesSearch"
          label="Country"
        />
      </v-col>
      <v-col cols="1">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              fab
              :disabled="loading"
              :loading="loading"
              v-on="on"
              @click="getLengths()"
            >
              <v-icon>sync</v-icon>
            </v-btn>
          </template>
          Get known lengths
        </v-tooltip>
      </v-col>
      <v-col cols="2">
        <v-text-field
          v-model="lengths"
          :error-messages="errors.lengthsError"
          label="Number Lengths"
        />
      </v-col>
      <v-col cols="2">
        <v-text-field
          v-if="showRandomDigits && forceRandoms.length === 0"
          v-model="randomDigits"
          :error-messages="errors.randomDigitsError"
          label="Random Digits"
        >
          <template v-slot:append>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">help</v-icon>
              </template>
              Used to generate only the trailing digits in the destination<br>
              <ul>
                <li v-for="(help, k) in randomsHelp" :key="k">
                  {{ help }}
                </li>
              </ul>
            </v-tooltip>
          </template>
        </v-text-field>
      </v-col>
      <v-col v-if="useRates" cols="1">
        <v-text-field
          v-model="rate"
          :error-messages="errors.rateError"
          label="$ Rate"
        />
      </v-col>
      <v-col cols="1">
        <v-btn color="primary" fab @click="remove()">
          <v-icon>clear</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { authFetch, objectToQueryString } from '@/plugins/authFetch'
  export default {
    props: {
      index: { type: String, default: null },
      value: {
        type: Object,
        default () {
          return {
            type: 'carrier',
            string: '',
            lengths: '',
            randomDigits: '0, 3',
            rate: '',
          }
        },
      },
      errors: {
        type: Object,
        default () {
          return {
            stringError: '',
            lengthsError: '',
            randomDigitsError: '',
            rateError: '',
          }
        },
      },
      lookbackType: { type: String, default: 'time' },
      lookbackData: { type: String, default: '1 day' },
      forceRandoms: { type: String, default: '' },
      multipleRandoms: { type: Boolean, default: true },
      useRates: { type: Boolean, default: true },
      typesOverride: { type: Array, default () { return [] } },
    },
    data () {
      return {
        type: this.value.type,
        string: this.value.string,
        lengths: this.value.lengths,
        randomDigits: this.value.randomDigits,
        rate: this.value.rate,
        types: [
          { text: 'Carrier ALL', value: 'carrierAll' },
          { text: 'Carrier', value: 'carrier' },
          { text: 'Prefix', value: 'prefix' },
          { text: 'Country', value: 'country' },
          { text: 'Country - Mobile Only', value: 'country-mobile' },
        ],
        showRandomDigits: false,
        loading: false,
        typeError: '',
        carriers: [],
        carriersLoading: [],
        carriersSearch: null,
        carriersLoaded: [],
        countries: [],
        countriesLoading: [],
        countriesSearch: null,
        countriesLoaded: [],
        stringItems: [],
        allCarriers: false,
        carriersAll: [],
        carriersAllLoading: [],
        carriersAllSearch: null,
        carriersAllLoaded: [],
        checkChange: false,
      }
    },
    computed: {
      randomsHelp () {
        const result = [
          'Empty: Leave empty to randomize',
          'Single digit: (eg 7) will generate 7 trailing digits using a random known working number',
          '0: Only use numbers that we have seen within the timerange. No randomization',
        ]
        if (this.multipleRandoms === true) {
          result.push('List: (eg 5, 6, 7) will choose 5, 6, or 7 trailing digits to randomize like above')
        }
        return result
      },
    },
    watch: {
      forceRandoms (val) {
        this.randomDigits = val
        this.value.randomDigits = val
      },
      type (val) {
        this.showRandomDigits = false
        if (this.forceRandoms.length === 0) {
          this.randomDigits = '0, 3'
        }
        this.typeError = ''
        this.errors.lengthsError = ''
        this.errors.randomDigitsError = ''
        this.errors.stringError = ''
        this.value.type = val
        this.$emit('input', this.value)
      },
      string (val) {
        if (this.checkChange === false) {
          return
        }
        this.showRandomDigits = false
        if (this.forceRandoms.length === 0) {
          this.randomDigits = '0, 3'
        }
        this.errors.stringError = ''
        this.value.string = val
        this.$emit('input', this.value)
      },
      lengths (val) {
        this.errors.lengthsError = ''
        this.value.lengths = val
        this.$emit('input', this.value)
      },
      randomDigits (val) {
        this.errors.randomDigitsError = ''
        this.value.randomDigits = val
        this.$emit('input', this.value)
      },
      rate (val) {
        this.errors.rate = ''
        this.value.rate = val
        this.$emit('input', this.value)
      },
      carriersSearch (val) {
        if (val === null || val.length < 2) {
          return
        }
        const lower = val.substring(0, 2).toLowerCase()
        if (this.carriersLoaded.indexOf(lower) !== -1) {
          return
        }
        this.carriersLoaded.push(lower)
        this.getCarriers(lower)
      },
      countriesSearch (val) {
        if (val === null || val.length < 2) {
          return
        }
        const lower = val.substring(0, 2).toLowerCase()
        if (this.countriesLoaded.indexOf(lower) !== -1) {
          return
        }
        this.countriesLoaded.push(lower)
        this.getCountries(lower)
      },
      carriersAllSearch (val) {
        if (val === null || val.length < 2) {
          return
        }
        const lower = val.substring(0, 2).toLowerCase()
        if (this.carriersAllLoaded.indexOf(lower) !== -1) {
          return
        }
        this.carriersAllLoaded.push(lower)
        this.getCarriersAll(lower)
      },
    },
    mounted () {
      if (this.randomDigits) {
        this.showRandomDigits = true
      }
      if (this.forceRandoms.length !== 0) {
        this.randomDigits = this.forceRandoms
        this.value.randomDigits = this.forceRandoms
        this.$emit('input', this.value)
      }
      if (this.value.string) {
        if (this.value.type !== 'carrier' && Array.isArray(this.value.string)) {
          this.value.string = this.value.string[0]
        }
        switch (this.value.type) {
          case 'carrier':
            this.string.forEach(v => {
              this.carriers.push(v)
            })
            break
          case 'carrierAll':
            this.carriersAll.push(this.value.string)
            break
          case 'country':
            this.countries.push(this.value.string)
            break
        }
      }
      let types = []
      if (this.typesOverride.length > 0) {
        types = []
        this.typesOverride.forEach(filterType => {
          this.types.forEach(type => {
            if (type.value === filterType) {
              types.push(type)
            }
          })
        })
      }
      if (types.length < 1) {
        types = this.types
      }
      this.types = types
      if (this.value.string === '') {
        this.value.type = this.types[0].value
        this.type = this.types[0].value
      }
      this.string = this.value.string
      this.$nextTick(() => {
        this.checkChange = true
      })
    },
    methods: {
      remove () {
        this.$emit('removeWhitelist', this.index)
      },
      getLengths () {
        if (!this.types.some(type => type.value === this.type)) {
          this.typeError = 'Invalid selection'
          return
        }
        if (!this.string) {
          this.errors.stringError = 'Required'
          return
        }
        if (!this.lookbackData) {
          if (this.lookbackType === 'time') {
            this.lookbackData = '1 day'
          } else {
            this.lookbackData = 1000
          }
        }
        this.loading = true
        this.showRandomDigits = false
        if (this.forceRandoms.length === 0) {
          this.randomDigits = '0, 3'
        }

        let string = this.string
        if (typeof string === 'string') {
          string = [string]
        }

        const postData = {
          string: string,
          lookbackType: this.lookbackType,
          data: this.lookbackData,
        }
        this.errors.lengthsError = ''
        authFetch(process.env.VUE_APP_API_ROOT + `tasks/cdrs/lengths/${this.type}/`, {
          method: 'POST',
          body: JSON.stringify(postData),
        })
          .then(async response => {
            const data = await response.json()
            if (data.length > 0) {
              this.lengths = data.join(', ')
              this.showRandomDigits = true
            } else {
              if (this.value.type === 'carrier') {
                if (this.string.length > 1) {
                  this.errors.lengthsError = 'Carriers have never been seen before'
                } else {
                  this.errors.lengthsError = 'Carrier has never been seen before'
                }
              } else if (this.value.type === 'country') {
                this.errors.lengthsError = 'Country has never been seen before'
              } else if (this.value.type === 'prefix') {
                this.errors.lengthsError = 'Prefix has never been seen before'
              } else {
                this.errors.lengthsError = 'No known lengths found'
              }
            }
          })
          .catch((error) => {
            console.log(error)
            this.errors.lengthsError = 'Error grabbing from server'
          })
          .finally(() => {
            this.loading = false
          })
      },
      getCarriers (search) {
        const queryString = objectToQueryString({
          search: search,
        })
        this.carriersLoading.push(search)
        authFetch(process.env.VUE_APP_API_ROOT + `carriers/?${queryString}`)
          .then(async response => {
            const data = await response.json()
            if (!Array.isArray(data)) {
              return
            }
            data.forEach(v => {
              this.carriers.push(v)
            })
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            this.carriersLoading = this.carriersLoading.filter(v => v !== search)
          })
      },
      getCarriersAll (search) {
        const queryString = objectToQueryString({
          search: search,
          base: 1,
        })
        this.carriersAllLoading.push(search)
        authFetch(process.env.VUE_APP_API_ROOT + `carriers/?${queryString}`)
          .then(async response => {
            const data = await response.json()
            if (!Array.isArray(data)) {
              return
            }
            data.forEach(v => {
              this.carriersAll.push(v)
            })
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            this.carriersAllLoading = this.carriersAllLoading.filter(v => v !== search)
          })
      },
      getCountries (search) {
        const queryString = objectToQueryString({
          search: search,
        })
        this.countriesLoading.push(search)
        authFetch(process.env.VUE_APP_API_ROOT + `countries/?${queryString}`)
          .then(async response => {
            const data = await response.json()
            if (!Array.isArray(data)) {
              return
            }
            data.forEach(v => {
              this.countries.push(v)
            })
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            this.countriesLoading = this.countriesLoading.filter(v => v !== search)
          })
      },
      removeItem (item) {
        const index = this.string.indexOf(item)
        if (index >= 0) this.string.splice(index, 1)
      },
      carrierFilterStatus () {
        if (Array.isArray(this.string) === false) {
          this.string = []
        }
        try {
          let result = null
          this.$refs.carrierAutocomplete.filteredItems.forEach(v => {
            if (this.string.includes(v)) {
              if (result === null) {
                result = 'all'
              } else {
                result = result === 'all' ? 'all' : 'some'
              }
            } else {
              if (result === null) {
                result = 'none'
              } else {
                result = result === 'none' ? 'none' : 'some'
              }
            }
          })
          return result === null ? 'none' : result
        } catch (e) {
          return 'none'
        }
      },
      toggleAllCarriers () {
        if (Array.isArray(this.string) === false) {
          this.string = []
        }
        this.$nextTick(() => {
          const status = this.carrierFilterStatus()
          if (status !== 'none') {
            this.$refs.carrierAutocomplete.filteredItems.forEach(v => {
              const index = this.string.indexOf(v)
              if (index !== -1) {
                this.string.splice(index, 1)
              }
            })
          } else {
            this.$refs.carrierAutocomplete.filteredItems.forEach(v => {
              if (this.string.includes(v) === false) {
                this.string.push(v)
              }
            })
          }
          this.$refs.carrierAutocomplete.focus()
        })
      },
      carrierSelectAllIcon () {
        const status = this.carrierFilterStatus()
        if (status === 'all') return 'mdi-close-box'
        if (status === 'some') return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
    },
  }

</script>

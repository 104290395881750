<template>
  <layout-panel>
    <v-container>
      <v-card>
        <v-card-text class="display-2 text-center mt-5 primary white--text">
          Dashboard
        </v-card-text>
      </v-card>
    </v-container>
    <v-container>
      <v-card>
        <v-card-title>
          Top Running Pairs
          <v-tooltip bottom max-width="400">
            <template v-slot:activator="{ on }">
              <v-icon class="pl-5" v-on="on">help</v-icon>
            </template>
            Profitable and live tasks that have been created by other users and
            shared for you to copy.
          </v-tooltip>
        </v-card-title>
        <v-card-text v-if="pairs.length < 1" class="text-center">
          <em>No pairs to show</em>
        </v-card-text>
        <v-card-text v-else>
          <v-row>
            <v-col
              v-for="p in pairs"
              :key="`${p.retail}|${p.destination}`"
              cols="4"
              xl="2"
            >
              <v-card elevation="5">
                <v-card-text class="primary text-center white--text">
                  {{ p.retail }}
                </v-card-text>
                <v-card-text class="text-center">
                  {{ p.destination }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-title>Daily Summary</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="4" xl="2">
              <strong>Inbound Calls:</strong> {{ inbound }}
            </v-col>
            <v-col cols="4" xl="2">
              <strong>Inbound Minutes:</strong> {{ minutes }}
            </v-col>
            <v-col cols="4" xl="2">
              <strong>Countries Calling:</strong> {{ countriesCalling }}
            </v-col>
            <v-col cols="4" xl="2">
              <strong>Numbers Owned:</strong> {{ numbers }}
            </v-col>
          </v-row>
        </v-card-text>
        <template v-if="showYourServers()">
          <v-card-title>Revenue / Cost Summary</v-card-title>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>&nbsp;</th>
                    <th>Revenue</th>
                    <th>Cost</th>
                    <th>Profit</th>
                    <th>RoI</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><strong>Today</strong></td>
                    <td>${{ floorToTwo(revenueSummary.data.revenueDay) }}</td>
                    <td>${{ floorToTwo(revenueSummary.data.costDay) }}</td>
                    <td>${{ floorToTwo(revenueSummary.data.revenueDay -
                      revenueSummary.data.costDay) }}</td>
                    <td>{{ translateRoi(revenueSummary.data.roiDay) }}</td>
                  </tr>
                  <tr>
                    <td><strong>This Week</strong></td>
                    <td>${{ floorToTwo(revenueSummary.data.revenueWeek) }}</td>
                    <td>${{ floorToTwo(revenueSummary.data.costWeek) }}</td>
                    <td>${{ floorToTwo(revenueSummary.data.revenueWeek -
                      revenueSummary.data.costWeek) }}</td>
                    <td>{{ translateRoi(revenueSummary.data.roiWeek) }}</td>
                  </tr>
                  <tr>
                    <td><strong>This Month</strong></td>
                    <td>${{ floorToTwo(revenueSummary.data.revenueMonth) }}</td>
                    <td>${{ floorToTwo(revenueSummary.data.costMonth) }}</td>
                    <td>${{ floorToTwo(revenueSummary.data.revenueMonth -
                      revenueSummary.data.costMonth) }}</td>
                    <td>{{ translateRoi(revenueSummary.data.roiMonth) }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </template>
        <template v-else>
          <v-card-title>Revenue Summary</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="4" xl="2">
                <strong>Today:</strong>
                ${{ floorToTwo(revenueSummary.data.revenueDay) }}
              </v-col>
              <v-col cols="4" xl="2">
                <strong>This Week:</strong>
                ${{ floorToTwo(revenueSummary.data.revenueWeek) }}
              </v-col>
              <v-col cols="4" xl="2">
                <strong>This Month:</strong>
                ${{ floorToTwo(revenueSummary.data.revenueMonth) }}
              </v-col>
            </v-row>
          </v-card-text>
        </template>
        <template v-if="showYourServers()">
          <v-card-title>Your Servers</v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                v-for="s in servers"
                :key="s"
                cols="2"
                xl="1"
                class="text-center"
              >
                {{ s }}
              </v-col>
            </v-row>
          </v-card-text>
        </template>
      </v-card>
    </v-container>
    <v-container>
      <v-card>
        <v-card-title>Incoming Calls (Last 48h)</v-card-title>
        <v-container>
          <v-row>
            <v-col class="xs-12">
              <div v-if="!incomingCalls.loading && !incomingCalls.error" class="carrier-graph">
                <line-chart
                  :chart-data="incomingCalls.data"
                  :options="graphOptions"
                  :styles="graphStyles"
                />
              </div>
              <div v-else-if="incomingCalls.error">
                <v-alert type="error" style="text-align: center">
                  Error loading graph data
                </v-alert>
              </div>
              <div v-else class="pa-5" style="text-align: center">
                <v-progress-circular
                  :size="70"
                  :width="7"
                  indeterminate
                />
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-container>
    <v-container>
      <v-card>
        <v-card-title class="title">Daily Profits (Last 2w)</v-card-title>
        <v-container>
          <v-row>
            <v-col class="xs-12">
              <div v-if="!dailyProfit.loading && !dailyProfit.error" class="carrier-graph">
                <line-chart
                  :chart-data="dailyProfit.data"
                  :options="graphOptions"
                  :styles="graphStyles"
                />
              </div>
              <div v-else-if="dailyProfit.error">
                <v-alert type="error" style="text-align: center">
                  Error loading graph data
                </v-alert>
              </div>
              <div v-else class="pa-5" style="text-align: center">
                <v-progress-circular
                  :size="70"
                  :width="7"
                  indeterminate
                />
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-container>
  </layout-panel>
</template>

<script>
  import { authFetch } from '@/plugins/authFetch'
  import LineChart from '@/components/carrierChart'
  import { floorToTwo } from '@/plugins/common'

  export default {
    components: { LineChart },
    data () {
      return {
        graphData: [],
        graphOptions: {
          maintainAspectRatio: false,
        },
        graphStyles: {
          position: 'relative',
          height: '500px',
        },
        incomingCalls: {
          data: { labels: [], datasets: [] },
          loading: false,
          error: false,
        },
        dailyProfit: {
          data: { labels: [], datasets: [] },
          loading: false,
          error: false,
        },
        revenueSummary: {
          data: { labels: [], datasets: [] },
          loading: false,
          error: false,
        },
        pairs: [],
        inbound: 0,
        minutes: 0,
        countriesCalling: 0,
        numbers: 0,
        servers: [],
      }
    },
    created () {
      this.getIncomingCalls()
      this.getDailyProfit()
      this.getRevenueSummary()
      this.getDashboard()
    },
    methods: {
      showYourServers () {
        if (this.$store.state.permission & 8) {
          return false
        }
        return true
      },
      floorToTwo (float) {
        return floorToTwo(float)
      },
      translateRoi (v) {
        if (typeof v === 'string') {
          return v
        }
        return floorToTwo(v)
      },
      getDashboard () {
        authFetch(process.env.VUE_APP_API_ROOT + 'dashboard/')
          .then(async response => {
            const data = await response.json()
            this.pairs = data.pairs
            this.inbound = data.inbound
            this.minutes = data.minutes
            this.countriesCalling = data.countriesCalling
            this.numbers = data.numbers
            this.servers = data.servers
          })
          .catch(error => {
            console.log(error)
          })
      },
      getIncomingCalls () {
        this.incomingCalls.loading = true
        this.incomingCalls.error = false
        authFetch(process.env.VUE_APP_API_ROOT + 'graphs/incoming-calls-48h/')
          .then(async response => {
            this.incomingCalls.data = await response.json()
          })
          .catch(error => {
            console.log(error)
            this.incomingCalls.error = true
          })
          .finally(() => {
            this.incomingCalls.loading = false
          })
      },
      getDailyProfit () {
        this.dailyProfit.loading = true
        this.dailyProfit.error = false
        authFetch(process.env.VUE_APP_API_ROOT + 'graphs/daily-profit-2w/')
          .then(async response => {
            this.dailyProfit.data = await response.json()
          })
          .catch(error => {
            console.log(error)
            this.dailyProfit.error = true
          })
          .finally(() => {
            this.dailyProfit.loading = false
          })
      },
      getRevenueSummary () {
        this.revenueSummary.loading = true
        this.revenueSummary.error = false
        authFetch(process.env.VUE_APP_API_ROOT + 'user/revenue-summary/')
          .then(async response => {
            this.revenueSummary.data = await response.json()
          })
          .catch(error => {
            console.log(error)
            this.revenueSummary.error = true
          })
          .finally(() => {
            this.revenueSummary.loading = false
          })
      },
    },
  }
</script>

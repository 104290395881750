<template>
  <layout-panel>
    <v-container>
      <v-card>
        <v-card-text class="display-2 text-center mt-5 primary white--text">
          Quick Launch All-In-One
        </v-card-text>
      </v-card>
    </v-container>
    <v-container>
      <v-card>
        <v-card-title>
          Day Time
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              v-for="c in itemsDay"
              :key="c"
              cols="4"
              xl="2"
              class="text-center pa-5"
            >
              <v-btn
                :disabled="submitting"
                :loading="submittingCountries[c]"
                rounded
                @click="submitQuickLaunch(c)"
              >
                {{ c }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-title>
          Night Time
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              v-for="c in itemsNight"
              :key="c"
              cols="4"
              xl="2"
              class="text-center pa-5"
            >
              <v-btn
                :disabled="submitting"
                :loading="submittingCountries[c]"
                rounded
                @click="submitQuickLaunch(c)"
              >{{ c }}</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-title>
          Manual Lookup
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-autocomplete
                v-model="country"
                :items="countries"
                :loading="countriesLoading.length > 0"
                :search-input.sync="countriesSearch"
                label="Country"
              />
            </v-col>
            <v-col cols="3" lg="2">
              <v-btn
                color="primary"
                :disabled="submitting"
                :loading="submitting"
                @click="submitQuickLaunch(country)"
              >
                Submit
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>

    <v-dialog v-model="quickLaunchErrorDialog" max-width="500">
      <v-card>
        <v-card-title class="headline" color="error">Server Error</v-card-title>
        <v-card-text>
          <p>There was an error processing your request.</p>
          <p v-for="(err, key) in quickLaunchErrors" :key="key">{{ err }}</p>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="quickLaunchErrorDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </layout-panel>
</template>

<script>
  import { authFetch, objectToQueryString } from '@/plugins/authFetch'

  export default {
    props: {
      headerText: { type: String, default: 'Running Pairs' },
    },
    data () {
      return {
        itemsDay: [
          'Canada',
          'France',
          'Hungary',
          'Ireland',
          'Malaysia',
          'Nigeria',
          'Romania',
          'Slovakia',
          'Spain',
          'United Kingdom',
          'United States',
        ],
        itemsNight: [
          'Australia',
          'Canada',
          'China',
          'Malaysia',
          'New Zealand',
          'South Korea',
          'United States',
          'Vietnam',
        ],
        submitting: false,
        submittingCountries: {},
        quickLaunchErrors: [],
        quickLaunchErrorDialog: false,
        country: null,
        countries: [],
        countriesLoading: [],
        countriesSearch: null,
        countriesLoaded: [],
      }
    },
    watch: {
      countriesSearch (val) {
        if (val === null || val.length < 2) {
          return
        }
        const lower = val.substring(0, 2).toLowerCase()
        if (this.countriesLoaded.indexOf(lower) !== -1) {
          return
        }
        this.countriesLoaded.push(lower)
        this.getCountries(lower)
      },
    },
    created () {
      this.itemsDay.forEach(country => {
        this.submittingCountries[country] = false
      })
      this.itemsNight.forEach(country => {
        this.submittingCountries[country] = false
      })
    },
    methods: {
      submitQuickLaunch (country) {
        if (!country) {
          return
        }
        const postData = { country: country }
        if (this.submitting === true) {
          return
        }
        this.submitting = true
        this.submittingCountries[country] = true
        this.quickLaunchErrors = []
        this.quickLaunchErrorDialog = false
        authFetch(`${process.env.VUE_APP_API_ROOT}tasks/aio/quick/`, {
          method: 'POST',
          body: JSON.stringify(postData),
        })
          .then(async response => {
            await response.json()
            this.$router.push({ name: 'tasks' })
          })
          .catch(async error => {
            console.log(error)
            const data = await error.json()
            if (typeof data === 'object' && Object.prototype.hasOwnProperty.call(data, 'message')) {
              // Is it an object we can loop through for feedback?
              if (typeof data.message === 'object') {
                Object.values(data.message).forEach(msg => {
                  this.quickLaunchErrors.push(msg)
                })
                // String returned, setup dialog
              } else {
                this.quickLaunchErrors = [data.message]
              }
              // Generic error if we didn't get a proper response
            } else {
              this.quickLaunchErrors = [`Server returned a ${error.status} status code`]
            }
            if (this.quickLaunchErrors.length > 0) {
              this.quickLaunchErrorDialog = true
            }
          })
          .finally(() => {
            this.submitting = false
            this.submittingCountries[country] = false
          })
      },
      getCountries (search) {
        const queryString = objectToQueryString({
          search: search,
        })
        this.countriesLoading.push(search)
        authFetch(process.env.VUE_APP_API_ROOT + `countries/?${queryString}`)
          .then(async response => {
            const data = await response.json()
            if (!Array.isArray(data)) {
              return
            }
            data.forEach(v => {
              this.countries.push(v)
            })
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            this.countriesLoading = this.countriesLoading.filter(v => v !== search)
          })
      },
    },
  }
</script>

<template>
  <v-container>
    <v-row>
      <v-col cols="5">
        <v-menu
          ref="start"
          v-model="showStart"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="start"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="start"
              label="Start Time"
              prepend-icon="access_time"
              readonly
              :error-messages="errors.startError"
              v-on="on"
            />
          </template>
          <v-time-picker
            v-if="showStart"
            v-model="start"
            format="24hr"
            @click:minute="$refs.start.save(start)"
          />
        </v-menu>
      </v-col>
      <v-col cols="5">
        <v-menu
          ref="end"
          v-model="showEnd"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="end"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="end"
              label="End Time"
              prepend-icon="access_time"
              readonly
              :error-messages="errors.endError"
              v-on="on"
            />
          </template>
          <v-time-picker
            v-if="showEnd"
            v-model="end"
            format="24hr"
            @click:minute="$refs.end.save(end)"
          />
        </v-menu>
      </v-col>
      <v-col cols="1">
        <v-btn
          color="primary"
          fab
          @click="remove()"
        >
          <v-icon>clear</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    props: {
      index: { type: String, default: null },
      value: {
        type: Array,
        default () {
          return []
        },
      },
      errors: {
        type: Object,
        default () {
          return {
            startError: '',
            endError: '',
          }
        },
      },
    },
    data () {
      return {
        start: this.value[0],
        end: this.value[1],
        showStart: false,
        showEnd: false,
      }
    },
    watch: {
      start (val) {
        this.errors.startError = ''
        this.errors.endError = ''
        this.value[0] = this.timeToInt(val, 'start')
        this.$emit('input', this.value)
      },
      end (val) {
        this.errors.endError = ''
        this.errors.startError = ''
        this.value[1] = this.timeToInt(val, 'end')
        this.$emit('input', this.value)
      },
    },
    mounted () {
      if (this.value[0] !== null) {
        this.start = this.intToTime(this.value[0], 'start')
      }
      if (this.value[1] !== null) {
        this.end = this.intToTime(this.value[1], 'end')
      }
    },
    methods: {
      remove () {
        this.$emit('removeSchedule', this.index)
      },
      timeToInt (time, type) {
        if (time === null) {
          return null
        }
        if (time === '00:00' && type === 'end') {
          return 86400
        }
        const parts = time.split(':')
        if (parts.length === 2) {
          return (parseInt(parts[1]) * 60) + (parseInt(parts[0]) * 3600)
        }
        return null
      },
      intToTime (time, type) {
        if (typeof time === 'undefined' || time === null) {
          return null
        }
        if (time === 86400 && type === 'end') {
          return '00:00'
        }
        const mins = Math.floor(time / 3600).toString()
        const secs = Math.floor((time % 3600) / 60).toString()
        return `${mins.padStart(2, '0')}:${secs.padStart(2, '0')}`
      },
    },
  }

</script>

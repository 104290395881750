<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col class="text-center">
        <img src="@/assets/logo_light.png" alt="Rubix">
        <p>
          Coming Soon
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    mounted () {
      this.$store.state.dark = false
      this.$ga.page('/')
    },
  }
</script>

<style scoped>

</style>

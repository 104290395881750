<template>
  <div>
    <v-container>
      <v-row>
        <v-col>Calls to {{ test.destination }}</v-col>
        <v-col cols="2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-on="on">
                <strong>PDD:</strong> {{ test.pdd }}
              </span>
            </template>
            <div v-for="(x, xk) in test.pdd_text" :key="`pdd_text_${xk}`">{{ x }}</div>
          </v-tooltip>
        </v-col>
        <v-col cols="2">
          <strong>MOS:</strong> {{ test.mos }}
        </v-col>
        <v-col>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar size="24" color="grey" class="black--text mr-1" rounded v-on="on">
                {{ test.call_counts.total }}
              </v-avatar>
            </template>
            <span>Total Calls</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar size="24" color="yellow darken-3" class="black--text mr-1" rounded v-on="on">
                {{ test.call_counts.complete }}
              </v-avatar>
            </template>
            <span>Completed Calls</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar size="24" color="green darken-3" class="white--text mr-1" rounded v-on="on">
                {{ test.call_counts.successful }}
              </v-avatar>
            </template>
            <span>Successful Calls</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar size="24" color="orange darken-3" class="black--text mr-1" rounded v-on="on">
                {{ test.call_counts.not_answered }}
              </v-avatar>
            </template>
            <span>Not Answered</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar size="24" color="red darken-3" class="black--text mr-1" rounded v-on="on">
                {{ test.call_counts.failed }}
              </v-avatar>
            </template>
            <span>Failed Calls</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :items="test.calls"
            :headers="headers"
            hide-default-footer
            dense
            disable-sort
          >
            <template v-slot:item.packets="row">
              {{ translatePackets(row.item) }}
            </template>
            <template v-slot:item.mos="row">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-on="on">{{ row.item.mos }}</span>
                </template>
                <div v-for="(x, xk) in row.item.mos_text" :key="`mos_text_${row.item.id}_${xk}`">{{ x }}</div>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  export default {
    props: {
      test: { type: Object, required: true },
    },
    data () {
      return {
        headers: [
          { text: 'Status', value: 'status' },
          { text: 'PDD', value: 'pdd' },
          { text: 'UPDD', value: 'updd' },
          { text: 'Ring Time', value: 'ring_time' },
          { text: 'Call Time', value: 'call_time' },
          { text: 'Packets', value: 'packets' },
          { text: 'MOS', value: 'mos' },
          { text: 'Final Code', value: 'final_code' },
          { text: 'Expected CLI', value: 'cli_expected' },
          { text: 'Actual CLI', value: 'cli_actual' },
        ],
      }
    },
    methods: {
      translatePackets (item) {
        return `${item.packets[0]} / ${item.packets[1]}`
      },
    },
  }
</script>

<style>

</style>

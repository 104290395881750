<template>
  <layout-panel>
    <v-container>
      <v-card>
        <v-card-text class="display-2 text-center mt-5 primary white--text">
          User Activity
        </v-card-text>
      </v-card>
    </v-container>
    <v-container v-if="loading === true" class="text-center mt-5">
      <v-progress-circular
        :size="50"
        :width="5"
        indeterminate
      />
    </v-container>
    <v-container v-else>
      <v-card class="mb-5">
        <v-card-title>Last 48 Hours</v-card-title>
        <v-card-text>
          <line-chart
            :chart-data="data['48h']"
            :options="graphOptions"
            :styles="graphStyles"
          />
        </v-card-text>
        <v-card-title>Totals</v-card-title>
        <v-card-text>
          <v-chip v-for="(row, key) in data['48h'].totals" :key="key" class="ma-1">
            {{ row.user }}: {{ row.count }}
          </v-chip>
        </v-card-text>
      </v-card>
      <v-card>
        <v-card-title>Last 2 Weeks</v-card-title>
        <v-card-text>
          <line-chart
            :chart-data="data['2w']"
            :options="graphOptions"
            :styles="graphStyles"
          />
        </v-card-text>
        <v-card-title>Totals</v-card-title>
        <v-card-text>
          <v-chip v-for="(row, key) in data['2w'].totals" :key="key" class="ma-1">
            {{ row.user }}: {{ row.count }}
          </v-chip>
        </v-card-text>
      </v-card>
    </v-container>
  </layout-panel>
</template>

<script>
  import { authFetch } from '@/plugins/authFetch'
  import LineChart from '@/components/carrierChart'

  export default {
    components: { LineChart },
    data () {
      return {
        loading: true,
        data: {},
        graphOptions: {
          maintainAspectRatio: false,
        },
        graphStyles: {
          position: 'relative',
          height: '500px',
        },
      }
    },
    created () {
      this.getData()
    },
    methods: {
      getData () {
        authFetch(process.env.VUE_APP_API_ROOT + 'admin/users/activity/')
          .then(async response => {
            this.data = await response.json()
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.loading = false
          })
      },
    },
  }
</script>

<template>
  <layout-panel>
    <div v-if="loading" class="pt-5" style="text-align: center">
      <v-progress-circular
        :size="70"
        :width="7"
        indeterminate
      />
    </div>
    <v-alert v-model="error" type="error" outlined text>{{ error }}</v-alert>
    <template v-if="!loading && !error">
      <v-container>
        <v-card>
          <v-card-text class="display-2 text-center mt-5 primary white--text">
            Task Info
          </v-card-text>
        </v-card>
      </v-container>

      <v-container>
        <v-card>
          <v-card-text>
            <v-container>
              <v-row class="text-center">
                <v-col>
                  <strong>Source Peer:</strong> {{ data.revBoostData.source }}
                </v-col>
                <v-col>
                  <strong>Destination Peer:</strong> {{ data.revBoostData.destination }}
                </v-col>
                <v-col>
                  <strong>Outgoing Dialing Prefix:</strong>
                  <em v-if="data.revBoostData.prefix === ''"> none</em>
                  <template v-else>{{ data.revBoostData.prefix }}</template>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-container>

      <v-container v-if="data.taskStatus === 'running'">
        <v-card>
          <v-card-title>Update Percentage</v-card-title>
          <v-form ref="form" v-model="formValid" @submit.prevent="submit">
            <v-card-text>
              <v-slider
                v-model="data.revBoostData.percentage"
                min="0"
                max="100"
                label="Percentage"
              >
                <template v-slot:append>
                  <v-text-field
                    v-model="data.revBoostData.percentage"
                    class="mt-0 pt-0"
                    hide-details
                    single-line
                    type="number"
                    style="width: 60px"
                  />
                </template>
              </v-slider>
            </v-card-text>
            <v-card-actions class="pa-4">
              <v-btn
                color="primary"
                type="submit"
                block
              >Update Task</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-container>

      <v-container>
        <v-row>
          <v-col>
            <v-card>
              <template v-if="showDstGraph">
                <v-card-title class="title">Retail Carrier Graph</v-card-title>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <div class="carrier-graph">
                        <line-chart
                          :chart-data="graphDstData"
                          :options="graphOptions"
                          :styles="graphStyles"
                        />
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
              <template v-if="showGraph">
                <v-card-title class="title">Source Carrier Graph</v-card-title>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <div class="carrier-graph">
                        <line-chart
                          :chart-data="graphData"
                          :options="graphOptions"
                          :styles="graphStylesSmall"
                        />
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
              <template v-if="showGraphLastTen">
                <v-card-title class="title">Incoming Over Last 10 Minutes</v-card-title>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <div class="carrier-graph">
                        <bar-chart
                          :chart-data="graphLastTenData"
                          :options="graphOptions"
                          :styles="graphStylesSmall"
                        />
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="data.statsSummary.length > 0">
        <v-row>
          <v-col>
            <v-card>
              <v-card-title class="display-1">
                <span class="pr-5">Stats</span>
                <v-switch
                  v-model="advancedStats"
                  label="Advanced stats"
                  class="d-inline-flex"
                />
              </v-card-title>
              <template v-if="!advancedStats">
                <v-data-table
                  :headers="statsHeadersSummarySimple"
                  :items="summarySimple"
                  :sort-by.sync="summaryTableOptions.sortBy"
                  :sort-desc.sync="summaryTableOptions.descending"
                  :footer-props="{ itemsPerPageOptions: [10,50,{'text':'All',value:-1}] }"
                >
                  <template v-if="showQuickLaunch" v-slot:header.quickLaunch="props">
                    <v-tooltip bottom max-width="300">
                      <template v-slot:activator="{ on }">
                        <span v-on="on">
                          {{ props.header.text }}
                          <v-icon size="16" class="pl-1" v-on="on">help</v-icon>
                        </span>
                      </template>
                      Quick Launch - Launch the selected tasks using the "Default Task
                      Settings" defined in User Settings
                    </v-tooltip>
                  </template>
                  <template v-slot:item.profit="row">
                    {{ floorToTwo(row.item.profit) }}
                  </template>
                  <template v-slot:item.roi="row">
                    {{ formatRoi(row.item.roi) }}%
                  </template>
                  <template v-slot:item.carrier="row">
                    {{ row.item.carrier || 'Unknown' }}
                  </template>
                  <template v-if="showQuickLaunch" v-slot:item.quickLaunch="row">
                    <v-checkbox
                      v-model="quickLaunch"
                      :value="summaryQuickLaunch[row.item.id]"
                      class="short"
                    />
                  </template>
                </v-data-table>
                <v-card-actions v-if="quickLaunch.length > 0">
                  <v-spacer />
                  <v-btn
                    color="primary"
                    :disabled="quickLaunchSubmitting"
                    :loading="quickLaunchSubmitting"
                    @click="submitQuickLaunch()"
                  >
                    Quick Launch {{ quickLaunch.length }} Tasks
                  </v-btn>
                </v-card-actions>
              </template>
              <template v-if="advancedStats">
                <v-data-table
                  :headers="statsHeadersSummaryAdvanced"
                  :items="summaryAdvanced"
                  :sort-by.sync="summaryTableOptions.sortBy"
                  :sort-desc.sync="summaryTableOptions.descending"
                  :footer-props="{ itemsPerPageOptions: [10,50,{'text':'All',value:-1}] }"
                >
                  <template v-if="showQuickLaunch" v-slot:header.quickLaunch="props">
                    <v-tooltip bottom max-width="300">
                      <template v-slot:activator="{ on }">
                        <span v-on="on">
                          {{ props.header.text }}
                          <v-icon size="16" class="pl-1" v-on="on">help</v-icon>
                        </span>
                      </template>
                      Quick Launch - Launch the selected tasks using the "Default Task
                      Settings" defined in User Settings
                    </v-tooltip>
                  </template>
                  <template v-slot:item.profit="row">
                    {{ floorToTwo(row.item.profit) }}
                  </template>
                  <template v-slot:item.roi="row">
                    {{ formatRoi(row.item.roi) }}%
                  </template>
                  <template v-slot:item.carrierPrefix="row">
                    {{ row.item.carrierPrefix || 'Unknown' }}
                  </template>
                  <template v-slot:item.carrier="row">
                    {{ row.item.carrier || 'Unknown' }}
                  </template>
                  <template v-if="showQuickLaunch" v-slot:item.quickLaunch="row">
                    <v-checkbox
                      v-model="quickLaunch"
                      :value="summaryQuickLaunchAdvanced[row.item.id]"
                      class="short"
                    />
                  </template>
                </v-data-table>
                <v-card-actions v-if="quickLaunch.length > 0">
                  <v-spacer />
                  <v-btn
                    color="primary"
                    :disabled="quickLaunchSubmitting"
                    :loading="quickLaunchSubmitting"
                    @click="submitQuickLaunch()"
                  >
                    Quick Launch {{ quickLaunch.length }} Tasks
                  </v-btn>
                </v-card-actions>
              </template>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <v-dialog v-model="quickLaunchErrorDialog" max-width="500">
      <v-card>
        <v-card-title class="headline" color="error">Server Error</v-card-title>
        <v-card-text>
          <p>There was an error processing your request.</p>
          <p v-for="(err, key) in quickLaunchErrors" :key="key">{{ err }}</p>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="quickLaunchErrorDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </layout-panel>
</template>

<script>
  import { authFetch } from '@/plugins/authFetch'
  import { floorToTwo } from '@/plugins/common'
  import LineChart from '@/components/carrierChart'
  import BarChart from '@/components/barChart'

  export default {
    components: { LineChart, BarChart },
    props: {
      taskId: { type: Number, default: 0 },
    },
    data () {
      return {
        data: {},
        loading: true,
        error: false,
        summaryTableOptions: { sortBy: 'roi', descending: true },
        advancedStats: false,
        graphOptions: {
          maintainAspectRatio: false,
        },
        graphLastTenOptions: {
          scaleOverride: true,
          scaleStepWidth: 1,
          maintainAspectRatio: false,
        },
        graphStyles: {
          position: 'relative',
          height: '300px',
        },
        graphStylesSmall: {
          position: 'relative',
          height: '250px',
        },
        quickLaunch: [],
        summarySimple: [],
        summaryQuickLaunch: {},
        summaryQuickLaunchAdvanced: {},
        summaryAdvanced: [],
        quickLaunchErrors: [],
        quickLaunchSubmitting: false,
        quickLaunchErrorDialog: false,
        formValid: true,
        percentageLoading: false,
      }
    },
    computed: {
      statsHeadersSummarySimple () {
        const result = [
          { text: 'Source Carrier', value: 'carrier' },
          { text: 'ACD', value: 'ivr' },
          { text: 'Destination Carrier', value: 'dstCarrier' },
          { text: 'Country', value: 'country' },
          { text: 'Calls', value: 'totalCalls' },
          { text: 'Duration', value: 'billsec' },
          { text: 'Earned', value: 'profit' },
        ]
        if (this.showQuickLaunch) {
          result.push({ text: 'QL', value: 'quickLaunch' })
        }
        return result
      },
      statsHeadersSummaryAdvanced () {
        const result = [
          { text: 'Source Prefix', value: 'carrierPrefix' },
          { text: 'Source Carrier', value: 'carrier' },
          { text: 'ACD', value: 'ivr' },
          { text: 'Destination Prefix - Carrier', value: 'dstCarrierWithPrefix' },
          { text: 'Country', value: 'country' },
          { text: 'Calls', value: 'totalCalls' },
          { text: 'Duration', value: 'billsec' },
          { text: 'Earned', value: 'profit' },
        ]
        if (this.showQuickLaunch) {
          result.push({ text: 'QL', value: 'quickLaunch' })
        }
        return result
      },
      showDownload () {
        return this.$store.state.permission & 4
      },
      showQuickLaunch () {
        return this.$store.state.permission & 6
      },
      showRetails () {
        return Object.prototype.hasOwnProperty.call(this.data, 'retails') &&
          this.data.retails.length > 0
      },
      sourceGroupComputed () {
        return this.data.sourceGroup !== null ? this.data.sourceGroup : 'All'
      },
      showDstGraph () {
        return Object.prototype.hasOwnProperty.call(this.data, 'graph') &&
          Object.prototype.hasOwnProperty.call(this.data.graph, 'dataDst') &&
          this.data.graph.dataDst.length > 0
      },
      graphDstData () {
        return {
          labels: this.data.graph.x,
          datasets: this.data.graph.dataDst,
        }
      },
      showGraph () {
        return Object.prototype.hasOwnProperty.call(this.data, 'graph') &&
          Object.prototype.hasOwnProperty.call(this.data.graph, 'data') &&
          this.data.graph.data.length > 0
      },
      graphData () {
        return {
          labels: this.data.graph.x,
          datasets: this.data.graph.data,
        }
      },
      showGraphLastTen () {
        return Object.prototype.hasOwnProperty.call(this.data, 'graphLastTen') &&
          Object.prototype.hasOwnProperty.call(this.data.graphLastTen, 'data') &&
          this.data.graphLastTen.data.length > 0
      },
      graphLastTenData () {
        let bg = this.$vuetify.theme.themes.light.primary
        if (this.$vuetify.theme.dark === true) {
          bg = this.$vuetify.theme.themes.dark.primary
        }
        return {
          labels: this.data.graphLastTen.x,
          datasets: [{
            label: 'Calls',
            backgroundColor: bg,
            data: this.data.graphLastTen.data,
          }],
        }
      },
    },
    watch: {
      advancedStats () {
        this.quickLaunch = []
      },
    },
    created () {
      this.getData()
    },
    methods: {
      submitQuickLaunch () {
        const postData = { tasks: this.quickLaunch }
        this.quickLaunchErrors = []
        this.quickLaunchSubmitting = true
        this.quickLaunchErrorDialog = false
        authFetch(`${process.env.VUE_APP_API_ROOT}tasks/quick/`, {
          method: 'POST',
          body: JSON.stringify(postData),
        })
          .then(async response => {
            await response.json()
            this.$router.push({ name: 'tasks' })
          })
          .catch(async error => {
            console.log(error)
            const data = await error.json()
            if (typeof data === 'object' && Object.prototype.hasOwnProperty.call(data, 'message')) {
              // Is it an object we can loop through for feedback?
              if (typeof data.message === 'object') {
                Object.values(data.message).forEach(msg => {
                  this.quickLaunchErrors.push(msg)
                })
                // String returned, setup dialog
              } else {
                this.quickLaunchErrors = [data.message]
              }
              // Generic error if we didn't get a proper response
            } else {
              this.quickLaunchErrors = [`Server returned a ${error.status} status code`]
            }
          })
          .finally(() => {
            this.quickLaunchSubmitting = false
            if (this.quickLaunchErrors.length > 0) {
              this.quickLaunchErrorDialog = true
            }
          })
      },
      submit () {
        this.percentageLoading = true
        authFetch(`${process.env.VUE_APP_API_ROOT}tasks/rev-boost/`, {
          method: 'PUT',
          body: JSON.stringify({
            id: this.taskId,
            percentage: this.data.revBoostData.percentage,
          }),
        })
          .then(async response => {
            await response.json()
            this.getData()
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            this.percentageLoading = false
          })
      },
      floorToTwo (float) {
        return floorToTwo(float)
      },
      getData () {
        this.loading = true
        authFetch(`${process.env.VUE_APP_API_ROOT}task/${this.taskId}/`)
          .then(async response => {
            const data = await response.json()
            this.data = data
            const totals = {}
            if (Object.prototype.hasOwnProperty.call(this.data, 'stats') && Array.isArray(this.data.stats)) {
              this.data.stats.forEach(v => {
                if (!Object.prototype.hasOwnProperty.call(totals, v.pdd)) {
                  totals[v.pdd] = 0
                }
                totals[v.pdd] += v.totalCalls
              })
            }
            this.buildSummarySimple()
            this.buildSummaryAdvanced()
          })
          .catch((error) => {
            console.log(error)
            if (error.status === 404) {
              this.error = 'Task not found'
            } else {
              this.error = `Server returned a ${error.status} status code`
            }
          })
          .finally(() => {
            this.loading = false
          })
      },
      buildSummarySimple () {
        const merged = {}
        let id = 0
        this.data.statsSummary.forEach(row => {
          const secondKey = `${row.carrierBase}:${row.dstCarrier}`
          if (!Object.prototype.hasOwnProperty.call(merged, secondKey)) {
            merged[secondKey] = {
              carrier: row.carrierBase,
              dstCarrier: row.dstCarrier,
              country: row.country,
              totalCalls: 0,
              billsec: 0,
              profit: 0,
              cost: 0,
              ivr: row.ivr,
              id: id,
            }
          }
          merged[secondKey].totalCalls += row.totalCalls
          merged[secondKey].billsec += row.billsec
          merged[secondKey].profit += row.profit
          merged[secondKey].cost += row.cost
          this.summaryQuickLaunch[id] = {
            destinations: { carrierAll: [row.carrierBase] },
            sources: [row.dstCarrier],
          }
          id++
        })
        Object.keys(merged).forEach(k => {
          let roi = false
          if (typeof merged[k].cost === 'number' && merged[k].cost !== 0) {
            roi = this.floorToTwo(
              ((merged[k].profit - merged[k].cost) / merged[k].cost) * 100
            )
          }
          merged[k].roi = roi
        })
        this.summarySimple = Object.values(merged)
      },
      buildSummaryAdvanced () {
        const result = []
        let id = 0
        this.data.statsSummary.forEach(row => {
          let dstCarrierWithPrefix = 'Unknown'
          if (row.dstCarrierPrefix) {
            dstCarrierWithPrefix = `${row.dstCarrierPrefix} - ${row.dstCarrier}`
          }
          let roi = false
          if (typeof row.cost === 'number' && row.cost !== 0) {
            roi = ((row.profit - row.cost) / row.cost) * 100
          }
          result.push({
            carrierPrefix: row.carrierPrefix,
            carrier: row.carrier,
            dstCarrierWithPrefix: dstCarrierWithPrefix,
            country: row.country,
            totalCalls: row.totalCalls,
            billsec: row.billsec,
            profit: row.profit,
            roi: roi,
            ivr: row.ivr,
            id: id,
          })
          this.summaryQuickLaunchAdvanced[id] = {
            destinations: { carrier: [row.carrier] },
            sources: [row.dstCarrier],
          }
          id++
        })
        this.summaryAdvanced = result
      },
      formatRoi (val) {
        if (typeof val !== 'number') {
          return 'n/a'
        }
        return floorToTwo(val)
      },
    },
  }
</script>

<style>
.v-datatable__actions .v-datatable__actions__select .v-select__selections input {
  display: none
}
</style>

<style scoped>
::v-deep .short {
  margin-top: 5px;
}
::v-deep .short .v-input__control {
  height: 35px;
}
</style>

<template>
  <layout-panel>
    <v-container>
      <v-card>
        <v-card-text class="display-2 text-center mt-5 primary white--text">
          Working Access
        </v-card-text>
      </v-card>
    </v-container>
    <v-container v-if="loading === true" class="text-center mt-5">
      <v-progress-circular
        :size="50"
        :width="5"
        indeterminate
      />
    </v-container>
    <v-container>
      <v-alert
        v-if="loading === false && items.length === 0"
        :value="true"
        color="error"
        icon="warning"
        class="mt-5"
      >
        No data avaialable
      </v-alert>
      <v-card v-else-if="loading === false">
        <v-data-table
          :items="items"
          :headers="headers"
          :footer-props="{ itemsPerPageOptions: [50,100,{'text':'All',value:-1}] }"
          :items-per-page="50"
          :sort-by.sync="tableOptions.sortBy"
          :sort-desc.sync="tableOptions.descending"
        >
          <template v-slot:item.prefixes="row">
            {{ translatePrefixes(row.item.prefixes) }}
          </template>
          <template v-slot:item.crp="row">
            {{ getCallReturnPercentage(row.item) }}
          </template>
          <template v-slot:header.crp="props">
            <v-tooltip bottom max-width="300">
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  {{ props.header.text }}
                  <v-icon size="16" class="pl-1" v-on="on">help</v-icon>
                </span>
              </template>
              Call Return Ratio - Percentage of returning calls in comparison to
              Attempts per task.
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </layout-panel>
</template>

<script>
  import { authFetch } from '@/plugins/authFetch'
  import { floorToTwo } from '@/plugins/common'

  export default {
    data () {
      return {
        items: [],
        loading: true,
        headers: [
          { text: 'Added', value: 'added', width: '100px' },
          { text: 'Destination Carrier', value: 'destination', width: 200 },
          { text: 'Retail Carrier', value: 'retail', width: 200 },
          { text: 'Destination Prefixes', value: 'prefixes', width: '100px' },
          { text: 'CR%', value: 'crp', width: '75px' },
        ],
        tableOptions: {
          sortBy: 'added',
          descending: true,
        },

      }
    },
    created () {
      this.getData()
    },
    methods: {
      translatePrefixes (prefixes) {
        if (prefixes.length === 0) {
          return 'n/a'
        }
        return prefixes.join(', ')
      },
      getCallReturnPercentage (item) {
        if (item.totalCalls === 0) {
          return 'n/a'
        }
        return floorToTwo((item.totalIncoming / item.totalCalls) * 100).toString() + '%'
      },
      getData () {
        authFetch(`${process.env.VUE_APP_API_ROOT}routes/confirmed/`)
          .then(async response => {
            this.items = await response.json()
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.loading = false
          })
      },
    },
  }
</script>

<template>
  <layout-panel>
    <v-container><v-card>
      <v-card-text class="display-2 text-center mt-5 primary white--text">
        General Access Report
        <div class="grey--text text--lighten-1 body-1">
          Access that has been seen within the selected time period.
        </div>
      </v-card-text>
    </v-card></v-container>
    <v-container>
      <v-row>
        <v-col>
          <v-card>
            <v-form ref="form" @submit.prevent="getData">
              <v-container>
                <v-row>
                  <v-col cols="1">
                    <v-text-field
                      v-model="lookbackTime"
                      reverse
                      label="Lookback"
                      type="number"
                    />
                  </v-col>
                  <v-col cols="2">
                    <v-select
                      v-model="lookbackTimePeriod"
                      :items="lookbackPeriods"
                    />
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="filterCarrier"
                      label="Carrier filter"
                      :rules="[rules.moreThanTwoCharacters]"
                      validate-on-blur
                    />
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="filterDstCarrier"
                      label="Retail Carrier filter"
                      :rules="[rules.moreThanTwoCharacters]"
                      validate-on-blur
                    />
                  </v-col>
                  <v-col>
                    <v-radio-group v-model="advanced">
                      <v-radio label="Normal" :value="false" />
                      <v-radio label="Advanced" :value="true" />
                    </v-radio-group>
                  </v-col>
                  <v-col cols="2">
                    <v-btn
                      color="primary"
                      type="submit"
                      :loading="loading"
                    >
                      Get Pairs
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-alert
        v-if="loaded === true && loading === false && total === 0"
        :value="true"
        color="error"
        icon="warning"
      >
        No data available
      </v-alert>
    </v-container>
    <template v-if="loading === false">
      <template v-for="table_key in tables">
        <v-container v-if="showTable(table_key)" :key="table_key">
          <v-card>
            <v-card-title class="display-2">{{ titles[table_key] }}</v-card-title>
            <v-data-table
              :items="items[table_key]"
              :headers="headers"
              :footer-props="{ itemsPerPageOptions: [50,100,{'text':'All',value:-1}] }"
              :items-per-page="50"
              item-key="qlId"
            >
              <template v-slot:header.quickLaunch="props">
                <v-tooltip bottom max-width="300">
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      {{ props.header.text }}
                      <v-icon size="16" class="pl-1" v-on="on">help</v-icon>
                    </span>
                  </template>
                  Quick Launch - Launch the selected tasks using the "Default Task
                  Settings" defined in User Settings
                </v-tooltip>
              </template>
              <template v-slot:item.actions="row">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      @click.stop="sendAction(row)"
                    >file_copy</v-icon>
                  </template>
                  <span>Create task using pairs</span>
                </v-tooltip>
              </template>
              <template v-slot:item.quickLaunch="row">
                <v-checkbox
                  v-model="quickLaunch"
                  :value="quickLaunchItems[row.item.qlId]"
                  dense
                  class="short"
                />
              </template>
            </v-data-table>
            <v-card-actions v-if="quickLaunch.length > 0">
              <v-spacer />
              <v-btn
                color="primary"
                :disabled="quickLaunchSubmitting"
                :loading="quickLaunchSubmitting"
                @click="submitQuickLaunch()"
              >
                {{ submitButtonText }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-container>
      </template>
    </template>

    <v-dialog v-model="quickLaunchErrorDialog" max-width="500">
      <v-card>
        <v-card-title class="headline" color="error">Server Error</v-card-title>
        <v-card-text>
          <p>There was an error processing your request.</p>
          <p v-for="(err, key) in quickLaunchErrors" :key="key">{{ err }}</p>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="quickLaunchErrorDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </layout-panel>
</template>

<script>
  import { authFetch, objectToQueryString } from '@/plugins/authFetch'
  import * as rules from '@/plugins/rules'

  export default {
    data () {
      return {
        lookbackTime: 1,
        lookbackTimePeriod: 'day',
        lookbackPeriods: [
          { text: 'Minutes', value: 'minute' },
          { text: 'Hours', value: 'hour' },
          { text: 'Days', value: 'day' },
          { text: 'Months', value: 'month' },
          { text: 'Years', value: 'year' },
        ],
        items: {},
        loading: false,
        loaded: false,
        total: 0,
        titles: {
          0.3: '0.30+',
          0.15: '0.15 - 0.2999',
          0.1: '0.10 - 0.1499',
          0.05: '0.05 - 0.0999',
          0: '0 - 0.0499',
        },
        tables: ['0.3', '0.15', '0.1', '0.05', '0'],
        filterCarrier: '',
        filterDstCarrier: '',
        rules: rules,
        quickLaunch: [],
        quickLaunchItems: {},
        quickLaunchErrors: [],
        quickLaunchSubmitting: false,
        quickLaunchErrorDialog: false,
        advanced: false,
        advancedSubmitted: false,
        submitButtonOverride: null,
      }
    },
    computed: {
      headers () {
        let result = []
        if (this.advancedSubmitted === true) {
          result = [
            { text: 'Carrier Prefix', value: 'carrierPrefix' },
            { text: 'Carrier', value: 'carrier' },
            { text: 'Retail Carrier Prefix', value: 'dstCarrierPrefix' },
            { text: 'Retail Carrier', value: 'dstCarrier' },
            { text: 'Calls', value: 'calls' },
            { text: 'Actions', value: 'actions' },
            { text: 'QL', value: 'quickLaunch' },
          ]
          if (this.$store.state.permission === 8) {
            result.splice(5)
          }
          return result
        }
        result = [
          { text: 'Carrier', value: 'carrier' },
          { text: 'Retail Carrier', value: 'dstCarrier' },
          { text: 'Calls', value: 'calls' },
          { text: 'Actions', value: 'actions' },
          { text: 'QL', value: 'quickLaunch' },
        ]
        if (this.$store.state.permission === 8) {
          result.splice(3)
        }
        return result
      },
      submitButtonText () {
        if (this.submitButtonOverride) {
          return this.submitButtonOverride
        }
        return `Quick Launch ${this.quickLaunch.length} Tasks`
      },
    },
    methods: {
      showTable (key) {
        return Object.prototype.hasOwnProperty.call(this.items, key)
      },
      sendAction (row) {
        this.$router.push({
          name: 'task-new-from-pairs-query',
          query: {
            source: row.item.dstCarrier,
            destination: row.item.carrier,
            carrierAll: this.advancedSubmitted === true ? 0 : 1,
          },
        })
      },
      getData () {
        if (!this.$refs.form.validate()) {
          this.submitting = false
          return false
        }
        const queryString = objectToQueryString({
          time: this.lookbackTime,
          period: this.lookbackTimePeriod,
          filterCarrier: this.filterCarrier,
          filterDstCarrier: this.filterDstCarrier,
          advanced: this.advanced ? 1 : 0,
        })
        this.total = 0
        this.loading = true
        this.loaded = false
        this.items = {}
        authFetch(process.env.VUE_APP_API_ROOT + `pairs/?${queryString}`)
          .then(async response => {
            const items = await response.json()
            this.advancedSubmitted = this.advanced
            if (typeof items !== 'object') {
              return
            }
            this.quickLaunch = []
            this.quickLaunchItems = {}
            let id = 0
            this.tables.forEach(k => {
              if (Object.prototype.hasOwnProperty.call(items, k)) {
                this.total += items[k].length
                this.items[k] = []
                items[k].forEach(item => {
                  item.qlId = id
                  this.items[k].push(item)
                  if (this.advancedSubmitted === true) {
                    this.quickLaunchItems[id] = {
                      destinations: { carrier: [item.carrier] },
                      sources: [item.dstCarrier],
                    }
                  } else {
                    this.quickLaunchItems[id] = {
                      destinations: { carrierAll: [item.carrier] },
                      sources: [item.dstCarrier],
                    }
                  }
                  id++
                })
              }
            })
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.loading = false
            this.loaded = true
          })
      },
      submitQuickLaunch () {
        const postData = { tasks: this.quickLaunch }
        this.quickLaunchErrors = []
        this.quickLaunchSubmitting = true
        this.quickLaunchErrorDialog = false
        authFetch(`${process.env.VUE_APP_API_ROOT}tasks/quick/`, {
          method: 'POST',
          body: JSON.stringify(postData),
        })
          .then(async response => {
            await response.json()
            // this.$router.push({ name: 'tasks' })
            this.submitButtonOverride = 'Tasks Created'
            setTimeout(() => { this.submitButtonOverride = null }, 2000)
          })
          .catch(async error => {
            console.log(error)
            const data = await error.json()
            if (typeof data === 'object' && Object.prototype.hasOwnProperty.call(data, 'message')) {
              // Is it an object we can loop through for feedback?
              if (typeof data.message === 'object') {
                Object.values(data.message).forEach(msg => {
                  this.quickLaunchErrors.push(msg)
                })
                // String returned, setup dialog
              } else {
                this.quickLaunchErrors = [data.message]
              }
              // Generic error if we didn't get a proper response
            } else {
              this.quickLaunchErrors = [`Server returned a ${error.status} status code`]
            }
          })
          .finally(() => {
            this.quickLaunchSubmitting = false
            if (this.quickLaunchErrors.length > 0) {
              this.quickLaunchErrorDialog = true
            }
          })
      },
    },
  }
</script>

<style scoped>
::v-deep .short {
  margin-top: 5px;
}
::v-deep .short .v-input__control {
  height: 35px;
}
</style>

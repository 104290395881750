<template>
  <layout-panel>
    <v-container><v-card>
      <v-card-text class="display-2 text-center mt-5 primary white--text">
        <template v-if="historic === true">
          Historic
        </template>
        All-In-One Exploration
      </v-card-text>
    </v-card></v-container>
    <v-container>
      <v-card>
        <v-stepper v-model="formStepsCurrent" :vertical="false">
          <v-stepper-header>
            <v-stepper-step :step="1">
              Destination Selection
            </v-stepper-step>
            <v-divider />
            <v-stepper-step :step="2">
              Route Options
            </v-stepper-step>
            <v-divider />
            <v-stepper-step :step="3">
              Task Options
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>

            <!-- START DESTINATION SELECTION -->
            <v-stepper-content step="1">
              <v-form
                ref="formDesination"
                v-model="formValid"
                @submit.prevent="validateAndGetDestinations()"
              >
                <v-card-text>
                  <v-container class="m-3">
                    <v-row>
                      <v-col cols="3">
                        <v-select
                          v-model="destinationType"
                          :items="destinationTypes"
                          label="Destination Selection Method"
                        />
                      </v-col>
                    </v-row>
                    <!-- START WHITELIST -->
                    <v-row v-if="destinationType === 'whitelist'">
                      <v-col cols="2">
                        <v-select
                          v-model="formData.destinationLookbackType"
                          :items="destinationLookbackTypes"
                          label="CDR Lookback Method"
                        />
                      </v-col>
                      <template v-if="formData.destinationLookbackType === 'time'">
                        <v-col cols="1">
                          <v-text-field
                            v-model="destinationLookbackTimeNum"
                            reverse
                          />
                        </v-col>
                        <v-col cols="2">
                          <v-select
                            v-model="destinationLookbackTimePeriod"
                            :items="destinationLookbackPeriods"
                          />
                        </v-col>
                      </template>
                      <template v-if="formData.destinationLookbackType === 'rows'">
                        <v-col cols="3">
                          <v-text-field
                            v-model="formData.destinationLookbackData"
                            :rules="[rules.required, rules.positiveNumber]"
                            type="number"
                            label="Amount"
                          />
                        </v-col>
                      </template>
                      <v-col cols="12">
                        <v-btn color="primary" @click="addWhitelist()">Add Filter</v-btn>
                      </v-col>
                      <v-col cols="12">
                        <template v-for="(child, index) in whitelists">
                          <component
                            :is="child.component"
                            :key="index"
                            v-model="formData.whitelists[index]"
                            v-bind="{
                              index: index,
                              errors: child.errors,
                              lookbackType: formData.destinationLookbackType,
                              lookbackData: formData.destinationLookbackData
                            }"
                            @removeWhitelist="removeWhitelist"
                          />
                        </template>
                      </v-col>
                    </v-row>
                    <!-- END WHITELIST -->
                    <!-- START HLR - Not currently used -->
                    <v-row v-else-if="destinationType === 'hlr'">
                      <v-col cols="3">
                        <div class="mt-5 text-end">HLR Minimum Updated Time</div>
                      </v-col>
                      <v-col cols="1">
                        <v-text-field
                          v-model="destinationLookbackTimeNum"
                          reverse
                        />
                      </v-col>
                      <v-col cols="2">
                        <v-select
                          v-model="destinationLookbackTimePeriod"
                          :items="destinationLookbackPeriods"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-btn color="primary" @click="addHLR()">Add Filter</v-btn>
                      </v-col>
                      <template v-for="(child, index) in hlrWhitelists">
                        <component
                          :is="child.component"
                          :key="index"
                          v-model="hlrWhitelistFilters[index]"
                          v-bind="{
                            index: index,
                            errors: child.errors,
                            lookbackType: formData.destinationLookbackType,
                            lookbackData: formData.destinationLookbackData
                          }"
                          @removeWhitelist="removeHLR"
                        />
                      </template>
                    </v-row>
                    <!-- END HLR -->
                    <!-- START CSV -->
                    <v-row v-if="formData.destinationType === 'custom'">
                      <v-col cols="6">
                        <v-file-input
                          accepts=".csv,text/csv"
                          label="CSV Number and Rate File"
                          required
                          :error="csvError.length > 0"
                          :error-messages="csvError"
                          @change="onFileChange"
                        />
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          v-model="csvNumberColumn"
                          :rules="[rules.required, rules.numberGT0]"
                          required
                          label="Number Column"
                          type="number"
                        />
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          v-model="csvRate"
                          :rules="[rules.required, rules.positiveFloat]"
                          required
                          label="Rate"
                          type="number"
                        />
                      </v-col>
                    </v-row>
                    <!-- END CSV -->
                  </v-container>
                </v-card-text>
                <task-stepper-actions
                  :page="formStepsCurrent"
                  :total-pages="formStepsTotal"
                />
              </v-form>
            </v-stepper-content>
            <!-- END DESTINATION SELECTION -->

            <!-- START ROUTE OPTIONS -->
            <v-stepper-content :step="2">
              <v-form
                ref="formRouteOptions"
                v-model="formValid"
                @submit.prevent="validateRouteOptions()"
              >
                <v-card-text>
                  <v-container class="m-3">
                    <v-row>
                      <template v-if="routesLoading === false">
                        <v-col cols="4">
                          <v-select
                            v-model="formData.routeId"
                            label="Route"
                            :items="routesItems"
                            :rules="[rules.required, rules.positiveNumber]"
                          />
                        </v-col>
                        <v-col cols="2">
                          <v-btn color="primary" @click="routeForm = true">
                            New Route
                          </v-btn>
                        </v-col>
                      </template>
                      <v-col v-else cols="6" style="text-align: center">
                        <v-progress-circular
                          :size="35"
                          :width="3"
                          indeterminate
                        />
                      </v-col>
                      <v-col cols="4" offset="1">
                        <v-select
                          v-model="formData.routeDialServer"
                          :items="dialServerItems"
                          label="Outgoing Dial Server"
                        >
                          <template v-slot:append-outer>
                            <v-tooltip bottom max-width="400">
                              <template v-slot:activator="{ on }">
                                <v-icon v-on="on">help</v-icon>
                              </template>
                              Select the dial server you want to use for this task.
                              Dial servers support a maximum number of outgoing calls
                              per server and a maximum number of calls per second. If
                              you go over this limit per server, tasks will fail to
                              start.
                            </v-tooltip>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <task-stepper-actions
                  :page="formStepsCurrent"
                  :total-pages="formStepsTotal"
                  :loading="submitting"
                  @prev="formStepsCurrent--"
                />
              </v-form>
            </v-stepper-content>
            <!-- END ROUTE OPTIONS -->

            <!-- START TASK OPTIONS -->
            <v-stepper-content :step="3">
              <v-form
                ref="formTaskOptions"
                v-model="formValid"
                @submit.prevent="validateTaskOptions()"
              >
                <v-card-text>
                  <v-container class="m-3">
                    <v-row>
                      <v-col cols="3">
                        <v-text-field
                          v-model="formData.taskName"
                          :rules="[rules.required]"
                          required
                          label="Task Name"
                        />
                      </v-col>
                      <v-col v-if="taskType !== 'finalized'" cols="3">
                        <v-text-field
                          v-model="formData.taskDuration"
                          :rules="[rules.required, rules.numberGT0]"
                          required
                          label="Task Duration (minutes)"
                          type="number"
                        />
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          v-model="formData.taskACD"
                          :rules="[rules.required, rules.positiveRange]"
                          required
                          label="ACD Required"
                          hint="Range"
                        >
                          <template v-slot:append>
                            <v-tooltip bottom max-width="400">
                              <template v-slot:activator="{ on }">
                                <v-icon v-on="on">help</v-icon>
                              </template>
                              Setting the ACD is setting the average duration of the
                              outgoing calls, this is a requirement that will change
                              between routes.
                            </v-tooltip>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          v-model="formData.sourceCliPrefix"
                          label="CLI Prefix"
                        >
                          <template v-slot:append>
                            <v-tooltip bottom max-width="400">
                              <template v-slot:activator="{ on }">
                                <v-icon v-on="on">help</v-icon>
                              </template>
                              Add a prefix to the CallerID when the call is delivered.
                            </v-tooltip>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          v-model="formData.taskMaxSimultaneousCalls"
                          :rules="[rules.required, rules.positiveNumber]"
                          required
                          label="Max Channels"
                          type="number"
                          :hint="channelsHint"
                          :persistent-hint="cpsChannelsDisabled"
                          min="1"
                          :max="channelsMax"
                          :disabled="cpsChannelsDisabled"
                        />
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          v-model="formData.taskMaxCallsPerInterval"
                          :rules="[rules.required, rules.positiveRange]"
                          required
                          label="Max Calls per Interval"
                          :hint="cpsHint"
                          :persistent-hint="cpsChannelsDisabled"
                          :disabled="cpsChannelsDisabled"
                        />
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          v-model="formData.taskMaxCallsInterval"
                          :rules="[rules.required, rules.positiveRange]"
                          required
                          label="Max Calls Interval (Seconds)"
                          hint="Range"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3">
                        <v-text-field
                          v-model="formData.taskPDDs"
                          :rules="[rules.required, rules.positiveCommaSepNumbers]"
                          validate-on-blur
                          required
                          label="PDDs (comma separated)"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <task-stepper-actions
                  :page="formStepsCurrent"
                  :total-pages="formStepsTotal"
                  :loading="submitting"
                  @prev="formStepsCurrent--"
                />
              </v-form>
            </v-stepper-content>
            <!-- END TASK OPTIONS -->
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-container>
    <v-dialog v-model="errorDialog" max-width="500">
      <v-card>
        <v-card-title class="headline" color="error">Validation Error</v-card-title>
        <v-card-text>
          <p>You made mistakes on the form that must be fixed.</p>
          <p v-for="(error, key) in errorDialogText" :key="key">{{ error }}</p>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="errorDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="lookbackWarning" persistent max-width="500">
      <v-card>
        <v-card-title class="headline">CDR Lookback Warning</v-card-title>
        <v-card-text>
          <p>You have chosen less than 30 days for CDR lookback. This can be dangerous and cause
            unexpected and unpredicatble behaviour</p>
          <p>Are you sure you want to continue?</p>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="error" @click="lookbackWarning = false">Cancel</v-btn>
          <v-btn color="primary" @click="validateAndGetDestinations(true)">I Understand</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="routeForm" max-width="500">
      <new-route-form @completed="handleNewRoute" @cancel="routeForm = false" />
    </v-dialog>
  </layout-panel>
</template>

<script>
  import { authFetch } from '@/plugins/authFetch'
  import WhitelistRow from '@/components/WhitelistRow'
  import HLRRow from '@/components/HLRRow'
  import * as rules from '@/plugins/rules'
  import {
    commaSeparatedNumbersToList,
    numberRangeToList,
    pipeSeparatedStringsToList,
    defaultDialServerMaximums,
  } from '@/plugins/common'
  import TaskStepperActions from '@/components/TaskStepperActions'
  import NewRouteForm from '@/components/ModalRouteForm.vue'

  export default {
    components: { TaskStepperActions, NewRouteForm },
    props: {
      taskType: { type: String, default: 'exploration' },
      historic: { type: Boolean, default: false },
      previousFormData: {
        type: Object,
        default () {
          return {
            sources: [],
            sourceCount: 'one',
            sourceType: 'auto',
            sourceAutoWhitelist: '',
            sourceAutoWhitelistType: 'white',
            sourceAutoBan: false,
            sourceAutoRefine: false,
            sourceMinimumPopularity: '',
            sourceLookbackTime: 1,
            sourceLookbackTimePeriod: 'day',
            sourceGroup: null,
            sourceCliPrefix: '',
            destinationType: 'whitelist',
            destinationLookbackType: 'time',
            destinationLookbackData: '3 month',
            destinationCSV: null,
            whitelists: {},
            taskName: '',
            taskDuration: '',
            taskACD: '',
            taskPDDs: '',
            taskMaxASR: '',
            taskMinASR: '',
            taskPDDLock: true,
            taskMaxSimultaneousCalls: '',
            taskMaxCallsPerInterval: '',
            taskMaxCallsInterval: '',
            routeId: '',
            routeDialServer: null,
            schedule: {},
            scheduleMinimumRoI: null,
            scheduleMaximumTimeBelow: null,
            scheduleGrace: null,
            sourceLastSeenCheck: 'none',
          }
        },
      },
    },
    data () {
      return {
        lookbackWarning: false,
        whitelists: {},
        whitelistKey: 0,
        submitting: false,
        errorDialog: false,
        errorDialogText: '',
        rules: rules,
        formValid: true,
        destinationLookbackTypes: [
          { text: 'Time', value: 'time' },
          { text: 'Rows', value: 'rows' },
        ],
        destinationLookbackPeriods: [
          { text: 'Hours', value: 'hour' },
          { text: 'Days', value: 'day' },
          { text: 'Months', value: 'month' },
          { text: 'Years', value: 'year' },
        ],
        manualLookbackPeriods: [
          { text: 'Minutes', value: 'minute' },
          { text: 'Hours', value: 'hour' },
          { text: 'Days', value: 'day' },
          { text: 'Months', value: 'month' },
          { text: 'Years', value: 'year' },
        ],
        formData: this.previousFormData,
        sources: [],
        manualLookbackLoading: false,
        manualRemoved: [],
        manualSearch: '',
        manualRemovedSearch: '',
        manualHeaders: [
          { text: 'Number', value: 'number' },
          { text: 'Pop', value: 'popularity' },
        ],
        manualTableOptions: { sortBy: 'popularity', descending: true },
        test: ['a', 'b', 'c'],
        autoWhitelistTypes: [
          { text: 'Whitelist', value: 'white' },
          { text: 'Blacklist', value: 'black' },
        ],
        dialServerItems: [],
        dialServerData: {},
        dialServerDefault: null,
        routesItems: [],
        routesLoading: true,
        routeForm: false,
        hlrWhitelists: {},
        hlrWhitelistKey: 0,
        hlrWhitelistFilters: {},
        csvError: [],
        csvNumberColumn: null,
        csvRate: null,
        csvFile: false,
        destinationType: this.previousFormData.destinationType,
        showDestinationsOverride: false,
        formStepsCurrent: 1,
        formStepsTotal: 3,
      }
    },
    computed: {
      cpsHint () {
        if (this.cpsChannelsDisabled === true) {
          return 'Must select Outgoing Dial Server first'
        }
        return `Maximum ${this.dialServer.maxCPS}`
      },
      cpsMax () {
        if (this.cpsChannelsDisabled === true) {
          return 0
        }
        return this.dialServer.maxCPS
      },
      channelsHint () {
        if (this.cpsChannelsDisabled === true) {
          return 'Must select Outgoing Dial Server first'
        }
        return `Maximum ${this.dialServer.maxChannels}`
      },
      channelsMax () {
        if (this.cpsChannelsDisabled === true) {
          return 0
        }
        return this.dialServer.maxChannels
      },
      cpsChannelsDisabled () {
        return this.dialServer === null
      },
      dialServer () {
        if (this.formData.routeDialServer === 'auto') {
          return {
            ip: 'auto',
            maxCPS: defaultDialServerMaximums.cps,
            maxChannels: defaultDialServerMaximums.channels,
          }
        }
        let ip = this.formData.routeDialServer
        if (ip === null && this.dialServerDefault !== null) {
          ip = this.dialServerDefault
        }
        if (Object.prototype.hasOwnProperty.call(this.dialServerData, ip) === false) {
          return null
        }
        return this.dialServerData[ip]
      },
      destinationTypes () {
        if (this.$store.state.permission & 4) {
          return [
            { text: 'Whitelist', value: 'whitelist' },
            { text: 'CSV File', value: 'custom' },
          ]
        }
        return [
          { text: 'Whitelist', value: 'whitelist' },
          { text: 'HLR', value: 'hlr' },
          { text: 'CSV File', value: 'custom' },
        ]
      },
      showDestinationFilters () {
        return this.formData.destinationType === 'whitelist'
      },
      destinationLookbackTimeNum: {
        get () {
          if (typeof this.formData.destinationLookbackData === 'undefined') {
            return 1
          }
          const parts = this.formData.destinationLookbackData.split(' ')
          if (isNaN(parts[0])) {
            return 1
          }
          return parseInt(parts[0], 10)
        },
        set (value) {
          this.formData.destinationLookbackData = `${value} ${this.destinationLookbackTimePeriod}`
        },
      },
      destinationLookbackTimePeriod: {
        get () {
          if (typeof this.formData.destinationLookbackData === 'undefined') {
            return 'day'
          }
          const parts = this.formData.destinationLookbackData.split(' ')
          if (parts.length < 2) {
            return 'day'
          }
          return parts[1]
        },
        set (value) {
          this.formData.destinationLookbackData = `${this.destinationLookbackTimeNum} ${value}`
        },
      },
      formDataLookbackData () {
        return this.formData.destinationLookbackData
      },
      formDataLookbackType () {
        return this.formData.destinationLookbackType
      },
      whiteOrBlacklist () {
        return this.formData.sourceAutoWhitelistType === 'white' ? 'Whitelist' : 'Blacklist'
      },
    },
    watch: {
      destinationType () {
        this.$set(this.formData, 'destinationType', this.destinationType)
      },
      formDataLookbackData () {
        if (this.formData.destinationLookbackType === 'time') {
          const parts = this.formData.destinationLookbackData.split(' ')
          if (parts.length < 2) {
            return
          }
          this.destinationLookbackTimeNum = parts[0]
          this.destinationLookbackTimePeriod = parts[1]
        }
      },
      formDataLookbackType () {
        if (this.formData.destinationLookbackType === 'time') {
          this.formData.destinationLookbackData = '1 day'
        } else {
          this.formData.destinationLookbackData = '1000'
        }
      },
    },
    mounted () {
      if (Object.keys(this.formData.whitelists).length === 0) {
        this.addWhitelist()
        this.addHLR()
      } else {
        if (Array.isArray(this.formData.whitelists) === true) {
          this.formData.whitelists = { ...this.formData.whitelists }
        }
        Object.keys(this.formData.whitelists).forEach((value, key) => {
          if (this.formData.destinationType === 'whitelist') {
            this.addWhitelist()
            this.addHLR()
          } else if (this.formData.destinationType === 'hlr') {
            this.hlrWhitelistFilters[key] = this.formData.whitelists[value]
            this.addWhitelist()
            this.addHLR(key)
          }
        })
      }
      if (this.taskId < 1) {
        this.formData.taskName = ''
      }
      authFetch(`${process.env.VUE_APP_API_ROOT}servers/`)
        .then(async response => {
          const items = await response.json()
          if (typeof items === 'object' && Object.prototype.hasOwnProperty.call(items, 'servers')) {
            if (items.servers.length > 0) {
              this.dialServerItems.push({
                text: 'Automatic',
                value: 'auto',
              })
            }
            if (Object.prototype.hasOwnProperty.call(items, 'default') && items.default !== null) {
              this.dialServerItems.push({
                text: `Use Default (${items.default.ip} - ` +
                  `${items.default.maxCPS} CPS/${items.default.maxChannels} Ports)`,
                value: null,
              })
              this.dialServerDefault = items.default.ip
            }
            items.servers.forEach(server => {
              this.dialServerItems.push({
                text: `${server.ip} - ${server.maxCPS} CPS/` +
                  `${server.maxChannels} Ports`,
                value: server.ip,
              })
              this.$set(this.dialServerData, server.ip, server)
            })
            if (this.dialServerDefault === null && this.formData.routeDialServer === null &&
              this.dialServerItems.length > 0) {
              this.$set(this.formData, 'routeDialServer', this.dialServerItems[0].value)
            }
          }
        })
      this.loadRoutes(this.formData.routeId)
    },
    methods: {
      formVerifyValidity (form, valid) {
        if (this.errorDialogText.length > 0) {
          this.errorDialog = true
          valid = false
        }
        if (form.validate() === false || valid === false) {
          this.submitting = false
          return false
        }
        return true
      },
      validateAndGetDestinations (ignoreLookback = false) {
        let valid = true
        this.errorDialogText = []
        this.lookbackWarning = false
        this.csvError = []
        this.destinationFilters = []

        // Check all destination filters and build
        let filters = []
        // Check for empty destination filters
        if (this.formData.destinationType === 'whitelist') {
          if (Object.keys(this.formData.whitelists).length < 1) {
            this.errorDialogText.push('Must include at least one destination filter')
          } else {
            filters = this.validateAndGetFilters()
            if (filters === false) {
              valid = false
            }
          }
        }
        if (this.formData.destinationType === 'hlr') {
          if (Object.keys(this.hlrWhitelistFilters).length < 1) {
            this.errorDialogText.push('Must include at least one destination filter')
          } else {
            filters = this.validateAndGetHLRFilters()
            if (filters === false) {
              valid = false
            }
          }
        }

        // CSV stuff
        if (this.formData.destinationType === 'custom') {
          if (this.csvFile === false) {
            this.csvError.push('File is required')
            valid = false
          }
        }

        if (this.externalTask === false &&
          this.formVerifyValidity(this.$refs.formDesination, valid) === false) {
          return false
        }

        if ((this.formData.destinationType === 'whitelist') &&
          this.formData.destinationLookbackType === 'time' &&
          this.destinationLookbackTimePeriod === 'day' &&
          this.destinationLookbackTimeNum <= 30 &&
          ignoreLookback === false) {
          this.lookbackWarning = true
          return false
        }

        if (this.formStepsCurrent < this.formStepsTotal) {
          this.formStepsCurrent++
        }
        return filters
      },
      validateTaskOptions () {
        if (this.formVerifyValidity(this.$refs.formTaskOptions, true) === false) {
          return false
        }

        if (this.formStepsCurrent === this.formStepsTotal) {
          return this.submit()
        } else {
          this.formStepsCurrent++
        }
      },
      validateRouteOptions () {
        if (this.formVerifyValidity(this.$refs.formRouteOptions, true) === false) {
          return false
        }

        if (this.formStepsCurrent === this.formStepsTotal) {
          return this.submit()
        } else {
          this.formStepsCurrent++
        }
      },
      onFileChange (f) {
        this.csvFile = f
        if (!this.csvFile) {
          this.csvFile = false
        }
      },
      toBase64 (file) {
        if (this.formData.destinationType === 'custom') {
          return new Promise((resolve, reject) => {
            var reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
          })
        } else {
          return new Promise((resolve, reject) => {
            resolve('')
          })
        }
      },
      handleNewRoute (id) {
        this.loadRoutes(id)
        this.routeForm = false
      },
      loadRoutes (setTo) {
        this.routesLoading = true
        authFetch(process.env.VUE_APP_API_ROOT + 'routes/')
          .then(async response => {
            const items = await response.json()
            this.routesItems = []
            items.forEach(r => {
              let prefix = 'No Prefix'
              if (r.prefix !== '') {
                prefix = `Prefix: ${r.prefix}`
              }
              let labelText = ''
              if (r.label) {
                labelText = `${r.label} - ${r.ip}:${r.port} ${prefix}`
              } else {
                labelText = `${r.ip}:${r.port} ${prefix}`
              }
              this.routesItems.push({
                text: labelText,
                value: r.id,
              })
            })
          })
          .finally(() => {
            this.routesLoading = false
            if (typeof setTo !== 'undefined') {
              this.formData.routeId = setTo
            }
          })
      },
      addWhitelist (key) {
        if (key !== undefined) {
          this.whitelistKey = key
        }
        this.$set(this.whitelists, this.whitelistKey, {
          component: WhitelistRow,
          errors: {
            stringError: '',
            lengthsError: '',
            randomDigitsError: '',
            rateError: '',
          },
        })
        this.whitelistKey++
      },
      removeWhitelist (index) {
        if (Object.prototype.hasOwnProperty.call(this.whitelists, index)) {
          this.$delete(this.whitelists, index)
        }
        if (Object.prototype.hasOwnProperty.call(this.formData.whitelists, index)) {
          this.$delete(this.formData.whitelists, index)
        }
      },
      addHLR (key) {
        if (key !== undefined) {
          this.hlrWhitelistKey = key
        }
        this.$set(this.hlrWhitelists, this.hlrWhitelistKey, {
          component: HLRRow,
          errors: {
            stringError: '',
            rateError: '',
          },
        })
        this.hlrWhitelistKey++
      },
      removeHLR (index) {
        if (Object.prototype.hasOwnProperty.call(this.hlrWhitelists, index)) {
          this.$delete(this.hlrWhitelists, index)
        }
        if (Object.prototype.hasOwnProperty.call(this.hlrWhitelistFilters, index)) {
          this.$delete(this.hlrWhitelistFilters, index)
        }
      },
      validateAndGetFilters () {
        const filters = []
        let valid = true
        for (const [key, filter] of Object.entries(this.formData.whitelists)) {
          if (!filter.string) {
            this.whitelists[key].errors.stringError = 'Required'
            valid = false
          }
          if (!filter.lengths) {
            this.whitelists[key].errors.lengthsError = 'Required'
            valid = false
          }
          if (!filter.rate) {
            this.whitelists[key].errors.rateError = 'Required'
            valid = false
          }
          let string = filter.string
          if (typeof string === 'string') {
            string = [string]
          }
          if (valid) {
            filters.push({
              type: filter.type,
              string: string,
              lengths: commaSeparatedNumbersToList(filter.lengths),
              randomDigits: commaSeparatedNumbersToList(filter.randomDigits),
              rate: filter.rate,
            })
          } else {
            return false
          }
        }
        return filters
      },
      validateAndGetHLRFilters () {
        const filters = []
        let valid = true
        for (const [key, filter] of Object.entries(this.hlrWhitelistFilters)) {
          if (!filter.string) {
            this.hlrWhitelists[key].errors.stringError = 'Required'
            valid = false
          }
          if (!filter.rate) {
            this.hlrWhitelists[key].errors.rateError = 'Required'
            valid = false
          }
          if (valid) {
            filters.push({
              type: filter.type,
              string: filter.string,
              rate: filter.rate,
            })
          } else {
            return false
          }
        }
        return filters
      },
      submit () {
        this.lookbackWarning = false
        this.errorDialogText = []
        let valid = true

        const sources = []
        const filters = this.validateAndGetDestinations(true)
        if (filters === false) {
          valid = false
        }

        let maxCPS = 0
        let maxChannels = 0
        if (this.formData.routeDialServer === null && this.dialServerDefault !== null) {
          maxCPS = this.dialServerData[this.dialServerDefault].maxCPS
          maxChannels = this.dialServerData[this.dialServerDefault].maxChannels
        } else if (this.dialServer !== null) {
          maxCPS = this.dialServer.maxCPS
          maxChannels = this.dialServer.maxChannels
        }

        let taskMaxSimultaneousCalls = parseInt(this.formData.taskMaxSimultaneousCalls, 10)
        if (taskMaxSimultaneousCalls > maxChannels) {
          taskMaxSimultaneousCalls = maxChannels
          this.formData.taskMaxSimultaneousCalls = maxChannels
        }
        const taskMaxCallsPerInterval = numberRangeToList(this.formData.taskMaxCallsPerInterval)
        let k = 0
        if (taskMaxCallsPerInterval.length > 1) {
          k = 1
        }
        if (taskMaxCallsPerInterval[k] > maxCPS) {
          taskMaxCallsPerInterval[k] = maxCPS
        }

        // CSV stuff
        let csvNumberColumn = 0
        let csvRate = 0
        if (this.formData.destinationType === 'custom') {
          csvNumberColumn = this.csvNumberColumn
          csvRate = this.csvRate
        }

        // Show dialog if needed
        if (this.errorDialogText.length > 0) {
          this.errorDialog = true
          valid = false
        }

        if (valid === false) {
          this.submitting = false
          return false
        }

        const postData = {
          taskType: this.taskType,
          taskParent: 0,
          sources: sources,
          sourceType: this.formData.sourceType,
          sourceCount: this.formData.sourceCount,
          sourceLookbackTime: this.formData.sourceLookbackTime,
          sourceLookbackTimePeriod: this.formData.sourceLookbackTimePeriod,
          sourceAutoWhitelist: pipeSeparatedStringsToList(this.formData.sourceAutoWhitelist),
          sourceAutoWhitelistType: this.formData.sourceAutoWhitelistType,
          sourceAutoBan: this.formData.sourceAutoBan,
          sourceAutoRefine: this.formData.sourceAutoRefine,
          sourceMinimumPopularity: parseInt(this.formData.sourceMinimumPopularity),
          sourceGroup: this.formData.sourceGroup,
          sourceLastSeenCheck: this.formData.sourceLastSeenCheck,
          sourceCliPrefix: this.formData.sourceCliPrefix,
          destinationType: this.formData.destinationType,
          destinationLookbackType: this.formData.destinationLookbackType,
          destinationLookbackData: this.formData.destinationLookbackData,
          destinationFilters: filters,
          destinationCSVNumberColumn: csvNumberColumn,
          destinationCSVRate: parseFloat(csvRate),
          taskName: this.formData.taskName,
          taskDuration: parseInt(this.formData.taskDuration, 10),
          taskPDDs: commaSeparatedNumbersToList(this.formData.taskPDDs),
          taskMinASR: parseInt(this.formData.taskMinASR, 10),
          taskMaxASR: parseInt(this.formData.taskMaxASR, 10),
          taskACD: numberRangeToList(this.formData.taskACD),
          taskPDDLock: this.formData.taskPDDLock,
          taskMaxSimultaneousCalls: taskMaxSimultaneousCalls,
          taskMaxCallsPerInterval: taskMaxCallsPerInterval,
          taskMaxCallsInterval: numberRangeToList(this.formData.taskMaxCallsInterval),
          routeId: parseInt(this.formData.routeId, 10),
          routeDialServer: this.formData.routeDialServer,
          historic: this.historic,
        }

        if (this.taskId > 0) {
          postData.taskId = this.taskId
        }

        this.submitting = true
        let method = ''
        if (this.taskId > 0) {
          method = 'PUT'
          postData.id = this.taskId
        } else {
          method = 'POST'
        }
        this.toBase64(this.csvFile).then(async b64File => {
          postData.destinationCSV = b64File
          await authFetch(`${process.env.VUE_APP_API_ROOT}tasks/aio/`, {
            method: method,
            body: JSON.stringify(postData),
          })
            .then(async response => {
              const data = await response.json()
              if (Object.prototype.hasOwnProperty.call(data, 'result') && data.result === 'success') {
                this.$router.push({ name: 'tasks' })
              } else {
                this.errorDialogText = ['Received an unexpected response from the server. Not sure what to do']
                this.valid = true
              }
            })
            .catch(async error => {
              console.log(error)
              const data = await error.json()
              // Correct error response?
              if (typeof data === 'object' && Object.prototype.hasOwnProperty.call(data, 'message')) {
                // Is it an object we can loop through for feedback?
                if (typeof data.message === 'object') {
                  this.errorDialogText = data.message
                // String returned, setup dialog
                } else {
                  this.errorDialogText = [data.message]
                }
              // Generic error if we didn't get a proper response
              } else {
                this.errorDialogText = [`Server returned a ${error.status} status code`]
              }
              if (this.errorDialogText) {
                this.errorDialog = true
              }
            })
            .finally(() => {
              this.submitting = false
            })
        })
      },
    },
  }
</script>

<style>
.v-datatable__actions .v-datatable__actions__select .v-select__selections input {
  display: none
}
.switch-center {
  display: flex;
  justify-content: center;
}
</style>

<template>
  <layout-panel>
    <v-container><v-card>
      <v-card-text class="display-2 text-center mt-5 primary white--text">
        Reverse Arbitrage
      </v-card-text>
    </v-card></v-container>
    <reverse-form-steps
      key="reverse-arbitrage"
      task-type="reverse-arbitrage"
    />
  </layout-panel>
</template>

<script>
  import ReverseFormSteps from '@/components/ReverseFormSteps'

  export default {
    components: { ReverseFormSteps },
    props: {
      type: { type: String, default: 'exploration' },
      external: { type: Boolean, default: false },
    },
  }
</script>

<template>
  <v-card-actions>
    <v-spacer />
    <v-btn
      v-if="showPrev"
      x-large
      @click="prev()"
    >Previous</v-btn>
    <v-btn
      v-if="showNext"
      type="submit"
      color="primary"
      x-large
      :disabled="loading"
      :loading="loading"
      @click="next()"
    >Next</v-btn>
    <v-btn
      v-if="showSubmit"
      type="submit"
      color="primary"
      x-large
      :disabled="loading"
      :loading="loading"
      @click="next()"
    >Submit</v-btn>
  </v-card-actions>
</template>

<script>
  export default {
    props: {
      page: { type: Number, required: true },
      totalPages: { type: Number, required: true },
      loading: { type: Boolean, default: false },
    },
    computed: {
      showNext () {
        return this.page < this.totalPages
      },
      showPrev () {
        return this.page > 1 && this.loading === false
      },
      showSubmit () {
        return this.page === this.totalPages
      },
    },
    methods: {
      next () {
        if (this.page < this.totalPages) {
          this.$emit('next')
        }
      },
      prev () {
        if (this.page > 1) {
          this.$emit('prev')
        }
      },
    },
  }
</script>

<template>
  <layout-panel>
    <v-container>
      <v-card>
        <v-card-text class="display-2 text-center mt-5 primary white--text">
          My SIP Routes
        </v-card-text>
      </v-card>
    </v-container>
    <route-form :id="id" @completed="completed" />
  </layout-panel>
</template>

<script>
  import RouteForm from '@/components/RouteForm'

  export default {
    components: { RouteForm },
    props: {
      id: { type: Number, default: 0 },
    },
    data () {
      return {}
    },
    methods: {
      completed () {
        this.$router.push({ name: 'routes' })
      },
    },
  }
</script>

<template>
  <layout-panel>
    <v-container><v-card>
      <v-card-text class="display-2 text-center mt-5 primary white--text">
        Default Task Settings
      </v-card-text>
    </v-card></v-container>
    <v-col v-if="loading" class="text-center">
      <v-progress-circular
        :size="70"
        :width="7"
        indeterminate
      />
    </v-col>
    <task-form
      v-else-if="formData === null"
      task-type="template"
    />
    <task-form
      v-else
      task-type="template"
      :previous-form-data="formData"
      :has-existing-destinations="true"
    />
  </layout-panel>
</template>

<script>
  import TaskForm from '@/components/TaskForm'
  import { authFetch } from '@/plugins/authFetch'

  export default {
    components: { TaskForm },
    data () {
      return {
        data: null,
        loading: true,
        error: false,
      }
    },
    computed: {
      formData () {
        if (this.data === null) {
          return null
        }
        const whitelist = []
        if (this.data.destinationFilters !== undefined) {
          this.data.destinationFilters.forEach(value => {
            const row = {
              type: value.type,
              string: value.string,
              rate: value.rate,
            }
            if (this.data.destinationType === 'whitelist') {
              row.lengths = value.lengths.join(', ')
              row.randomDigits = value.randomDigits.join(', ')
            }
            whitelist.push(row)
          })
        }
        let duration = this.data.taskDuration
        if (this.data.taskMaxAttempts !== null) {
          duration = null
        }
        return {
          sources: [],
          sourceCount: this.data.sourceCount,
          sourceType: this.data.sourceType,
          sourceAutoWhitelist: this.data.sourceAutoWhitelist.join('| '),
          sourceAutoWhitelistType: this.data.sourceAutoWhitelistType,
          sourceAutoBan: this.data.sourceAutoBan,
          sourceAutoRefine: this.data.sourceAutoRefine,
          sourceMinimumPopularity: this.data.sourceMinimumPopularity,
          sourceLookbackTime: this.data.sourceLookbackTime,
          sourceLookbackTimePeriod: this.data.sourceLookbackTimePeriod,
          sourceGroup: this.data.sourceGroup,
          sourceCliPrefix: this.data.sourceCliPrefix,
          sourceLastSeenCheck: this.data.sourceLastSeenCheck,
          destinationType: this.data.destinationType,
          destinationLookbackType: this.data.destinationLookbackType,
          destinationLookbackData: this.data.destinationLookbackData,
          taskName: this.data.taskName,
          whitelists: whitelist,
          taskDuration: duration,
          taskPDDs: this.data.taskPDDs !== undefined ? this.data.taskPDDs.join(', ') : '',
          taskMinASR: this.data.taskMinASR,
          taskMaxASR: this.data.taskMaxASR,
          taskACD: this.data.taskACD,
          taskMaxSimultaneousCalls: this.data.taskMaxSimultaneousCalls,
          taskMaxCallsPerInterval: this.data.taskMaxCallsPerInterval !== undefined
            ? this.data.taskMaxCallsPerInterval.join('-') : '',
          taskMaxCallsInterval: this.data.taskMaxCallsInterval !== undefined
            ? this.data.taskMaxCallsInterval.join('-') : '1',
          routeId: this.data.routeId,
          routeDialServer: this.data.routeDialServer,
          schedule: this.data.schedule,
          scheduleMinimumRoI: this.data.scheduleMinimumRoI,
          scheduleMaximumTimeBelow: this.data.scheduleMaximumTimeBelow,
          scheduleGrace: this.data.scheduleGrace,
          taskPDDLock: this.data.taskPDDLock,
          accuracyStrip: this.data.accuracyStrip,
          accuracy: this.data.accuracy,
          taskMaxAttempts: this.data.taskMaxAttempts,
        }
      },
    },
    created () {
      this.loading = true
      this.error = false
      authFetch(`${process.env.VUE_APP_API_ROOT}task/templates/default/`)
        .then(async response => {
          this.data = await response.json()
        })
        .catch((error) => {
          console.log(error)
          this.error = `Server returned a ${error.status} status code`
        })
        .finally(() => {
          this.loading = false
        })
    },
  }
</script>

<template>
  <layout-panel>
    <div v-if="loading" class="pt-5" style="text-align: center">
      <v-progress-circular
        :size="70"
        :width="7"
        indeterminate
      />
    </div>
    <v-alert :value="error !== false" type="error">{{ error }}</v-alert>
    <template v-if="!loading && !error">
      <v-container>
        <v-card>
          <v-card-text class="display-2 text-center mt-5 primary white--text">
            {{ translateType(data.taskType) }}
          </v-card-text>
        </v-card>
      </v-container>
      <task-form-steps
        :task-type="data.taskType"
        :previous-form-data="formData"
        :show-destinations="data.destinationType !== 'custom'"
      />
    </template>
  </layout-panel>
</template>

<script>
  import { authFetch } from '@/plugins/authFetch'
  import TaskFormSteps from '@/components/TaskFormSteps'
  import { typeMap } from '@/plugins/common'

  export default {
    components: { TaskFormSteps },
    props: {
      taskId: {
        type: Number,
        required: true,
      },
    },
    data () {
      return {
        loading: true,
        error: false,
        data: {},
      }
    },
    computed: {
      formData () {
        let sources = []
        if (this.data.sources !== undefined) {
          sources = this.data.sources
        }
        let destinationType = this.data.destinationType
        if (this.data.destinationType === 'exact') {
          destinationType = 'whitelist'
        }
        const whitelist = []
        if (this.data.destinationFilters !== undefined) {
          this.data.destinationFilters.forEach(value => {
            const row = {
              type: value.type,
              string: value.string,
              rate: value.rate,
            }
            if (Object.prototype.hasOwnProperty.call(value, 'strip') === true) {
              row.strip = value.strip
            }
            if (destinationType === 'whitelist') {
              row.lengths = value.lengths.join(', ')
              row.randomDigits = value.randomDigits.join(', ')
            }
            whitelist.push(row)
          })
        }
        let duration = this.data.taskDuration
        if (this.data.taskMaxAttempts !== null) {
          duration = null
        }
        return {
          sources: sources,
          sourceCount: this.data.sourceCount,
          sourceType: this.data.sourceType,
          sourceAutoWhitelist: this.data.sourceAutoWhitelist.join('| '),
          sourceAutoWhitelistType: this.data.sourceAutoWhitelistType,
          sourceAutoBan: this.data.sourceAutoBan,
          sourceAutoRefine: this.data.sourceAutoRefine,
          sourceMinimumPopularity: this.data.sourceMinimumPopularity,
          sourceLookbackTime: this.data.sourceLookbackTime,
          sourceLookbackTimePeriod: this.data.sourceLookbackTimePeriod,
          sourceGroup: this.data.sourceGroup,
          sourceLastSeenCheck: this.data.sourceLastSeenCheck,
          sourceCliPrefix: this.data.sourceCliPrefix,
          destinationType: destinationType,
          destinationLookbackType: this.data.destinationLookbackType,
          destinationLookbackData: this.data.destinationLookbackData,
          taskName: this.data.taskName,
          whitelists: whitelist,
          taskDuration: duration,
          taskPDDs: this.data.taskPDDs !== undefined ? this.data.taskPDDs.join(', ') : '',
          taskMinASR: this.data.taskMinASR,
          taskMaxASR: this.data.taskMaxASR,
          taskACD: this.data.taskACD,
          taskMaxSimultaneousCalls: this.data.taskMaxSimultaneousCalls,
          taskMaxCallsPerInterval: this.data.taskMaxCallsPerInterval !== undefined
            ? this.data.taskMaxCallsPerInterval.join('-') : '',
          taskMaxCallsInterval: this.data.taskMaxCallsInterval !== undefined
            ? this.data.taskMaxCallsInterval.join('-') : '1',
          routeId: this.data.routeId,
          routeDialServer: this.data.routeDialServer,
          schedule: this.data.schedule,
          scheduleMinimumRoI: this.data.scheduleMinimumRoI,
          scheduleMaximumTimeBelow: this.data.scheduleMaximumTimeBelow,
          scheduleGrace: this.data.scheduleGrace,
          taskPDDLock: this.data.taskPDDLock,
          taskMaxAttempts: this.data.taskMaxAttempts,
          sourceMinimumProfit: this.data.sourceMinimumProfit,
          sourceMaximumProfit: this.data.sourceMaximumProfit,
        }
      },
    },
    mounted () {
      this.getData()
    },
    methods: {
      translateType (key) {
        if (Object.prototype.hasOwnProperty.call(typeMap, key)) {
          return typeMap[key]
        }
        return `Unknown (${key})`
      },
      getData () {
        authFetch(process.env.VUE_APP_API_ROOT + `task/${this.taskId}/`)
          .then(async response => {
            const data = await response.json()
            this.data = data
          })
          .catch((error) => {
            console.log(error)
            if (error.status === 404) {
              this.error = 'Task not found'
            } else {
              this.error = `Server returned a ${error.status} status code`
            }
          })
          .finally(() => {
            this.loading = false
          })
      },
    },
  }
</script>

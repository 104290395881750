<template>
  <v-form ref="form" v-model="formValid" @submit.prevent="routeSubmit">
    <v-card>
      <v-card-title class="headline">Add Route</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="formData.prefix"
              required
              label="Prefix"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="formData.ip"
              :rules="[rules.required, rules.ip]"
              validate-on-blur
              required
              label="IP"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="formData.port"
              :rules="[rules.required, rules.port]"
              required
              label="Port"
              type="number"
            />
          </v-col>
          <v-col cols="6">
            <v-combobox
              v-model="formData.label"
              :rules="[rules.required]"
              required
              :items="labels"
              label="Provider"
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="formData.callsPerInterval"
              :rules="[rules.required, rules.positiveRange]"
              required
              label="Group CPS"
              hint="Range"
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="formData.maxSimultaneousCalls"
              :rules="[rules.required, rules.numberGT0]"
              required
              label="Group Channels"
              type="number"
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="formData.pdds"
              :rules="[rules.required, rules.positiveCommaSepNumbers]"
              required
              label="Group PDDs"
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="formData.acd"
              :rules="[rules.required, rules.positiveRange]"
              required
              label="ACD Required"
              hint="Range"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="formError.length > 0">
        <p v-for="(error, k) in formError" :key="k" class="red--text text--lighten-1">
          {{ error }}
        </p>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn color="error" @click="cancel">Cancel</v-btn>
        <v-btn
          color="primary"
          :disabled="formLoading"
          :loading="formLoading"
          type="submit"
        >
          Add
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
  import { authFetch } from '@/plugins/authFetch'
  import { numberRangeToList, commaSeparatedNumbersToList } from '@/plugins/common'
  import * as rules from '@/plugins/rules'

  export default {
    data () {
      return {
        formData: {
          prefix: null,
          ip: null,
          port: null,
          label: null,
          callsPerInterval: null,
          maxSimultaneousCalls: null,
          pdds: null,
          acd: null,
        },
        formLoading: false,
        formError: [],
        formValid: true,
        rules: rules,
        labels: [],
      }
    },
    mounted () {
      this.getLabels()
    },
    methods: {
      cancel () {
        this.$emit('cancel')
      },
      getLabels () {
        authFetch(`${process.env.VUE_APP_API_ROOT}routes/labels/`)
          .then(async response => {
            this.labels = await response.json()
          })
          .catch(error => {
            console.log(error)
          })
      },
      routeSubmit () {
        this.formLoading = true
        this.formError = []
        if (!this.$refs.form.validate()) {
          this.formLoading = false
          return false
        }
        const postData = {
          prefix: this.formData.prefix,
          ip: this.formData.ip,
          port: parseInt(this.formData.port, 10),
          label: this.formData.label,
          callsPerInterval: numberRangeToList(this.formData.callsPerInterval),
          callsInterval: [1],
          maxSimultaneousCalls: parseInt(this.formData.maxSimultaneousCalls, 10),
          pdds: commaSeparatedNumbersToList(this.formData.pdds),
          acd: numberRangeToList(this.formData.acd),
        }
        authFetch(`${process.env.VUE_APP_API_ROOT}routes/`, {
          method: 'POST',
          body: JSON.stringify(postData),
        })
          .then(async response => {
            const data = await response.json()
            if (typeof data === 'object' && Object.prototype.hasOwnProperty.call(data, 'routeId')) {
              this.$emit('completed', data.routeId)
            } else {
              this.$emit('completed')
            }
          })
          .catch(async error => {
            console.log(error)
            const data = await error.json()
            if (typeof data === 'object' && Object.prototype.hasOwnProperty.call(data, 'message')) {
              // Is it an object we can loop through for feedback?
              if (typeof data.message === 'object') {
                Object.values(data.message).forEach(msg => {
                  this.formError.push(msg)
                })
                // String returned, setup dialog
              } else {
                this.formError = [data.message]
              }
              // Generic error if we didn't get a proper response
            } else {
              this.formError = [`Server returned a ${error.status} status code`]
            }
          })
          .finally(() => {
            this.formLoading = false
          })
      },
    },
  }
</script>

<style>

</style>

<template>
  <layout-panel>
    <div v-if="loading" class="pt-5" style="text-align: center">
      <v-progress-circular
        :size="70"
        :width="7"
        indeterminate
      />
    </div>
    <v-alert :value="error" type="error">{{ error }}</v-alert>
    <template v-if="!loading && !error">
      <v-container>
        <v-card>
          <v-card-text class="display-2 text-center mt-5 primary white--text">
            Task Info
          </v-card-text>
        </v-card>
      </v-container>
      <v-container>
        <v-row>
          <v-col cols="12" sm="6" md="4" lg="3">
            <v-card>
              <v-card-title><h4>Task Settings</h4></v-card-title>
              <v-divider />
              <v-list dense>
                <v-list-item>
                  <v-list-item-content>Name:</v-list-item-content>
                  <v-list-item-content class="text-right">
                    {{ data.taskName }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>Required ACD:</v-list-item-content>
                  <v-list-item-content class="text-right">
                    {{ data.taskACD }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>Max Channels:</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ data.taskMaxSimultaneousCalls }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>Max CpS:</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ data.taskMaxCallsPerInterval.join(', ') }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="data.taskMaxAttempts === null">
                  <v-list-item-content>Duration (Mins):</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ data.taskDuration }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-else>
                  <v-list-item-content>Duration (Attempts):</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ data.taskMaxAttempts }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>Status:</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ data.taskStatus }}
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <v-card>
              <v-card-title><h4>Route Settings</h4></v-card-title>
              <v-divider />
              <v-list dense>
                <v-list-item>
                  <v-list-item-content>IP:</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ data.routeIP }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>Port:</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ data.routePort }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>Prefix:</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ data.routePrefix }}
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <v-card>
              <v-card-title><h4>Sources Settings</h4></v-card-title>
              <v-divider />
              <v-list dense>
                <v-list-item>
                  <v-list-item-content>Used by Task:</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ data.sourcesUsedCount }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>Group:</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ sourceGroupComputed }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="data.sourceCliPrefix !== ''">
                  <v-list-item-content>CLI PRefix:</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ data.sourceCliPrefix }}
                  </v-list-item-content>
                </v-list-item>
                <template v-if="data.sourceType === 'auto'">
                  <v-list-item>
                    <v-list-item-content>Type:</v-list-item-content>
                    <v-list-item-content class="align-end">
                      {{ sourceTypeComputed }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>Whitelist:</v-list-item-content>
                    <v-list-item-content class="align-end">
                      {{ autoWhitelistComputed[0] }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content
                      v-for="(row, key) in autoWhitelistComputed.slice(1)"
                      :key="key"
                      class="align-end"
                    >
                      {{ row }}
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-if="data.sourceType !== 'auto'">
                  <v-list-item>
                    <v-list-item-content>Numbers per Source:</v-list-item-content>
                    <v-list-item-content class="align-end">
                      {{ sourceCountComputed }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>Type:</v-list-item-content>
                    <v-list-item-content class="align-end">
                      {{ sourceTypeComputed }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider />
                  <v-data-table
                    v-if="data.sources.length > 0"
                    hide-default-header
                    :items="data.sources"
                    :footer-props="{ itemsPerPageOptions: [10,50,{'text':'All',value:-1}] }"
                  >
                    <template v-slot:item="rows">
                      <tr>
                        <td>{{ rows.item }}</td>
                      </tr>
                    </template>
                  </v-data-table>
                </template>
              </v-list>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <v-card>
              <v-card-title><h4>Destination Settings</h4></v-card-title>
              <v-divider />
              <v-list dense>
                <v-list-item>
                  <v-list-item-content>CDR/HLR Lookback:</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ destinationLookbackComputed }}
                  </v-list-item-content>
                </v-list-item>
                <v-data-iterator
                  :items="data.destinationFilters"
                  :items-per-page="5"
                >
                  <template v-slot:default="props">
                    <template v-for="(item, k) in props.items">
                      <v-divider :key="`div${k}`" />
                      <v-list :key="`list${k}`">
                        <v-list-item>
                          <v-list-item-content>Filter:</v-list-item-content>
                          <v-list-item-content class="align-end">
                            {{ typeToString(item.type) }}
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>Terms:</v-list-item-content>
                          <v-list-item-content class="align-end">
                            {{ item.string.join(', ') }}
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="Object.prototype.hasOwnProperty.call(item, 'lengths')">
                          <v-list-item-content>Lengths:</v-list-item-content>
                          <v-list-item-content class="align-end">
                            {{ item.lengths.join(', ') }}
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="Object.prototype.hasOwnProperty.call(item, 'randomDigits') && item.randomDigits.length > 0">
                          <v-list-item-content>Random Digits:</v-list-item-content>
                          <v-list-item-content class="align-end">
                            {{ item.randomDigits.join(', ') }}
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>Rate:</v-list-item-content>
                          <v-list-item-content class="align-end">
                            ${{ item.rate }}
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </template>
                  </template>
                </v-data-iterator>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <template v-if="showDownload">
        <div>
          <span class="display-2 pr-5">Download CDRs</span>
        </div>
        <v-container class="mb-5">
          <v-card>
            <v-container>
              <v-row>
                <v-col class="text-center">
                  <v-btn
                    color="primary"
                    :disabled="downloadLoading"
                    :loading="downloadLoading"
                    @click="downloadCSV(false)"
                  >Incoming CSV</v-btn>
                </v-col>
                <v-col class="text-center">
                  <v-btn
                    color="primary"
                    :disabled="downloadLoading"
                    :loading="downloadLoading"
                    @click="downloadCSV(true)"
                  >Outgoing CSV</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-container>
      </template>

      <div>
        <span class="display-2 pr-5">Stats</span>
        <v-switch
          v-model="advancedStats"
          label="Advanced stats"
          class="d-inline-flex"
        />
      </div>

      <targeted-prefixes
        v-if="Object.keys(data.targetedPrefixes).length > 0"
        :task="data"
      />

      <v-container v-if="showRetails">
        <v-card>
          <v-card-title class="display-1">Retails in Use</v-card-title>
          <v-card-text>
            <v-chip v-for="(number, key) in data.retails" :key="`rn-${key}`">
              {{ number }}
            </v-chip>
          </v-card-text>
        </v-card>
      </v-container>

      <v-container>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title class="display-1">Summary</v-card-title>
              <v-container>
                <v-row>
                  <v-col cols="3" class="text-center">
                    Sent {{ data.totalCalls }} attempts
                  </v-col>
                  <v-col cols="3" class="text-center">
                    {{ data.totalConnected }} connected
                  </v-col>
                  <v-col cols="3" class="text-center">
                    We recieved {{ data.totalIncoming }} calls back
                  </v-col>
                  <v-col cols="3" class="text-center">
                    {{ callReturnPercentage }}% call return rate
                  </v-col>
                </v-row>
              </v-container>
              <v-container>
                <v-row>
                  <v-col cols="2">
                    <h3>PDD Totals:</h3>
                  </v-col>
                  <v-col cols="10">
                    <v-chip v-for="(row, key) in pddTotals" :key="key">
                      PDD {{ row[0] }}: {{ row[1] }}
                    </v-chip>
                  </v-col>
                </v-row>
              </v-container>
              <v-container v-if="data.bans.length > 0">
                <v-row>
                  <v-col cols="2">
                    <h3>DST : Retail Bans:</h3>
                  </v-col>
                  <v-col cols="10">
                    <v-chip v-for="(row, key) in data.bans" :key="`bans-${key}`">
                      {{ row }}
                    </v-chip>
                  </v-col>
                </v-row>
              </v-container>
              <v-container v-if="data.refinement.length > 0">
                <v-row>
                  <v-col cols="2">
                    <h3>DST : Retail Refinement:</h3>
                  </v-col>
                  <v-col cols="10">
                    <v-chip v-for="(row, key) in data.refinement" :key="`ref-${key}`">
                      {{ row }}
                    </v-chip>
                  </v-col>
                </v-row>
              </v-container>
              <template v-if="showDstGraph">
                <v-card-title class="title">Retail Carrier Graph</v-card-title>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <div class="carrier-graph">
                        <line-chart
                          :chart-data="graphDstData"
                          :options="graphOptions"
                          :styles="graphStyles"
                        />
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
              <template v-if="showGraph">
                <v-card-title class="title">Source Carrier Graph</v-card-title>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <div class="carrier-graph">
                        <line-chart
                          :chart-data="graphData"
                          :options="graphOptions"
                          :styles="graphStylesSmall"
                        />
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
              <template v-if="showConnected">
                <v-card-title class="title">Connected Simultaneous Calls</v-card-title>
                <v-container>
                  <v-row>
                    <v-col class="text-center">
                      <img
                        :src="`http://167.172.101.210/cacti/graph_image.php?ip=${data.routeLastUsedDialServer}`"
                        alt="Connected Simultaneous Calls"
                      >
                    </v-col>
                  </v-row>
                </v-container>
              </template>
              <template v-if="showGraphLastTen">
                <v-card-title class="title">Incoming Over Last 10 Minutes</v-card-title>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <div class="carrier-graph">
                        <bar-chart
                          :chart-data="graphLastTenData"
                          :options="graphOptions"
                          :styles="graphStylesSmall"
                        />
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="data.statsSummary.length > 0">
        <v-row>
          <v-col>
            <v-card>
              <v-card-title class="display-1">Totals</v-card-title>
              <template v-if="!advancedStats">
                <v-data-table
                  :headers="statsHeadersSummarySimple"
                  :items="summarySimple"
                  :sort-by.sync="summaryTableOptions.sortBy"
                  :sort-desc.sync="summaryTableOptions.descending"
                  :footer-props="{ itemsPerPageOptions: [10,50,{'text':'All',value:-1}] }"
                >
                  <template v-if="showQuickLaunch" v-slot:header.quickLaunch="props">
                    <v-tooltip bottom max-width="300">
                      <template v-slot:activator="{ on }">
                        <span v-on="on">
                          {{ props.header.text }}
                          <v-icon size="16" class="pl-1" v-on="on">help</v-icon>
                        </span>
                      </template>
                      Quick Launch - Launch the selected tasks using the "Default Task
                      Settings" defined in User Settings
                    </v-tooltip>
                  </template>
                  <template v-slot:item.profit="row">
                    {{ floorToTwo(row.item.profit) }}
                  </template>
                  <template v-slot:item.roi="row">
                    {{ formatRoi(row.item.roi) }}%
                  </template>
                  <template v-slot:item.carrier="row">
                    {{ row.item.carrier || 'Unknown' }}
                  </template>
                  <template v-if="showQuickLaunch" v-slot:item.quickLaunch="row">
                    <v-checkbox
                      v-model="quickLaunch"
                      :value="summaryQuickLaunch[row.item.id]"
                      class="short"
                    />
                  </template>
                  <template v-slot:item.cost="row">
                    {{ floorToTwo(row.item.cost) }}
                  </template>
                </v-data-table>
                <v-card-actions v-if="quickLaunch.length > 0">
                  <v-spacer />
                  <v-btn
                    color="primary"
                    :disabled="quickLaunchSubmitting"
                    :loading="quickLaunchSubmitting"
                    @click="submitQuickLaunch()"
                  >
                    Quick Launch {{ quickLaunch.length }} Tasks
                  </v-btn>
                </v-card-actions>
              </template>
              <template v-if="advancedStats">
                <v-data-table
                  :headers="statsHeadersSummaryAdvanced"
                  :items="summaryAdvanced"
                  :sort-by.sync="summaryTableOptions.sortBy"
                  :sort-desc.sync="summaryTableOptions.descending"
                  :footer-props="{ itemsPerPageOptions: [10,50,{'text':'All',value:-1}] }"
                >
                  <template v-if="showQuickLaunch" v-slot:header.quickLaunch="props">
                    <v-tooltip bottom max-width="300">
                      <template v-slot:activator="{ on }">
                        <span v-on="on">
                          {{ props.header.text }}
                          <v-icon size="16" class="pl-1" v-on="on">help</v-icon>
                        </span>
                      </template>
                      Quick Launch - Launch the selected tasks using the "Default Task
                      Settings" defined in User Settings
                    </v-tooltip>
                  </template>
                  <template v-slot:item.profit="row">
                    {{ floorToTwo(row.item.profit) }}
                  </template>
                  <template v-slot:item.roi="row">
                    {{ formatRoi(row.item.roi) }}%
                  </template>
                  <template v-slot:item.carrierPrefix="row">
                    {{ row.item.carrierPrefix || 'Unknown' }}
                  </template>
                  <template v-slot:item.carrier="row">
                    {{ row.item.carrier || 'Unknown' }}
                  </template>
                  <template v-if="showQuickLaunch" v-slot:item.quickLaunch="row">
                    <v-checkbox
                      v-model="quickLaunch"
                      :value="summaryQuickLaunchAdvanced[row.item.id]"
                      class="short"
                    />
                  </template>
                </v-data-table>
                <v-card-actions v-if="quickLaunch.length > 0">
                  <v-spacer />
                  <v-btn
                    color="primary"
                    :disabled="quickLaunchSubmitting"
                    :loading="quickLaunchSubmitting"
                    @click="submitQuickLaunch()"
                  >
                    Quick Launch {{ quickLaunch.length }} Tasks
                  </v-btn>
                </v-card-actions>
              </template>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="data.stats.length > 0 && !advancedStats">
        <v-row>
          <v-col v-for="(rows, key) in statsByPDDSimple" :key="key" cols="12">
            <v-card>
              <v-card-title class="display-1">PDD: {{ key }}</v-card-title>
              <v-data-table
                :headers="statsHeadersSimple"
                :items="rows"
                :sort-by.sync="tableOptions.sortBy"
                :sort-desc.sync="tableOptions.descending"
                :footer-props="{ itemsPerPageOptions: [10,50,{'text':'All',value:-1}] }"
              >
                <template v-slot:item.profit="rows">
                  {{ floorToTwo(rows.item.profit) }}
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="data.stats.length > 0 && advancedStats">
        <v-row>
          <v-col v-for="(rows, key) in statsByPDDAdvanced" :key="key" cols="12">
            <v-card>
              <v-card-title class="display-1">PDD: {{ key }}</v-card-title>
              <v-data-table
                :headers="statsHeadersAdvanced"
                :items="rows"
                :sort-by.sync="tableOptions.sortBy"
                :sort-desc.sync="tableOptions.descending"
                :footer-props="{ itemsPerPageOptions: [10,50,{'text':'All',value:-1}] }"
              >
                <template v-slot:item="rows">
                  <tr>
                    <td>{{ rows.item.carrierPrefix || 'Unknown' }}</td>
                    <td>{{ rows.item.carrier || 'Unknown' }}</td>
                    <td>{{ rows.item.dstCarrierWithPrefix }}</td>
                    <td>{{ rows.item.country }}</td>
                    <td>{{ rows.item.totalCalls }}</td>
                    <td>{{ rows.item.billsec }}</td>
                    <td>{{ floorToTwo(rows.item.profit) }}</td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-container v-if="showEdit">
        <v-row>
          <v-col>
            <v-card class="pa-3">
              <v-btn
                color="primary"
                large
                block
                :loading="editButton"
                :disabled="editButton"
                @click="editClick()"
              >
                Edit Task
              </v-btn>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <!-- <v-container v-if="showButton">
        <v-layout>
          <v-flex>
            <v-card class="pa-3">
              <v-btn
                color="primary"
                large
                block
                :loading="formButton"
                :disabled="formButton"
                @click="formButton = true"
              >
                Create {{ newTaskName }}
              </v-btn>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container> -->

      <h1 v-if="showForm" class="display-2">
        <template v-if="formButton">{{ newTaskName }}</template>
        <template v-else>Edit Task</template>
      </h1>
      <task-form
        v-if="showForm"
        :task-id="formTaskId"
        :task-type="newTaskType"
        :task-parent="0"
        :previous-form-data="formData"
        :show-destinations="formData.destinationType !== 'custom'"
      />
    </template>

    <v-dialog v-model="quickLaunchErrorDialog" max-width="500">
      <v-card>
        <v-card-title class="headline" color="error">Server Error</v-card-title>
        <v-card-text>
          <p>There was an error processing your request.</p>
          <p v-for="(err, key) in quickLaunchErrors" :key="key">{{ err }}</p>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="quickLaunchErrorDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </layout-panel>
</template>

<script>
  import { authFetch, authDownload, objectToQueryString } from '@/plugins/authFetch'
  import TaskForm from '@/components/TaskForm'
  import { floorToTwo } from '@/plugins/common'
  import LineChart from '@/components/carrierChart'
  import BarChart from '@/components/barChart'
  import TargetedPrefixes from '@/components/TargetedPrefixes'

  export default {
    components: { TaskForm, LineChart, BarChart, TargetedPrefixes },
    props: {
      taskId: { type: Number, default: 0 },
    },
    data () {
      return {
        data: {},
        loading: true,
        error: false,
        statsHeadersSimple: [
          { text: 'Source Carrier', value: 'carrier' },
          { text: 'Destination Carrier', value: 'dstCarrier' },
          { text: 'Country', value: 'country' },
          { text: 'Calls', value: 'totalCalls' },
          { text: 'Duration', value: 'billsec' },
          { text: 'Earned', value: 'profit' },
        ],
        statsHeadersAdvanced: [
          { text: 'Source Carrier Prefix', value: 'carrierPrefix' },
          { text: 'Source Carrier', value: 'carrier' },
          { text: 'Destination Prefix - Carrier', value: 'dstCarrierWithPrefix' },
          { text: 'Country', value: 'country' },
          { text: 'Calls', value: 'totalCalls' },
          { text: 'Duration', value: 'billsec' },
          { text: 'Earned', value: 'profit' },
        ],
        tableOptions: { sortBy: 'profit', descending: true },
        summaryTableOptions: { sortBy: 'roi', descending: true },
        filters: [
          { type: 'Country', string: 'United', lengths: [11, 12, 13], randomDigits: [5, 6] },
          { type: 'Carrier', string: 'France', lengths: [11, 12], randomDigits: [] },
        ],
        dataByPDD: {},
        formButton: false,
        editButton: false,
        advancedStats: false,
        lookbackTimeMap: {
          day: 'Day(s)',
          month: 'Month(s)',
          year: 'Year(s)',
        },
        formTaskId: 0,
        pddTotals: [],
        graphOptions: {
          maintainAspectRatio: false,
        },
        graphLastTenOptions: {
          scaleOverride: true,
          scaleStepWidth: 1,
          maintainAspectRatio: false,
        },
        graphStyles: {
          position: 'relative',
          height: '300px',
        },
        graphStylesSmall: {
          position: 'relative',
          height: '250px',
        },
        downloadLoading: false,
        quickLaunch: [],
        summarySimple: [],
        summaryQuickLaunch: {},
        summaryQuickLaunchAdvanced: {},
        summaryAdvanced: [],
        quickLaunchErrors: [],
        quickLaunchSubmitting: false,
        quickLaunchErrorDialog: false,
      }
    },
    computed: {
      statsHeadersSummarySimple () {
        const result = [
          { text: 'Source Carrier', value: 'carrier' },
          { text: 'ACD', value: 'ivr' },
          { text: 'Destination Carrier', value: 'dstCarrier' },
          { text: 'Country', value: 'country' },
          { text: 'Calls', value: 'totalCalls' },
          { text: 'Duration', value: 'billsec' },
          { text: 'Earned', value: 'profit' },
          { text: 'RoI', value: 'roi' },
          { text: 'Cost', value: 'cost' },
        ]
        if (this.showQuickLaunch) {
          result.push({ text: 'QL', value: 'quickLaunch' })
        }
        return result
      },
      statsHeadersSummaryAdvanced () {
        const result = [
          { text: 'Source Prefix', value: 'carrierPrefix' },
          { text: 'Source Carrier', value: 'carrier' },
          { text: 'ACD', value: 'ivr' },
          { text: 'Destination Prefix - Carrier', value: 'dstCarrierWithPrefix' },
          { text: 'Country', value: 'country' },
          { text: 'Calls', value: 'totalCalls' },
          { text: 'Duration', value: 'billsec' },
          { text: 'Earned', value: 'profit' },
          { text: 'RoI', value: 'roi' },
          { text: 'Cost', value: 'cost' },
        ]
        if (this.showQuickLaunch) {
          result.push({ text: 'QL', value: 'quickLaunch' })
        }
        return result
      },
      showDownload () {
        return this.$store.state.permission & 4
      },
      showQuickLaunch () {
        return this.$store.state.permission & 6
      },
      showRetails () {
        return Object.prototype.hasOwnProperty.call(this.data, 'retails') &&
          this.data.retails.length > 0
      },
      sourceGroupComputed () {
        return this.data.sourceGroup !== null ? this.data.sourceGroup : 'All'
      },
      showDstGraph () {
        return Object.prototype.hasOwnProperty.call(this.data, 'graph') &&
          Object.prototype.hasOwnProperty.call(this.data.graph, 'dataDst') &&
          this.data.graph.dataDst.length > 0
      },
      graphDstData () {
        return {
          labels: this.data.graph.x,
          datasets: this.data.graph.dataDst,
        }
      },
      showGraph () {
        return Object.prototype.hasOwnProperty.call(this.data, 'graph') &&
          Object.prototype.hasOwnProperty.call(this.data.graph, 'data') &&
          this.data.graph.data.length > 0
      },
      graphData () {
        return {
          labels: this.data.graph.x,
          datasets: this.data.graph.data,
        }
      },
      showGraphLastTen () {
        return Object.prototype.hasOwnProperty.call(this.data, 'graphLastTen') &&
          Object.prototype.hasOwnProperty.call(this.data.graphLastTen, 'data') &&
          this.data.graphLastTen.data.length > 0
      },
      graphLastTenData () {
        let bg = this.$vuetify.theme.themes.light.primary
        if (this.$vuetify.theme.dark === true) {
          bg = this.$vuetify.theme.themes.dark.primary
        }
        return {
          labels: this.data.graphLastTen.x,
          datasets: [{
            label: 'Calls',
            backgroundColor: bg,
            data: this.data.graphLastTen.data,
          }],
        }
      },
      callReturnPercentage () {
        if (this.data.totalCalls === 0) {
          return 'n/a'
        }
        return floorToTwo((this.data.totalIncoming / this.data.totalCalls) * 100)
      },
      showForm () {
        return this.formButton === true || this.editButton === true
      },
      showButton () {
        return (this.data.taskStatus === 'completed' || this.data.taskStatus === 'aborted') &&
          this.formButton === false &&
          this.editButton === false
      },
      showEdit () {
        return this.editButton === false &&
          this.formButton === false &&
          (this.data.taskStatus === 'paused' ||
          this.data.taskStatus === 'error' ||
          this.data.taskStatus === 'aborted' ||
          this.data.taskStatus === 'completed' ||
          this.data.taskStatus === 'killed')
      },
      showConnected () {
        console.log(this.data)
        return this.data.taskStatus === 'running' &&
          this.data.routeLastUsedDialServer !== null
      },
      formData () {
        let sources = []
        if (this.data.sources !== undefined) {
          sources = this.data.sources
        }
        const whitelist = []
        if (this.data.destinationFilters !== undefined) {
          this.data.destinationFilters.forEach(value => {
            const row = {
              type: value.type,
              string: value.string,
              rate: value.rate,
            }
            if (this.data.destinationType === 'whitelist') {
              row.lengths = value.lengths.join(', ')
              row.randomDigits = value.randomDigits.join(', ')
            }
            whitelist.push(row)
          })
        }
        return {
          sources: sources,
          sourceCount: this.data.sourceCount,
          sourceType: this.data.sourceType,
          sourceAutoWhitelist: this.data.sourceAutoWhitelist.join('| '),
          sourceAutoWhitelistType: this.data.sourceAutoWhitelistType,
          sourceAutoBan: this.data.sourceAutoBan,
          sourceAutoRefine: this.data.sourceAutoRefine,
          sourceMinimumPopularity: this.data.sourceMinimumPopularity,
          sourceLookbackTime: this.data.sourceLookbackTime,
          sourceLookbackTimePeriod: this.data.sourceLookbackTimePeriod,
          sourceGroup: this.data.sourceGroup,
          sourceLastSeenCheck: this.data.sourceLastSeenCheck,
          sourceCliPrefix: this.data.sourceCliPrefix,
          destinationType: this.data.destinationType,
          destinationLookbackType: this.data.destinationLookbackType,
          destinationLookbackData: this.data.destinationLookbackData,
          taskName: this.data.taskName,
          whitelists: whitelist,
          taskDuration: this.data.taskDuration,
          taskPDDs: this.data.taskPDDs !== undefined ? this.data.taskPDDs.join(', ') : '',
          taskMinASR: this.data.taskMinASR,
          taskMaxASR: this.data.taskMaxASR,
          taskACD: this.data.taskACD,
          taskMaxSimultaneousCalls: this.data.taskMaxSimultaneousCalls,
          taskMaxCallsPerInterval: this.data.taskMaxCallsPerInterval !== undefined
            ? this.data.taskMaxCallsPerInterval.join('-') : '',
          taskMaxCallsInterval: this.data.taskMaxCallsInterval !== undefined
            ? this.data.taskMaxCallsInterval.join('-') : '1',
          routeId: this.data.routeId,
          routeDialServer: this.data.routeDialServer,
          schedule: this.data.schedule,
          scheduleMinimumRoI: this.data.scheduleMinimumRoI,
          scheduleMaximumTimeBelow: this.data.scheduleMaximumTimeBelow,
          scheduleGrace: this.data.scheduleGrace,
          taskPDDLock: this.data.taskPDDLock,
          sourceMinimumProfit: this.data.sourceMinimumProfit,
          sourceMaximumProfit: this.data.sourceMaximumProfit,
        }
      },
      sourceCountComputed () {
        switch (this.data.sourceCount) {
          case 'all': return 'All Numbers'
          case 'one': return '12 Numbers'
          default: return 'Unknown'
        }
      },
      sourceTypeComputed () {
        return this.typeToString(this.data.sourceType)
      },
      autoWhitelistComputed () {
        if (this.data.sourceAutoWhitelist === undefined || this.data.sourceAutoWhitelist.length < 1) {
          return ['none']
        } else {
          return this.data.sourceAutoWhitelist
        }
      },
      destinationLookbackComputed () {
        if (this.data.destinationLookbackType === 'time') {
          const parts = this.data.destinationLookbackData.split(' ')
          return `${parts[0]} ${this.lookbackTimeMap[parts[1]]}`
        }
        return 'this.data.destinationLookbackData rows'
      },
      newTaskType () {
        if (this.formButton === true) {
          switch (this.data.taskType) {
            case 'exploration': return 'refinement'
            case 'refinement':
            case 'finalized':
              return 'finalized'
            default: return 'exploration'
          }
        }
        return this.data.taskType
      },
      newTaskName () {
        switch (this.data.taskType) {
          case 'exploration': return 'New Fine Tuning Task'
          case 'refinement':
          case 'finalized':
            return 'New Production Task'
          default: return 'New Exploration Task'
        }
      },
      statsByPDDSimple () {
        const merged = {}
        this.data.stats.forEach(row => {
          if (!Object.prototype.hasOwnProperty.call(merged, row.pdd)) {
            merged[row.pdd] = {}
          }
          const secondKey = `${row.carrierBase} - ${row.dstCarrier}`
          if (!Object.prototype.hasOwnProperty.call(merged[row.pdd], secondKey)) {
            merged[row.pdd][secondKey] = {
              carrier: row.carrierBase,
              dstCarrier: row.dstCarrier,
              country: row.country,
              totalCalls: 0,
              billsec: 0,
              profit: 0,
            }
          }
          merged[row.pdd][secondKey].totalCalls += row.totalCalls
          merged[row.pdd][secondKey].billsec += row.billsec
          merged[row.pdd][secondKey].profit += row.profit
        })
        const result = {}
        Object.keys(merged).forEach(key => {
          result[key] = Object.values(merged[key])
        })
        return result
      },
      statsByPDDAdvanced () {
        const result = {}
        this.data.stats.forEach(row => {
          if (!Object.prototype.hasOwnProperty.call(result, row.pdd)) {
            result[row.pdd] = []
          }
          let dstCarrierWithPrefix = 'Unknown'
          if (row.dstCarrierPrefix) {
            dstCarrierWithPrefix = `${row.dstCarrierPrefix} - ${row.dstCarrier}`
          }
          result[row.pdd].push({
            carrierPrefix: row.carrierPrefix,
            carrier: row.carrier,
            dstCarrierWithPrefix: dstCarrierWithPrefix,
            country: row.country,
            totalCalls: row.totalCalls,
            billsec: row.billsec,
            profit: row.profit,
          })
        })
        return result
      },
    },
    watch: {
      advancedStats () {
        this.quickLaunch = []
      },
    },
    created () {
      this.getData()
    },
    methods: {
      submitQuickLaunch () {
        const postData = { tasks: this.quickLaunch }
        this.quickLaunchErrors = []
        this.quickLaunchSubmitting = true
        this.quickLaunchErrorDialog = false
        authFetch(`${process.env.VUE_APP_API_ROOT}tasks/quick/`, {
          method: 'POST',
          body: JSON.stringify(postData),
        })
          .then(async response => {
            await response.json()
            this.$router.push({ name: 'tasks' })
          })
          .catch(async error => {
            console.log(error)
            const data = await error.json()
            if (typeof data === 'object' && Object.prototype.hasOwnProperty.call(data, 'message')) {
              // Is it an object we can loop through for feedback?
              if (typeof data.message === 'object') {
                Object.values(data.message).forEach(msg => {
                  this.quickLaunchErrors.push(msg)
                })
                // String returned, setup dialog
              } else {
                this.quickLaunchErrors = [data.message]
              }
              // Generic error if we didn't get a proper response
            } else {
              this.quickLaunchErrors = [`Server returned a ${error.status} status code`]
            }
          })
          .finally(() => {
            this.quickLaunchSubmitting = false
            if (this.quickLaunchErrors.length > 0) {
              this.quickLaunchErrorDialog = true
            }
          })
      },
      formatRoi (val) {
        if (typeof val !== 'number') {
          return 'n/a'
        }
        return floorToTwo(val)
      },
      editClick () {
        this.formTaskId = this.taskId
        this.editButton = true
      },
      floorToTwo (float) {
        return floorToTwo(float)
      },
      typeToString (type) {
        switch (type) {
          case 'prefix': return 'Prefix'
          case 'carrier': return 'Exploration'
          case 'country': return 'Country'
          case 'auto': return 'Final Production'
          case 'auto-prefix': return 'Historical Access'
          default: return 'Unknown'
        }
      },
      downloadCSV (outgoing) {
        this.downloadLoading = true
        const tid = parseInt(this.taskId)
        const query = { task: tid, outgoing: 0 }
        if (outgoing === true) {
          query.outgoing = 1
        }
        authDownload(`${process.env.VUE_APP_API_ROOT}cdr/download/?${objectToQueryString(query)}`)
          .finally(() => {
            this.downloadLoading = false
          })
      },
      getData () {
        authFetch(process.env.VUE_APP_API_ROOT + `task/${this.taskId}/`)
          .then(async response => {
            const data = await response.json()
            this.data = data
            const totals = {}
            if (Object.prototype.hasOwnProperty.call(this.data, 'stats') && Array.isArray(this.data.stats)) {
              this.data.stats.forEach(v => {
                if (!Object.prototype.hasOwnProperty.call(totals, v.pdd)) {
                  totals[v.pdd] = 0
                }
                totals[v.pdd] += v.totalCalls
              })
              Object.keys(totals).forEach(pdd => {
                this.pddTotals.push([pdd, totals[pdd]])
              })
              this.pddTotals.sort((f, s) => {
                return s[1] - f[1]
              })
            }
            this.buildSummarySimple()
            this.buildSummaryAdvanced()
          })
          .catch((error) => {
            console.log(error)
            if (error.status === 404) {
              this.error = 'Task not found'
            } else {
              this.error = `Server returned a ${error.status} status code`
            }
          })
          .finally(() => {
            this.loading = false
          })
      },
      buildSummarySimple () {
        const merged = {}
        let id = 0
        this.data.statsSummary.forEach(row => {
          const secondKey = `${row.carrierBase}:${row.dstCarrier}`
          if (!Object.prototype.hasOwnProperty.call(merged, secondKey)) {
            merged[secondKey] = {
              carrier: row.carrierBase,
              dstCarrier: row.dstCarrier,
              country: row.country,
              totalCalls: 0,
              billsec: 0,
              profit: 0,
              cost: 0,
              ivr: row.ivr,
              id: id,
            }
          }
          merged[secondKey].totalCalls += row.totalCalls
          merged[secondKey].billsec += row.billsec
          merged[secondKey].profit += row.profit
          merged[secondKey].cost += row.cost
          this.summaryQuickLaunch[id] = {
            destinations: { carrierAll: [row.carrierBase] },
            sources: [row.dstCarrier],
          }
          id++
        })
        Object.keys(merged).forEach(k => {
          let roi = false
          if (typeof merged[k].cost === 'number' && merged[k].cost !== 0) {
            roi = this.floorToTwo(
              ((merged[k].profit - merged[k].cost) / merged[k].cost) * 100
            )
          }
          merged[k].roi = roi
        })
        this.summarySimple = Object.values(merged)
      },
      buildSummaryAdvanced () {
        const result = []
        let id = 0
        this.data.statsSummary.forEach(row => {
          let dstCarrierWithPrefix = 'Unknown'
          if (row.dstCarrierPrefix) {
            dstCarrierWithPrefix = `${row.dstCarrierPrefix} - ${row.dstCarrier}`
          }
          let roi = false
          if (typeof row.cost === 'number' && row.cost !== 0) {
            roi = ((row.profit - row.cost) / row.cost) * 100
          }
          result.push({
            carrierPrefix: row.carrierPrefix,
            carrier: row.carrier,
            dstCarrierWithPrefix: dstCarrierWithPrefix,
            country: row.country,
            totalCalls: row.totalCalls,
            billsec: row.billsec,
            profit: row.profit,
            roi: roi,
            ivr: row.ivr,
            id: id,
          })
          this.summaryQuickLaunchAdvanced[id] = {
            destinations: { carrier: [row.carrier] },
            sources: [row.dstCarrier],
          }
          id++
        })
        this.summaryAdvanced = result
      },
    },
  }
</script>

<style>
.v-datatable__actions .v-datatable__actions__select .v-select__selections input {
  display: none
}
</style>

<style scoped>
::v-deep .short {
  margin-top: 5px;
}
::v-deep .short .v-input__control {
  height: 35px;
}
</style>

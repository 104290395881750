<template>
  <layout-panel>
    <v-container>
      <v-card>
        <v-card-text class="display-2 text-center mt-5 primary white--text">
          I-Test Results
        </v-card-text>
      </v-card>
    </v-container>
    <v-card class="mt-5">
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :footer-props="{
            itemsPerPageOptions: [50, 100, 500]
          }"
          :options.sync="options"
          :server-items-length="total"
          :sort-by="sortBy"
          :sort-desc="descending"
          :single-expand="false"
          :expanded.sync="expanded"
          item-key="taskId"
          show-expand
        >
          <template v-slot:item.clis="row">
            {{ translateCLIs(row.item) }}
          </template>
          <template v-slot:item.status="row">
            {{ translateStatus(row.item.status) }}
          </template>
          <template v-slot:item.countries="row">
            {{ row.item.countries.join(', ') }}
          </template>
          <template v-slot:expanded-item="row">
            <td :colspan="row.headers.length">
              <v-container v-if="showError(row.item)">
                <v-alert type="error" dense outlined>
                  {{ row.item.meta[0].error }}
                </v-alert>
              </v-container>
              <v-container v-else-if="showMessage(row.item)">
                <v-alert type="warning" dense outlined>
                  {{ row.item.meta[0].message }}
                </v-alert>
              </v-container>
              <v-container v-else-if="isRunning(row.item)" class="text-center text--disabled">
                <em>Data will be populated when the test is complete</em>
              </v-container>
              <i-test-details v-for="(d, dk) in row.item.meta" v-else :key="`dest_${dk}`" :test="d" />
            </td>
          </template>
          <template v-slot:no-data>
            <div v-if="!loading && !error" class="text-center">
              No tests found
            </div>
            <v-alert
              v-if="!loading && error"
              :value="true"
              color="error"
              icon="warning"
            >
              Error loading results
            </v-alert>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </layout-panel>
</template>

<script>
  import { authFetch, objectToQueryString } from '@/plugins/authFetch'
  import { statusMap } from '@/plugins/common'
  import ITestDetails from '@/components/ITestDetails'
  export default {
    components: { ITestDetails },
    props: {
      taskId: { type: Number, default: 0 },
    },
    data () {
      return {
        headers: [
          { text: 'Task ID', value: 'taskId' },
          { text: 'User', value: 'user' },
          { text: 'Created', value: 'created' },
          { text: 'Matched CLIs', value: 'clis', sortable: false },
          { text: 'Status', value: 'status' },
          { text: 'Countries', value: 'countries', sortable: false },
          { text: 'Details', value: 'data-table-expand' },
        ],
        items: [],
        loading: false,
        error: false,
        total: 0,
        options: {},
        sortBy: 'task_id',
        descending: true,
        expanded: [],
      }
    },
    watch: {
      options: {
        handler () {
          this.getData()
        },
        deep: true,
      },
    },
    methods: {
      showError (item) {
        return item.meta.length > 0 && Object.prototype.hasOwnProperty.call(item.meta[0], 'error')
      },
      showMessage (item) {
        return item.meta.length > 0 && Object.prototype.hasOwnProperty.call(item.meta[0], 'message')
      },
      isRunning (item) {
        return ['running', 'created'].includes(item.status)
      },
      translateCLIs (item) {
        if (item.cliAttempted === null) {
          return 'n/a'
        }
        return `${item.cliMatched} / ${item.cliAttempted}`
      },
      translateStatus (key) {
        if (Object.prototype.hasOwnProperty.call(statusMap, key)) {
          return statusMap[key]
        }
        return `Unknown (${key})`
      },
      getData () {
        this.loading = true
        const query = objectToQueryString({
          itemsPerPage: this.options.itemsPerPage,
          page: this.options.page,
          sortBy:
            this.options.sortBy.length === 0
              ? this.sortBy
              : this.options.sortBy[0],
          sortDesc:
            this.options.sortDesc.length === 0
              ? this.descending === true
                ? 1
                : 0
              : this.options.sortDesc[0] === true
                ? 1
                : 0,
        })
        authFetch(`${process.env.VUE_APP_API_ROOT}admin/i-tests/?${query}`)
          .then(async response => {
            const r = await response.json()
            this.total = r.total
            this.items = r.items
          })
          .catch(error => {
            console.log(error)
            this.error = true
          })
          .finally(() => {
            this.loading = false
          })
      },
    },
  }
</script>

<template>
  <layout-panel>
    <v-container>
      <v-card>
        <v-card-text class="display-2 text-center mt-5 primary white--text">
          Support Tickets
        </v-card-text>
      </v-card>
    </v-container>
    <v-container>
      <v-card>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="itemsArray"
            hide-default-footer
            :items-per-page="-1"
            :single-expand="false"
            :expanded.sync="expanded"
            show-expand
          >
            <template v-slot:expanded-item="row">
              <td :colspan="row.headers.length">
                <v-card>
                  <v-card-title class="subtitle-2">Body</v-card-title>
                  <v-card-text>{{ row.item.body }}</v-card-text>
                  <v-card-title class="subtitle-2">Contact Options</v-card-title>
                  <v-card-text>
                    <v-list two-line subheader>
                      <v-list-item
                        v-for="(v, k) in row.item.contact"
                        :key="`contacts_${row.item.id}_${k}`"
                      >
                        <v-list-item-content>
                          <v-list-item-title v-text="v" />
                          <v-list-item-subtitle v-text="k" />
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </td>
            </template>
            <template v-slot:item.actions="row">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="primary"
                    icon
                    small
                    :disabled="row.item.loading"
                    :loading="row.item.loading"
                    v-on="on"
                    @click="submit(row.item.id)"
                  >
                    <v-icon>check</v-icon>
                  </v-btn>
                </template>
                <span>Mark Resolved</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
    <h1 class="display-2 mt-5">Resolved Support Tickets</h1>
    <v-container>
      <v-card>
        <v-card-text>
          <v-data-table
            :headers="resolvedHeaders"
            :items="resolvedItems"
            :single-expand="false"
            :expanded.sync="resolvedExpanded"
            show-expand
            :loading="resolvedLoading"
            :footer-props="{
              itemsPerPageOptions: [10, 50, 100]
            }"
            :options.sync="resolvedOptions"
            :server-items-length="resolvedTotal"
            :sort-by="resolvedSortBy"
            :sort-desc="resolvedDescending"
          >
            <template v-slot:expanded-item="row">
              <td :colspan="row.headers.length">
                <v-card>
                  <v-card-title class="subtitle-2">Body</v-card-title>
                  <v-card-text>{{ row.item.body }}</v-card-text>
                  <v-card-title class="subtitle-2">Contact Options</v-card-title>
                  <v-card-text>
                    <v-list two-line subheader>
                      <v-list-item
                        v-for="(v, k) in row.item.contact"
                        :key="`resolved_${row.item.id}_${k}`"
                      >
                        <v-list-item-content>
                          <v-list-item-title v-text="v" />
                          <v-list-item-subtitle v-text="k" />
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </td>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </layout-panel>
</template>

<script>
  import { authFetch, objectToQueryString } from '@/plugins/authFetch'

  export default {
    data () {
      return {
        items: {},
        headers: [
          { text: 'Added', value: 'date' },
          { text: 'Username', value: 'user' },
          { text: 'Subject', value: 'subject' },
          { text: 'Actions', value: 'actions' },
          { text: 'View', value: 'data-table-expand' },
        ],
        loading: true,
        expanded: [],
        resolvedHeaders: [
          { text: 'Added', value: 'date' },
          { text: 'Username', value: 'user' },
          { text: 'Subject', value: 'subject' },
          { text: 'View', value: 'data-table-expand' },
        ],
        resolvedItems: [],
        resolvedLoading: false,
        resolvedTotal: 0,
        resolvedOptions: {},
        resolvedSortBy: 'date',
        resolvedDescending: true,
        resolvedError: null,
        resolvedExpanded: [],
      }
    },
    computed: {
      itemsArray () {
        const result = []
        Object.values(this.items).forEach(r => {
          result.push(r)
        })
        return result
      },
    },
    watch: {
      resolvedOptions: {
        handler () {
          this.getResolved()
        },
        deep: true,
      },
    },
    created () {
      this.getData()
    },
    methods: {
      getResolved () {
        this.resolvedLoading = true
        const query = objectToQueryString({
          itemsPerPage: this.resolvedOptions.itemsPerPage,
          page: this.resolvedOptions.page,
          sortBy:
            this.resolvedOptions.sortBy.length === 0
              ? this.resolvedSortBy
              : this.resolvedOptions.sortBy[0],
          sortDesc:
            this.resolvedOptions.sortDesc.length === 0
              ? this.resolvedDescending === true
                ? 1
                : 0
              : this.resolvedOptions.sortDesc[0] === true
                ? 1
                : 0,
        })
        authFetch(process.env.VUE_APP_API_ROOT + `admin/support/tickets/resolved/?${query}`)
          .then(async response => {
            const r = await response.json()
            this.resolvedTotal = r.total
            this.resolvedItems = r.items
          })
          .catch(error => {
            console.log(error)
            this.resolvedError = true
          })
          .finally(() => {
            this.resolvedLoading = false
          })
      },
      getData () {
        this.loading = true
        authFetch(process.env.VUE_APP_API_ROOT + 'admin/support/tickets/')
          .then(async response => {
            const items = await response.json()
            items.forEach(r => {
              r.loading = false
              this.$set(this.items, r.id, r)
            })
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.loading = false
          })
      },
      submit (id) {
        this.items[id].loading = true
        const postData = {
          id: id,
        }
        authFetch(process.env.VUE_APP_API_ROOT + 'admin/support/tickets/', {
          method: 'PUT',
          body: JSON.stringify(postData),
        })
          .then(async response => {
            await response.json()
            this.$delete(this.items, id)
            this.getResolved()
          })
          .catch(async error => {
            console.log(error)
            this.items[id].loading = false
          })
      },
    },
  }
</script>

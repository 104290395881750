<template>
  <layout-panel>
    <v-container>
      <v-card>
        <v-card-text class="display-2 text-center mt-5 primary white--text">
          Previous Tasks
        </v-card-text>
      </v-card>
    </v-container>
    <v-container>
      <v-card>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="items"
            :loading="reloading"
            item-key="id"
            :options.sync="options"
            :sort-by="sortBy"
            :sort-desc="descending"
            :footer-props="{
              itemsPerPageOptions: [10, 25, 50]
            }"
            :server-items-length="total"
          >
            <v-progress-linear v-slot:progress color="blue" indeterminate />
            <template v-slot:item="row">
              <tr
                :key="row.item.id"
                @click="goToTask(row.item.id)"
              >
                <td>{{ row.item.taskCreated }}</td>
                <td>{{ row.item.taskName }}</td>
                <td>{{ translateType(row.item.taskType) }}</td>
                <td>{{ translateStatus(row.item.taskStatus) }}</td>
                <td v-if="showDuration(row.item)">{{ row.item.taskDuration }}</td>
                <td v-else>n/a</td>
                <td>
                  {{ row.item.asr }}
                  <template v-if="row.item.asr !== 'n/a'">%</template>
                </td>
                <td>
                  <template v-if="!row.loading">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          class="pl-2"
                          v-on="on"
                          @click.stop="sendShow(row)"
                        >visibility</v-icon>
                      </template>
                      <span>Show</span>
                    </v-tooltip>
                  </template>
                  <v-progress-circular
                    v-else
                    indeterminate
                    size="15"
                    width="2"
                  />
                </td>
              </tr>
            </template>
            <template v-slot:no-data>
              <div v-if="!loading && !error" class="text-center">
                You have no tasks to display
              </div>
              <v-alert v-if="!loading && error" :value="true" color="error" icon="warning">
                Error loading tasks
              </v-alert>
              <div v-if="initialLoad" class="text-center">
                <v-progress-circular indeterminate />
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </layout-panel>
</template>

<script>
  import { authFetch, objectToQueryString } from '@/plugins/authFetch'
  import { statusMap, typeMap } from '@/plugins/common'

  export default {
    data () {
      return {
        headers: [
          { text: 'Created', value: 'taskCreated' },
          { text: 'Name', value: 'taskName' },
          { text: 'Type', value: 'taskType' },
          { text: 'Status', value: 'taskStatus' },
          { text: 'Dur', value: 'taskDuration', sortable: false },
          { text: 'ASR', value: 'asr', sortable: false },
          { text: 'Actions', value: 'id', sortable: false },
        ],
        items: [],
        initialLoad: true,
        loading: true,
        reloadTimer: null,
        reloading: false,
        error: false,
        options: {},
        sortBy: 'taskCreated',
        descending: true,
        total: 0,
      }
    },
    watch: {
      options: {
        handler () {
          this.getData()
        },
        deep: true,
      },
    },
    methods: {
      getData () {
        if (!this.initialLoad) {
          this.reloading = true
        }
        this.loading = true
        const queryString = objectToQueryString({
          hidden: 1,
          itemsPerPage: this.options.itemsPerPage,
          page: this.options.page,
          sortBy:
            this.options.sortBy.length === 0
              ? this.sortBy
              : this.options.sortBy[0],
          sortDesc:
            this.options.sortDesc.length === 0
              ? this.descending === true
                ? 1
                : 0
              : this.options.sortDesc[0] === true
                ? 1
                : 0,
        })
        authFetch(process.env.VUE_APP_API_ROOT + `tasks/?${queryString}`)
          .then(async response => {
            const result = await response.json()
            this.items = result.items
            this.total = result.total
          })
          .catch(error => {
            console.log(error)
            this.error = true
          })
          .finally(() => {
            this.initialLoad = false
            this.reloading = false
            this.loading = false
          })
      },
      showDuration (item) {
        return item.taskType !== 'finalized'
      },
      goToTask (id) {
        this.$router.push({
          name: 'task',
          params: { taskId: id },
        })
      },
      translateStatus (key) {
        if (Object.prototype.hasOwnProperty.call(statusMap, key)) {
          return statusMap[key]
        }
        return `Unknown (${key})`
      },
      translateType (key) {
        if (Object.prototype.hasOwnProperty.call(typeMap, key)) {
          return typeMap[key]
        }
        return `Unknown (${key})`
      },
      sendShow (row) {
        row.loading = true
        authFetch(process.env.VUE_APP_API_ROOT + `task/action/${row.item.id}/`, {
          method: 'POST',
          body: JSON.stringify({ action: 'show' }),
        })
          .then(async response => {
            const result = await response.json()
            row.item.taskStatus = result.result
            this.items.forEach((v, k) => {
              if (v.id === row.item.id) {
                this.$delete(this.items, k)
              }
            })
          })
          .catch(error => {
            console.log(error)
            this.error = true
          })
          .finally(() => {
            row.loading = false
          })
      },
    },
  }
</script>

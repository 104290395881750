<template>
  <v-main>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4">
          <div style="text-align: center">
            <img :src="logo" alt="pvtltd">
          </div>

          <v-card class="mb-5">
            <v-card-text class="text-center py-5">
              <v-carousel
                hide-delimiters
                :show-arrows="false"
                height="30"
                continuous
                cycle
                touchless
              >
                <v-carousel-item
                  v-for="(slide, k) in slides"
                  :key="k"
                >
                  <span class="headline" :style="carouselText">{{ slide }}</span>
                </v-carousel-item>
              </v-carousel>
            </v-card-text>
          </v-card>

          <v-card class="mb-5">
            <v-form ref="login" v-model="valid" @submit.prevent="submit">
              <v-card-text>
                <v-text-field
                  v-model="formData.user"
                  :rules="userRules"
                  :error-messages="formErrors.user"
                  prepend-icon="person"
                  name="login"
                  label="Username"
                  type="text"
                  required
                  @keydown="formErrors.user = null"
                />
                <v-text-field
                  v-model="formData.password"
                  :rules="passwordRules"
                  :error-messages="formErrors.password"
                  prepend-icon="lock"
                  name="password"
                  label="Password"
                  type="password"
                  required
                  @keydown="formErrors.password = null"
                />
                <center>
                  <vue-recaptcha
                    ref="captcha"
                    :sitekey="recaptchaKey"
                    :load-recaptcha-script="true"
                    :theme="captchaTheme"
                    @error="captchaClear"
                    @expired="captchaClear"
                    @verify="captchaSet"
                  />
                </center>
                <v-switch v-model="formData.persist" label="Remember Me" />
              </v-card-text>
              <v-card-actions class="px-4">
                <v-spacer />
                <v-btn color="primary" @click="register()">Register</v-btn>
                <v-btn
                  color="primary"
                  type="submit"
                  :disabled="!valid"
                  :loading="loading"
                >Login</v-btn>
                <v-btn
                  color="primary"
                  :to="{ name: 'contact' }"
                >Contact Us</v-btn>
              </v-card-actions>
              <v-card-actions>
                <v-spacer />

              </v-card-actions>
            </v-form>
          </v-card>
          <div class="my-5 text-right overline">
            <router-link :to="{ name: 'terms' }">Terms and Conditions</router-link> |
            <router-link :to="{ name: 'privacy' }">Privacy Policy</router-link>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <v-card-title class="headline" color="error">Server Error</v-card-title>
        <v-card-text>
          <p>There was an error processing your request.</p>
          <p>{{ errorText }}</p>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
  import VueRecaptcha from 'vue-recaptcha'

  export default {
    components: { VueRecaptcha },
    props: {
      originUri: {
        type: String,
        default: 'dashboard',
      },
      logout: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        dialog: false,
        errorText: null,
        valid: true,
        loading: false,
        formData: {
          persist: true,
          user: null,
          password: null,
          captcha: null,
        },
        formErrors: {
          user: null,
          password: null,
        },
        userRules: [v => !!v || 'Username is required'],
        passwordRules: [v => !!v || 'Password is required'],
        slides: [
          'Welcome to Rubix Platform',
          'IPRN World Leaders',
          'VAS Specialists',
          'CLI Verification Toolset',
          'Register now to join our platform',
          'Highest Payout Guaranteed!',
        ],
      }
    },
    computed: {
      carouselText () {
        if (this.$vuetify.theme.dark === true) {
          return { color: 'white' }
        }
        return { color: 'black' }
      },
      recaptchaKey () {
        return process.env.VUE_APP_RECAPTCHA_PUBLIC
      },
      logo () {
        if (this.$vuetify.theme.dark === true) {
          return require('@/assets/logo.png')
        }
        return require('@/assets/logo_light.png')
      },
      captchaTheme () {
        if (this.$vuetify.theme.dark === true) {
          return 'dark'
        }
        return 'light'
      },
    },
    created () {
      if (this.logout === true) {
        this.$store.dispatch('clearSession')
        console.log('Logged out')
      }
    },
    methods: {
      register () {
        this.$router.push({ name: 'register' })
      },
      submit () {
        // Clear errors
        this.$refs.login.resetValidation()
        this.errorText = null
        Object.keys(this.formErrors).forEach(key => {
          this.formErrors[key] = null
        })
        this.valid = false

        if (!this.formData.captcha) {
          this.errorText = 'Captcha must be completed'
          this.dialog = true
          this.valid = true
          return false
        }

        // Validate
        if (this.$refs.login.validate()) {
          this.loading = true
          fetch(process.env.VUE_APP_API_ROOT + 'session/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(this.formData),
          })
            .then(async response => {
              // Non 2XX?
              if (!response.ok) {
                this.captchaClear()
                const data = await response.json()
                // Correct error response?
                if (typeof data === 'object' && Object.prototype.hasOwnProperty.call(data, 'message')) {
                  // Is it an object we can loop through for feedback?
                  if (typeof data.message === 'object') {
                    // Show errors by their input if so
                    Object.keys(data.message).forEach(key => {
                      if (Object.prototype.hasOwnProperty.call(this.formErrors, key)) {
                        this.formErrors[key] = data.message[key]
                      } else {
                        this.errorText = `${key}: ${data.message[key]}`
                        this.valid = true
                      }
                    })
                  // String returned, setup dialog
                  } else {
                    this.errorText = `(${response.status}) ${data.message}`
                    this.valid = true
                  }
                // Generic error if we didn't get a proper response
                } else {
                  this.errorText = `Server returned a ${response.status} status code`
                }
              // Handle success
              } else {
                const data = await response.json()
                if (Object.prototype.hasOwnProperty.call(data, 'id')) {
                  await this.$store.dispatch('setSessionId', data.id)
                  await this.$store.dispatch('populateUserDetails', data.id)
                  // Do we return to the last viewed page?
                  if (this.$store.state.permission & 1) {
                    this.$router.push({ name: 'admin-invoice-view' })
                  } else {
                    this.$router.push({ name: this.originUri })
                  }
                } else {
                  this.errorText = 'Received an unexpected response from the server. Not sure what to do'
                  this.valid = true
                }
              }
              if (this.errorText) {
                this.dialog = true
              }
            })
            .catch((error) => {
              console.log(error)
              this.captchaClear()
              this.errorText = 'Unable to reach API server'
              this.dialog = true
              this.valid = true
            })
            .finally(() => {
              this.loading = false
            })
        }
      },
      captchaSet (v) {
        this.formData.captcha = v
      },
      captchaClear () {
        this.formData.captcha = null
        this.$refs.captcha.reset()
      },
    },
  }
</script>

<style>
  .v-toolbar__content {
    text-align: center;
    padding-top: 1em;
    padding-bottom: 1em;
  }
  .v-toolbar__title {
    font-size: 3em;
    font-weight: 300;
    width: 100%;
  }
  .v-toolbar__title strong {
    font-weight: 700;
  }
</style>

<template>
  <layout-panel>
    <task-form-steps
      :task-type="type"
      :page-header="pageHeader"
      :page-description="pageDescription"
    />
  </layout-panel>
</template>

<script>
  import TaskFormSteps from '@/components/TaskFormAutoSteps'

  export default {
    components: { TaskFormSteps },
    props: {
      type: { type: String, default: 'auto-exploration' },
      pageHeader: { type: String, default: 'Find New Access' },
    },
    computed: {
      pageDescription () {
        if (this.type === 'auto-smart') {
          return 'Search IPRN Access Historically, using access that has been seen ' +
            'before on the system. Select the amount of time to look backwards in ' +
            'history.'
        } else {
          return 'Upload your providers rate sheet, choose the configuration options ' +
            'and the platform will explore the selected routes potential and ' +
            'capabilities, and return the working access for you to work with using ' +
            'the Generic Access Finder.'
        }
      },
    },
  }
</script>

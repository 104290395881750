<template>
  <layout-panel>
    <v-container>
      <v-card>
        <v-card-text class="display-2 text-center mt-5 primary white--text">
          Invoices
        </v-card-text>
      </v-card>
    </v-container>
    <v-container>
      <v-card>
        <v-container>
          <v-row>
            <v-col cols="7">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="date"
                    label="Invoice Date"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                  />
                </template>
                <v-date-picker v-model="date" type="month" @input="menu = false" />
              </v-menu>
            </v-col>
            <v-col cols="4" offset="1">
              <v-btn color="primary" @click="submit()">Get Invoices</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-container>

    <v-container v-if="loading">
      <v-row>
        <v-col class="text-center">
          <v-progress-circular
            :size="70"
            :width="7"
            indeterminate
          />
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="loading === false && requested === true && items.length === 0">
      <v-alert
        type="info"
        text
      >
        No invoices found
      </v-alert>
    </v-container>

    <template v-if="items.length > 0">
      <v-container>
        <v-card>
          <v-card-title class="display-1">Monthly Summary</v-card-title>
          <v-data-table
            :items="totalItems"
            :headers="headers"
            hide-default-footer
            :items-per-page="-1"
          >
            <template v-slot:item.totalRevenue="row">
              ${{ floorToTwo(row.item.totalRevenue) }}
            </template>
            <template v-slot:item.totalCost="row">
              ${{ floorToTwo(row.item.totalCost) }}
            </template>
            <template v-slot:item.ourCost="row">
              ${{ floorToTwo(row.item.ourCost) }}
            </template>
            <template v-slot:item.userCost="row">
              ${{ floorToTwo(row.item.userCost) }}
            </template>
            <template v-slot:item.totalProfit="row">
              ${{ floorToTwo(row.item.totalProfit) }}
            </template>
            <template v-slot:item.ourProfit="row">
              ${{ floorToTwo(row.item.ourProfit) }}
            </template>
            <template v-slot:item.userProfit="row">
              ${{ floorToTwo(owedToUser(row)) }}
            </template>
          </v-data-table>
          <v-card-title class="headline">Totals</v-card-title>
          <v-data-table
            :items="totals.totals"
            :headers="headersTotal"
            hide-default-footer
            :items-per-page="-1"
            disable-sort
          >
            <template v-slot:item.totalRevenue="row">
              ${{ floorToTwo(row.item.totalRevenue) }}
            </template>
            <template v-slot:item.totalCost="row">
              ${{ floorToTwo(row.item.totalCost) }}
            </template>
            <template v-slot:item.ourCost="row">
              ${{ floorToTwo(row.item.ourCost) }}
            </template>
            <template v-slot:item.userCost="row">
              ${{ floorToTwo(row.item.userCost) }}
            </template>
            <template v-slot:item.totalProfit="row">
              ${{ floorToTwo(row.item.totalProfit) }}
            </template>
            <template v-slot:item.ourProfit="row">
              ${{ floorToTwo(row.item.ourProfit) }}
            </template>
            <template v-slot:item.userProfit="row">
              ${{ floorToTwo(owedToUser(row)) }}
            </template>
          </v-data-table>
        </v-card>
      </v-container>
      <v-container v-for="d in items" :key="d.date">
        <v-card>
          <v-card-title class="display-1">{{ d.date }}</v-card-title>
          <v-data-table
            :items="d.items"
            :headers="headers"
            hide-default-footer
            :items-per-page="-1"
          >
            <template v-slot:item.totalRevenue="row">
              ${{ floorToTwo(row.item.totalRevenue) }}
            </template>
            <template v-slot:item.totalCost="row">
              ${{ floorToTwo(row.item.totalCost) }}
            </template>
            <template v-slot:item.ourCost="row">
              ${{ floorToTwo(row.item.ourCost) }}
            </template>
            <template v-slot:item.userCost="row">
              ${{ floorToTwo(row.item.userCost) }}
            </template>
            <template v-slot:item.totalProfit="row">
              ${{ floorToTwo(row.item.totalProfit) }}
            </template>
            <template v-slot:item.ourProfit="row">
              ${{ floorToTwo(row.item.ourProfit) }}
            </template>
            <template v-slot:item.userProfit="row">
              ${{ floorToTwo(owedToUser(row)) }}
            </template>
          </v-data-table>
          <v-card-title class="headline">Totals</v-card-title>
          <v-data-table
            :items="d.totals"
            :headers="headersTotal"
            hide-default-footer
            :items-per-page="-1"
            disable-sort
          >
            <template v-slot:item.totalRevenue="row">
              ${{ floorToTwo(row.item.totalRevenue) }}
            </template>
            <template v-slot:item.totalCost="row">
              ${{ floorToTwo(row.item.totalCost) }}
            </template>
            <template v-slot:item.ourCost="row">
              ${{ floorToTwo(row.item.ourCost) }}
            </template>
            <template v-slot:item.userCost="row">
              ${{ floorToTwo(row.item.userCost) }}
            </template>
            <template v-slot:item.totalProfit="row">
              ${{ floorToTwo(row.item.totalProfit) }}
            </template>
            <template v-slot:item.ourProfit="row">
              ${{ floorToTwo(row.item.ourProfit) }}
            </template>
            <template v-slot:item.userProfit="row">
              ${{ floorToTwo(owedToUser(row)) }}
            </template>
          </v-data-table>
        </v-card>
      </v-container>
    </template>
  </layout-panel>
</template>

<script>
  import { authFetch, objectToQueryString } from '@/plugins/authFetch'
  import { floorToTwo } from '@/plugins/common'

  export default {
    props: {
      yearProp: { type: Number, required: true },
      monthProp: { type: Number, required: true },
    },
    data () {
      return {
        year: this.yearProp,
        month: this.monthProp,
        items: [],
        totals: [],
        loading: false,
        headers: [
          { text: 'User', value: 'user' },
          { text: 'Total Revenue', value: 'totalRevenue' },
          { text: 'Total Cost', value: 'totalCost' },
          { text: 'Our Cost', value: 'ourCost' },
          { text: 'User Cost', value: 'userCost' },
          { text: 'Total Profit', value: 'totalProfit' },
          { text: 'Our Profit', value: 'ourProfit' },
          { text: 'Owed to User', value: 'userProfit' },
        ],
        headersTotal: [
          { text: 'Total Revenue', value: 'totalRevenue' },
          { text: 'Total Cost', value: 'totalCost' },
          { text: 'Our Cost', value: 'ourCost' },
          { text: 'Users Cost', value: 'userCost' },
          { text: 'Total Profit', value: 'totalProfit' },
          { text: 'Our Profit', value: 'ourProfit' },
          { text: 'Owed to Users', value: 'userProfit' },
        ],
        menu: false,
        requested: false,
      }
    },
    computed: {
      date: {
        get () {
          if (!this.year || !this.month) {
            return new Date().toISOString().substr(0, 7)
          }
          return `${this.year}-${this.month}`
        },
        set (v) {
          this.updateDate(v)
        },
      },
      totalItems () {
        return Object.values(this.totals.items)
      },
    },
    watch: {
      monthProp () {
        this.onPropChange()
      },
      yearProp () {
        this.onPropChange()
      },
    },
    mounted () {
      if (this.yearProp && this.monthProp) {
        this.getData(this.yearProp, this.monthProp)
      } else {
        this.date = new Date().toISOString().substr(0, 7)
      }
    },
    methods: {
      onPropChange () {
        this.year = this.yearProp
        this.month = this.monthProp
        if (this.requested === true) {
          this.requested = false
        }
        this.getData(this.year, this.month)
      },
      updateDate (v) {
        let p = v.split('-')
        if (p.length !== 2) {
          const nv = new Date().toISOString().substr(0, 7)
          p = nv.split('-')
        }
        this.year = parseInt(p[0], 10)
        this.month = parseInt(p[1], 10)
      },
      floorToTwo (v) {
        return floorToTwo(v)
      },
      owedToUser (row) {
        if (row.item.totalCost > row.item.totalRevenue) {
          return row.item.totalRevenue
        }
        return row.item.userProfit + row.item.totalCost
      },
      totalObject () {
        return {
          totalRevenue: 0,
          totalCost: 0,
          totalProfit: 0,
          ourRevenue: 0,
          ourCost: 0,
          userRevenue: 0,
          userCost: 0,
          ourProfit: 0,
          userProfit: 0,
        }
      },
      submit () {
        this.$router.push({
          name: 'admin-invoice-view',
          query: {
            year: this.year,
            month: this.month,
          },
        })
      },
      getData (year, month) {
        if (!year || !month) {
          return
        }
        const queryString = objectToQueryString({
          month: month,
          year: year,
        })
        this.loading = true
        this.requested = true
        this.totals = []
        this.items = []
        authFetch(process.env.VUE_APP_API_ROOT + `admin/accounting/invoices/?${queryString}`)
          .then(async response => {
            const items = await response.json()
            this.items = items.items
            this.totals = items.totals
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.loading = false
          })
      },
    },
  }
</script>

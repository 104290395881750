<template>
  <v-form ref="form" @submit.prevent="submit">
    <v-container>
      <v-row>
        <v-col v-for="(data, random) in task.targetedPrefixes" :key="`randoms_${random}`">
          <v-card>
            <v-card-title>{{ random }} Randoms</v-card-title>
            <v-data-table
              v-model="checked[random]"
              :headers="headers"
              :items="data"
              hide-default-footer
              :items-per-page="-1"
              sort-by="total"
              :sort-desc="true"
              item-key="prefix"
              show-select
            >
              <template v-slot:item.prefix="{ item }">
                {{ item.prefix + 'x'.repeat(random) }}
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="end">
        <v-col v-if="!disableSubmit" class="text-right" cols="2">
          <v-text-field
            v-model="randoms"
            label="Randoms"
            :rules="[rules.positiveCommaSepNumbers, rules.required]"
            required
          />
        </v-col>
        <v-col cols="3">
          <v-btn
            color="primary"
            :disabled="disableSubmit"
            :loading="loading"
            large
            @click="submit"
          >
            Submit Selected Prefixes
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
  import { authFetch } from '@/plugins/authFetch'
  import * as rules from '@/plugins/rules'
  import { commaSeparatedNumbersToList } from '@/plugins/common'

  export default {
    props: {
      task: { type: Object },
    },
    data () {
      return {
        rules: rules,
        headers: [
          { text: 'Prefix', value: 'prefix' },
          { text: 'Total Seen', value: 'total' },
        ],
        checked: {},
        loading: false,
        randoms: this.task.destinationFilters[0].randomDigits.join(', '),
      }
    },
    computed: {
      disableSubmit () {
        let result = true
        Object.keys(this.checked).forEach(v => {
          if (this.checked[v].length > 0) {
            result = false
            return true
          }
        })
        return result
      },
    },
    methods: {
      submit () {
        if (!this.$refs.form.validate()) {
          return false
        }
        const filters = []
        Object.keys(this.checked).forEach(r => {
          r = parseInt(r, 10)
          this.checked[r].forEach(v => {
            filters.push({
              lengths: this.task.destinationFilters[0].lengths,
              randomDigits: commaSeparatedNumbersToList(this.randoms),
              rate: this.task.destinationFilters[0].rate,
              string: [v.prefix],
              type: 'prefix',
            })
          })
        })
        if (filters.length === 0) {
          return false
        }
        const name = `Sub Pair: ${this.task.taskName}`

        const postData = {
          id: this.task.id,
          filters: filters,
          name: name,
        }
        this.loading = true
        authFetch(`${process.env.VUE_APP_API_ROOT}tasks/targeted/`, {
          method: 'POST',
          body: JSON.stringify(postData),
        })
          .then(async response => {
            await response.json()
            await this.$router.push({ name: 'tasks' })
          })
          .catch(async error => {
            console.log(error)
          })
          .finally(() => {
            this.loading = false
          })
      },
    },
  }
</script>

<style scoped>

</style>

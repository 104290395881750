<template>
  <layout-panel>
    <v-container><v-card>
      <v-card-text class="display-2 text-center mt-5 primary white--text">
        Hourly Access Report Table
        <div class="grey--text text--lighten-1 body-1">
          Access that has been seen within the last month, counted hourly.
        </div>
      </v-card-text>
    </v-card></v-container>
    <v-container>
      <v-row>
        <v-col>
          <v-card>
            <v-form ref="form" @submit.prevent="getData">
              <v-container>
                <v-row>
                  <v-col>
                    <v-select
                      v-model="months"
                      :items="monthsItems"
                    />
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="filterDestination"
                      label="Destination Carrier filter"
                      :rules="[rules.moreThanTwoCharacters]"
                      validate-on-blur
                    />
                  </v-col>
                  <v-col>
                    <v-radio-group v-model="advanced" row>
                      <v-radio label="Normal" :value="false" />
                      <v-radio label="Advanced" :value="true" />
                    </v-radio-group>
                  </v-col>
                  <v-col cols="2">
                    <v-btn
                      color="primary"
                      type="submit"
                      :loading="loading"
                    >
                      Get Pairs
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-alert
        v-if="loaded === true && loading === false && total === 0"
        :value="true"
        color="error"
        icon="warning"
      >
        No data available
      </v-alert>
    </v-container>
    <template v-if="loading === false && total > 0">
      <v-expansion-panels>
        <v-expansion-panel v-for="table_key in tables" :key="table_key">
          <v-expansion-panel-header class="title">
            {{ titles[table_key] }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
              :items="items[table_key]"
              :headers="headers"
              :footer-props="{ itemsPerPageOptions: [50,100,{'text':'All',value:-1}] }"
              :items-per-page="50"
              item-key="qlId"
              sort-by="count"
              :sort-desc="true"
            >
              <template v-slot:header.quickLaunch="props">
                <v-tooltip bottom max-width="300">
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      {{ props.header.text }}
                      <v-icon size="16" class="pl-1" v-on="on">help</v-icon>
                    </span>
                  </template>
                  Quick Launch - Launch the selected tasks using the "Default Task
                  Settings" defined in User Settings
                </v-tooltip>
              </template>
              <template v-slot:item.firstSeen="row">
                {{ firstSeenToText(row.item.firstSeen) }}
              </template>
              <template v-slot:item.actions="row">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      @click.stop="sendAction(row)"
                    >file_copy</v-icon>
                  </template>
                  <span>Create task using pairs</span>
                </v-tooltip>
              </template>
              <template v-slot:item.quickLaunch="row">
                <v-checkbox
                  v-model="quickLaunch"
                  :value="quickLaunchItems[row.item.qlId]"
                  dense
                  class="short"
                />
              </template>
            </v-data-table>
            <v-card-actions v-if="quickLaunch.length > 0">
              <v-spacer />
              <v-btn
                color="primary"
                :disabled="quickLaunchSubmitting"
                :loading="quickLaunchSubmitting"
                @click="submitQuickLaunch()"
              >
                Quick Launch {{ quickLaunch.length }} Tasks
              </v-btn>
            </v-card-actions>
          </v-expansion-panel-content>

        </v-expansion-panel>
      </v-expansion-panels>
    </template>

    <v-dialog v-model="quickLaunchErrorDialog" max-width="500">
      <v-card>
        <v-card-title class="headline" color="error">Server Error</v-card-title>
        <v-card-text>
          <p>There was an error processing your request.</p>
          <p v-for="(err, key) in quickLaunchErrors" :key="key">{{ err }}</p>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="quickLaunchErrorDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </layout-panel>
</template>

<script>
  import { authFetch, objectToQueryString } from '@/plugins/authFetch'
  import * as rules from '@/plugins/rules'

  export default {
    data () {
      return {
        rules: rules,
        daysOfWeek: [
          { text: 'Sunday', value: 1 },
          { text: 'Monday', value: 2 },
          { text: 'Tuesday', value: 3 },
          { text: 'Wednesday', value: 4 },
          { text: 'Thursday', value: 5 },
          { text: 'Friday', value: 6 },
          { text: 'Saturday', value: 7 },
        ],
        months: 1,
        monthsItems: [
          { text: '1 Month', value: 1 },
          { text: '3 Months', value: 3 },
        ],
        items: {},
        total: 0,
        loading: false,
        loaded: false,
        titles: {
          0: '00:00 - 01:59',
          2: '02:00 - 03:59',
          4: '04:00 - 05:59',
          6: '06:00 - 07:59',
          8: '08:00 - 09:59',
          10: '10:00 - 11:59',
          12: '12:00 - 13:59',
          14: '14:00 - 15:59',
          16: '16:00 - 17:59',
          18: '18:00 - 19:59',
          20: '20:00 - 21:59',
          22: '22:00 - 23:59',
        },
        tables: ['0', '2', '4', '6', '8', '10', '12', '14', '16', '18', '20', '22'],
        quickLaunch: [],
        quickLaunchItems: {},
        quickLaunchErrors: [],
        quickLaunchSubmitting: false,
        quickLaunchErrorDialog: false,
        advanced: false,
        advancedSubmitted: false,
        filterDestination: '',
      }
    },
    computed: {
      headers () {
        const result = [
          { text: 'Retail Carrier', value: 'retail' },
          { text: 'Destination Carrier', value: 'destination' },
          { text: 'Days First Seen', value: 'firstSeen' },
          { text: 'Actions', value: 'actions' },
          { text: 'QL', value: 'quickLaunch' },
        ]
        if (this.$store.state.permission === 8) {
          result.splice(4)
        }
        return result
      },
    },
    methods: {
      showTable (key) {
        return Object.prototype.hasOwnProperty.call(this.items, key)
      },
      sendAction (row) {
        this.$router.push({
          name: 'task-new-from-pairs-query',
          query: {
            source: row.item.retail,
            destination: row.item.destination,
            carrierAll: this.advancedSubmitted === true ? 0 : 1,
          },
        })
      },
      getData () {
        if (!this.$refs.form.validate()) {
          this.submitting = false
          return false
        }
        const queryString = objectToQueryString({
          months: this.months,
          advanced: this.advanced ? 1 : 0,
          filterDestination: this.filterDestination,
        })
        this.total = 0
        this.loading = true
        this.loaded = false
        this.items = {}
        authFetch(process.env.VUE_APP_API_ROOT + `pairs/hourly/?${queryString}`)
          .then(async response => {
            const items = await response.json()
            this.advancedSubmitted = this.advanced
            if (typeof items !== 'object') {
              return
            }
            this.quickLaunch = []
            this.quickLaunchItems = {}
            let id = 0
            this.tables.forEach(k => {
              if (Object.prototype.hasOwnProperty.call(items, k)) {
                this.total += items[k].length
                this.items[k] = []
                items[k].forEach(item => {
                  item.qlId = id
                  this.items[k].push(item)
                  if (this.advancedSubmitted === true) {
                    this.quickLaunchItems[id] = {
                      destinations: { carrier: [item.destination] },
                      sources: [item.retail],
                    }
                  } else {
                    this.quickLaunchItems[id] = {
                      destinations: { carrierAll: [item.destination] },
                      sources: [item.retail],
                    }
                  }
                  id++
                })
              }
            })
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.loading = false
            this.loaded = true
          })
      },
      submitQuickLaunch () {
        const postData = { tasks: this.quickLaunch }
        this.quickLaunchErrors = []
        this.quickLaunchSubmitting = true
        this.quickLaunchErrorDialog = false
        authFetch(`${process.env.VUE_APP_API_ROOT}tasks/quick/`, {
          method: 'POST',
          body: JSON.stringify(postData),
        })
          .then(async response => {
            await response.json()
            this.$router.push({ name: 'tasks' })
          })
          .catch(async error => {
            console.log(error)
            const data = await error.json()
            if (typeof data === 'object' && Object.prototype.hasOwnProperty.call(data, 'message')) {
              // Is it an object we can loop through for feedback?
              if (typeof data.message === 'object') {
                Object.values(data.message).forEach(msg => {
                  this.quickLaunchErrors.push(msg)
                })
                // String returned, setup dialog
              } else {
                this.quickLaunchErrors = [data.message]
              }
              // Generic error if we didn't get a proper response
            } else {
              this.quickLaunchErrors = [`Server returned a ${error.status} status code`]
            }
          })
          .finally(() => {
            this.quickLaunchSubmitting = false
            if (this.quickLaunchErrors.length > 0) {
              this.quickLaunchErrorDialog = true
            }
          })
      },
      firstSeenToText (arr) {
        const result = []
        arr.forEach(v => {
          switch (v) {
            case 1:
              result.push('Sunday')
              break
            case 2:
              result.push('Monday')
              break
            case 3:
              result.push('Tuesday')
              break
            case 4:
              result.push('Wednesday')
              break
            case 5:
              result.push('Thursday')
              break
            case 6:
              result.push('Friday')
              break
            default:
              result.push('Saturday')
          }
        })
        return result.join(', ')
      },
    },
  }
</script>

<style scoped>
::v-deep .short {
  margin-top: 5px;
}
::v-deep .short .v-input__control {
  height: 35px;
}
</style>

<template>
  <layout-panel>
    <v-container><v-card>
      <v-card-text class="display-2 text-center mt-5 primary white--text">
        Rubix Robot
      </v-card-text>
    </v-card>
    </v-container>

    <v-container>
      <v-card>
        <v-card-title>Enable Robot</v-card-title>
        <v-card-subtitle>
          You must have at least one route mapped below for this feature to work
        </v-card-subtitle>
        <v-card-text v-if="autoStartLoaded">
          <v-switch
            v-model="autoStart"
            label="Automatically Launch New Popular Tasks"
            :loading="autoStartLoading"
            :disabled="autoStartLoading"
          />
        </v-card-text>
        <v-card-text v-else class="text-center">
          <v-progress-circular
            indeterminate
            size="25"
            width="2"
          />
        </v-card-text>
      </v-card>
    </v-container>

    <v-container v-if="loading">
      <v-row>
        <v-col class="text-center">
          <v-progress-circular
            :size="70"
            :width="7"
            indeterminate
          />
        </v-col>
      </v-row>
    </v-container>

    <v-form v-else ref="form" @submit.prevent="submit">
      <v-container>
        <v-card>
          <v-card-title class="title">Destination Options</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="2">
                <v-select
                  v-model="formData.destinationLookbackType"
                  :items="destinationLookbackTypes"
                  label="CDR Lookback Method"
                />
              </v-col>
              <template v-if="formData.destinationLookbackType === 'time'">
                <v-col cols="1">
                  <v-text-field
                    v-model="destinationLookbackTimeNum"
                    reverse
                  />
                </v-col>
                <v-col cols="2">
                  <v-select
                    v-model="destinationLookbackTimePeriod"
                    :items="destinationLookbackPeriods"
                  />
                </v-col>
              </template>
              <template v-if="formData.destinationLookbackType === 'rows'">
                <v-col cols="3">
                  <v-text-field
                    v-model="formData.destinationLookbackData"
                    :rules="[rules.required, rules.positiveNumber]"
                    type="number"
                    label="Amount"
                  />
                </v-col>
              </template>
            </v-row>
            <v-row>
              <v-col cols="2">
                <v-text-field
                  v-model="formData.destinationForceRandomDigits"
                  label="Random Digits"
                  :rules="[rules.positiveCommaSepNumbers]"
                >
                  <template v-slot:append>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">help</v-icon>
                      </template>
                      Used to generate only the trailing digits in the destination<br>
                      <ul>
                        <li v-for="(help, k) in randomsHelp" :key="k">
                          {{ help }}
                        </li>
                      </ul>
                    </v-tooltip>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-container>

      <v-container>
        <v-card>
          <v-card-title class="title">Source Options</v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <v-radio-group v-model="formData.sourceCount" row>
                  <v-radio
                    v-for="(item, key) in sourceCountItems"
                    :key="key"
                    :label="item.text"
                    :value="item.value"
                  />
                  <v-tooltip bottom max-width="400">
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">help</v-icon>
                    </template>
                    Selecting to use 12 numbers will test 3 of each numbers that
                    you add from the IPRN source list. This can be helpful in
                    speeding up the finding of new access. If the task is a
                    production/semi production task, you should select to use
                    All Numbers.
                  </v-tooltip>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2">
                <v-text-field
                  v-model="formData.sourceCliPrefix"
                  label="CLI Prefix"
                >
                  <template v-slot:append>
                    <v-tooltip bottom max-width="400">
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">help</v-icon>
                      </template>
                      Add a prefix to the CallerID when the call is delivered.
                    </v-tooltip>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-radio-group
                  v-model="formData.sourceLastSeenCheck"
                  label="Source Filtering"
                  row
                >
                  <v-radio label="None" value="none" />
                  <v-radio label="Verified Only" value="verified" />
                  <v-radio label="Exploration" value="exploration" />
                </v-radio-group>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-container>

      <v-container>
        <v-card>
          <v-card-title class="title">Task Options</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="3">
                <v-text-field
                  v-model="formData.taskDuration"
                  :rules="[rules.required, rules.numberGT0]"
                  required
                  label="Task Duration (minutes)"
                  type="number"
                />
              </v-col>
              <v-col cols="3">
                <v-select
                  v-model="formData.sourceGroup"
                  :items="sourceGroupItems"
                  label="Source Number Group"
                />
              </v-col>
            </v-row>
            <v-row />
          </v-card-text>
        </v-card>
      </v-container>

      <v-container>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title class="title">Route Map</v-card-title>
              <v-card-subtitle>
                Design your dial plan. Which of your routes belongs to which country? Please
                use the tool below to map your routes to their appropriate countries
              </v-card-subtitle>
              <v-card-text>
                <v-row>
                  <v-col cols="2">
                    <v-btn color="primary" @click="routeForm = true">
                      New Route
                    </v-btn>
                  </v-col>
                  <v-col v-if="routesLoading">
                    <v-progress-circular
                      :size="30"
                      :width="3"
                      indeterminate
                    />
                  </v-col>
                </v-row>
                <v-row v-for="(cmp, key) in routeMaps" :key="key">
                  <v-col>
                    <component
                      :is="cmp.component"
                      ref="routeMaps"
                      :key="key"
                      v-model="formData.routeMap[key]"
                      :index="key"
                      :errors="cmp.errors"
                      :routes="routesItems"
                      :rates="rates"
                      @remove="removeRouteMap"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-btn color="primary" @click="addRouteMap()">Add Route Map</v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <v-row>
          <v-col>
            <v-card>
              <v-card-text>
                <v-btn
                  color="primary"
                  large
                  block
                  type="submit"
                  :disabled="submitting"
                  :loading="submitting"
                >
                  {{ startButtonText }}
                </v-btn>
              </v-card-text>
              <v-card-text v-if="savedSuccess">
                <v-alert
                  v-model="savedSuccess"
                  type="success"
                  dismissible
                >Saved successfully</v-alert>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-dialog v-model="errorDialog" max-width="500">
        <v-card>
          <v-card-title class="headline" color="error">Validation Error</v-card-title>
          <v-card-text>
            <p>You made mistakes on the form that must be fixed.</p>
            <p v-for="(error, key) in errorDialogText" :key="key">{{ error }}</p>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" @click="errorDialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-form>

    <v-dialog v-model="routeForm" max-width="500">
      <new-route-form @completed="handleNewRoute" @cancel="routeForm = false" />
    </v-dialog>

  </layout-panel>
</template>

<script>
  import RouteMapRow from '@/components/RouteMapRow'
  import { authFetch } from '@/plugins/authFetch'
  import * as rules from '@/plugins/rules'
  import { commaSeparatedNumbersToList, sourceGroupItems } from '@/plugins/common'
  import NewRouteForm from '@/components/ModalRouteForm.vue'

  export default {
    components: { NewRouteForm },
    data () {
      return {
        sourceCountItems: [
          { text: 'Use 12 Numbers', value: 'one' },
          { text: 'Use All Numbers', value: 'all' },
        ],
        submitting: false,
        errorDialog: false,
        errorDialogText: '',
        rules: rules,
        destinationLookbackTypes: [
          { text: 'Time', value: 'time' },
          { text: 'Rows', value: 'rows' },
        ],
        destinationLookbackPeriods: [
          { text: 'Hours', value: 'hour' },
          { text: 'Days', value: 'day' },
          { text: 'Months', value: 'month' },
          { text: 'Years', value: 'year' },
        ],
        formData: {
          sourceCount: 'one',
          sourceGroup: null,
          sourceCliPrefix: '',
          destinationLookbackType: 'time',
          destinationLookbackData: '3 month',
          destinationForceRandomDigits: '',
          taskDuration: null,
          sourceLastSeenCheck: 'none',
          routeMap: {},
        },
        routesItems: [],
        routesLoading: true,
        routeForm: false,
        savedSuccess: false,
        randomsHelp: [
          'Empty: Leave empty to randomize',
          'Single digit: (eg 7) will generate 7 trailing digits using a random known working number',
          '0: Only use numbers that we have seen within the timerange. No randomization',
          'List: (eg 5, 6, 7) will choose 5, 6, or 7 trailing digits to randomize like above',
        ],
        startButtonText: 'Save',
        forceRandomDigits: null,
        sourceGroupItems: sourceGroupItems,
        routeMapKey: 0,
        routeMaps: {},
        rates: {},
        loading: false,
        autoStartLoaded: false,
        autoStartLoading: false,
        autoStart: null,
      }
    },
    computed: {
      destinationLookbackTimeNum: {
        get () {
          if (typeof this.formData.destinationLookbackData === 'undefined') {
            return 1
          }
          const parts = this.formData.destinationLookbackData.split(' ')
          if (isNaN(parts[0])) {
            return 1
          }
          return parseInt(parts[0], 10)
        },
        set (value) {
          this.formData.destinationLookbackData = `${value} ${this.destinationLookbackTimePeriod}`
        },
      },
      destinationLookbackTimePeriod: {
        get () {
          if (typeof this.formData.destinationLookbackData === 'undefined') {
            return 'day'
          }
          const parts = this.formData.destinationLookbackData.split(' ')
          if (parts.length < 2) {
            return 'day'
          }
          return parts[1]
        },
        set (value) {
          this.formData.destinationLookbackData = `${this.destinationLookbackTimeNum} ${value}`
        },
      },
      formDataLookbackData () {
        return this.formData.destinationLookbackData
      },
      formDataLookbackType () {
        return this.formData.destinationLookbackType
      },
    },
    watch: {
      formDataLookbackData () {
        if (this.formData.destinationLookbackType === 'time') {
          const parts = this.formData.destinationLookbackData.split(' ')
          if (parts.length < 2) {
            return
          }
          this.destinationLookbackTimeNum = parts[0]
          this.destinationLookbackTimePeriod = parts[1]
        }
      },
      formDataLookbackType () {
        if (this.formData.destinationLookbackType === 'time') {
          this.formData.destinationLookbackData = '1 day'
        } else {
          this.formData.destinationLookbackData = '1000'
        }
      },
      autoStart (v) {
        if (this.autoStartLoaded === false) { return }
        this.updateSettings({ autoStart: v })
      },
    },
    created () {
      this.loading = true
      this.error = false
      this.loadRoutes()
      authFetch(`${process.env.VUE_APP_API_ROOT}task/templates/auto/`)
        .then(async response => {
          const data = await response.json()
          this.rates = data.rates
          delete data.rates
          if (Object.keys(data).length > 0) {
            this.formData = data
          }
          if (Array.isArray(this.formData.destinationForceRandomDigits)) {
            this.formData.destinationForceRandomDigits = this.formData.destinationForceRandomDigits.join(', ')
          }
        })
        .catch((error) => {
          console.log(error)
          this.error = `Server returned a ${error.status} status code`
        })
        .finally(() => {
          this.loading = false
          this.populateRouteMap()
        })
      authFetch(`${process.env.VUE_APP_API_ROOT}user/settings/`)
        .then(async response => {
          const data = await response.json()
          this.autoStart = data.autoStart
          this.$nextTick(() => {
            this.autoStartLoaded = true
          })
        })
        .catch(async error => {
          console.log(error)
        })
    },
    methods: {
      populateRouteMap () {
        if (Object.keys(this.formData.routeMap).length === 0) {
          this.addRouteMap()
        } else {
          Object.keys(this.formData.routeMap).forEach(k => {
            this.addRouteMap(k)
          })
        }
      },
      addRouteMap (key = null) {
        if (key !== null) {
          this.routeMapKey = key
        }
        this.$set(this.routeMaps, this.routeMapKey, {
          component: RouteMapRow,
        })
        this.routeMapKey++
      },
      removeRouteMap (key) {
        if (Object.prototype.hasOwnProperty.call(this.routeMaps, key)) {
          this.$delete(this.routeMaps, key)
        }
        if (Object.prototype.hasOwnProperty.call(this.formData.routeMap, key)) {
          this.$delete(this.formData.routeMap, key)
        }
      },
      handleNewRoute (id) {
        this.loadRoutes(id)
        this.routeForm = false
      },
      loadRoutes (setTo) {
        this.routesLoading = true
        authFetch(process.env.VUE_APP_API_ROOT + 'routes/')
          .then(async response => {
            const items = await response.json()
            this.routesItems = []
            items.forEach(r => {
              let prefix = 'No Prefix'
              if (r.prefix !== '') {
                prefix = `Prefix: ${r.prefix}`
              }
              let labelText = ''
              if (r.label) {
                labelText = `${r.label} - ${r.ip}:${r.port} ${prefix}`
              } else {
                labelText = `${r.ip}:${r.port} ${prefix}`
              }
              this.routesItems.push({
                text: labelText,
                value: r.id,
              })
            })
          })
          .finally(() => {
            this.routesLoading = false
          })
      },
      submit () {
        this.savedSuccess = false
        this.errorDialogText = []
        let valid = true

        // Route map
        this.$refs.routeMaps.forEach(r => {
          if (r.validate() === false) {
            valid = false
          }
        })
        if (Object.keys(this.formData.routeMap).length === 0) {
          this.errorDialogText.push('At least once mapped route is required')
        }

        // Show dialog if needed
        if (this.errorDialogText.length > 0) {
          this.errorDialog = true
          valid = false
        }

        // Built-in validation
        if (!this.$refs.form.validate() || !valid) {
          this.submitting = false
          return false
        }

        const postData = {
          taskType: 'template',
          taskParent: 0,
          sources: [],
          sourceType: 'carrier',
          sourceCount: this.formData.sourceCount,
          sourceLookbackTime: 1,
          sourceLookbackTimePeriod: 'day',
          sourceAutoWhitelist: [],
          sourceAutoWhitelistType: 'white',
          sourceAutoBan: false,
          sourceAutoRefine: false,
          sourceMinimumPopularity: 0,
          sourceGroup: this.formData.sourceGroup,
          sourceLastSeenCheck: this.formData.sourceLastSeenCheck,
          sourceCliPrefix: this.formData.sourceCliPrefix,
          destinationType: 'exact',
          destinationLookbackType: this.formData.destinationLookbackType,
          destinationLookbackData: this.formData.destinationLookbackData,
          destinationFilters: [{ type: '', string: '' }],
          destinationCSV: '',
          destinationCSVNumberColumn: 0,
          destinationCSVRate: 0,
          taskName: '',
          taskDuration: parseInt(this.formData.taskDuration, 10),
          taskPDDs: [],
          taskMinASR: 0,
          taskMaxASR: 0,
          taskACD: [0],
          taskPDDLock: false,
          taskMaxSimultaneousCalls: 0,
          taskMaxCallsPerInterval: [0],
          taskMaxCallsInterval: [1],
          routeId: 0,
          routeDialServer: 'auto',
          destinationForceRandomDigits: commaSeparatedNumbersToList(
            this.formData.destinationForceRandomDigits
          ),
          routeMap: Object.values(this.formData.routeMap),
        }

        this.submitting = true
        authFetch(`${process.env.VUE_APP_API_ROOT}task/templates/auto/`, {
          method: 'PUT',
          body: JSON.stringify(postData),
        })
          .then(async response => {
            const data = await response.json()
            if (Object.prototype.hasOwnProperty.call(data, 'result') && data.result === 'success') {
              this.savedSuccess = true
            } else {
              this.errorDialogText = ['Received an unexpected response from the server. Not sure what to do']
              this.valid = true
            }
          })
          .catch(async error => {
            console.log(error)
            const data = await error.json()
            // Correct error response?
            if (typeof data === 'object' && Object.prototype.hasOwnProperty.call(data, 'message')) {
              // Is it an object we can loop through for feedback?
              if (typeof data.message === 'object') {
                this.errorDialogText = data.message
                // String returned, setup dialog
              } else {
                this.errorDialogText = [data.message]
              }
              // Generic error if we didn't get a proper response
            } else {
              this.errorDialogText = [`Server returned a ${error.status} status code`]
            }
            if (this.errorDialogText) {
              this.errorDialog = true
            }
          })
          .finally(() => {
            this.submitting = false
          })
      },
      updateSettings (obj) {
        return new Promise((resolve, reject) => {
          this.autoStartLoading = true
          authFetch(`${process.env.VUE_APP_API_ROOT}user/settings/`, {
            method: 'PUT',
            body: JSON.stringify({ settings: obj }),
          })
            .then(async response => {
              await response.json()
              resolve()
            })
            .catch(async error => {
              console.log(error)
              reject(error)
            })
            .finally(() => {
              this.autoStartLoading = false
            })
        })
      },
    },
  }
</script>

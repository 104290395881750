<template>
  <div>
    <v-form ref="form" v-model="formValid" @submit.prevent="submitWithLookbackCheck">
      <v-container v-if="showDestinations && externalTask === false">
        <v-row>
          <v-col>
            <v-card>
              <v-container class="mx-3">
                <v-row>
                  <v-col cols="3">
                    <span class="title">Destination Selection</span>
                  </v-col>
                  <v-col cols="3">
                    <v-select
                      v-if="destinationTypes.length > 1"
                      v-model="destinationType"
                      :items="destinationTypes"
                      label="Destination Selection Method"
                    />
                  </v-col>
                </v-row>
                <v-row v-if="formData.destinationType === 'whitelist'">
                  <template v-if="showDestinationFilters">
                    <v-col cols="2">
                      <v-select
                        v-model="formData.destinationLookbackType"
                        :items="destinationLookbackTypes"
                        label="CDR Lookback Method"
                      />
                    </v-col>
                    <template v-if="formData.destinationLookbackType === 'time'">
                      <v-col cols="1">
                        <v-text-field
                          v-model="destinationLookbackTimeNum"
                          reverse
                        />
                      </v-col>
                      <v-col cols="2">
                        <v-select
                          v-model="destinationLookbackTimePeriod"
                          :items="destinationLookbackPeriods"
                        />
                      </v-col>
                    </template>
                    <template v-if="formData.destinationLookbackType === 'rows'">
                      <v-col cols="3">
                        <v-text-field
                          v-model="formData.destinationLookbackData"
                          :rules="[rules.required, rules.positiveNumber]"
                          type="number"
                          label="Amount"
                        />
                      </v-col>
                    </template>
                  </template>
                  <template v-if="showDestinationFilters">
                    <v-col cols="12">
                      <v-btn color="primary" @click="addWhitelist()">Add Filter</v-btn>
                    </v-col>
                    <v-col cols="12">
                      <template v-for="(child, index) in whitelists">
                        <component
                          :is="child.component"
                          :key="index"
                          v-model="formData.whitelists[index]"
                          v-bind="{
                            index: index,
                            errors: child.errors,
                            lookbackType: formData.destinationLookbackType,
                            lookbackData: formData.destinationLookbackData
                          }"
                          @removeWhitelist="removeWhitelist"
                        />
                      </template>
                    </v-col>
                  </template>
                </v-row>
                <v-row v-if="formData.destinationType === 'hlr'">
                  <v-col cols="3">
                    <div class="mt-5 text-end">HLR Minimum Updated Time</div>
                  </v-col>
                  <v-col cols="1">
                    <v-text-field
                      v-model="destinationLookbackTimeNum"
                      reverse
                    />
                  </v-col>
                  <v-col cols="2">
                    <v-select
                      v-model="destinationLookbackTimePeriod"
                      :items="destinationLookbackPeriods"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-btn color="primary" @click="addHLR()">Add Filter</v-btn>
                  </v-col>
                  <template v-for="(child, index) in hlrWhitelists">
                    <component
                      :is="child.component"
                      :key="index"
                      v-model="hlrWhitelistFilters[index]"
                      v-bind="{
                        index: index,
                        errors: child.errors,
                        lookbackType: formData.destinationLookbackType,
                        lookbackData: formData.destinationLookbackData
                      }"
                      @removeWhitelist="removeHLR"
                    />
                  </template>
                </v-row>
                <v-row v-if="formData.destinationType === 'custom'">
                  <v-col cols="6">
                    <v-file-input
                      accepts=".csv,text/csv"
                      label="CSV Number File"
                      required
                      :error="csvError.length > 0"
                      :error-messages="csvError"
                      @change="onFileChange"
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="csvNumberColumn"
                      :rules="[rules.required, rules.numberGT0]"
                      required
                      label="Number Column"
                      type="number"
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="csvRate"
                      :rules="[rules.required, rules.positiveFloat]"
                      required
                      label="Rate"
                      type="number"
                    />
                  </v-col>
                </v-row>
                <v-row v-if="formData.destinationType === 'template'">
                  <v-col v-if="hasExistingDestinations === true" cols="12">
                    <v-switch
                      v-model="keepDestinations"
                      label="Keep Existing Destinations"
                    />
                  </v-col>
                  <template v-if="hasExistingDestinations === false || keepDestinations === false">
                    <v-col cols="6">
                      <v-file-input
                        accepts=".csv,text/csv"
                        label="CSV Number and Rate File"
                        required
                        :error="csvError.length > 0"
                        :error-messages="csvError"
                        @change="onFileChange"
                      />
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                        v-model="csvNumberColumn"
                        :rules="[rules.required, rules.numberGT0]"
                        required
                        label="Prefix Column Number"
                        type="number"
                      />
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                        v-model="csvRate"
                        :rules="[rules.required, rules.numberGT0]"
                        required
                        label="Rate Column Number"
                        type="number"
                      />
                    </v-col>
                    <v-col cols="2">
                      <v-select
                        v-model="formData.destinationLookbackType"
                        :items="destinationLookbackTypes"
                        label="CDR Lookback Method"
                      />
                    </v-col>
                    <template v-if="formData.destinationLookbackType === 'time'">
                      <v-col cols="1">
                        <v-text-field
                          v-model="destinationLookbackTimeNum"
                          reverse
                        />
                      </v-col>
                      <v-col cols="2">
                        <v-select
                          v-model="destinationLookbackTimePeriod"
                          :items="destinationLookbackPeriods"
                        />
                      </v-col>
                    </template>
                    <template v-if="formData.destinationLookbackType === 'rows'">
                      <v-col cols="3">
                        <v-text-field
                          v-model="formData.destinationLookbackData"
                          :rules="[rules.required, rules.positiveNumber]"
                          type="number"
                          label="Amount"
                        />
                      </v-col>
                    </template>
                    <v-col cols="2" offset="1">
                      <v-text-field
                        v-model="forceRandomDigits"
                        label="Random Digits"
                        :rules="[rules.positiveCommaSepNumbers]"
                      >
                        <template v-slot:append>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-icon v-on="on">help</v-icon>
                            </template>
                            Used to generate only the trailing digits in the destination<br>
                            <ul>
                              <li v-for="(help, k) in randomsHelp" :key="k">
                                {{ help }}
                              </li>
                            </ul>
                          </v-tooltip>
                        </template>
                      </v-text-field>
                    </v-col>
                  </template>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title class="title">Source Selection</v-card-title>
              <v-container class="m-3">
                <v-row>
                  <v-col v-if="taskType !== 'template'" cols="3">
                    <v-select
                      v-model="sourceType"
                      :items="sourceTypeItems"
                      label="Source Selection Method"
                    />
                  </v-col>
                  <template v-if="formData.sourceType === 'auto-prefix'">
                    <v-col cols="2" offset="1">
                      <v-text-field
                        v-model="formData.sourceLookbackTime"
                        reverse
                        label="Popularity Lookback"
                        type="number"
                      />
                    </v-col>
                    <v-col cols="2">
                      <v-select
                        v-model="formData.sourceLookbackTimePeriod"
                        :items="manualLookbackPeriods"
                      />
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        v-model="formData.sourceMinimumProfit"
                        label="Minimum Retail Rate"
                        type="number"
                        :rules="[rules.positiveFloat]"
                      />
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        v-model="formData.sourceMaximumProfit"
                        label="Maximum Retail Rate"
                        type="number"
                        :rules="[rules.positiveFloat]"
                      />
                    </v-col>
                  </template>
                  <template v-else-if="formData.sourceType === 'auto'">
                    <v-col cols="2" offset="1">
                      <v-text-field
                        v-model="formData.sourceLookbackTime"
                        reverse
                        label="Popularity Lookback"
                        type="number"
                      />
                    </v-col>
                    <v-col cols="2">
                      <v-select
                        v-model="formData.sourceLookbackTimePeriod"
                        :items="manualLookbackPeriods"
                      />
                    </v-col>
                    <v-col>
                      <div class="text-center">
                        <v-switch
                          v-model="formData.sourceAutoBan"
                          label="Auto Ban"
                          class="switch-center"
                        />
                      </div>
                    </v-col>
                    <v-col cols="2">
                      <div class="text-center">
                        <v-switch
                          v-model="formData.sourceAutoRefine"
                          label="Auto Refine"
                          class="switch-center"
                        />
                      </div>
                    </v-col>
                    <v-col cols="2" offset="4">
                      <v-select
                        v-model="formData.sourceAutoWhitelistType"
                        label="Filter Type"
                        :items="autoWhitelistTypes"
                      />
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                        v-model="formData.sourceAutoWhitelist"
                        :rules="[rules.commaSepString]"
                        :label="'Carrier/Prefix ' + whiteOrBlacklist"
                        hint="Optional, pipe (|) separated"
                        persistent-hint=""
                      />
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                        v-model="formData.sourceMinimumPopularity"
                        :rules="[rules.positiveNumber]"
                        label="Popularity Required"
                        hint="Optional"
                        persistent-hint
                        type="number"
                      />
                    </v-col>
                  </template>
                  <template v-else-if="formData.sourceType === 'manual'">
                    <v-col cols="3">
                      <v-text-field
                        v-model="formData.sourceLookbackTime"
                        reverse
                        label="Popularity Lookback"
                        type="number"
                      />
                    </v-col>
                    <v-col cols="2">
                      <v-select
                        v-model="formData.sourceLookbackTimePeriod"
                        :items="manualLookbackPeriods"
                      />
                    </v-col>
                    <v-col cols="2">
                      <v-btn
                        color="primary"
                        :loading="manualLookbackLoading"
                        :disabled="manualLookbackLoading"
                        @click="loadPopularity()"
                      >
                        Lookup Sources
                      </v-btn>
                    </v-col>
                    <template v-if="sources.length > 0 || manualRemoved.length > 0">
                      <v-col cols="6">
                        <v-card elevation="0">
                          <v-card-title class="subheading">
                            Active Numbers
                            <v-spacer />
                            <v-text-field
                              v-model="manualSearch"
                              append-icon="filter_list"
                              label="Filter"
                              single-line
                              hide-details
                              clearable
                            />
                          </v-card-title>
                          <v-data-table
                            ref="manualTable"
                            :items="sources"
                            :headers="manualHeaders"
                            :search="manualSearch"
                            :sort-by.sync="manualTableOptions.sortBy"
                            :sort-desc.sync="manualTableOptions.descending"
                            :footer-props="{ itemsPerPageOptions: [10,50,{'text':'All',value:-1}] }"
                          >
                            <template v-slot:item="props">
                              <tr @click="removeManualNumber(props.item)">
                                <td>{{ props.item.number }}</td>
                                <td>{{ props.item.popularity }}</td>
                                <td class="text-right">
                                  <v-btn
                                    color="primary"
                                    small
                                    fab
                                  >
                                    <v-icon>remove</v-icon>
                                  </v-btn>
                                </td>
                              </tr>
                            </template>
                            <template v-slot:footer>
                              <td :colspan="headers.length">
                                <v-btn
                                  color="primary"
                                  @click="removeAllManualNumbers()"
                                >Remove All
                                </v-btn>
                              </td>
                            </template>
                            <template v-slot:no-results>
                              <v-alert :value="true" color="error" icon="warning">
                                Your search for "{{ manualSearch }}" found no results.
                              </v-alert>
                            </template>
                          </v-data-table>
                        </v-card>
                      </v-col>
                      <v-col cols="6">
                        <v-card elevation="0">
                          <v-card-title class="subheading">
                            Removed Numbers
                            <v-spacer />
                            <v-text-field
                              v-model="manualRemovedSearch"
                              append-icon="filter_list"
                              label="Filter"
                              single-line
                              hide-details
                              clearable
                            />
                          </v-card-title>
                          <v-data-table
                            ref="manualRemovedTable"
                            :items="manualRemoved"
                            :headers="manualHeaders"
                            :search="manualRemovedSearch"
                            :sort-by.sync="manualTableOptions.sortBy"
                            :sort-desc.sync="manualTableOptions.descending"
                            :footer-props="{ itemsPerPageOptions: [10,50,{'text':'All',value:-1}] }"
                            no-data-text="No numbers"
                          >
                            <template v-slot:item="props">
                              <tr @click="addManualNumber(props.item)">
                                <td>{{ props.item.number }}</td>
                                <td>{{ props.item.popularity }}</td>
                                <td class="text-right">
                                  <v-btn color="primary" small fab>
                                    <v-icon>add</v-icon>
                                  </v-btn>
                                </td>
                              </tr>
                            </template>
                            <template v-slot:footer>
                              <td :colspan="headers.length">
                                <v-btn color="primary" @click="addAllManualNumbers()">
                                  Add All
                                </v-btn>
                              </td>
                            </template>
                            <template v-slot:no-results>
                              <v-alert :value="true" color="error" icon="warning">
                                Your search for "{{ manualRemovedSearch }}" found no results.
                              </v-alert>
                            </template>
                          </v-data-table>
                        </v-card>
                      </v-col>
                    </template>
                  </template>
                  <template v-else-if="taskType === 'template'">
                    <v-col cols="7">
                      <v-radio-group v-model="formData.sourceCount" row>
                        <v-radio
                          v-for="(item, key) in sourceCountItems"
                          :key="key"
                          :label="item.text"
                          :value="item.value"
                        />
                        <v-tooltip bottom max-width="400">
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">help</v-icon>
                          </template>
                          Selecting to use 12 numbers will test 3 of each numbers that
                          you add from the IPRN source list. This can be helpful in
                          speeding up the finding of new access. If the task is a
                          production/semi production task, you should select to use
                          All Numbers.
                        </v-tooltip>
                      </v-radio-group>
                    </v-col>
                  </template>
                  <template v-else>
                    <v-col cols="7" offset="2">
                      <v-radio-group v-model="formData.sourceCount" row>
                        <v-radio
                          v-for="(item, key) in sourceCountItems"
                          :key="key"
                          :label="item.text"
                          :value="item.value"
                        />
                        <v-tooltip bottom max-width="400">
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">help</v-icon>
                          </template>
                          Selecting to use 12 numbers will test 3 of each numbers that
                          you add from the IPRN source list. This can be helpful in
                          speeding up the finding of new access. If the task is a
                          production/semi production task, you should select to use
                          All Numbers.
                        </v-tooltip>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="6">
                      <v-card elevation="0">
                        <v-card-title class="subheading">
                          Available Sources
                          <v-spacer />
                          <v-text-field
                            v-model="sourceSearch"
                            append-icon="filter_list"
                            label="Filter"
                            single-line
                            hide-details
                            clearable
                          />
                        </v-card-title>
                        <v-data-table
                          ref="sourceTable"
                          :items="sourceItems"
                          :headers="headers"
                          :loading="sourceLoading"
                          :footer-props="{ itemsPerPageOptions: [10,50,{'text':'All',value:-1}] }"
                          :search="sourceSearch"
                          hide-default-header
                        >
                          <template v-slot:item="props">
                            <tr @click="addSource(props.item)">
                              <td>{{ props.item.name }}</td>
                              <td>{{ props.item.count }}
                              </td>
                              <td class="text-right">
                                <v-btn color="primary" small fab>
                                  <v-icon>add</v-icon>
                                </v-btn>
                              </td>
                            </tr>
                          </template>
                          <template v-slot:footer>
                            <td :colspan="headers.length">
                              <v-btn color="primary" @click="addAllSources()">
                                Add All
                              </v-btn>
                            </td>
                          </template>
                          <template v-slot:no-data>
                            <v-alert v-if="!sourceLoading" :value="true" color="error" icon="warning">
                              No numbers avaialable
                            </v-alert>
                            <div v-if="sourceLoading" class="text-center">
                              <v-progress-circular indeterminate />
                            </div>
                          </template>
                          <template v-slot:no-results>
                            <v-alert :value="true" color="error" icon="warning">
                              Your search for "{{ sourceSearch }}" found no results.
                            </v-alert>
                          </template>
                        </v-data-table>
                      </v-card>
                    </v-col>
                    <v-col cols="6">
                      <v-card elevation="0">
                        <v-card-title class="subheading">
                          Selected Sources ({{ formData.sources.length }})
                          <v-spacer />
                          <v-text-field
                            v-model="sourceSelectedSearch"
                            append-icon="filter_list"
                            label="Filter"
                            single-line
                            hide-details
                            clearable
                          />
                        </v-card-title>
                        <v-data-table
                          ref="sourceSelectedTable"
                          :items="sources"
                          :headers="headers"
                          :footer-props="{ itemsPerPageOptions: [10,50,{'text':'All',value:-1}] }"
                          :search="sourceSelectedSearch"
                          :loading="sourceLoading"
                          hide-default-header
                          no-data-text="No numbers added"
                        >
                          <template v-slot:item="props">
                            <tr @click="removeSource(props.item.name)">
                              <td>{{ props.item.name }}</td>
                              <td>{{ props.item.count }}</td>
                              <td class="text-right">
                                <v-btn color="primary" small fab>
                                  <v-icon>remove</v-icon>
                                </v-btn>
                              </td>
                            </tr>
                          </template>
                          <template v-slot:footer>
                            <td :colspan="headers.length">
                              <v-btn color="primary" @click="removeAllSources()">
                                Remove All
                              </v-btn>
                            </td>
                          </template>
                          <template v-slot:no-results>
                            <v-alert :value="true" color="error" icon="warning">
                              Your search for "{{ sourceSelectedSearch }}" found no results.
                            </v-alert>
                          </template>
                        </v-data-table>
                      </v-card>
                    </v-col>
                  </template>
                </v-row>
              </v-container>
              <v-card-text>
                <v-row>
                  <v-col cols="2">
                    <v-text-field
                      v-model="formData.sourceCliPrefix"
                      label="CLI Prefix"
                    >
                      <template v-slot:append>
                        <v-tooltip bottom max-width="400">
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">help</v-icon>
                          </template>
                          Add a prefix to the CallerID when the call is delivered.
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <v-radio-group
                      v-model="formData.sourceLastSeenCheck"
                      label="Source Filtering"
                      row
                    >
                      <v-radio label="None" value="none" />
                      <v-radio label="Verified Only" value="verified" />
                      <v-radio label="Exploration" value="exploration" />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="showDialingOptions">
        <v-row>
          <v-col>
            <v-card>
              <v-card-title class="title">Route Options</v-card-title>
              <v-container class="m-3">
                <v-row>
                  <template v-if="routesLoading === false">
                    <v-col cols="4">
                      <v-select
                        v-model="formData.routeId"
                        label="Route"
                        :items="routesItems"
                        :rules="[rules.required, rules.positiveNumber]"
                      />
                    </v-col>
                    <v-col cols="2">
                      <v-btn color="primary" @click="routeForm = true">
                        New Route
                      </v-btn>
                    </v-col>
                  </template>
                  <v-col v-else cols="6" style="text-align: center">
                    <v-progress-circular
                      :size="35"
                      :width="3"
                      indeterminate
                    />
                  </v-col>
                  <v-col v-if="taskType !== 'template'" cols="4" offset="1">
                    <v-select
                      v-model="formData.routeDialServer"
                      :items="dialServerItems"
                      label="Outgoing Dial Server"
                    >
                      <template v-slot:append-outer>
                        <v-tooltip bottom max-width="400">
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">help</v-icon>
                          </template>
                          Select the dial server you want to use for this task.
                          Dial servers support a maximum number of outgoing calls
                          per server and a maximum number of calls per second. If
                          you go over this limit per server, tasks will fail to
                          start.
                        </v-tooltip>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title class="title">Task Options</v-card-title>
              <v-container class="m-3">
                <v-row>
                  <v-col v-if="taskType !== 'template'" cols="3">
                    <v-text-field
                      v-model="formData.taskName"
                      :rules="[rules.required]"
                      required
                      label="Task Name"
                    />
                  </v-col>
                  <v-col v-if="showDialingOptions" cols="3">
                    <v-text-field
                      v-model="formData.taskACD"
                      :rules="[rules.required, rules.positiveRange]"
                      required
                      label="ACD Required"
                      hint="Range"
                    >
                      <template v-slot:append>
                        <v-tooltip bottom max-width="400">
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">help</v-icon>
                          </template>
                          Setting the ACD is setting the average duration of the
                          outgoing calls, this is a requirement that will change
                          between routes.
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-select
                      v-if="sourceType !== 'manual' || taskType === 'template'"
                      v-model="formData.sourceGroup"
                      :items="sourceGroupItems"
                      label="Source Number Group"
                    />
                  </v-col>
                  <v-col v-if="showDialingOptions" cols="3">
                    <v-text-field
                      v-model="formData.taskMaxSimultaneousCalls"
                      :rules="[rules.required, rules.positiveNumber]"
                      required
                      label="Max Channels"
                      type="number"
                      :hint="channelsHint"
                      :persistent-hint="cpsChannelsDisabled"
                      min="1"
                      :max="channelsMax"
                      :disabled="cpsChannelsDisabled"
                    />
                  </v-col>
                  <v-col v-if="showDialingOptions" cols="3">
                    <v-text-field
                      v-model="formData.taskMaxCallsPerInterval"
                      :rules="[rules.required, rules.positiveRange]"
                      required
                      label="Max Calls per Interval"
                      :hint="cpsHint"
                      :persistent-hint="cpsChannelsDisabled"
                      :disabled="cpsChannelsDisabled"
                    />
                  </v-col>
                  <v-col v-if="showDialingOptions" cols="3">
                    <v-text-field
                      v-model="formData.taskMaxCallsInterval"
                      :rules="[rules.required, rules.positiveRange]"
                      required
                      label="Max Calls Interval (Seconds)"
                      hint="Range"
                    />
                  </v-col>
                </v-row>
                <v-row v-if="taskType !== 'finalized'">
                  <v-col cols="4">
                    <v-switch
                      v-model="useMaxAttempts"
                      label="Limit total attempts"
                    />
                  </v-col>
                  <v-col v-if="useMaxAttempts === false" cols="3">
                    <v-text-field
                      v-model="formData.taskDuration"
                      :rules="[rules.required, rules.numberGT0]"
                      required
                      label="Task Duration (minutes)"
                      type="number"
                    />
                  </v-col>
                  <v-col v-else cols="3">
                    <v-text-field
                      v-model="formData.taskMaxAttempts"
                      :rules="[rules.required, rules.numberGT0]"
                      required
                      label="Max Outgoing Attempts"
                      type="number"
                    />
                  </v-col>
                </v-row>
                <v-row v-if="formData.destinationType === 'template'">
                  <v-col cols="4">
                    <v-switch
                      v-model="formData.accuracy"
                      label="High Accuracy Mode"
                    />
                  </v-col>
                  <v-col v-if="formData.accuracy === true" cols="3">
                    <v-text-field
                      v-model="formData.accuracyStrip"
                      :rules="[rules.required, rules.numberGT0]"
                      required
                      label="Digits to Strip for Accuracy"
                      hint="Range"
                    />
                  </v-col>
                </v-row>
                <v-row v-if="showDialingOptions">
                  <v-col cols="4">
                    <v-switch
                      v-model="autoPDD"
                      label="Automatic PDD Adjust"
                    >
                      <template v-slot:append>
                        <v-tooltip bottom max-width="400">
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">help</v-icon>
                          </template>
                          Enter the minimum and maximum ASR that you want
                          to run on this route and the platform will try
                          its best to acheive a comfortable number between
                          the entered values. If it cannot reach a number
                          between the values entered, the task will eventually
                          be automatically stopped.
                        </v-tooltip>
                      </template>
                    </v-switch>
                  </v-col>
                  <template v-if="autoPDD === true">
                    <v-col cols="3">
                      <v-text-field
                        v-model="formData.taskMinASR"
                        :rules="[rules.required, rules.positiveNumber]"
                        required
                        label="Min ASR"
                        type="number"
                      />
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                        v-model="formData.taskMaxASR"
                        :rules="[rules.required, rules.positiveNumber]"
                        required
                        label="Max ASR"
                        type="number"
                      />
                    </v-col>
                  </template>
                  <template v-else>
                    <v-col cols="3">
                      <v-text-field
                        v-model="formData.taskPDDs"
                        :rules="[rules.required, rules.positiveCommaSepNumbers]"
                        validate-on-blur
                        required
                        label="PDDs (comma separated)"
                      />
                    </v-col>
                  </template>
                </v-row>
                <v-row v-if="showDialingOptions">
                  <v-col cols="4">
                    <v-switch
                      v-model="alternateACD"
                      label="Alternate ACD"
                    >
                    </v-switch>
                  </v-col>
                  <template v-if="alternateACD === true">
                    <v-col cols="3">
                      <v-text-field
                        v-model="formData.taskAltACD"
                        :rules="[rules.required, rules.positiveRange]"
                        required
                        label="Alternate ACD"
                        hint="Range"
                      />
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                        v-model="formData.taskAltACDPercent"
                        :rules="[rules.required, rules.positiveNumber]"
                        required
                        label="Percent chance"
                        type="number"
                        suffix="%"
                      />
                    </v-col>
                  </template>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="taskType === 'finalized'">
        <v-row>
          <v-col>
            <v-card>
              <v-card-title class="title">Schedule (All Times in GMT)</v-card-title>
              <v-container class="mx-3">
                <v-row>
                  <v-col cols="12">
                    <v-btn color="primary" @click="addSchedule">
                      Add Time Range
                    </v-btn>
                  </v-col>
                  <template v-for="(child, index) in schedules">
                    <component
                      :is="child.component"
                      :key="index"
                      v-model="formData.schedule[index]"
                      v-bind="{
                        index: index,
                        errors: child.errors
                      }"
                      @removeSchedule="removeSchedule"
                    />
                  </template>
                </v-row>
              </v-container>
              <v-container class="m-3">
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      v-model="formData.scheduleMinimumRoI"
                      :rules="[rules.required, rules.positiveNumber]"
                      required
                      label="Return on Investment Required (percentage)"
                      type="number"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="formData.scheduleMaximumTimeBelow"
                      :rules="[rules.required, rules.positiveNumber]"
                      validate-on-blur
                      required
                      label="Maximum Time Below RoI (minutes)"
                      type="number"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="formData.scheduleGrace"
                      :rules="[rules.required, rules.positiveNumber]"
                      required
                      label="Grace Period (minutes)"
                      type="number"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-row>
          <v-col>
            <v-card>
              <v-card-text>
                <v-btn
                  color="primary"
                  large
                  block
                  type="submit"
                  :disabled="submitting"
                  :loading="submitting"
                >
                  {{ startButtonText }}
                </v-btn>
              </v-card-text>
              <v-card-text v-if="savedSuccess">
                <v-alert
                  v-model="savedSuccess"
                  type="success"
                  dismissible
                >Saved successfully
                </v-alert>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-dialog v-model="errorDialog" max-width="500">
        <v-card>
          <v-card-title class="headline" color="error">Validation Error</v-card-title>
          <v-card-text>
            <p>You made mistakes on the form that must be fixed.</p>
            <p v-for="(error, key) in errorDialogText" :key="key">{{ error }}</p>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" @click="errorDialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="lookbackWarning" persistent max-width="500">
        <v-card>
          <v-card-title class="headline">CDR Lookback Warning</v-card-title>
          <v-card-text>
            <p>You have chosen less than 30 days for CDR lookback. This can be dangerous and cause
              unexpected and unpredicatble behaviour</p>
            <p>Are you sure you want to continue?</p>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn color="error" @click="lookbackWarning = false">Cancel</v-btn>
            <v-btn color="primary" @click="submit">{{ startButtonText }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <v-dialog v-model="routeForm" max-width="500">
      <new-route-form @completed="handleNewRoute" @cancel="routeForm = false" />
    </v-dialog>
  </div>
</template>

<script>
  import { authFetch } from '@/plugins/authFetch'
  import WhitelistRow from '@/components/WhitelistRow'
  import HLRRow from '@/components/HLRRow'
  import ScheduleRow from '@/components/ScheduleRow'
  import * as rules from '@/plugins/rules'
  import {
    commaSeparatedNumbersToList,
    numberRangeToList,
    pipeSeparatedStringsToList,
    defaultDialServerMaximums,
    sourceGroupItems,
  } from '@/plugins/common'
  import NewRouteForm from '@/components/ModalRouteForm.vue'

  export default {
    components: { NewRouteForm },
    props: {
      taskType: { type: String, default: 'exploration' },
      taskParent: { type: Number, default: 0 },
      taskId: { type: Number, default: 0 },
      showDestinations: { type: Boolean, default: true },
      externalTask: { type: Boolean, default: false },
      previousFormData: {
        type: Object,
        default () {
          return {
            sources: [],
            sourceCount: 'one',
            sourceType: 'carrier',
            sourceAutoWhitelist: '',
            sourceAutoWhitelistType: 'white',
            sourceAutoBan: false,
            sourceAutoRefine: false,
            sourceMinimumPopularity: '',
            sourceLookbackTime: 1,
            sourceLookbackTimePeriod: 'day',
            sourceGroup: null,
            sourceCliPrefix: '',
            destinationType: 'whitelist',
            destinationLookbackType: 'time',
            destinationLookbackData: '3 month',
            destinationCSV: null,
            whitelists: {},
            taskName: '',
            taskDuration: '',
            taskACD: '',
            taskPDDs: '',
            taskMaxASR: '',
            taskMinASR: '',
            taskPDDLock: false,
            taskMaxSimultaneousCalls: '',
            taskMaxCallsPerInterval: '',
            taskMaxCallsInterval: '1',
            routeId: '',
            routeDialServer: null,
            schedule: {},
            scheduleMinimumRoI: null,
            scheduleMaximumTimeBelow: null,
            scheduleGrace: null,
            sourceLastSeenCheck: 'none',
            accuracyStrip: null,
            accuracy: false,
            taskMaxAttempts: null,
            sourceMinimumProfit: '',
            sourceMaximumProfit: '',
            taskAlternateACD: '',
            taskAltACDPercent: '',
          }
        },
      },
      hasExistingDestinations: { type: Boolean, default: false },
    },
    data () {
      return {
        lookbackWarning: false,
        sourceLoading: true,
        sourceCountItems: [
          { text: 'Use 12 Numbers', value: 'one' },
          { text: 'Use All Numbers', value: 'all' },
        ],
        sourceSearch: '',
        sourceSelectedSearch: '',
        sourcePrefixItems: [],
        sourceCarrierItems: [],
        headers: [
          { text: 'Name', value: 'name' },
          { text: 'Actions', value: 'name' },
        ],
        whitelists: {},
        whitelistKey: 0,
        schedules: {},
        schedulesKey: 0,
        submitting: false,
        errorDialog: false,
        errorDialogText: '',
        rules: rules,
        formValid: true,
        destinationLookbackTypes: [
          { text: 'Time', value: 'time' },
          { text: 'Rows', value: 'rows' },
        ],
        destinationLookbackPeriods: [
          { text: 'Hours', value: 'hour' },
          { text: 'Days', value: 'day' },
          { text: 'Months', value: 'month' },
          { text: 'Years', value: 'year' },
        ],
        manualLookbackPeriods: [
          { text: 'Minutes', value: 'minute' },
          { text: 'Hours', value: 'hour' },
          { text: 'Days', value: 'day' },
          { text: 'Months', value: 'month' },
          { text: 'Years', value: 'year' },
        ],
        formData: this.previousFormData,
        sources: [],
        manualLookbackLoading: false,
        manualRemoved: [],
        manualSearch: '',
        manualRemovedSearch: '',
        manualHeaders: [
          { text: 'Number', value: 'number' },
          { text: 'Pop', value: 'popularity' },
        ],
        manualTableOptions: { sortBy: 'popularity', descending: true },
        test: ['a', 'b', 'c'],
        autoWhitelistTypes: [
          { text: 'Whitelist', value: 'white' },
          { text: 'Blacklist', value: 'black' },
        ],
        sourceGroupItems: sourceGroupItems,
        dialServerItems: [],
        dialServerData: {},
        dialServerDefault: null,
        routesItems: [],
        routesLoading: true,
        routeForm: false,
        hlrWhitelists: {},
        hlrWhitelistKey: 0,
        hlrWhitelistFilters: {},
        csvError: [],
        csvNumberColumn: null,
        csvRate: null,
        csvFile: false,
        destinationType: this.previousFormData.destinationType,
        showDestinationsOverride: false,
        savedSuccess: false,
        forceRandomDigits: null,
        randomsHelp: [
          'Empty: Leave empty to randomize',
          'Single digit: (eg 7) will generate 7 trailing digits using a random known working number',
          '0: Only use numbers that we have seen within the timerange. No randomization',
          'List: (eg 5, 6, 7) will choose 5, 6, or 7 trailing digits to randomize like above',
        ],
        keepDestinations: true,
        useMaxAttempts: false,
        alternateACD: false,
      }
    },
    computed: {
      cpsHint () {
        if (this.cpsChannelsDisabled === true) {
          return 'Must select Outgoing Dial Server first'
        }
        return `Maximum ${this.dialServer.maxCPS}`
      },
      cpsMax () {
        if (this.cpsChannelsDisabled === true) {
          return 0
        }
        return this.dialServer.maxCPS
      },
      channelsHint () {
        if (this.cpsChannelsDisabled === true) {
          return 'Must select Outgoing Dial Server first'
        }
        return `Maximum ${this.dialServer.maxChannels}`
      },
      channelsMax () {
        if (this.cpsChannelsDisabled === true) {
          return 0
        }
        return this.dialServer.maxChannels
      },
      cpsChannelsDisabled () {
        return this.dialServer === null
      },
      dialServer () {
        if (this.formData.routeDialServer === 'auto' || this.taskType === 'template') {
          return {
            ip: 'auto',
            maxCPS: defaultDialServerMaximums.cps,
            maxChannels: defaultDialServerMaximums.channels,
          }
        }
        let ip = this.formData.routeDialServer
        if (ip === null && this.dialServerDefault !== null) {
          ip = this.dialServerDefault
        }
        if (Object.prototype.hasOwnProperty.call(this.dialServerData, ip) === false) {
          return null
        }
        return this.dialServerData[ip]
      },
      startButtonText () {
        if (this.taskType === 'template') {
          return 'Save'
        } else if (this.taskId === 0) {
          return 'Submit Changes'
        }
        return 'Start Task'
      },
      destinationTypes () {
        if (this.taskType === 'template') {
          return [{ text: 'CSV File', value: 'template' }]
        }
        if (this.$store.state.permission & 4) {
          return [
            { text: 'Whitelist', value: 'whitelist' },
            { text: 'CSV File', value: 'custom' },
          ]
        }
        return [
          { text: 'Whitelist', value: 'whitelist' },
          { text: 'HLR', value: 'hlr' },
          { text: 'CSV File', value: 'custom' },
        ]
      },
      sourceTypeItems () {
        if (this.formData.destinationType === 'none') {
          return [
            { text: 'Exploration', value: 'carrier' },
          ]
        }
        return [
          { text: 'Exploration', value: 'carrier' },
          { text: 'Final Production', value: 'auto' },
          { text: 'Historical Access', value: 'auto-prefix' },
        ]
      },
      showDestinationFilters () {
        return this.formData.destinationType === 'whitelist'
      },
      showDialingOptions () {
        return this.formData.destinationType !== 'none'
      },
      sourceItems () {
        if (this.formData.sourceType === 'prefix') {
          if (this.sourcePrefixItems.length === 0) {
            this.loadSource(this.formData.sourceType)
          }
          return this.sourcePrefixItems
        } else if (this.formData.sourceType === 'carrier') {
          if (this.sourceCarrierItems.length === 0) {
            this.loadSource(this.formData.sourceType)
          }
          return this.sourceCarrierItems
        }
        return []
      },
      autoPDD: {
        get () {
          return !this.formData.taskPDDLock
        },
        set (value) {
          this.formData.taskPDDLock = !value
        },
      },
      destinationLookbackTimeNum: {
        get () {
          if (typeof this.formData.destinationLookbackData === 'undefined') {
            return 1
          }
          const parts = this.formData.destinationLookbackData.split(' ')
          if (isNaN(parts[0])) {
            return 1
          }
          return parseInt(parts[0], 10)
        },
        set (value) {
          this.formData.destinationLookbackData = `${value} ${this.destinationLookbackTimePeriod}`
        },
      },
      destinationLookbackTimePeriod: {
        get () {
          if (typeof this.formData.destinationLookbackData === 'undefined') {
            return 'day'
          }
          const parts = this.formData.destinationLookbackData.split(' ')
          if (parts.length < 2) {
            return 'day'
          }
          return parts[1]
        },
        set (value) {
          this.formData.destinationLookbackData = `${this.destinationLookbackTimeNum} ${value}`
        },
      },
      formDataLookbackData () {
        return this.formData.destinationLookbackData
      },
      formDataLookbackType () {
        return this.formData.destinationLookbackType
      },
      sourceType: {
        get () {
          return this.formData.sourceType
        },
        set (val) {
          this.sources = []
          this.formData.sources = []
          this.formData.sourceType = val
        },
      },
      whiteOrBlacklist () {
        return this.formData.sourceAutoWhitelistType === 'white' ? 'Whitelist' : 'Blacklist'
      },
    },
    watch: {
      destinationType () {
        this.formData.destinationType = this.destinationType
        if (this.destinationType === 'none') {
          this.formData.sourceType = 'carrier'
        }
      },
      formDataLookbackData () {
        if (this.formData.destinationLookbackType === 'time') {
          const parts = this.formData.destinationLookbackData.split(' ')
          if (parts.length < 2) {
            return
          }
          this.destinationLookbackTimeNum = parts[0]
          this.destinationLookbackTimePeriod = parts[1]
        }
      },
      formDataLookbackType () {
        if (this.formData.destinationLookbackType === 'time') {
          this.formData.destinationLookbackData = '1 day'
        } else {
          this.formData.destinationLookbackData = '1000'
        }
      },
    },
    mounted () {
      if (this.taskType === 'template') {
        this.destinationType = 'template'
      }
      if (this.externalTask === true) {
        this.destinationType = 'none'
      }
      if (Object.keys(this.formData.whitelists).length === 0) {
        this.addWhitelist()
        this.addHLR()
      } else {
        if (Array.isArray(this.formData.whitelists) === true) {
          this.formData.whitelists = { ...this.formData.whitelists }
        }
        Object.keys(this.formData.whitelists).forEach((value, key) => {
          if (this.formData.destinationType === 'whitelist') {
            this.addWhitelist()
            this.addHLR()
          } else if (this.formData.destinationType === 'hlr') {
            this.hlrWhitelistFilters[key] = this.formData.whitelists[value]
            this.addWhitelist()
            this.addHLR(key)
          }
        })
      }
      if (typeof this.formData.schedule === 'undefined' || Object.keys(this.formData.schedule).length === 0) {
        this.addSchedule()
      } else {
        Object.keys(this.formData.schedule).forEach((value, key) => {
          this.addSchedule(key)
        })
      }
      if (this.formData.sourceType === 'manual') {
        this.loadPopularity(this.formData.sources)
      }
      if (this.taskId < 1) {
        this.formData.taskName = ''
      }
      if (Array.isArray(this.formData.taskACD)) {
        this.formData.taskACD = this.formData.taskACD.join('-')
      }
      if (this.formData.taskMaxAttempts !== null) {
        this.useMaxAttempts = true
      }
      if (this.hasExistingDestinations === false) {
        this.keepDestinations = false
      }
      authFetch(`${process.env.VUE_APP_API_ROOT}servers/`)
        .then(async response => {
          const items = await response.json()
          if (typeof items === 'object' && Object.prototype.hasOwnProperty.call(items, 'servers')) {
            if (items.servers.length > 0) {
              this.dialServerItems.push({
                text: 'Automatic',
                value: 'auto',
              })
            }
            if (Object.prototype.hasOwnProperty.call(items, 'default') && items.default !== null) {
              this.dialServerItems.push({
                text: `Use Default (${items.default.ip} - ` +
                  `${items.default.maxCPS} CPS/${items.default.maxChannels} Ports)`,
                value: null,
              })
              this.dialServerDefault = items.default.ip
            }
            items.servers.forEach(server => {
              this.dialServerItems.push({
                text: `${server.ip} - ${server.maxCPS} CPS/` +
                  `${server.maxChannels} Ports`,
                value: server.ip,
              })
              this.$set(this.dialServerData, server.ip, server)
            })
            if (this.dialServerDefault === null && this.formData.routeDialServer === null &&
              this.dialServerItems.length > 0) {
              this.$set(this.formData, 'routeDialServer', this.dialServerItems[0].value)
            }
          }
        })
      this.loadRoutes(this.formData.routeId)
    },
    methods: {
      onFileChange (f) {
        this.csvFile = f
        if (!this.csvFile) {
          this.csvFile = false
        }
      },
      toBase64 (file) {
        if (this.formData.destinationType === 'custom' ||
          (this.formData.destinationType === 'template' &&
          (this.hasExistingDestinations === false || this.keepDestinations === false))) {
          return new Promise((resolve, reject) => {
            var reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
          })
        } else {
          return new Promise((resolve, reject) => {
            resolve('')
          })
        }
      },
      handleNewRoute (id) {
        this.loadRoutes(id)
        this.routeForm = false
      },
      loadRoutes (setTo) {
        this.routesLoading = true
        authFetch(process.env.VUE_APP_API_ROOT + 'routes/')
          .then(async response => {
            const items = await response.json()
            this.routesItems = []
            items.forEach(r => {
              let prefix = 'No Prefix'
              if (r.prefix !== '') {
                prefix = `Prefix: ${r.prefix}`
              }
              let labelText = ''
              if (r.label) {
                labelText = `${r.label} - ${r.ip}:${r.port} ${prefix}`
              } else {
                labelText = `${r.ip}:${r.port} ${prefix}`
              }
              this.routesItems.push({
                text: labelText,
                value: r.id,
              })
            })
          })
          .finally(() => {
            this.routesLoading = false
            if (typeof setTo !== 'undefined') {
              this.formData.routeId = setTo
            }
          })
      },
      loadSource (type) {
        this.sourceLoading = true
        authFetch(process.env.VUE_APP_API_ROOT + `tasks/sources/${type}/`)
          .then(async response => {
            const items = await response.json()
            if (type === 'prefix') {
              items.forEach(row => {
                const src = {
                  name: `${row.prefix} - ${row.carrier}`,
                  count: row.count,
                  submit: row.prefix,
                }
                this.sourcePrefixItems.push(src)

                if (this.formData.sources.indexOf(row.prefix) !== -1) {
                  this.addSource(src)
                }
              })
            } else {
              items.forEach(row => {
                const src = {
                  name: row.carrier,
                  count: row.count,
                  submit: row.carrier,
                }
                this.sourceCarrierItems.push(src)
                if (this.formData.sources.indexOf(row.carrier) !== -1) {
                  this.addSource(src)
                }
              })
            }
          })
          .catch((error) => {
            console.log(error)
            this.items = []
          })
          .finally(() => {
            this.sourceLoading = false
          })
      },
      loadPopularity (initialSources) {
        this.errorDialogText = []
        this.sources = []
        this.manualRemoved = []
        let filters = []
        // Check for empty destination filters
        if (this.formData.destinationType === 'whitelist') {
          if (Object.keys(this.formData.whitelists).length < 1) {
            this.errorDialogText.push('Must include at least one destination filter')
            this.errorDialog = true
            return false
          }
          // Check all destination filters and build
          filters = this.validateAndGetFilters()
          if (filters === false) {
            return false
          }
        } else if (this.formData.destinationType === 'hlr') {
          if (Object.keys(this.hlrWhitelistFilters).length < 1) {
            this.errorDialogText.push('Must include at least one destination filter')
            this.errorDialog = true
            return false
          }
          // Check all destination filters and build
          filters = this.validateAndGetHLRFilters()
          if (filters === false) {
            return false
          }
        }

        this.manualLookbackLoading = true
        authFetch(process.env.VUE_APP_API_ROOT + 'tasks/cdrs/popularity/', {
          method: 'POST',
          body: JSON.stringify({
            filters: filters,
            lookbackTime: this.formData.sourceLookbackTime,
            lookbackTimePeriod: this.formData.sourceLookbackTimePeriod,
          }),
        })
          .then(async response => {
            const items = await response.json()
            if (!Array.isArray(items) || items.length < 1) {
              this.errorDialogText.push('No numbers found')
              this.errorDialog = true
              return
            }
            if (initialSources === undefined || initialSources.length < 1) {
              items.forEach(value => {
                this.sources.push({
                  number: value[0],
                  popularity: value[1],
                })
              })
            } else {
              items.forEach(value => {
                if (initialSources.indexOf(value[0]) !== -1) {
                  this.sources.push({
                    number: value[0],
                    popularity: value[1],
                  })
                } else {
                  this.manualRemoved.push({
                    number: value[0],
                    popularity: value[1],
                  })
                }
              })
            }
          })
          .catch((error) => {
            console.log(error)
            this.manualRemoved = []
            this.sources = []
          })
          .finally(() => {
            this.manualLookbackLoading = false
          })
      },
      addSource (source) {
        for (let i = 0; i < this.sources.length; i++) {
          if (this.sources[i].name === source.name) {
            return false
          }
        }
        this.sources.push(source)
        return true
      },
      removeSource (source) {
        let index = null
        this.sources.some((value, key) => {
          if (value.name === source) {
            index = key
            return true
          }
          return false
        })
        if (index !== null) {
          this.sources.splice(index, 1)
        }
      },
      addAllSources () {
        this.sourceItems.forEach(v => {
          if (this.sourceSearch === null || this.sourceSearch === '' ||
            this.$refs.sourceTable.customFilter(v.name, this.sourceSearch)) {
            this.addSource(v)
          }
        })
      },
      removeAllSources () {
        this.sources.slice(0).forEach(v => {
          if (this.sourceSelectedSearch === null || this.sourceSelectedSearch === '' ||
            this.$refs.sourceSelectedTable.customFilter(v.name, this.sourceSelectedSearch)) {
            this.removeSource(v.name)
          }
        })
      },
      addManualNumber (number) {
        let index = null
        this.manualRemoved.some((value, key) => {
          if (value.number === number.number) {
            index = key
            return true
          }
          return false
        })
        if (index !== null) {
          this.sources.push(number)
          this.manualRemoved.splice(index, 1)
        }
      },
      removeManualNumber (number) {
        let index = null
        this.sources.some((value, key) => {
          if (value.number === number.number) {
            index = key
            return true
          }
          return false
        })
        if (index !== null) {
          this.manualRemoved.push(number)
          this.sources.splice(index, 1)
        }
      },
      addAllManualNumbers () {
        this.manualRemoved.slice(0).forEach(v => {
          if (this.manualRemovedSearch === null || this.manualRemovedSearch === '' ||
            this.$refs.manualRemovedTable.customFilter(v.number, this.manualRemovedSearch)) {
            this.addManualNumber(v)
          }
        })
      },
      removeAllManualNumbers () {
        this.sources.slice(0).forEach(v => {
          if (this.manualSearch === null || this.manualSearch === '' ||
            this.$refs.manualTable.customFilter(v.number, this.manualSearch)) {
            this.removeManualNumber(v)
          }
        })
      },
      addWhitelist (key) {
        if (key !== undefined) {
          this.whitelistKey = key
        }
        this.$set(this.whitelists, this.whitelistKey, {
          component: WhitelistRow,
          errors: {
            stringError: '',
            lengthsError: '',
            randomDigitsError: '',
            rateError: '',
          },
        })
        this.whitelistKey++
      },
      removeWhitelist (index) {
        if (Object.prototype.hasOwnProperty.call(this.whitelists, index)) {
          this.$delete(this.whitelists, index)
        }
        if (Object.prototype.hasOwnProperty.call(this.formData.whitelists, index)) {
          this.$delete(this.formData.whitelists, index)
        }
      },
      addHLR (key) {
        if (key !== undefined) {
          this.hlrWhitelistKey = key
        }
        this.$set(this.hlrWhitelists, this.hlrWhitelistKey, {
          component: HLRRow,
          errors: {
            stringError: '',
            rateError: '',
          },
        })
        this.hlrWhitelistKey++
      },
      removeHLR (index) {
        if (Object.prototype.hasOwnProperty.call(this.hlrWhitelists, index)) {
          this.$delete(this.hlrWhitelists, index)
        }
        if (Object.prototype.hasOwnProperty.call(this.hlrWhitelistFilters, index)) {
          this.$delete(this.hlrWhitelistFilters, index)
        }
      },
      addSchedule (key) {
        if (key !== undefined) {
          this.schedulesKey = key
        }
        this.$set(this.schedules, this.schedulesKey, {
          component: ScheduleRow,
          errors: {
            startError: '',
            endError: '',
          },
        })
        this.schedulesKey++
      },
      removeSchedule (index) {
        if (Object.prototype.hasOwnProperty.call(this.schedules, index)) {
          this.$delete(this.schedules, index)
        }
        if (Object.prototype.hasOwnProperty.call(this.formData.schedule, index)) {
          this.$delete(this.formData.schedule, index)
        }
      },
      validateAndGetFilters () {
        const filters = []
        let valid = true
        for (const [key, filter] of Object.entries(this.formData.whitelists)) {
          if (!filter.string) {
            this.whitelists[key].errors.stringError = 'Required'
            valid = false
          }
          if (!filter.lengths) {
            this.whitelists[key].errors.lengthsError = 'Required'
            valid = false
          }
          if (!filter.rate) {
            this.whitelists[key].errors.rateError = 'Required'
            valid = false
          }
          let string = filter.string
          if (typeof string === 'string') {
            string = [string]
          }
          if (valid) {
            filters.push({
              type: filter.type,
              string: string,
              lengths: commaSeparatedNumbersToList(filter.lengths),
              randomDigits: commaSeparatedNumbersToList(filter.randomDigits),
              rate: filter.rate,
            })
          } else {
            return false
          }
        }
        return filters
      },
      validateAndGetHLRFilters () {
        const filters = []
        let valid = true
        for (const [key, filter] of Object.entries(this.hlrWhitelistFilters)) {
          if (!filter.string) {
            this.hlrWhitelists[key].errors.stringError = 'Required'
            valid = false
          }
          if (!filter.rate) {
            this.hlrWhitelists[key].errors.rateError = 'Required'
            valid = false
          }
          if (valid) {
            filters.push({
              type: filter.type,
              string: filter.string,
              rate: filter.rate,
            })
          } else {
            return false
          }
        }
        return filters
      },
      submitWithLookbackCheck () {
        if (!this.$refs.form.validate()) {
          return false
        }
        if ((this.formData.destinationType === 'whitelist') &&
          this.formData.destinationLookbackType === 'time' &&
          this.destinationLookbackTimePeriod === 'day' &&
          this.destinationLookbackTimeNum <= 30 &&
          this.taskType !== 'template') {
          this.lookbackWarning = true
          return false
        }
        this.submit()
      },
      submit () {
        this.savedSuccess = false
        this.lookbackWarning = false
        this.errorDialogText = []
        let valid = true
        this.csvError = []

        if (this.autoPDD === true) {
          this.formData.taskPDDs = '18,19,20'
        } else {
          this.formData.taskMinASR = '7'
          this.formData.taskMaxASR = '14'
        }

        // Check for invalid sourceType
        if (!this.sourceTypeItems.some(type => type.value === this.formData.sourceType)) {
          this.errorDialogText.push('Invalid selection for source type')
        }
        // Make sure we have something if needed
        let sources = []
        if (this.formData.sourceType === 'carrier' || this.formData.sourceType === 'prefix') {
          // Check for invalid sourceCount
          if (!this.sourceCountItems.some(type => type.value === this.formData.sourceCount)) {
            this.errorDialogText.push('Invalid selection for source count')
          }
        }
        if (this.formData.sourceType === 'manual') {
          sources = this.sources.map(source => source.number)
        } else if (this.formData.sourceType !== 'auto') {
          sources = this.sources.map(source => source.submit)
        }
        if (this.formData.sourceType !== 'auto' &&
          this.formData.sourceType !== 'auto-prefix' &&
          sources.length < 1 &&
          this.taskType !== 'template') {
          this.errorDialogText.push('You must add at least one source')
        }

        // Check all destination filters and build
        let filters = []
        // Check for empty destination filters
        if (this.formData.destinationType === 'whitelist') {
          if (Object.keys(this.formData.whitelists).length < 1 &&
            this.taskType !== 'template') {
            this.errorDialogText.push('Must include at least one destination filter')
          } else {
            filters = this.validateAndGetFilters()
            if (filters === false) {
              valid = false
            }
          }
        }
        if (this.formData.destinationType === 'hlr') {
          if (Object.keys(this.hlrWhitelistFilters).length < 1 &&
            this.taskType !== 'template') {
            this.errorDialogText.push('Must include at least one destination filter')
          } else {
            filters = this.validateAndGetHLRFilters()
            if (filters === false) {
              valid = false
            }
          }
        }

        let maxCPS = 0
        let maxChannels = 0
        if (this.formData.routeDialServer === null && this.dialServerDefault !== null) {
          maxCPS = this.dialServerData[this.dialServerDefault].maxCPS
          maxChannels = this.dialServerData[this.dialServerDefault].maxChannels
        } else if (this.dialServer !== null) {
          maxCPS = this.dialServer.maxCPS
          maxChannels = this.dialServer.maxChannels
        }

        let taskMaxSimultaneousCalls = parseInt(this.formData.taskMaxSimultaneousCalls, 10)
        if (taskMaxSimultaneousCalls > maxChannels) {
          taskMaxSimultaneousCalls = maxChannels
          this.formData.taskMaxSimultaneousCalls = maxChannels
        }
        const taskMaxCallsPerInterval = numberRangeToList(this.formData.taskMaxCallsPerInterval)
        let k = 0
        if (taskMaxCallsPerInterval.length > 1) {
          k = 1
        }
        if (taskMaxCallsPerInterval[k] > maxCPS) {
          taskMaxCallsPerInterval[k] = maxCPS
        }

        // Schedule!
        const schedule = []
        if (this.taskType === 'finalized') {
          let showScheduleDialog = true
          for (const [key, range] of Object.entries(this.formData.schedule)) {
            let hasKeys = true
            if (range[0] === null) {
              this.schedules[key].errors.startError = 'Required'
              valid = false
              hasKeys = false
              showScheduleDialog = false
            }
            if (range[1] === null) {
              this.schedules[key].errors.endError = 'Required'
              valid = false
              hasKeys = false
              showScheduleDialog = false
            }
            if (hasKeys && range[0] >= range[1]) {
              this.schedules[key].errors.startError = 'Must be before end time'
              valid = false
              showScheduleDialog = false
            }
            if (valid) {
              schedule.push([range[0], range[1]])
            }
          }
          if (schedule.length < 1 && showScheduleDialog) {
            this.errorDialogText.push('Must include at least one valid schedule entry')
          }
        }

        // CSV stuff
        let csvNumberColumn = 0
        let csvRate = 0
        if (this.formData.destinationType === 'custom' ||
          this.formData.destinationType === 'template') {
          if (this.csvFile === false &&
            (this.hasExistingDestinations === false || this.keepDestinations === false)) {
            this.csvError.push('File is required')
            valid = false
          }
          csvNumberColumn = this.csvNumberColumn
          csvRate = this.csvRate
        }

        if (this.useMaxAttempts === true) {
          this.taskDuration = 600
        } else {
          this.formData.taskMaxAttempts = null
        }

        // Show dialog if needed
        if (this.errorDialogText.length > 0) {
          this.errorDialog = true
          valid = false
        }

        // Built-in validation
        if (!this.$refs.form.validate() || !valid) {
          this.submitting = false
          return false
        }

        if (!this.formData.sourceMinimumProfit) {
          this.formData.sourceMinimumProfit = 0
        }
        if (!this.formData.sourceMaximumProfit) {
          this.formData.sourceMaximumProfit = null
        }

        let forceRandomDigits = []
        if (this.forceRandomDigits !== null) {
          forceRandomDigits = commaSeparatedNumbersToList(this.forceRandomDigits)
        }

        let altACD = null
        let altACDPercent = null
        if (this.alternateACD) {
          altACD = numberRangeToList(this.formData.taskAltACD)
          altACDPercent = parseInt(this.formData.taskAltACDPercent)
        }

        const postData = {
          taskType: this.taskType,
          taskParent: 0,
          sources: sources,
          sourceType: this.formData.sourceType,
          sourceCount: this.formData.sourceCount,
          sourceLookbackTime: this.formData.sourceLookbackTime,
          sourceLookbackTimePeriod: this.formData.sourceLookbackTimePeriod,
          sourceAutoWhitelist: pipeSeparatedStringsToList(this.formData.sourceAutoWhitelist),
          sourceAutoWhitelistType: this.formData.sourceAutoWhitelistType,
          sourceAutoBan: this.formData.sourceAutoBan,
          sourceAutoRefine: this.formData.sourceAutoRefine,
          sourceMinimumPopularity: parseInt(this.formData.sourceMinimumPopularity),
          sourceGroup: this.formData.sourceGroup,
          sourceLastSeenCheck: this.formData.sourceLastSeenCheck,
          sourceCliPrefix: this.formData.sourceCliPrefix,
          destinationType: this.formData.destinationType,
          destinationLookbackType: this.formData.destinationLookbackType,
          destinationLookbackData: this.formData.destinationLookbackData,
          destinationFilters: filters,
          destinationCSVNumberColumn: csvNumberColumn,
          destinationCSVRate: parseFloat(csvRate),
          taskName: this.formData.taskName,
          taskDuration: parseInt(this.formData.taskDuration, 10),
          taskPDDs: commaSeparatedNumbersToList(this.formData.taskPDDs),
          taskMinASR: parseInt(this.formData.taskMinASR, 10),
          taskMaxASR: parseInt(this.formData.taskMaxASR, 10),
          taskACD: numberRangeToList(this.formData.taskACD),
          taskPDDLock: this.formData.taskPDDLock,
          taskMaxSimultaneousCalls: taskMaxSimultaneousCalls,
          taskMaxCallsPerInterval: taskMaxCallsPerInterval,
          taskMaxCallsInterval: numberRangeToList(this.formData.taskMaxCallsInterval),
          routeId: parseInt(this.formData.routeId, 10),
          routeDialServer: this.formData.routeDialServer,
          destinationForceRandomDigits: forceRandomDigits,
          taskMaxAttempts: this.formData.taskMaxAttempts,
          sourceMinimumProfit: this.formData.sourceMinimumProfit,
          sourceMaximumProfit: this.formData.sourceMaximumProfit,
          taskAltACD: altACD,
          taskAltACDPercent: altACDPercent,
        }

        if (this.taskType === 'template') {
          postData.accuracy = this.formData.accuracy
          postData.accuracyStrip = this.formData.accuracyStrip
          postData.keepDestinations = this.keepDestinations
        }

        if (this.taskType === 'finalized') {
          postData.schedule = schedule
          postData.scheduleMinimumRoI = this.formData.scheduleMinimumRoI
          postData.scheduleMaximumTimeBelow = this.formData.scheduleMaximumTimeBelow
          postData.scheduleGrace = this.formData.scheduleGrace
        }

        if (this.taskId > 0) {
          postData.taskId = this.taskId
        }

        this.submitting = true
        let method = ''
        let uri = `${process.env.VUE_APP_API_ROOT}tasks/`
        if (this.taskType === 'template') {
          method = 'PUT'
          uri = `${process.env.VUE_APP_API_ROOT}task/templates/default/`
        } else if (this.taskId > 0) {
          method = 'PUT'
          postData.id = this.taskId
        } else {
          method = 'POST'
        }
        this.toBase64(this.csvFile).then(async b64File => {
          postData.destinationCSV = b64File
          await authFetch(uri, {
            method: method,
            body: JSON.stringify(postData),
          })
            .then(async response => {
              const data = await response.json()
              if (Object.prototype.hasOwnProperty.call(data, 'result') && data.result === 'success') {
                if (this.taskType === 'template') {
                  this.savedSuccess = true
                } else {
                  this.$router.push({ name: 'tasks' })
                }
              } else {
                this.errorDialogText = ['Received an unexpected response from the server. Not sure what to do']
                this.valid = true
              }
            })
            .catch(async error => {
              console.log(error)
              const data = await error.json()
              // Correct error response?
              if (typeof data === 'object' && Object.prototype.hasOwnProperty.call(data, 'message')) {
                // Is it an object we can loop through for feedback?
                if (typeof data.message === 'object') {
                  this.errorDialogText = data.message
                  // String returned, setup dialog
                } else {
                  this.errorDialogText = [data.message]
                }
                // Generic error if we didn't get a proper response
              } else {
                this.errorDialogText = [`Server returned a ${error.status} status code`]
              }
              if (this.errorDialogText) {
                this.errorDialog = true
              }
            })
            .finally(() => {
              this.submitting = false
            })
        })
      },
    },
  }
</script>

<style>
.v-datatable__actions .v-datatable__actions__select .v-select__selections input {
  display: none
}

.switch-center {
  display: flex;
  justify-content: center;
}
</style>

<template>
  <layout-panel>
    <v-container>
      <v-card>
        <v-card-text class="display-2 text-center mt-5 primary white--text">
          High Rate VoIP Users
        </v-card-text>
      </v-card>
    </v-container>
    <v-container>
      <v-card>
        <v-card-text>
          <v-data-table
            :items="items"
            :headers="headers"
            :footer-props="{
              itemsPerPageOptions: [10, 50, 100]
            }"
            :options.sync="options"
            :server-items-length="total"
            :sort-by="sortBy"
            :sort-desc="descending"
            :loading="loading"
            :single-expand="false"
            :expanded.sync="expanded"
            show-expand
          >
            <template v-slot:item.active="row">
              {{ activeToText(row) }}
            </template>
            <template v-slot:expanded-item="row">
              <td :colspan="row.headers.length">
                <v-container>
                  <h3 class="mb-1">Contact Details</h3>
                  <v-row
                    v-for="(v, k) in row.item.contactDetails"
                    :key="k"
                    class="pl-5 pb-1"
                  >
                    <strong>{{ k }}:</strong> {{ v }}
                  </v-row>
                  <h3 class="mb-1 mt-3">Payment Details</h3>
                  <v-row
                    v-for="(v, k) in row.item.paymentDetails"
                    :key="k"
                    class="pl-5 pb-1"
                  >
                    <strong>{{ k }}:</strong> {{ v }}
                  </v-row>
                </v-container>
              </td>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </layout-panel>
</template>

<script>
  import { authFetch, objectToQueryString } from '@/plugins/authFetch'
  export default {
    data: () => ({
      items: [],
      loading: false,
      headers: [
        { text: 'User', value: 'user' },
        { text: 'Active', value: 'active' },
        { text: 'Name', value: 'name' },
        { text: 'Registered', value: 'added' },
        { text: 'Details', value: 'data-table-expand' },
      ],
      options: {},
      total: 0,
      sortBy: 'added',
      descending: true,
      expanded: [],
    }),
    watch: {
      options: {
        handler () {
          this.getData()
        },
        deep: true,
      },
    },
    methods: {
      activeToText (row) {
        if (row.item.active === 1) {
          return 'Yes'
        }
        return 'No'
      },
      getData () {
        this.loading = true
        const query = objectToQueryString({
          itemsPerPage: this.options.itemsPerPage,
          page: this.options.page,
          sortBy:
            this.options.sortBy.length === 0
              ? this.sortBy
              : this.options.sortBy[0],
          sortDesc:
            this.options.sortDesc.length === 0
              ? this.descending === true
                ? 1
                : 0
              : this.options.sortDesc[0] === true
                ? 1
                : 0,
        })
        authFetch(process.env.VUE_APP_API_ROOT + `admin/hrv/users/?${query}`)
          .then(async response => {
            const data = await response.json()
            this.total = data.total
            this.items = data.items
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            this.loading = false
          })
      },
    },
  }
</script>

<style scoped>
.pre { white-space: pre-wrap; }
</style>

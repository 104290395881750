<template>
  <layout-panel>
    <v-container>
      <v-card>
        <v-card-text class="display-2 text-center mt-5 primary white--text">
          My Live Calls
        </v-card-text>
      </v-card>
    </v-container>
    <v-container>
      <v-card>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="items"
            hide-default-footer
            :items-per-page="-1"
            :loading="loading"
          >
            <template v-slot:no-data>
              <div v-if="!loading && !error" class="text-center">
                No active incoming calls
              </div>
              <v-alert
                v-if="!loading && error"
                :value="true"
                color="error"
                icon="warning"
              >
                Error loading calls
              </v-alert>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </layout-panel>
</template>

<script>
  import { authFetch } from '@/plugins/authFetch'

  export default {
    props: {
      taskId: { type: Number, default: 0 },
    },
    data () {
      return {
        headers: [
          { text: 'Incoming CLI', value: 'source' },
          { text: 'Inc. Country', value: 'sourceCountry' },
          { text: 'Inc. Carrier', value: 'sourceCarrier' },
          { text: 'Retail Number', value: 'retail' },
          { text: 'Ret. Country', value: 'retailCountry' },
          { text: 'Ret. Carrier', value: 'retailCarrier' },
          { text: 'Duration', value: 'duration' },
          { text: 'Revenue', value: 'value' },
        ],
        items: [],
        loading: false,
        reloadTimer: null,
        error: false,
      }
    },
    created () {
      this.getData()
      this.reloadTimer = setInterval(() => {
        this.getData()
      }, 10000)
    },
    beforeDestroy () {
      clearInterval(this.reloadTimer)
    },
    methods: {
      getData () {
        this.loading = true
        authFetch(process.env.VUE_APP_API_ROOT + 'user/active-incoming/')
          .then(async response => {
            this.items = await response.json()
            this.error = false
          })
          .catch(error => {
            console.log(error)
            this.error = true
          })
          .finally(() => {
            this.loading = false
          })
      },
    },
  }
</script>

<template>
  <div>
    <v-container>
      <v-card>
        <v-stepper v-model="formStepsCurrent" :vertical="false">
          <v-stepper-header>
            <v-stepper-step :step="formStepsDestinations">
              Destination Selection
            </v-stepper-step>
            <template v-if="showDialingOptions">
              <v-divider />
              <v-stepper-step :step="formStepsRouteOptions">
                Route Options
              </v-stepper-step>
            </template>
            <v-divider />
            <v-stepper-step :step="formStepsTaskOptions">
              Task Options
            </v-stepper-step>
            <template v-if="taskType === 'finalized'">
              <v-divider />
              <v-stepper-step :step="formStepsSchedule">
                Schedule
              </v-stepper-step>
            </template>
          </v-stepper-header>
          <v-stepper-items>

            <!-- START DESTINATION SELECTION -->
            <v-stepper-content :step="formStepsDestinations">
              <v-form
                ref="formSource"
                v-model="formValid"
                @submit.prevent="validateAndGetSources()"
              >
                <v-card-text>
                  <v-container class="m-3">
                    <v-row>
                      <template>
                        <v-col cols="6">
                          <v-card elevation="0">
                            <v-card-title class="subheading">
                              Available Retails
                              <v-spacer />
                              <v-text-field
                                v-model="sourceSearch"
                                append-icon="filter_list"
                                label="Filter"
                                single-line
                                hide-details
                                clearable
                              />
                            </v-card-title>
                            <v-data-table
                              ref="sourceTable"
                              :items="sourceItems"
                              :headers="headers"
                              :loading="sourceLoading"
                              :footer-props="{ itemsPerPageOptions: [10,50,{'text':'All',value:-1}] }"
                              :search="sourceSearch"
                              hide-default-header
                            >
                              <template v-slot:item="props">
                                <tr @click="addSource(props.item)">
                                  <td>{{ props.item.name }}</td>
                                  <td>{{ props.item.count }}
                                  </td><td class="text-right">
                                    <v-btn color="primary" small fab>
                                      <v-icon>add</v-icon>
                                    </v-btn>
                                  </td>
                                </tr>
                              </template>
                              <template v-slot:footer>
                                <td :colspan="headers.length">
                                  <v-btn color="primary" @click="addAllSources()">
                                    Add All
                                  </v-btn>
                                </td>
                              </template>
                              <template v-slot:no-data>
                                <v-alert v-if="!sourceLoading" :value="true" color="error" icon="warning">
                                  No numbers avaialable
                                </v-alert>
                                <div v-if="sourceLoading" class="text-center">
                                  <v-progress-circular indeterminate />
                                </div>
                              </template>
                              <template v-slot:no-results>
                                <v-alert :value="true" color="error" icon="warning">
                                  Your search for "{{ sourceSearch }}" found no results.
                                </v-alert>
                              </template>
                            </v-data-table>
                          </v-card>
                        </v-col>
                        <v-col cols="6">
                          <v-card elevation="0">
                            <v-card-title class="subheading">
                              Selected Retails ({{ formData.sources.length }})
                              <v-spacer />
                              <v-text-field
                                v-model="sourceSelectedSearch"
                                append-icon="filter_list"
                                label="Filter"
                                single-line
                                hide-details
                                clearable
                              />
                            </v-card-title>
                            <v-data-table
                              ref="sourceSelectedTable"
                              :items="sources"
                              :headers="headers"
                              :footer-props="{ itemsPerPageOptions: [10,50,{'text':'All',value:-1}] }"
                              :search="sourceSelectedSearch"
                              :loading="sourceLoading"
                              hide-default-header
                              no-data-text="No numbers added"
                            >
                              <template v-slot:item="props">
                                <tr @click="removeSource(props.item.name)">
                                  <td>{{ props.item.name }}</td>
                                  <td>{{ props.item.count }}</td>
                                  <td class="text-right">
                                    <v-btn color="primary" small fab>
                                      <v-icon>remove</v-icon>
                                    </v-btn>
                                  </td>
                                </tr>
                              </template>
                              <template v-slot:footer>
                                <td :colspan="headers.length">
                                  <v-btn color="primary" @click="removeAllSources()">
                                    Remove All
                                  </v-btn>
                                </td>
                              </template>
                              <template v-slot:no-results>
                                <v-alert :value="true" color="error" icon="warning">
                                  Your search for "{{ sourceSelectedSearch }}" found no results.
                                </v-alert>
                              </template>
                            </v-data-table>
                          </v-card>
                        </v-col>
                      </template>
                    </v-row>
                  </v-container>
                </v-card-text>
                <task-stepper-actions
                  :page="formStepsCurrent"
                  :total-pages="formStepsTotal"
                  @prev="formStepsCurrent--"
                />
              </v-form>
            </v-stepper-content>
            <!-- END SOURCE SELECTION -->

            <!-- START ROUTE OPTIONS -->
            <v-stepper-content v-if="showDialingOptions" :step="formStepsRouteOptions">
              <v-form
                ref="formRouteOptions"
                v-model="formValid"
                @submit.prevent="validateRouteOptions()"
              >
                <v-card-text>
                  <v-container class="m-3">
                    <v-row>
                      <template v-if="routesLoading === false">
                        <v-col cols="4">
                          <v-select
                            v-model="formData.routeId"
                            label="Route"
                            :items="routesItems"
                            :rules="[rules.required, rules.positiveNumber]"
                          />
                        </v-col>
                        <v-col cols="2">
                          <v-btn color="primary" @click="routeForm = true">
                            New Route
                          </v-btn>
                        </v-col>
                      </template>
                      <v-col v-else cols="6" style="text-align: center">
                        <v-progress-circular
                          :size="35"
                          :width="3"
                          indeterminate
                        />
                      </v-col>
                      <v-col cols="4" offset="1">
                        <v-select
                          v-model="formData.routeDialServer"
                          :items="dialServerItems"
                          label="Outgoing Dial Server"
                        >
                          <template v-slot:append-outer>
                            <v-tooltip bottom max-width="400">
                              <template v-slot:activator="{ on }">
                                <v-icon v-on="on">help</v-icon>
                              </template>
                              Select the dial server you want to use for this task.
                              Dial servers support a maximum number of outgoing calls
                              per server and a maximum number of calls per second. If
                              you go over this limit per server, tasks will fail to
                              start.
                            </v-tooltip>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <task-stepper-actions
                  :page="formStepsCurrent"
                  :total-pages="formStepsTotal"
                  :loading="submitting"
                  @prev="formStepsCurrent--"
                />
              </v-form>
            </v-stepper-content>
            <!-- END ROUTE OPTIONS -->

            <!-- START TASK OPTIONS -->
            <v-stepper-content :step="formStepsTaskOptions">
              <v-form
                ref="formTaskOptions"
                v-model="formValid"
                @submit.prevent="validateTaskOptions()"
              >
                <v-card-text>
                  <v-container class="m-3">
                    <v-row>
                      <v-col cols="3">
                        <v-text-field
                          v-model="formData.taskName"
                          :rules="[rules.required]"
                          required
                          label="Task Name"
                        />
                      </v-col>
                      <v-col v-if="showDialingOptions" cols="3">
                        <v-text-field
                          v-model="formData.taskACD"
                          :rules="[rules.required, rules.positiveRange]"
                          required
                          label="ACD Required"
                          hint="Range"
                        >
                          <template v-slot:append>
                            <v-tooltip bottom max-width="400">
                              <template v-slot:activator="{ on }">
                                <v-icon v-on="on">help</v-icon>
                              </template>
                              Setting the ACD is setting the average duration of the
                              outgoing calls, this is a requirement that will change
                              between routes.
                            </v-tooltip>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col v-if="showDialingOptions" cols="3">
                        <v-text-field
                          v-model="formData.taskMaxSimultaneousCalls"
                          :rules="[rules.required, rules.positiveNumber]"
                          required
                          label="Max Channels"
                          type="number"
                          :hint="channelsHint"
                          :persistent-hint="cpsChannelsDisabled"
                          min="1"
                          :max="channelsMax"
                          :disabled="cpsChannelsDisabled"
                        />
                      </v-col>
                      <v-col v-if="showDialingOptions" cols="3">
                        <v-text-field
                          v-model="formData.taskMaxCallsPerInterval"
                          :rules="[rules.required, rules.positiveRange]"
                          required
                          label="Max Calls per Interval"
                          :hint="cpsHint"
                          :persistent-hint="cpsChannelsDisabled"
                          :disabled="cpsChannelsDisabled"
                        />
                      </v-col>
                      <v-col v-if="showDialingOptions" cols="3">
                        <v-text-field
                          v-model="formData.taskMaxCallsInterval"
                          :rules="[rules.required, rules.positiveRange]"
                          required
                          label="Max Calls Interval (Seconds)"
                          hint="Range"
                        />
                      </v-col>
                    </v-row>
                    <v-row v-if="taskType !== 'finalized'">
                      <v-col cols="4">
                        <v-switch
                          v-model="useMaxAttempts"
                          label="Limit total attempts"
                        />
                      </v-col>
                      <v-col v-if="useMaxAttempts === false" cols="3">
                        <v-text-field
                          v-model="formData.taskDuration"
                          :rules="[rules.required, rules.numberGT0]"
                          required
                          label="Task Duration (minutes)"
                          type="number"
                        />
                      </v-col>
                      <v-col v-else cols="3">
                        <v-text-field
                          v-model="formData.taskMaxAttempts"
                          :rules="[rules.required, rules.numberGT0]"
                          required
                          label="Max Outgoing Attempts"
                          type="number"
                        />
                      </v-col>
                    </v-row>
                    <v-row v-if="showDialingOptions">
                      <v-col cols="4">
                        <v-switch
                          v-model="autoPDD"
                          label="Automatic PDD Adjust"
                        >
                          <template v-slot:append>
                            <v-tooltip bottom max-width="400">
                              <template v-slot:activator="{ on }">
                                <v-icon v-on="on">help</v-icon>
                              </template>
                              Enter the minimum and maximum ASR that you want
                              to run on this route and the platform will try
                              its best to acheive a comfortable number between
                              the entered values. If it cannot reach a number
                              between the values entered, the task will eventually
                              be automatically stopped.
                            </v-tooltip>
                          </template>
                        </v-switch>
                      </v-col>
                      <template v-if="autoPDD === true">
                        <v-col cols="3">
                          <v-text-field
                            v-model="formData.taskMinASR"
                            :rules="[rules.required, rules.positiveNumber]"
                            required
                            label="Min ASR"
                            type="number"
                          />
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            v-model="formData.taskMaxASR"
                            :rules="[rules.required, rules.positiveNumber]"
                            required
                            label="Max ASR"
                            type="number"
                          />
                        </v-col>
                      </template>
                      <template v-else>
                        <v-col cols="3">
                          <v-text-field
                            v-model="formData.taskPDDs"
                            :rules="[rules.required, rules.positiveCommaSepNumbers]"
                            validate-on-blur
                            required
                            label="PDDs (comma separated)"
                          />
                        </v-col>
                      </template>
                    </v-row>
                    <v-row v-if="showDialingOptions">
                      <v-col cols="4">
                        <v-switch
                          v-model="alternateACD"
                          label="Alternate ACD"
                        >
                        </v-switch>
                      </v-col>
                      <template v-if="alternateACD === true">
                        <v-col cols="3">
                          <v-text-field
                            v-model="formData.taskAltACD"
                            :rules="[rules.required, rules.positiveRange]"
                            required
                            label="Alternate ACD"
                            hint="Range"
                          />
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            v-model="formData.taskAltACDPercent"
                            :rules="[rules.required, rules.positiveNumber]"
                            required
                            label="Percent chance"
                            type="number"
                            suffix="%"
                          />
                        </v-col>
                      </template>
                    </v-row>
                    <v-row>
                      <v-col cols="3">
                        <v-text-field
                          v-model="cliOverride"
                          label="Outgoing CLI"
                        />
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          v-model="rate"
                          :rules="[rules.required, rules.positiveFloat]"
                          required
                          validate-on-blur
                          label="Rate"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <task-stepper-actions
                  :page="formStepsCurrent"
                  :total-pages="formStepsTotal"
                  :loading="submitting"
                  @prev="formStepsCurrent--"
                />
              </v-form>
            </v-stepper-content>
            <!-- END TASK OPTIONS -->
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-container>
    <v-dialog v-model="errorDialog" max-width="500">
      <v-card>
        <v-card-title class="headline" color="error">Validation Error</v-card-title>
        <v-card-text>
          <p>You made mistakes on the form that must be fixed.</p>
          <p v-for="(error, key) in errorDialogText" :key="key">{{ error }}</p>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="errorDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="routeForm" max-width="500">
      <new-route-form @completed="handleNewRoute" @cancel="routeForm = false" />
    </v-dialog>
  </div>
</template>

<script>
  import { authFetch } from '@/plugins/authFetch'
  import * as rules from '@/plugins/rules'
  import {
    commaSeparatedNumbersToList,
    numberRangeToList,
    pipeSeparatedStringsToList,
    defaultDialServerMaximums,
    sourceGroupItems,
  } from '@/plugins/common'
  import TaskStepperActions from '@/components/TaskStepperActions'
  import NewRouteForm from '@/components/ModalRouteForm.vue'

  export default {
    components: { TaskStepperActions, NewRouteForm },
    props: {
      taskType: { type: String, default: 'reverse-arbitrage' },
      taskId: { type: Number, default: 0 },
      defaultTask: { type: Boolean, default: false },
      previousFormData: {
        type: Object,
        default () {
          return {
            sources: [],
            sourceCount: 'one',
            sourceType: 'carrier',
            sourceAutoWhitelist: '',
            sourceAutoWhitelistType: 'white',
            sourceAutoBan: false,
            sourceAutoRefine: false,
            sourceMinimumPopularity: '',
            sourceLookbackTime: 1,
            sourceLookbackTimePeriod: 'day',
            sourceGroup: null,
            sourceCliPrefix: '',
            destinationType: 'whitelist',
            destinationLookbackType: 'time',
            destinationLookbackData: '3 month',
            destinationCSV: null,
            whitelists: {},
            taskName: '',
            taskDuration: '',
            taskACD: '',
            taskPDDs: '',
            taskMaxASR: '',
            taskMinASR: '',
            taskPDDLock: false,
            taskMaxSimultaneousCalls: '',
            taskMaxCallsPerInterval: '',
            taskMaxCallsInterval: '',
            routeId: '',
            routeDialServer: null,
            schedule: {},
            scheduleMinimumRoI: null,
            scheduleMaximumTimeBelow: null,
            scheduleGrace: null,
            sourceLastSeenCheck: 'none',
            accuracy: false,
            accuracyStrip: null,
            taskMaxAttempts: null,
            taskAltACD: '',
            taskAltACDPercent: '',
          }
        },
      },
    },
    data () {
      return {
        lookbackWarning: false,
        sourceLoading: true,
        sourceCountItems: [
          { text: 'Use 12 Numbers', value: 'one' },
          { text: 'Use All Numbers', value: 'all' },
        ],
        sourceSearch: '',
        sourceSelectedSearch: '',
        sourcePrefixItems: [],
        sourceCarrierItems: [],
        headers: [
          { text: 'Name', value: 'name' },
          { text: 'Actions', value: 'name' },
        ],
        whitelists: {},
        whitelistKey: 0,
        schedules: {},
        schedulesKey: 0,
        submitting: false,
        errorDialog: false,
        errorDialogText: '',
        rules: rules,
        formValid: true,
        destinationLookbackTypes: [
          { text: 'Time', value: 'time' },
          { text: 'Rows', value: 'rows' },
        ],
        destinationLookbackPeriods: [
          { text: 'Hours', value: 'hour' },
          { text: 'Days', value: 'day' },
          { text: 'Months', value: 'month' },
          { text: 'Years', value: 'year' },
        ],
        manualLookbackPeriods: [
          { text: 'Minutes', value: 'minute' },
          { text: 'Hours', value: 'hour' },
          { text: 'Days', value: 'day' },
          { text: 'Months', value: 'month' },
          { text: 'Years', value: 'year' },
        ],
        formData: this.previousFormData,
        sources: [],
        manualLookbackLoading: false,
        manualRemoved: [],
        manualSearch: '',
        manualRemovedSearch: '',
        manualHeaders: [
          { text: 'Number', value: 'number' },
          { text: 'Pop', value: 'popularity' },
        ],
        manualTableOptions: { sortBy: 'popularity', descending: true },
        test: ['a', 'b', 'c'],
        autoWhitelistTypes: [
          { text: 'Whitelist', value: 'white' },
          { text: 'Blacklist', value: 'black' },
        ],
        sourceGroupItems: sourceGroupItems,
        dialServerItems: [],
        dialServerData: {},
        dialServerDefault: null,
        routesItems: [],
        routesLoading: true,
        routeForm: false,
        hlrWhitelists: {},
        hlrWhitelistKey: 0,
        hlrWhitelistFilters: {},
        csvError: [],
        csvNumberColumn: null,
        csvRate: null,
        csvFile: false,
        destinationType: this.previousFormData.destinationType,
        showDestinationsOverride: false,
        formStepsCurrent: 1,
        accuracy: false,
        accuracyStrip: null,
        accuracyLoading: false,
        accuracyResults: null,
        accuracyError: null,
        useMaxAttempts: false,
        cliOverride: '',
        rate: null,
        alternateACD: false,
      }
    },
    computed: {
      cpsHint () {
        if (this.cpsChannelsDisabled === true) {
          return 'Must select Outgoing Dial Server first'
        }
        return `Maximum ${this.dialServer.maxCPS}`
      },
      cpsMax () {
        if (this.cpsChannelsDisabled === true) {
          return 0
        }
        return this.dialServer.maxCPS
      },
      channelsHint () {
        if (this.cpsChannelsDisabled === true) {
          return 'Must select Outgoing Dial Server first'
        }
        return `Maximum ${this.dialServer.maxChannels}`
      },
      channelsMax () {
        if (this.cpsChannelsDisabled === true) {
          return 0
        }
        return this.dialServer.maxChannels
      },
      cpsChannelsDisabled () {
        return this.dialServer === null
      },
      dialServer () {
        if (this.formData.routeDialServer === 'auto') {
          return {
            ip: 'auto',
            maxCPS: defaultDialServerMaximums.cps,
            maxChannels: defaultDialServerMaximums.channels,
          }
        }
        let ip = this.formData.routeDialServer
        if (ip === null && this.dialServerDefault !== null) {
          ip = this.dialServerDefault
        }
        if (Object.prototype.hasOwnProperty.call(this.dialServerData, ip) === false) {
          return null
        }
        return this.dialServerData[ip]
      },
      formStepsTotal () {
        return 3
      },
      formStepsTaskOptions () {
        return 3
      },
      formStepsRouteOptions () {
        return 2
      },
      formStepsDestinations () {
        return 1
      },
      sourceTypeItems () {
        if (this.formData.destinationType === 'none') {
          return [
            { text: 'Exploration', value: 'carrier' },
          ]
        }
        return [
          { text: 'Exploration', value: 'carrier' },
          { text: 'Final Production', value: 'auto' },
          { text: 'Historical Access', value: 'auto-prefix' },
        ]
      },
      showDestinationFilters () {
        return this.formData.destinationType === 'whitelist'
      },
      showDialingOptions () {
        return this.formData.destinationType !== 'none'
      },
      sourceItems () {
        if (this.formData.sourceType === 'prefix') {
          if (this.sourcePrefixItems.length === 0) {
            this.loadSource(this.formData.sourceType)
          }
          return this.sourcePrefixItems
        } else if (this.formData.sourceType === 'carrier') {
          if (this.sourceCarrierItems.length === 0) {
            this.loadSource(this.formData.sourceType)
          }
          return this.sourceCarrierItems
        }
        return []
      },
      autoPDD: {
        get () {
          return !this.formData.taskPDDLock
        },
        set (value) {
          this.formData.taskPDDLock = !value
        },
      },
      destinationLookbackTimeNum: {
        get () {
          if (typeof this.formData.destinationLookbackData === 'undefined') {
            return 1
          }
          const parts = this.formData.destinationLookbackData.split(' ')
          if (isNaN(parts[0])) {
            return 1
          }
          return parseInt(parts[0], 10)
        },
        set (value) {
          this.formData.destinationLookbackData = `${value} ${this.destinationLookbackTimePeriod}`
        },
      },
      destinationLookbackTimePeriod: {
        get () {
          if (typeof this.formData.destinationLookbackData === 'undefined') {
            return 'day'
          }
          const parts = this.formData.destinationLookbackData.split(' ')
          if (parts.length < 2) {
            return 'day'
          }
          return parts[1]
        },
        set (value) {
          this.formData.destinationLookbackData = `${this.destinationLookbackTimeNum} ${value}`
        },
      },
      formDataLookbackData () {
        return this.formData.destinationLookbackData
      },
      formDataLookbackType () {
        return this.formData.destinationLookbackType
      },
      sourceType: {
        get () {
          return this.formData.sourceType
        },
        set (val) {
          this.sources = []
          this.formData.sources = []
          this.formData.sourceType = val
        },
      },
      whiteOrBlacklist () {
        return this.formData.sourceAutoWhitelistType === 'white' ? 'Whitelist' : 'Blacklist'
      },
    },
    watch: {
      destinationType () {
        this.$set(this.formData, 'destinationType', this.destinationType)
        if (this.destinationType === 'none') {
          this.formData.sourceType = 'carrier'
        }
      },
      formDataLookbackData () {
        if (this.formData.destinationLookbackType === 'time') {
          const parts = this.formData.destinationLookbackData.split(' ')
          if (parts.length < 2) {
            return
          }
          this.destinationLookbackTimeNum = parts[0]
          this.destinationLookbackTimePeriod = parts[1]
        }
      },
      formDataLookbackType () {
        if (this.formData.destinationLookbackType === 'time') {
          this.formData.destinationLookbackData = '1 day'
        } else {
          this.formData.destinationLookbackData = '1000'
        }
      },
    },
    mounted () {
      if (this.taskId < 1) {
        this.formData.taskName = ''
      }
      if (Array.isArray(this.formData.taskACD)) {
        this.formData.taskACD = this.formData.taskACD.join('-')
      }
      if (Array.isArray(this.formData.taskMaxCallsPerInterval)) {
        this.formData.taskMaxCallsPerInterval = this.formData.taskMaxCallsPerInterval.join('-')
      }
      if (Array.isArray(this.formData.taskMaxCallsInterval)) {
        this.formData.taskMaxCallsInterval = this.formData.taskMaxCallsInterval.join('-')
      }
      if (Object.keys(this.formData.whitelists).length > 0 &&
        Object.prototype.hasOwnProperty.call(this.formData.whitelists[0], 'strip') === true &&
        this.formData.whitelists[0].strip !== null) {
        this.accuracy = true
        this.accuracyStrip = this.formData.whitelists[0].strip
      }
      if (this.formData.taskMaxAttempts !== null) {
        this.useMaxAttempts = true
      }
      authFetch(`${process.env.VUE_APP_API_ROOT}servers/`)
        .then(async response => {
          const items = await response.json()
          if (typeof items === 'object' && Object.prototype.hasOwnProperty.call(items, 'servers')) {
            if (items.servers.length > 0) {
              this.dialServerItems.push({
                text: 'Automatic',
                value: 'auto',
              })
            }
            if (Object.prototype.hasOwnProperty.call(items, 'default') && items.default !== null) {
              this.dialServerItems.push({
                text: `Use Default (${items.default.ip} - ` +
                  `${items.default.maxCPS} CPS/${items.default.maxChannels} Ports)`,
                value: null,
              })
              this.dialServerDefault = items.default.ip
            }
            items.servers.forEach(server => {
              this.dialServerItems.push({
                text: `${server.ip} - ${server.maxCPS} CPS/` +
                  `${server.maxChannels} Ports`,
                value: server.ip,
              })
              this.$set(this.dialServerData, server.ip, server)
            })
            if (this.dialServerDefault === null && this.formData.routeDialServer === null &&
              this.dialServerItems.length > 0) {
              this.$set(this.formData, 'routeDialServer', this.dialServerItems[0].value)
            }
          }
        })
      this.loadRoutes(this.formData.routeId)
    },
    methods: {
      formVerifyValidity (form, valid) {
        if (this.errorDialogText.length > 0) {
          this.errorDialog = true
          valid = false
        }
        if (form.validate() === false || valid === false) {
          this.submitting = false
          return false
        }
        return true
      },
      validateAndGetSources () {
        const valid = true
        this.errorDialogText = []

        const sources = this.sources.map(source => source.submit)
        if (sources.length < 1) {
          this.errorDialogText.push('You must add at least one destination')
        }

        if (this.formVerifyValidity(this.$refs.formSource, valid) === false) {
          return false
        }

        if (this.formStepsCurrent < this.formStepsTotal) {
          this.formStepsCurrent++
        }
        return sources
      },
      validateTaskOptions () {
        if (this.formVerifyValidity(this.$refs.formTaskOptions, true) === false) {
          return false
        }

        if (this.formStepsCurrent === this.formStepsTotal) {
          return this.submit()
        } else {
          this.formStepsCurrent++
        }
      },
      validateRouteOptions () {
        if (this.formVerifyValidity(this.$refs.formRouteOptions, true) === false) {
          return false
        }

        if (this.formStepsCurrent === this.formStepsTotal) {
          return this.submit()
        } else {
          this.formStepsCurrent++
        }
      },
      handleNewRoute (id) {
        this.loadRoutes(id)
        this.routeForm = false
      },
      loadRoutes (setTo) {
        this.routesLoading = true
        authFetch(process.env.VUE_APP_API_ROOT + 'routes/')
          .then(async response => {
            const items = await response.json()
            this.routesItems = []
            items.forEach(r => {
              let prefix = 'No Prefix'
              if (r.prefix !== '') {
                prefix = `Prefix: ${r.prefix}`
              }
              let labelText = ''
              if (r.label) {
                labelText = `${r.label} - ${r.ip}:${r.port} ${prefix}`
              } else {
                labelText = `${r.ip}:${r.port} ${prefix}`
              }
              this.routesItems.push({
                text: labelText,
                value: r.id,
              })
            })
          })
          .finally(() => {
            this.routesLoading = false
            if (typeof setTo !== 'undefined') {
              this.formData.routeId = setTo
            }
          })
      },
      loadSource (type) {
        this.sourceLoading = true
        authFetch(process.env.VUE_APP_API_ROOT + `tasks/sources/${type}/`)
          .then(async response => {
            const items = await response.json()
            if (type === 'prefix') {
              items.forEach(row => {
                const src = {
                  name: `${row.prefix} - ${row.carrier}`,
                  count: row.count,
                  submit: row.prefix,
                }
                this.sourcePrefixItems.push(src)

                if (this.formData.sources.indexOf(row.prefix) !== -1) {
                  this.addSource(src)
                }
              })
            } else {
              items.forEach(row => {
                const src = {
                  name: row.carrier,
                  count: row.count,
                  submit: row.carrier,
                }
                this.sourceCarrierItems.push(src)
                if (this.formData.sources.indexOf(row.carrier) !== -1) {
                  this.addSource(src)
                }
              })
            }
          })
          .catch((error) => {
            console.log(error)
            this.items = []
          })
          .finally(() => {
            this.sourceLoading = false
          })
      },
      addSource (source) {
        for (let i = 0; i < this.sources.length; i++) {
          if (this.sources[i].name === source.name) {
            return false
          }
        }
        this.sources.push(source)
        return true
      },
      removeSource (source) {
        let index = null
        this.sources.some((value, key) => {
          if (value.name === source) {
            index = key
            return true
          }
          return false
        })
        if (index !== null) {
          this.sources.splice(index, 1)
        }
      },
      addAllSources () {
        this.sourceItems.forEach(v => {
          if (this.sourceSearch === null || this.sourceSearch === '' ||
            this.$refs.sourceTable.customFilter(v.name, this.sourceSearch)) {
            this.addSource(v)
          }
        })
      },
      removeAllSources () {
        this.sources.slice(0).forEach(v => {
          if (this.sourceSelectedSearch === null || this.sourceSelectedSearch === '' ||
            this.$refs.sourceSelectedTable.customFilter(v.name, this.sourceSelectedSearch)) {
            this.removeSource(v.name)
          }
        })
      },
      submit () {
        this.lookbackWarning = false
        this.errorDialogText = []
        let valid = true

        if (this.autoPDD === true) {
          this.formData.taskPDDs = '18,19,20'
        } else {
          this.formData.taskMinASR = '7'
          this.formData.taskMaxASR = '14'
        }

        const sources = this.validateAndGetSources()
        if (sources === false) {
          valid = false
        }

        let maxCPS = 0
        let maxChannels = 0
        if (this.formData.routeDialServer === null && this.dialServerDefault !== null) {
          maxCPS = this.dialServerData[this.dialServerDefault].maxCPS
          maxChannels = this.dialServerData[this.dialServerDefault].maxChannels
        } else if (this.dialServer !== null) {
          maxCPS = this.dialServer.maxCPS
          maxChannels = this.dialServer.maxChannels
        }

        let taskMaxSimultaneousCalls = parseInt(this.formData.taskMaxSimultaneousCalls, 10)
        if (taskMaxSimultaneousCalls > maxChannels) {
          taskMaxSimultaneousCalls = maxChannels
          this.formData.taskMaxSimultaneousCalls = maxChannels
        }
        const taskMaxCallsPerInterval = numberRangeToList(this.formData.taskMaxCallsPerInterval)
        let k = 0
        if (taskMaxCallsPerInterval.length > 1) {
          k = 1
        }
        if (taskMaxCallsPerInterval[k] > maxCPS) {
          taskMaxCallsPerInterval[k] = maxCPS
        }

        // Schedule!
        let schedule = []
        if (this.taskType === 'finalized') {
          schedule = this.validateAndGetSchedule(true)
          if (schedule === false) {
            valid = false
          }
        }

        // CSV stuff
        let csvNumberColumn = 0
        let csvRate = 0
        if (this.formData.destinationType === 'custom') {
          csvNumberColumn = this.csvNumberColumn
          csvRate = this.csvRate
        }

        if (this.useMaxAttempts === true) {
          this.formData.taskDuration = 600
        } else {
          this.formData.taskMaxAttempts = null
        }

        let altACD = null
        let altACDPercent = null
        if (this.alternateACD) {
          altACD = numberRangeToList(this.formData.taskAltACD)
          altACDPercent = parseInt(this.formData.taskAltACDPercent)
        }

        // Show dialog if needed
        if (this.errorDialogText.length > 0) {
          this.errorDialog = true
          valid = false
        }

        if (valid === false) {
          this.submitting = false
          return false
        }

        const postData = {
          taskType: this.taskType,
          taskParent: 0,
          sources: [this.cliOverride],
          sourceType: 'manual',
          sourceCount: 'all',
          sourceLookbackTime: this.formData.sourceLookbackTime,
          sourceLookbackTimePeriod: this.formData.sourceLookbackTimePeriod,
          sourceAutoWhitelist: pipeSeparatedStringsToList(this.formData.sourceAutoWhitelist),
          sourceAutoWhitelistType: this.formData.sourceAutoWhitelistType,
          sourceAutoBan: this.formData.sourceAutoBan,
          sourceAutoRefine: this.formData.sourceAutoRefine,
          sourceMinimumPopularity: parseInt(this.formData.sourceMinimumPopularity),
          sourceGroup: this.formData.sourceGroup,
          sourceLastSeenCheck: this.formData.sourceLastSeenCheck,
          sourceCliPrefix: this.formData.sourceCliPrefix,
          destinationType: 'retail',
          destinationLookbackType: this.formData.destinationLookbackType,
          destinationLookbackData: this.formData.destinationLookbackData,
          destinationFilters: [this.rate, sources],
          destinationCSVNumberColumn: csvNumberColumn,
          destinationCSVRate: parseFloat(csvRate),
          destinationCSV: '',
          taskName: this.formData.taskName,
          taskDuration: parseInt(this.formData.taskDuration, 10),
          taskPDDs: commaSeparatedNumbersToList(this.formData.taskPDDs),
          taskMinASR: parseInt(this.formData.taskMinASR, 10),
          taskMaxASR: parseInt(this.formData.taskMaxASR, 10),
          taskACD: numberRangeToList(this.formData.taskACD),
          taskPDDLock: this.formData.taskPDDLock,
          taskMaxSimultaneousCalls: taskMaxSimultaneousCalls,
          taskMaxCallsPerInterval: taskMaxCallsPerInterval,
          taskMaxCallsInterval: numberRangeToList(this.formData.taskMaxCallsInterval),
          routeId: parseInt(this.formData.routeId, 10),
          routeDialServer: this.formData.routeDialServer,
          taskMaxAttempts: this.formData.taskMaxAttempts,
          taskAltACD: altACD,
          taskAltACDPercent: altACDPercent,
        }

        if (this.taskType === 'finalized') {
          postData.schedule = schedule
          postData.scheduleMinimumRoI = this.formData.scheduleMinimumRoI
          postData.scheduleMaximumTimeBelow = this.formData.scheduleMaximumTimeBelow
          postData.scheduleGrace = this.formData.scheduleGrace
        }

        if (this.taskId > 0) {
          postData.taskId = this.taskId
        }

        this.submitting = true
        let method = ''
        if (this.taskId > 0) {
          method = 'PUT'
          postData.id = this.taskId
        } else {
          method = 'POST'
        }
        authFetch(process.env.VUE_APP_API_ROOT + 'tasks/', {
          method: method,
          body: JSON.stringify(postData),
        })
          .then(async response => {
            const data = await response.json()
            if (Object.prototype.hasOwnProperty.call(data, 'result') && data.result === 'success') {
              this.$router.push({ name: 'tasks' })
            } else {
              this.errorDialogText = ['Received an unexpected response from the server. Not sure what to do']
              this.valid = true
            }
          })
          .catch(async error => {
            console.log(error)
            const data = await error.json()
            // Correct error response?
            if (typeof data === 'object' && Object.prototype.hasOwnProperty.call(data, 'message')) {
              // Is it an object we can loop through for feedback?
              if (typeof data.message === 'object') {
                this.errorDialogText = data.message
              // String returned, setup dialog
              } else {
                this.errorDialogText = [data.message]
              }
            // Generic error if we didn't get a proper response
            } else {
              this.errorDialogText = [`Server returned a ${error.status} status code`]
            }
            if (this.errorDialogText) {
              this.errorDialog = true
            }
          })
          .finally(() => {
            this.submitting = false
          })
      },
    },
  }
</script>

<style>
.v-datatable__actions .v-datatable__actions__select .v-select__selections input {
  display: none
}
.switch-center {
  display: flex;
  justify-content: center;
}
</style>

<template>
  <layout-panel>
    <v-container>
      <v-card>
        <v-card-text class="display-2 text-center mt-5 primary white--text">
          User Accounting
        </v-card-text>
      </v-card>
    </v-container>
    <v-container>
      <v-card>
        <v-card-title class="pt-5">Totals Owed</v-card-title>
        <v-card-text>
          <v-data-table
            :items="items"
            :headers="headers"
            :loading="loading"
            hide-default-footer
            :items-per-page="-1"
            :single-expand="false"
            :expanded.sync="expanded"
            show-expand
          >
            <template v-slot:item.amount="row">
              ${{ floorToTwo(row.item.amount) }}
            </template>
            <template v-slot:expanded-item="row">
              <td :colspan="row.headers.length">
                <v-container>
                  <v-row v-for="(v, k) in row.item.details" :key="`${k}`">
                    <v-col class="pre"><strong>{{ k }}</strong>: {{ v }}</v-col>
                  </v-row>
                </v-container>
              </td>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-title>
          Total
        </v-card-title>
        <v-card-text>
          ${{ floorToTwo(total) }}
        </v-card-text>
      </v-card>
    </v-container>
  </layout-panel>
</template>

<script>
  import { authFetch } from '@/plugins/authFetch'
  import { floorToTwo } from '@/plugins/common'
  export default {
    data: () => ({
      items: [],
      loading: false,
      headers: [
        { text: 'User', value: 'user' },
        { text: 'Period Start', value: 'start' },
        { text: 'Amount', value: 'amount' },
        { text: 'Details', value: 'data-table-expand' },
      ],
      expanded: [],
      total: 0,
    }),
    computed: {
      translatedBalance () {
        if (this.balance === null) {
          return 'Balance loading...'
        }
        return `Your standing balance for this period: $${floorToTwo(this.balance)}`
      },
    },
    mounted () {
      this.getData()
    },
    methods: {
      floorToTwo (v) {
        return floorToTwo(v)
      },
      getData () {
        this.loading = true
        authFetch(process.env.VUE_APP_API_ROOT + 'admin/user-accounting/')
          .then(async response => {
            const data = await response.json()
            this.items = []
            let id = 0
            data.items.forEach(i => {
              i.id = id
              this.items.push(i)
              id++
            })
            this.total = data.total
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            this.loading = false
          })
      },
    },
  }
</script>

<style scoped>
.pre { white-space: pre-wrap; }
</style>

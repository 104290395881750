<template>
  <layout-panel>
    <v-container>
      <v-card>
        <v-card-text class="display-2 text-center mt-5 primary white--text">
          Route Group Production
        </v-card-text>
      </v-card>
    </v-container>
    <task-form-steps />
  </layout-panel>
</template>

<script>
  import TaskFormSteps from '@/components/TaskFormGroupSteps'

  export default {
    components: { TaskFormSteps },
  }
</script>

<template>
  <div>
    <v-navigation-drawer
      v-model="primaryDrawer"
      width="300"
      style="max-height: calc(100% - 52px);"
      app
    >
      <div class="py-3" style="text-align: center">
        <img :src="logo" alt="pvtltd" width="200px">
      </div>
      <template v-if="isDialUser()">
        <template v-for="(links, title) in items">
          <v-list :key="title + 'a'" dark>
            <v-list-item
              flat
              active-class="navigationHeaderBackground"
              :input-value="true"
            >
              <v-list-item-content>
                <v-list-item-title class="title">{{ title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list :key="title + 'b'" dense nav>
            <v-list-item
              v-for="(link, key) in links"
              :key="title + link.to + key"
              link
              @click="goTo(link)"
            >
              <v-list-item-icon>
                <v-icon>{{ link.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ link.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </template>
      <template v-if="isAdmin()">
        <template v-for="(links, title) in itemsAdmin">
          <v-list :key="title + 'admin'" dark>
            <v-list-item
              flat
              active-class="navigationHeaderBackground"
              :input-value="true"
            >
              <v-list-item-content>
                <v-list-item-title class="title">{{ title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list :key="title + 'badmin'" dense nav>
            <v-list-item
              v-for="(link, key) in links"
              :key="title + link.to + key"
              link
              @click="goTo(link)"
            >
              <v-list-item-icon>
                <v-icon>{{ link.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ link.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </template>
    </v-navigation-drawer>
    <v-app-bar app color="headerBackground" dark>
      <v-app-bar-nav-icon @click.stop="primaryDrawer = !primaryDrawer" />
      <v-toolbar-title>
        <strong>Rubix</strong>
      </v-toolbar-title>
      <v-spacer />
      {{ user }}
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            class="ml-4"
            fab
            small
            color="primary"
            v-on="on"
            @click="logout()"
          >
            <v-icon>mdi-power</v-icon>
          </v-btn>
        </template>
        Logout
      </v-tooltip>
    </v-app-bar>
    <v-main>
      <v-container ref="mainContainer" class="fill-height" fluid>
        <v-row align="center">
          <v-col ref="main-content" style="padding-bottom: 100px;">
            <div ref="marquee" class="marquee">
              <span ref="marqueeContent" :style="tickerStyle">
                {{ ticker }}
              </span>
              <span :style="tickerStyleSecondary" aria-hidden="true">
                {{ ticker }}
              </span>
            </div>
            <slot />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
  import { authFetch } from '@/plugins/authFetch'

  export default {
    data: () => ({
      hrvRouteAllowedUsers: ['schoua'],
      primaryDrawer: null,
      chat: false,
      itemsAdmin: {
        Accounting: [
          { icon: 'calendar_today', text: 'Monthly Invoices', to: 'admin-invoice-view' },
          { icon: 'event', text: 'Daily Stats', to: 'admin-stats' },
          // { icon: 'money_off', text: 'User Payments', to: 'admin-user-payments' }
          { icon: 'money_off', text: 'Payment Requests', to: 'admin-payment-requests' },
          { icon: 'mdi-account-alert', text: 'User Accounting', to: 'admin-user-accounting' },
        ],
        System: [
          { icon: 'ring_volume', text: 'Global Live Calls', to: 'admin-incoming-calls' },
          { icon: 'speaker', text: 'IVR Stats', to: 'admin-ivr-stats' },
          { icon: 'cake', text: 'Running Tasks', to: 'admin-tasks-running' },
          { icon: 'mdi-alert-box', text: 'Global Message', to: 'admin-global-messages' },
          { icon: 'mdi-run-fast', text: 'User Activity', to: 'admin-user-activity' },
          { icon: 'mdi-test-tube', text: 'I-Tests', to: 'admin-itests' },
        ],
        'User Review': [
          { icon: 'dialer_sip', text: 'IVR SIP', to: 'admin-ivr-sip' },
          { icon: 'mdi-upload', text: 'IVR Uploads', to: 'admin-ivr-upload' },
          { icon: 'headset_mic', text: 'Support Tickets', to: 'admin-support-tickets' },
          { icon: 'mdi-account-plus', text: 'Pending Users', to: 'admin-users-pending' },
        ],
        'High Rate VoIP': [
          { icon: 'money_off', text: 'Payment Requests', to: 'admin-hrv-payment-requests' },
          { icon: 'mdi-earth', text: 'Routes', to: 'admin-hrv-routes' },
          { icon: 'mdi-account-multiple', text: 'Users', to: 'admin-hrv-users' },
        ],
        'User Settings': [
          { icon: 'mdi-settings', text: 'Settings / Password', to: 'user-settings' },
          { icon: 'mdi-account-multiple-check', text: 'Manage Sessions', to: 'sessions' },
        ],
      },
      tickerTimer: false,
      tickerStyle: '',
      tickerStyleSecondary: '',
      ticker: '',
    }),
    computed: {
      user () {
        return this.$store.state.username
      },
      items () {
        const r = {
          Dashboard: [
            { icon: 'dashboard', text: 'Home', to: 'dashboard' },
            { icon: 'ring_volume', text: 'My Live Calls', to: 'user-incoming-calls' },
            { icon: 'trending_up', text: 'Popular Tasks', to: 'tasks-running-pairs' },
            { icon: 'mdi-history', text: 'Historic Popular Tasks', to: 'tasks-historic-pairs' },
            { icon: 'headset_mic', text: 'Submit Ticket', to: 'support-ticket' },
          ],
          'Rubix Control': [
            { icon: 'mdi-robot', text: 'Rubix Robot', to: 'task-templates-auto' },
          ],
          'Task Analysis': [
            { icon: 'cake', text: 'Running Tasks', to: 'tasks' },
            { icon: 'visibility_off', text: 'Previous Tasks', to: 'tasks-hidden' },
          ],
          'Simple Production': [
            { icon: 'hot_tub', text: 'Launch Popular Task', to: 'tasks-running-pairs' },
          ],
          'Access Finding Tools': [
            { icon: 'mdi-animation-play', text: 'All-In-One', to: 'task-aio' },
            { icon: 'mdi-animation-play-outline', text: 'Historic All-In-One', to: 'task-aio-historic' },
            { icon: 'mdi-expand-all', text: 'Quick Launch All-In-One', to: 'task-aio-quick' },
            { icon: 'offline_bolt', text: 'Search Previous Access', to: 'task-new-auto-smart' },
            { icon: 'brightness_auto', text: 'Find New Access', to: 'task-new-auto' },
            { icon: 'rss_feed', text: 'Automatic Access Test', to: 'task-new-route' },
          ],
          'Access Production': [
            { icon: 'add_circle', text: 'Generic Access Production', to: 'task-new' },
            { icon: 'add_circle_outline', text: 'Advanced Access Production', to: 'task-new-production' },
            { icon: 'mdi-plus-box-multiple', text: 'Route Group Production', to: 'task-groups-new' },
          ],
          'Revenue Booster': [
            { icon: 'mdi-cash-plus', text: 'New Task', to: 'revboost-new' },
            { icon: 'mdi-cash-multiple', text: 'View Tasks', to: 'revboost' },
          ],
          'External Tasks': [
            { icon: 'phone_disabled', text: 'External Traffic Task', to: 'task-new-external' },
          ],
          'Billing Reports': [
            { icon: 'attach_money', text: 'Billing Statement', to: 'billing' },
            { icon: 'dialpad', text: 'My Number Rates', to: 'user-numbers' },
            { icon: 'attach_money', text: 'Request Payment', to: 'payment-requests' },
          ],
          'Route Management': [
            { icon: 'dialer_sip', text: 'My SIP Routes', to: 'routes' },
            { icon: 'assignment_turned_in', text: 'CLI Verification', to: 'cli-test' },
            { icon: 'mdi-phone-log', text: 'Route Groups', to: 'route-groups' },
          ],
          'Access Reports': [
            { icon: 'people_alt', text: 'General Access Report', to: 'pairs' },
            { icon: 'description', text: 'Entire Access Report', to: 'access' },
            { icon: 'phonelink_ring', text: 'Last 1000 Calls', to: 'cdr-recent' },
          ],
          'User Settings': [
            { icon: 'mdi-settings', text: 'Profile Management', to: 'user-settings' },
            { icon: 'mdi-account-multiple-check', text: 'View My Sessions', to: 'sessions' },
            { icon: 'mdi-ballot', text: 'Default Task Settings', to: 'task-templates-default' },
          ],
          CDRs: [
            { icon: 'phone', text: 'Inbound CDR', to: 'cdr-incoming' },
            // If modified, make sure to change "r['CDRs'] = r['CDRs'].slice(0, 1)" below
            { icon: 'phone', text: 'Outbound CDR', to: 'cdr-outgoing' },
          ],
          'Arbitrage Tools': [
            { icon: 'local_atm', text: 'Arbitrage with Rate Sheet', to: 'arbitrage-compare' },
            { icon: 'local_atm', text: 'Reverse Arbitrage', to: 'task-reverse-new' },
          ],
          'IVR Management': [
            { icon: 'mdi-upload', text: 'Upload IVR', to: 'ivr-upload' },
            { icon: 'dialer_sip', text: 'Custom SIP Server', to: 'ivr-sip' },
          ],
          'HLR Tools': [
            { icon: 'done', text: 'Create HLR Task', to: 'hlr-new' },
            { icon: 'done_all', text: 'HLR Tasks', to: 'hlr' },
          ],
        }
        if (['alan', 'jim', 'schoua', 'orbit', 'arik', 'ryan'].includes(this.$store.state.username)) {
          r['Access Reports'].push(
            { icon: 'mdi-treasure-chest', text: 'Treasure Map', to: 'pairs-hourly' }
          )
        }
        if (this.$store.state.permission & 12) {
          if (this.$store.state.permission & 8) {
            r.Dashboard = [r.Dashboard[0], r.Dashboard[1], r.Dashboard[4]]
            delete r['Simple Production']
            delete r['Access Finding Tools']
            delete r['Access Production']
            delete r['Route Management']
            delete r['Arbitrage Tools']
            delete r['HLR Tools']
            delete r['Revenue Booster']
            delete r['Rubix Control']
            r.CDRs = r.CDRs.slice(0, 1)
          }
          delete r['HLR Tools']
          if (this.$store.state.permission & 4) {
            r.CDRs.push({ icon: 'archive', text: 'Download Task CSVs', to: 'cdr-download' })
          }
        }
        if (this.hrvRouteAllowedUsers.includes(this.$store.state.username) === true) {
          r['Route Management'].push(
            { icon: 'mdi-earth', text: 'High Rate VoIP', to: 'admin-hrv-routes' },
            { icon: 'mdi-account-multiple', text: 'HRV Users', to: 'admin-hrv-users' }
          )
        }

        return r
      },
      logo () {
        if (this.$vuetify.theme.dark === true) {
          return require('@/assets/logo.png')
        }
        return require('@/assets/logo_light.png')
      },
    },
    created () {
      this.getTicker()
      this.tickerTimer = setInterval(() => {
        this.getTicker()
      }, 300000)
    },
    beforeDestroy () {
      if (this.tickerTimer) {
        clearInterval(this.tickerTimer)
      }
    },
    methods: {
      goTo (link) {
        if (Object.prototype.hasOwnProperty.call(link, 'external')) {
          window.open(link.external, '_blank')
        } else {
          this.$router.push({ name: link.to })
        }
      },
      logout () {
        this.$router.push({ name: 'login', params: { logout: true } })
      },
      isDialUser () {
        return this.$store.state.permission & 14
      },
      isAdmin () {
        return this.$store.state.permission & 1
      },
      updateTicker () {
        const c = this.$refs.mainContainer.clientWidth
        const w = this.$refs.marqueeContent.clientWidth
        if (w > c) {
          const s = {
            animation: `marquee ${Math.floor(w / 50)}s linear infinite`,
            'margin-left': '40px',
          }
          this.tickerStyle = s
          this.tickerStyleSecondary = s
        } else {
          this.tickerStyleSecondary = { display: 'none' }
          this.tickerStyle = {
            position: 'absolute',
            width: '100%',
            height: '100%',
            transform: 'translateX(100%)',
            animation: `marqueeSingle ${Math.floor(w / 25)}s linear infinite`,
          }
        }
      },
      getTicker () {
        if (!this.$store.state.permission) {
          return
        }

        authFetch(`${process.env.VUE_APP_API_ROOT}ticker/`)
          .then(async response => {
            this.ticker = await response.json()
            this.$nextTick(() => {
              this.updateTicker()
            })
          })
          .catch(error => {
            console.log(error)
          })
      },
    },
  }
</script>

<style>
.marquee {
  overflow: hidden;
  white-space: nowrap;
  position: relative;
}
.marquee::before {
  content: '\00a0';
}

.marquee span {
  display: inline-block;
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  } 100% {
    transform: translate(-100%, 0);
  }
}

@keyframes marqueeSingle {
  0% {
    transform: translate(100%, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
</style>

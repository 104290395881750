import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

import PageNotFound from '@/views/PageNotFound'
import Login from '@/views/Login'
// import Register from '@/views/Register'
import Tasks from '@/views/Tasks'
import TasksHidden from '@/views/TasksHidden'
import TaskNew from '@/views/TaskNew'
import TaskClone from '@/views/TaskClone'
import TaskCloneAuto from '@/views/TaskCloneAuto'
import Task from '@/views/Task'
import TaskNewAuto from '@/views/TaskNewAuto'
import Billing from '@/views/Billing'
import Access from '@/views/Access'
import CDRIncoming from '@/views/CDRIncoming'
import CDROutgoing from '@/views/CDROutgoing'
import CDRDownload from '@/views/CDRDownload'
import Dashboard from '@/views/Dashboard'
import Pairs from '@/views/Pairs'
import Routes from '@/views/Routes'
import ArbitrageCompare from '@/views/ArbitrageCompare'
import ArbitrageCompareResult from '@/views/ArbitrageCompareResult'
import HLRNew from '@/views/HLRNew'
import HLRs from '@/views/HLRs'
import TaskNewRoute from '@/views/TaskNewRoute'
import RouteTasks from '@/views/RouteTasks'
import RoutesConfirmed from '@/views/RoutesConfirmed'
import CLITest from '@/views/CLITest'
import CLITestResult from '@/views/CLITestResult'
import UserActiveIncoming from '@/views/UserActiveIncoming'
import UserNumbers from '@/views/UserNumbers'
import TargetsCountries from '@/views/TargetsCountries'
import IVRSIP from '@/views/IVRSIP'
import SupportTicket from '@/views/SupportTicket'
import AdminSupportTickets from '@/views/AdminSupportTickets'
import UserSettings from '@/views/UserSettings'
import TasksRunningPairs from '@/views/TasksRunningPairs'
import TasksHistoricPairs from '@/views/TasksHistoricPairs'
import TaskNewFromPairs from '@/views/TaskNewFromPairs'
import CDRRecent from '@/views/CDRRecent'
import PaymentRequests from '@/views/PaymentRequests'
import Sessions from '@/views/Sessions'
import TandC from '@/views/TandC'
import Privacy from '@/views/Privacy'
import IVRUpload from '@/views/IVRUpload'
// import Contact from '@/views/Contact'
import Home from '@/views/Home'
import TaskDefault from '@/views/TaskDefault'
import RevBoostNew from '@/views/RevBoostNew'
import RevBoostList from '@/views/RevBoostList'
import RevBoostTask from '@/views/RevBoostTask'
import TaskAuto from '@/views/TaskAuto'
import PairsHourly from '@/views/PairsHourly'
import TaskAIO from '@/views/TaskAIO'
import TaskAIOQuick from '@/views/TaskAIOQuick'
import TaskNewReverse from '@/views/TaskNewReverse'
import RouteGroups from '@/views/RouteGroups'
import TaskNewGroups from '@/views/TaskNewGroups'
import RouteGroup from '@/views/RouteGroup'
import Route from '@/views/Route'

import AdminInvoiceView from '@/views/AdminInvoiceView'
import AdminDailyStats from '@/views/AdminDailyStats'
import AdminActiveIncoming from '@/views/AdminActiveIncoming'
import AdminIVRStats from '@/views/AdminIVRStats'
import AdminRunningTasks from '@/views/AdminRunningTasks'
import AdminPaymentRequests from '@/views/AdminPaymentRequests'
import AdminPendingUsers from '@/views/AdminPendingUsers'
import AdminIVRSIP from '@/views/AdminIVRSIP'
import AdminIVRUpload from '@/views/AdminIVRUpload'
import AdminUserAccounting from '@/views/AdminUserAccounting'
import AdminHRVPaymentRequests from '@/views/AdminHRVPaymentRequests'
import AdminHRVRoutes from '@/views/AdminHRVRoutes'
import AdminHRVUsers from '@/views/AdminHRVUsers'
import AdminGlobalMessages from '@/views/AdminGlobalMessages'
import AdminUserActivity from '@/views/AdminUserActivity'
import AdminITests from '@/views/AdminITests'

Vue.use(Router)

const publicRoutes = [
  'login',
  'register',
  'terms',
  'privacy',
  'contact',
  'home',
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard,
    },
    {
      path: '/tasks',
      name: 'tasks',
      component: Tasks,
    },
    {
      path: '/tasks/hidden',
      name: 'tasks-hidden',
      component: TasksHidden,
    },
    {
      path: '/task/view/:taskId?',
      name: 'task',
      component: Task,
      props: route => {
        const taskId = route.params.taskId
        return {
          taskId:
            taskId === undefined || isNaN(taskId) ? 0 : parseInt(taskId, 10),
        }
      },
    },
    {
      path: '/task/new',
      name: 'task-new',
      component: TaskNew,
    },
    {
      path: '/task/clone/auto/:taskId?',
      name: 'task-clone-auto',
      component: TaskCloneAuto,
      props: route => {
        const taskId = route.params.taskId
        return {
          taskId:
            taskId === undefined || isNaN(taskId) ? 0 : parseInt(taskId, 10),
        }
      },
    },
    {
      path: '/task/clone/:taskId?',
      name: 'task-clone',
      component: TaskClone,
      props: route => {
        const taskId = route.params.taskId
        return {
          taskId:
            taskId === undefined || isNaN(taskId) ? 0 : parseInt(taskId, 10),
        }
      },
    },
    {
      path: '/task/new/production',
      name: 'task-new-production',
      component: TaskNew,
      props: { type: 'finalized' },
    },
    {
      path: '/task/new/external',
      name: 'task-new-external',
      component: TaskNew,
      props: { external: true },
    },
    {
      path: '/task/new/auto',
      name: 'task-new-auto',
      component: TaskNewAuto,
    },
    {
      path: '/task/new/auto-production',
      name: 'task-new-auto-production',
      component: TaskNewAuto,
      props: {
        type: 'auto-production',
        pageHeader: 'New Auto Production Task',
      },
    },
    {
      path: '/task/new/auto-smart',
      name: 'task-new-auto-smart',
      component: TaskNewAuto,
      props: {
        type: 'auto-smart',
        pageHeader: 'Search Previous Access',
      },
    },
    {
      path: '/pairs',
      name: 'pairs',
      component: Pairs,
    },
    {
      path: '/pairs/hourly',
      name: 'pairs-hourly',
      component: PairsHourly,
    },
    {
      path: '/billing/',
      name: 'billing',
      component: Billing,
      props: route => {
        return {
          startProp: route.query.start || '',
          endProp: route.query.end || '',
        }
      },
    },
    {
      path: '/access-report/',
      name: 'access',
      component: Access,
      props: route => {
        return {
          startProp: route.query.start || '',
          endProp: route.query.end || '',
        }
      },
    },
    {
      path: '/cdr/incoming/',
      name: 'cdr-incoming',
      component: CDRIncoming,
      props: route => {
        return {
          startProp: route.query.start || '',
          endProp: route.query.end || '',
        }
      },
    },
    {
      path: '/cdr/outgoing/',
      name: 'cdr-outgoing',
      component: CDROutgoing,
      props: route => {
        return {
          startProp: route.query.start || '',
          endProp: route.query.end || '',
        }
      },
    },
    {
      path: '/cdr/download/',
      name: 'cdr-download',
      component: CDRDownload,
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      props: true,
    },
    {
      path: '/register',
      name: 'register',
      // component: Register,
      // props: true,
      redirect: 'login',
    },
    {
      path: '/routes',
      name: 'routes',
      component: Routes,
    },
    {
      path: '/arbitrage/compare',
      name: 'arbitrage-compare',
      component: ArbitrageCompare,
    },
    {
      path: '/arbitrage/compare/:taskId?',
      name: 'arbitrage-compare-result',
      component: ArbitrageCompareResult,
      props: route => {
        const taskId = route.params.taskId
        return {
          taskId:
            taskId === undefined || isNaN(taskId) ? 0 : parseInt(taskId, 10),
        }
      },
    },
    {
      path: '/cli/test',
      name: 'cli-test',
      component: CLITest,
    },
    {
      path: '/cli/test/:taskId?',
      name: 'cli-test-result',
      component: CLITestResult,
      props: route => {
        const taskId = route.params.taskId
        return {
          taskId:
            taskId === undefined || isNaN(taskId) ? 0 : parseInt(taskId, 10),
        }
      },
    },
    {
      path: '/hlr/new',
      name: 'hlr-new',
      component: HLRNew,
    },
    {
      path: '/hlr',
      name: 'hlr',
      component: HLRs,
    },
    {
      path: '/task/new/route',
      name: 'task-new-route',
      component: TaskNewRoute,
    },
    {
      path: '/task/view/route/:taskId?',
      name: 'task-route',
      component: RouteTasks,
      props: route => {
        const taskId = route.params.taskId
        return {
          taskId:
            taskId === undefined || isNaN(taskId) ? 0 : parseInt(taskId, 10),
        }
      },
    },
    {
      path: '/routes-confirmed',
      name: 'routes-confirmed',
      component: RoutesConfirmed,
    },
    {
      path: '/user/incoming-calls',
      name: 'user-incoming-calls',
      component: UserActiveIncoming,
    },
    {
      path: '/user/numbers',
      name: 'user-numbers',
      component: UserNumbers,
    },
    {
      path: '/targets/countries',
      name: 'targets-countries',
      component: TargetsCountries,
    },
    {
      path: '/ivr/sip',
      name: 'ivr-sip',
      component: IVRSIP,
    },
    {
      path: '/ivr/upload',
      name: 'ivr-upload',
      component: IVRUpload,
    },
    {
      path: '/support/ticket',
      name: 'support-ticket',
      component: SupportTicket,
    },
    {
      path: '/user/settings',
      name: 'user-settings',
      component: UserSettings,
    },
    {
      path: '/tasks/running/pairs',
      name: 'tasks-running-pairs',
      component: TasksRunningPairs,
    },
    {
      path: '/tasks/historic/pairs',
      name: 'tasks-historic-pairs',
      component: TasksHistoricPairs,
    },
    {
      path: '/task/templates/default/',
      name: 'task-templates-default',
      component: TaskDefault,
    },
    {
      path: '/task/templates/auto/',
      name: 'task-templates-auto',
      component: TaskAuto,
    },
    {
      path: '/task/new-from-pairs/:taskId',
      name: 'task-new-from-pairs',
      component: TaskNewFromPairs,
      props: route => {
        const taskId = route.params.taskId
        return {
          taskId:
            taskId === undefined || isNaN(taskId) ? 0 : parseInt(taskId, 10),
        }
      },
    },
    {
      path: '/task/new-from-pairs/',
      name: 'task-new-from-pairs-query',
      component: TaskNewFromPairs,
      props: route => {
        return {
          source: route.query.source || '',
          destination: route.query.destination || '',
          carrierAll: parseInt(route.query.carrierAll) || 0,
        }
      },
    },
    {
      path: '/cdr/recent',
      name: 'cdr-recent',
      component: CDRRecent,
    },
    {
      path: '/payment-requests',
      name: 'payment-requests',
      component: PaymentRequests,
    },
    {
      path: '/tasks/rev-boost/new',
      name: 'revboost-new',
      component: RevBoostNew,
    },
    {
      path: '/tasks/rev-boost',
      name: 'revboost',
      component: RevBoostList,
    },
    {
      path: '/tasks/rev-boost/:taskId?',
      name: 'revboost-task',
      component: RevBoostTask,
      props: route => {
        const taskId = route.params.taskId
        return {
          taskId:
            taskId === undefined || isNaN(taskId) ? 0 : parseInt(taskId, 10),
        }
      },
    },
    {
      path: '/tasks/aio',
      name: 'task-aio',
      component: TaskAIO,
    },
    {
      path: '/tasks/aio/historic',
      name: 'task-aio-historic',
      component: TaskAIO,
      props: { historic: true },
    },
    {
      path: '/tasks/aio/quick',
      name: 'task-aio-quick',
      component: TaskAIOQuick,
    },
    {
      path: '/reverse-arbitrage',
      name: 'task-reverse-new',
      component: TaskNewReverse,
    },
    {
      path: '/route-groups',
      name: 'route-groups',
      component: RouteGroups,
    },
    {
      path: '/route-group/:id',
      name: 'route-group',
      component: RouteGroup,
      props: router => ({ id: parseInt(router.params.id, 10) }),
    },
    {
      path: '/route/:id',
      name: 'route',
      component: Route,
      props: router => ({ id: parseInt(router.params.id, 10) }),
    },
    {
      path: '/task/groups/new',
      name: 'task-groups-new',
      component: TaskNewGroups,
    },
    {
      path: '/sessions',
      name: 'sessions',
      component: Sessions,
    },
    {
      path: '/terms',
      name: 'terms',
      component: TandC,
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: Privacy,
    },
    {
      path: '/contact',
      name: 'contact',
      // component: Contact,
      redirect: 'login',
    },
    {
      path: '/',
      name: 'home',
      component: Home,
      // redirect: 'login',
    },
    {
      path: '/404',
      alias: '*',
      name: '404',
      component: PageNotFound,
    },
    // {
    //   path: '/about',
    //   name: 'about',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () =>
    //     import(/* webpackChunkName: 'about' */ './views/About.vue')
    // }

    {
      path: '/admin/invoice',
      name: 'admin-invoice-view',
      component: AdminInvoiceView,
      props: route => {
        return {
          yearProp: parseInt(route.query.year, 10) || 0,
          monthProp: parseInt(route.query.month, 10) || 0,
        }
      },
    },
    {
      path: '/admin/stats',
      name: 'admin-stats',
      component: AdminDailyStats,
      props: route => {
        return { dateProp: route.query.date || '' }
      },
    },
    {
      path: '/admin/incoming-calls',
      name: 'admin-incoming-calls',
      component: AdminActiveIncoming,
    },
    {
      path: '/admin/stats/ivr',
      name: 'admin-ivr-stats',
      component: AdminIVRStats,
    },
    {
      path: '/admin/ivr/sip',
      name: 'admin-ivr-sip',
      component: AdminIVRSIP,
    },
    {
      path: '/admin/support/tickets',
      name: 'admin-support-tickets',
      component: AdminSupportTickets,
    },
    {
      path: '/admin/tasks/running',
      name: 'admin-tasks-running',
      component: AdminRunningTasks,
    },
    {
      path: '/admin/payment-requests',
      name: 'admin-payment-requests',
      component: AdminPaymentRequests,
    },
    {
      path: '/admin/users/pending',
      name: 'admin-users-pending',
      component: AdminPendingUsers,
    },
    {
      path: '/admin/ivr/upload',
      name: 'admin-ivr-upload',
      component: AdminIVRUpload,
    },
    {
      path: '/admin/user-accounting',
      name: 'admin-user-accounting',
      component: AdminUserAccounting,
    },
    {
      path: '/admin/hrv/payment-requests',
      name: 'admin-hrv-payment-requests',
      component: AdminHRVPaymentRequests,
    },
    {
      path: '/admin/hrv/routes',
      name: 'admin-hrv-routes',
      component: AdminHRVRoutes,
    },
    {
      path: '/admin/hrv/users',
      name: 'admin-hrv-users',
      component: AdminHRVUsers,
    },
    {
      path: '/admin/global-messages',
      name: 'admin-global-messages',
      component: AdminGlobalMessages,
    },
    {
      path: '/admin/users/activity',
      name: 'admin-user-activity',
      component: AdminUserActivity,
    },
    {
      path: '/admin/i-tests',
      name: 'admin-itests',
      component: AdminITests,
    },
  ],
})

router.beforeEach(async (to, from, next) => {
  // Did someone try to redirect to a bad page?
  if (router.resolve({ name: to.name }).resolved.matched.length < 1) {
    next({ name: '404' })
  }
  // Do we need to populate the user's info?
  try {
    if (
      store.state.sessionId &&
      (!store.state.username || !store.state.permission) &&
      !(await store.dispatch('populateUserDetails', store.state.sessionId))
    ) {
      await store.dispatch('clearSessionId')
    }
  } catch (error) {
    console.log(error)
    await store.dispatch('clearSessionId')
  }
  // Force login
  if (publicRoutes.includes(to.name) === false && !store.state.sessionId) {
    next({ name: 'login' })
  } else {
    next()
  }
})

export default router

<template>
  <layout-panel>
    <v-container>
      <v-card>
        <v-card-text class="display-2 text-center mt-5 primary white--text">
          New HLR Batch
        </v-card-text>
      </v-card>
    </v-container>
    <v-form ref="form" @submit.prevent="submitWithLookbackCheck">
      <v-container>
        <v-card>
          <v-container class="m-3">
            <v-row>
              <v-col cols="3">
                <span class="title">Destination Selection</span>
              </v-col>
              <v-col cols="3">
                <v-select
                  v-model="destinationType"
                  :items="destinationTypes"
                  label="Destination Selection Method"
                />
              </v-col>
            </v-row>
            <v-row v-if="destinationType === 'whitelist'">
              <v-col cols="2">
                <v-select
                  v-model="destinationLookbackType"
                  :items="destinationLookbackTypes"
                  label="CDR Lookback Method"
                />
              </v-col>
              <template v-if="destinationLookbackType === 'time'">
                <v-col cols="1">
                  <v-text-field
                    v-model="destinationLookbackTimeNum"
                    reverse
                  />
                </v-col>
                <v-col cols="2">
                  <v-select
                    v-model="destinationLookbackTimePeriod"
                    :items="destinationLookbackPeriods"
                  />
                </v-col>
              </template>
              <template v-if="destinationLookbackType === 'rows'">
                <v-col cols="3">
                  <v-text-field
                    v-model="destinationLookbackData"
                    :rules="[rules.required, rules.positiveNumber]"
                    type="number"
                    label="Amount"
                  />
                </v-col>
              </template>
              <v-col cols="12">
                <v-btn color="primary" @click="addWhitelist()">Add Filter</v-btn>
              </v-col>
              <template v-for="(child, index) in whitelists">
                <component
                  :is="child.component"
                  :key="index + 'wl'"
                  v-model="whitelistFilters[index]"
                  v-bind="{
                    index: index,
                    errors: child.errors,
                    lookbackType: destinationLookbackType,
                    lookbackData: destinationLookbackData,
                  }"
                  :multiple-randoms="false"
                  :use-rates="false"
                  :types-override="['carrier']"
                  @removeWhitelist="removeWhitelist"
                />
              </template>
            </v-row>
          </v-container>
          <v-container>
            <v-row>
              <v-col cols="12">
                <span class="title">Batch Options</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  v-model="hlrAmount"
                  :rules="[rules.required, rules.positiveNumber]"
                  required
                  label="Numbers to generate"
                  type="number"
                  hint="Maximum 10,000,000"
                  min="1"
                  max="10000000"
                  steps="10000"
                />
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="hlrApiKey"
                  :rules="[rules.required]"
                  required
                  label="HLR API Key"
                />
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="hlrPassword"
                  :rules="[rules.required]"
                  required
                  label="HLR Password"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-container>
      <v-container>
        <v-card class="pa-3">
          <v-btn
            color="primary"
            large
            block
            type="submit"
            :disabled="submitting"
            :loading="submitting"
          >
            Start Batch
          </v-btn>
        </v-card>
      </v-container>
      <v-dialog v-model="errorDialog" max-width="500">
        <v-card>
          <v-card-title class="headline" color="error">Validation Error</v-card-title>
          <v-card-text>
            <p>You made mistakes on the form that must be fixed.</p>
            <p v-for="(error, key) in errorDialogText" :key="key">{{ error }}</p>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" @click="errorDialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="lookbackWarning" persistent max-width="500">
        <v-card>
          <v-card-title class="headline">CDR Lookback Warning</v-card-title>
          <v-card-text>
            <p>You have chosen less than 30 days for CDR lookback. This can be dangerous and cause
              unexpected and unpredicatble behaviour</p>
            <p>Are you sure you want to continue?</p>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn color="error" @click="lookbackWarning = false">Cancel</v-btn>
            <v-btn color="primary" @click="submit">Start Task</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
  </layout-panel>
</template>

<script>
  import WhitelistRow from '@/components/WhitelistRow'
  import * as rules from '@/plugins/rules'
  import { commaSeparatedNumbersToList } from '@/plugins/common'
  import { authFetch } from '@/plugins/authFetch'

  export default {
    data () {
      return {
        destinationType: 'whitelist',
        destinationLookbackType: 'time',
        destinationLookbackData: '3 year',
        destinationTypes: [
          { text: 'Whitelist', value: 'whitelist' },
        ],
        destinationLookbackTypes: [
          { text: 'Time', value: 'time' },
          { text: 'Rows', value: 'rows' },
        ],
        destinationLookbackPeriods: [
          { text: 'Hours', value: 'hour' },
          { text: 'Days', value: 'day' },
          { text: 'Months', value: 'month' },
          { text: 'Years', value: 'year' },
        ],
        whitelists: {},
        whitelistKey: 0,
        whitelistFilters: {},
        hlrAmount: null,
        hlrApiKey: null,
        hlrPassword: null,
        rules: rules,
        submitting: false,
        forceRandoms: false,
        lookbackWarning: false,
        errorDialog: false,
        errorDialogText: [],
      }
    },
    computed: {
      destinationLookbackTimeNum: {
        get () {
          if (typeof this.destinationLookbackData === 'undefined') {
            return 1
          }
          const parts = this.destinationLookbackData.split(' ')
          if (isNaN(parts[0])) {
            return 1
          }
          return parseInt(parts[0], 10)
        },
        set (value) {
          this.destinationLookbackData = `${value} ${this.destinationLookbackTimePeriod}`
        },
      },
      destinationLookbackTimePeriod: {
        get () {
          if (typeof this.destinationLookbackData === 'undefined') {
            return 'day'
          }
          const parts = this.destinationLookbackData.split(' ')
          if (parts.length < 2) {
            return 'day'
          }
          return parts[1]
        },
        set (value) {
          this.destinationLookbackData = `${this.destinationLookbackTimeNum} ${value}`
        },
      },
    },
    mounted () {
      this.addWhitelist()
    },
    methods: {
      addWhitelist (key) {
        if (key !== undefined) {
          this.whitelistKey = key
        }
        this.$set(this.whitelists, this.whitelistKey, {
          component: WhitelistRow,
          errors: {
            stringError: '',
            lengthsError: '',
            randomDigitsError: '',
            rateError: '',
          },
        })
        this.whitelistKey++
      },
      removeWhitelist (index) {
        if (Object.prototype.hasOwnProperty.call(this.whitelists, index)) {
          this.$delete(this.whitelists, index)
        }
        if (Object.prototype.hasOwnProperty.call(this.whitelistFilters, index)) {
          this.$delete(this.whitelistFilters, index)
        }
      },
      validateAndGetFilters () {
        const filters = []
        let valid = true
        const strings = []
        for (const [key, filter] of Object.entries(this.whitelistFilters)) {
          if (!filter.string) {
            this.whitelists[key].errors.stringError = 'Required'
            valid = false
          }
          if (!filter.lengths) {
            this.whitelists[key].errors.lengthsError = 'Required'
            valid = false
          }
          if (strings.includes(filter.string)) {
            this.whitelists[key].errors.stringError = 'Duplicate'
            valid = false
          }
          strings.push(filter.string)

          let randoms = []
          if (filter.randomDigits) {
            randoms = [Math.max(...commaSeparatedNumbersToList(filter.randomDigits))]
          }

          if (valid) {
            filters.push({
              string: filter.string,
              lengths: commaSeparatedNumbersToList(filter.lengths),
              randomDigits: randoms,
            })
          } else {
            return false
          }
        }
        return filters
      },
      submitWithLookbackCheck () {
        if (!this.$refs.form.validate()) {
          return false
        }
        if (this.destinationType === 'whitelist' &&
          this.destinationLookbackType === 'time' &&
          this.destinationLookbackTimePeriod === 'day' &&
          this.destinationLookbackTimeNum <= 30) {
          this.lookbackWarning = true
          return false
        }
        this.submit()
      },
      submit () {
        this.lookbackWarning = false
        this.errorDialogText = []
        let valid = true

        let hlrAmount = parseInt(this.hlrAmount, 10)
        if (hlrAmount > 10000000) {
          hlrAmount = 10000000
          this.hlrAmount = 10000000
        }

        let filters = []
        if (this.destinationType === 'whitelist') {
          if (Object.keys(this.whitelists).length < 1) {
            this.errorDialogText.push('Must include at least one destination filter')
          }
          filters = this.validateAndGetFilters()
          if (filters === false) {
            valid = false
          }
        }

        if (!this.$refs.form.validate() || !valid) {
          this.submitting = false
          return false
        }

        const postData = {
          destinationType: this.destinationType,
          destinationLookbackType: this.destinationLookbackType,
          destinationLookbackData: this.destinationLookbackData,
          destinationFilters: filters,
          destinationNumbers: [],
          hlrAmount: hlrAmount,
          hlrApiKey: this.hlrApiKey,
          hlrPassword: this.hlrPassword,
        }

        this.submitting = true
        const method = 'POST'

        authFetch(process.env.VUE_APP_API_ROOT + 'hlr/', {
          method: method,
          body: JSON.stringify(postData),
        })
          .then(async response => {
            const data = await response.json()
            if (Object.prototype.hasOwnProperty.call(data, 'result') && data.result === 'success') {
              this.$router.push({ name: 'hlr' })
            } else {
              this.errorDialogText = ['Received an unexpected response from the server. Not sure what to do']
              this.valid = true
            }
          })
          .catch(async error => {
            console.log(error)
            const data = await error.json()
            // Correct error response?
            if (typeof data === 'object' && Object.prototype.hasOwnProperty.call(data, 'message')) {
              // Is it an object we can loop through for feedback?
              if (typeof data.message === 'object') {
                this.errorDialogText = data.message
                // String returned, setup dialog
              } else {
                this.errorDialogText = [data.message]
              }
              // Generic error if we didn't get a proper response
            } else {
              this.errorDialogText = [`Server returned a ${error.status} status code`]
            }
            if (this.errorDialogText) {
              this.errorDialog = true
            }
          })
          .finally(() => {
            this.submitting = false
          })
      },
    },
  }
</script>

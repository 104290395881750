<template>
  <layout-panel>
    <v-container>
      <v-card>
        <v-card-text class="display-2 text-center mt-5 primary white--text">
          My SIP Routes
        </v-card-text>
      </v-card>
    </v-container>

    <route-form @completed="getRoutes" />

    <v-container>
      <v-card>
        <v-card-title class="title">Visible Routes</v-card-title>
        <v-card-text
          v-if="showRoutes === false && routesLoading === false"
          class="text-center"
          style="font-style: italic;"
        >
          No routes to show
        </v-card-text>
        <v-card-text
          v-else-if="routesLoading === true"
          class="text-center"
        >
          <v-progress-circular
            :size="35"
            :width="3"
            indeterminate
          />
        </v-card-text>
        <v-data-table
          v-else
          :items="routes"
          :headers="headers"
          :footer-props="{ itemsPerPageOptions: [50,100,{'text':'All',value:-1}] }"
          :items-per-page="50"
        >
          <template v-slot:item.default="row">
            <v-progress-circular
              v-if="defaultLoading === true"
              :size="17"
              :width="2"
              indeterminate
            />
            <v-icon v-else-if="row.item.default === true">star</v-icon>
            <v-tooltip v-else bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  @click="setDefault(row.item.id)"
                >star_border
                </v-icon>
              </template>
              <span>Set to default</span>
            </v-tooltip>
          </template>
          <template v-slot:item.actions="row">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  class="mr-2"
                  v-on="on"
                  @click="editRoute(row.item)"
                >mdi-pencil
                </v-icon>
              </template>
              <span>Edit</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  @click="setHidden(row.item.id, true)"
                >visibility_off
                </v-icon>
              </template>
              <span>Hide</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    <v-container>
      <v-card>
        <v-card-title class="title">Hidden Routes</v-card-title>
        <v-card-text
          v-if="showHiddenRoutes === false && routesHiddenLoading === false"
          class="text-center"
          style="font-style: italic;"
        >
          No routes to show
        </v-card-text>
        <v-card-text
          v-else-if="routesHiddenLoading === true"
          class="text-center"
        >
          <v-progress-circular
            :size="35"
            :width="3"
            indeterminate
          />
        </v-card-text>
        <v-data-table
          v-else
          :items="routesHidden"
          :headers="headersHidden"
          :footer-props="{ itemsPerPageOptions: [50,100,{'text':'All',value:-1}] }"
          :items-per-page="50"
        >
          <template v-slot:item.actions="row">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  @click="setHidden(row.item.id, false)"
                >visibility_on
                </v-icon>
              </template>
              <span>Show</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </layout-panel>
</template>

<script>
  import { authFetch } from '@/plugins/authFetch'
  import RouteForm from '@/components/RouteForm'

  export default {
    components: { RouteForm },
    data () {
      return {
        routes: {},
        routesHidden: {},
        routesLoading: true,
        routesHiddenLoading: true,
        headers: [
          { text: 'IP', value: 'ip' },
          { text: 'Port', value: 'port' },
          { text: 'Prefix', value: 'prefix' },
          { text: 'Company', value: 'label' },
          { text: 'Default', value: 'default' },
          { text: 'Actions', value: 'actions', sortable: false, width: 125 },
        ],
        headersHidden: [
          { text: 'IP', value: 'ip' },
          { text: 'Port', value: 'port' },
          { text: 'Prefix', value: 'prefix' },
          { text: 'Provider', value: 'label' },
          { text: 'Actions', value: 'actions', sortable: false, width: 75 },
        ],
        defaultLoading: false,
      }
    },
    computed: {
      showRoutes () {
        return Object.keys(this.routes).length !== 0
      },
      showHiddenRoutes () {
        return Object.keys(this.routesHidden).length !== 0
      },
    },
    created () {
      this.getRoutes()
      this.getHiddenRoutes()
    },
    methods: {
      setDefault (id) {
        this.defaultLoading = true
        const postData = {
          default: true,
        }
        this.updateRoute(id, postData)
          .then(response => {
            this.routes.forEach(v => {
              if (v.id === id) {
                v.default = true
              } else {
                v.default = false
              }
            })
            this.routesHidden.forEach(v => {
              if (v.id === id) {
                v.default = true
              } else {
                v.default = false
              }
            })
          })
          .finally(() => {
            this.defaultLoading = false
          })
      },
      setHidden (id, hidden) {
        this.defaultLoading = true
        const postData = {
          hidden: hidden,
        }
        this.updateRoute(id, postData)
          .then(response => {
            let from = this.routes
            let to = this.routesHidden
            if (hidden === false) {
              from = this.routesHidden
              to = this.routes
            }
            let key = false
            from.forEach((v, k) => {
              if (v.id === id) {
                key = k
                return false
              }
            })
            if (key !== false) {
              const removed = from.splice(key, 1)
              if (removed.length > 0) {
                to.push(removed[0])
              }
            }
          })
          .finally(() => {
            this.defaultLoading = false
          })
      },
      updateRoute (id, postData) {
        return new Promise((resolve, reject) => {
          authFetch(`${process.env.VUE_APP_API_ROOT}route/${id}/`, {
            method: 'PUT',
            body: JSON.stringify(postData),
          })
            .then(async response => {
              resolve(await response.json())
            })
            .catch(error => {
              reject(error)
            })
        })
      },
      getRoutes () {
        this.routes = {}
        this.routesLoading = true
        authFetch(`${process.env.VUE_APP_API_ROOT}routes/`)
          .then(async response => {
            this.routes = await response.json()
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.routesLoading = false
          })
      },
      getHiddenRoutes () {
        this.routesHidden = {}
        this.routesHiddenLoading = true
        authFetch(process.env.VUE_APP_API_ROOT + 'routes/hidden/')
          .then(async response => {
            this.routesHidden = await response.json()
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.routesHiddenLoading = false
          })
      },
      editRoute (item) {
        this.$router.push({ name: 'route', params: { id: item.id } })
      },
    },
  }
</script>

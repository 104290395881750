export function ucFirst (string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function commaSeparatedNumbersToList (string) {
  return string
    .split(',')
    .filter(block => block.trim() !== '' && !isNaN(block.trim()))
    .map(block => parseInt(block.trim(), 10))
}

export function commaSeparatedStringsToList (string) {
  return string
    .split(',')
    .filter(block => block.trim() !== '')
    .map(block => block.trim())
}

export function pipeSeparatedStringsToList (string) {
  return string
    .split('|')
    .filter(block => block.trim() !== '')
    .map(block => block.trim())
}

export function numberRangeToList (string) {
  return string
    .split('-')
    .filter(block => block.trim() !== '' && !isNaN(block.trim()))
    .map(block => parseInt(block.trim(), 10))
    .slice(0, 2)
}

export function floorToTwo (float) {
  return Math.floor(float * 100) / 100
}

export const statusMap = {
  created: 'Created',
  launching: 'Launching',
  finishing: 'Finishing',
  error: 'Error Occurred',
  pausing: 'Pausing',
  paused: 'Paused',
  killing: 'Killing',
  killed: 'Killed',
  aborting: 'Cancelling',
  aborted: 'Cancelled',
  schedule: 'Scheduled Pause',
  completed: 'Completed',
  running: 'Running',
  restarting: 'Restarting',
}

export const typeMap = {
  exploration: 'Access Production',
  refinement: 'Fine Tuning',
  finalized: 'Advanced Access Production',
  'auto-exploration': 'Find Access with Rate Sheet',
  'auto-production': 'Auto Production',
  'auto-smart': 'Smart Exploration',
  'auto-usa': 'USA Smart Exploration',
  'reverse-arbitrage': 'Reverse Arbitrage',
}

export const defaultDialServerMaximums = {
  cps: 50,
  channels: 500,
}

export function destinationsToArray (dst) {
  if (Array.isArray(dst)) {
    return dst
  }
  const result = []
  Object.values(dst).forEach(v => {
    v.forEach(c => {
      result.push(c)
    })
  })
  return result
}

export function toMoney (v) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })
  return formatter.format(Math.floor((v * 100)) / 100)
}

export const sourceGroupItems = [
  { text: 'All Number Groups', value: null },
  { text: '1', value: 1 },
  { text: '2', value: 2 },
  { text: '3', value: 3 },
  { text: '4', value: 4 },
  { text: '5', value: 5 },
]

export function translateRoute (route) {
  let prefix = 'No Prefix'
  if (route.prefix !== '') {
    prefix = `Prefix: ${route.prefix}`
  }
  if (route.label) {
    return `${route.label} - ${route.ip}:${route.port} ${prefix}`
  }
  return `${route.ip}:${route.port} ${prefix}`
}

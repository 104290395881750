<template>
  <layout-panel>
    <v-container><v-card>
      <v-card-text class="display-2 text-center mt-5 primary white--text">
        CLI Verification
        <div class="grey--text text--lighten-1 body-1">
          Use this tool to verify the caller ID quality of your routes. If a route is
          good quality, the cli will be returned for the user to see.
        </div>
      </v-card-text>
    </v-card></v-container>
    <v-form ref="form" v-model="formValid" @submit.prevent="submit">
      <v-container>
        <v-row>
          <v-col>
            <v-card>
              <v-container class="m-3">
                <v-row>
                  <v-col cols="12">
                    <v-select
                      v-if="routesLoading === false"
                      v-model="routeId"
                      label="Route"
                      :items="routesItems"
                      :rules="[rules.required, rules.positiveNumber]"
                    />
                    <v-progress-circular
                      v-else
                      :size="35"
                      :width="3"
                      indeterminate
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-card-title>Source Selection</v-card-title>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-card elevation="0">
                      <v-card-title class="subheading">
                        Available Sources
                        <v-spacer />
                        <v-text-field
                          v-model="sourceSearch"
                          append-icon="filter_list"
                          label="Filter"
                          single-line
                          hide-details
                          clearable
                        />
                      </v-card-title>
                      <v-data-table
                        ref="sourceTable"
                        :items="sourceItems"
                        :headers="sourceHeaders"
                        :loading="sourceLoading"
                        :footer-props="{ itemsPerPageOptions: [10,50,{'text':'All',value:-1}] }"
                        :search="sourceSearch"
                        hide-default-header
                      >
                        <template v-slot:item="props">
                          <tr @click="addSource(props.item)">
                            <td>{{ props.item.name }}</td>
                            <td>{{ props.item.count }}
                            </td><td class="text-right">
                              <v-btn color="primary" small fab>
                                <v-icon>add</v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </template>
                        <template v-slot:no-data>
                          <v-alert v-if="!sourceLoading" :value="true" color="error" icon="warning">
                            No numbers avaialable
                          </v-alert>
                          <div v-if="sourceLoading" class="text-center">
                            <v-progress-circular indeterminate />
                          </div>
                        </template>
                        <template v-slot:no-results>
                          <v-alert :value="true" color="error" icon="warning">
                            Your search for "{{ sourceSearch }}" found no results.
                          </v-alert>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                  <v-col cols="6">
                    <v-card elevation="0">
                      <v-card-title class="subheading">
                        Selected Sources ({{ sources.length }})
                        <v-spacer />
                        <v-text-field
                          v-model="sourceSelectedSearch"
                          append-icon="filter_list"
                          label="Filter"
                          single-line
                          hide-details
                          clearable
                        />
                      </v-card-title>
                      <v-data-table
                        ref="sourceSelectedTable"
                        :items="sources"
                        :headers="sourceHeaders"
                        :footer-props="{ itemsPerPageOptions: [10,50,{'text':'All',value:-1}] }"
                        :search="sourceSelectedSearch"
                        :loading="sourceLoading"
                        hide-default-header
                        no-data-text="No numbers added"
                      >
                        <template v-slot:item="props">
                          <tr @click="removeSource(props.item.name)">
                            <td>{{ props.item.name }}</td>
                            <td>{{ props.item.count }}</td>
                            <td class="text-right">
                              <v-btn color="primary" small fab>
                                <v-icon>remove</v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </template>
                        <template v-slot:footer>
                          <td :colspan="headers.length">
                            <v-btn color="primary" @click="removeAllSources()">
                              Remove All
                            </v-btn>
                          </td>
                        </template>
                        <template v-slot:no-results>
                          <v-alert :value="true" color="error" icon="warning">
                            Your search for "{{ sourceSelectedSearch }}" found no results.
                          </v-alert>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-row>
          <v-col class="text-center">
            IP: 167.172.238.101 must be authorized in order to perform Caller ID tests.
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card class="pa-3">
              <v-btn
                color="primary"
                large
                block
                type="submit"
                :disabled="submitting"
                :loading="submitting"
              >
                Start Test
              </v-btn>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-dialog v-model="errorDialog" max-width="500">
      <v-card>
        <v-card-title class="headline" color="error">Validation Error</v-card-title>
        <v-card-text>
          <p>You made mistakes on the form that must be fixed.</p>
          <p v-for="(error, key) in errorDialogText" :key="key">{{ error }}</p>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="errorDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </layout-panel>
</template>

<script>
  import { authFetch } from '@/plugins/authFetch'
  import * as rules from '@/plugins/rules'

  export default {
    data () {
      return {
        submitted: false,
        loading: false,
        errorDialog: false,
        errorDialogText: '',
        rules: rules,
        csvError: [],
        headers: [
          { text: 'CSV Prefix', value: 'prefix' },
          { text: 'CSV Rate', value: 'cost' },
          { text: 'Retail Revenue', value: 'revenue' },
          { text: 'Difference', value: 'profit' },
          { text: 'Retail Numbers', value: 'total' },
          { text: 'Number Tested', value: 'retail' },
          { text: 'Exec Result', value: 'result' },
        ],
        csvFile: null,
        csvPrefixColumn: null,
        csvRateColumn: null,
        routeId: null,
        formValid: true,
        routesItems: [],
        routesLoading: true,
        sources: [],
        sourceType: 'carrier',
        sourceSearch: '',
        sourceSelectedSearch: '',
        sourcePrefixItems: [],
        sourceCarrierItems: [],
        sourceHeaders: [
          { text: 'Name', value: 'name' },
          { text: 'Actions', value: 'name' },
        ],
        sourceLoading: true,
      }
    },
    computed: {
      sourceItems () {
        if (this.sourceType === 'prefix') {
          if (this.sourcePrefixItems.length === 0) {
            this.loadSource(this.sourceType)
          }
          return this.sourcePrefixItems
        } else if (this.sourceType === 'carrier') {
          if (this.sourceCarrierItems.length === 0) {
            this.loadSource(this.sourceType)
          }
          return this.sourceCarrierItems
        }
        return []
      },
    },
    mounted () {
      this.loadRoutes()
    },
    methods: {
      loadRoutes () {
        this.routesLoading = true
        authFetch(process.env.VUE_APP_API_ROOT + 'routes/')
          .then(async response => {
            const items = await response.json()
            this.routesItems = []
            items.forEach(r => {
              let prefix = 'No Prefix'
              if (r.prefix !== '') {
                prefix = `Prefix: ${r.prefix}`
              }
              let labelText = ''
              if (r.label) {
                labelText = `${r.label} - ${r.ip}:${r.port} ${prefix}`
              } else {
                labelText = `${r.ip}:${r.port} ${prefix}`
              }
              this.routesItems.push({
                text: labelText,
                value: r.id,
              })
            })
          })
          .finally(() => {
            this.routesLoading = false
          })
      },
      loadSource (type) {
        this.sourceLoading = true
        authFetch(process.env.VUE_APP_API_ROOT + `tasks/sources/${type}/`)
          .then(async response => {
            const items = await response.json()
            if (type === 'prefix') {
              items.forEach(row => {
                const src = {
                  name: `${row.prefix} - ${row.carrier}`,
                  count: row.count,
                  submit: row.prefix,
                }
                this.sourcePrefixItems.push(src)

                if (this.sources.indexOf(row.prefix) !== -1) {
                  this.addSource(src)
                }
              })
            } else {
              items.forEach(row => {
                const src = {
                  name: row.carrier,
                  count: row.count,
                  submit: row.carrier,
                }
                this.sourceCarrierItems.push(src)
                if (this.sources.indexOf(row.carrier) !== -1) {
                  this.addSource(src)
                }
              })
            }
          })
          .catch((error) => {
            console.log(error)
            this.items = []
          })
          .finally(() => {
            this.sourceLoading = false
          })
      },
      addSource (source) {
        for (let i = 0; i < this.sources.length; i++) {
          if (this.sources[i].name === source.name) {
            return false
          }
        }
        this.sources.push(source)
        return true
      },
      removeSource (source) {
        let index = null
        this.sources.some((value, key) => {
          if (value.name === source) {
            index = key
            return true
          }
          return false
        })
        if (index !== null) {
          this.sources.splice(index, 1)
        }
      },
      removeAllSources () {
        this.sources.slice(0).forEach(v => {
          if (this.sourceSelectedSearch === null || this.sourceSelectedSearch === '' ||
            this.$refs.sourceSelectedTable.customFilter(v.name, this.sourceSelectedSearch)) {
            this.removeSource(v.name)
          }
        })
      },
      submit () {
        this.errorDialogText = []
        let valid = true

        const sources = this.sources.map(source => source.submit)
        if (sources.length === 0) {
          this.errorDialogText.push('You must add at least one source')
        }

        if (this.errorDialogText.length > 0) {
          this.errorDialog = true
          valid = false
        }

        // Built-in validation
        if (!this.$refs.form.validate() || !valid) {
          this.submitting = false
          return false
        }

        const postData = {
          routeId: this.routeId,
          sourceType: this.sourceType,
          sources: sources,
        }

        this.loading = true
        this.submitted = true
        const method = 'POST'

        authFetch(process.env.VUE_APP_API_ROOT + 'cli/test/', {
          method: method,
          body: JSON.stringify(postData),
        })
          .then(async response => {
            const result = await response.json()
            this.$router.push({
              name: 'cli-test-result',
              params: { taskId: result.id },
            })
          })
          .catch(async error => {
            console.log(error)
            const data = await error.json()
            // Correct error response?
            if (typeof data === 'object' && Object.prototype.hasOwnProperty.call(data, 'message')) {
              // Is it an object we can loop through for feedback?
              if (typeof data.message === 'object') {
                this.errorDialogText = data.message
                // String returned, setup dialog
              } else {
                this.errorDialogText = [data.message]
              }
              // Generic error if we didn't get a proper response
            } else {
              this.errorDialogText = [`Server returned a ${error.status} status code`]
            }
            if (this.errorDialogText) {
              this.errorDialog = true
            }
          })
          .finally(() => {
            this.loading = false
          })
      },
    },
  }
</script>

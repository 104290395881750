<template>
  <layout-panel>
    <div v-if="loading" class="pt-5" style="text-align: center">
      <v-progress-circular
        :size="70"
        :width="7"
        indeterminate
      />
    </div>
    <v-alert :value="error !== false" type="error">{{ error }}</v-alert>
    <template v-if="!loading && !error">
      <v-container>
        <v-card>
          <v-card-text class="display-2 text-center mt-5 primary white--text">
            New Access Finder from Existing Pairs
          </v-card-text>
        </v-card>
      </v-container>
      <task-form-steps
        task-type="exploration"
        :previous-form-data="formData"
      />
    </template>
  </layout-panel>
</template>

<script>
  import { authFetch } from '@/plugins/authFetch'
  import TaskFormSteps from '@/components/TaskFormSteps'
  import { typeMap } from '@/plugins/common'

  export default {
    components: { TaskFormSteps },
    props: {
      taskId: {
        type: Number,
        required: false,
        default: 0,
      },
      destination: {
        type: String,
        required: false,
        default: '',
      },
      source: {
        type: String,
        required: false,
        default: '',
      },
      carrierAll: {
        type: Number,
        required: false,
        default: 0,
      },
    },
    data () {
      return {
        loading: true,
        error: false,
        data: {},
      }
    },
    computed: {
      formData () {
        const whitelist = []
        let randomDigits = []
        let destinationLookbackData = '1 day'
        let sourceCount = 'one'
        if (this.taskId === 0) {
          randomDigits = randomDigits.concat([0, 3, 4])
          destinationLookbackData = '5 year'
          sourceCount = 'all'
        }
        if (Object.prototype.hasOwnProperty.call(this.data.destinations, 'carrierAll')) {
          this.data.destinations.carrierAll.forEach(value => {
            const row = {
              type: 'carrierAll',
              string: [value],
              rate: null,
              lengths: [],
              randomDigits: randomDigits,
            }
            whitelist.push(row)
          })
        }
        if (Object.prototype.hasOwnProperty.call(this.data.destinations, 'carrier')) {
          this.data.destinations.carrier.forEach(value => {
            const row = {
              type: 'carrier',
              string: [value],
              rate: null,
              lengths: [],
              randomDigits: randomDigits,
            }
            whitelist.push(row)
          })
        }
        console.log(this.data)
        return {
          sources: this.data.sources,
          sourceCount: sourceCount,
          sourceType: 'carrier',
          sourceAutoWhitelist: '',
          sourceAutoWhitelistType: 'white',
          sourceAutoBan: false,
          sourceAutoRefine: false,
          sourceMinimumPopularity: '',
          sourceLookbackTime: 1,
          sourceLookbackTimePeriod: 'day',
          sourceGroup: null,
          sourceCliPrefix: '',
          destinationType: 'whitelist',
          destinationLookbackType: 'time',
          destinationLookbackData: destinationLookbackData,
          destinationCSV: null,
          whitelists: whitelist,
          taskName: '',
          taskDuration: '',
          taskACD: this.data.taskMeta.taskACD,
          taskPDDs: '',
          taskMaxASR: '',
          taskMinASR: '',
          taskPDDLock: false,
          taskMaxSimultaneousCalls: this.data.taskMeta.taskMaxSimultaneousCalls,
          taskMaxCallsPerInterval: this.data.taskMeta.taskMaxCallsPerInterval,
          taskMaxCallsInterval: '1',
          routeId: '',
          routeDialServer: null,
          schedule: {},
          scheduleMinimumRoI: null,
          scheduleMaximumTimeBelow: null,
          scheduleGrace: null,
          sourceLastSeenCheck: 'none',
        }
      },
    },
    mounted () {
      if (this.taskId !== 0) {
        this.getData()
      } else if (this.destination !== '' && this.source !== '') {
        if (this.carrierAll === 1) {
          this.data.destinations = { carrierAll: [this.destination] }
        } else {
          this.data.destinations = { carrier: [this.destination] }
        }
        this.data.sources = [this.source]
        this.data.taskMeta = {}
        this.data.taskMeta.taskACD = ''
        this.data.taskMeta.taskMaxSimultaneousCalls = ''
        this.data.taskMeta.taskMaxCallsPerInterval = ''
        this.loading = false
      }
    },
    methods: {
      translateType (key) {
        if (Object.prototype.hasOwnProperty.call(typeMap, key)) {
          return typeMap[key]
        }
        return `Unknown (${key})`
      },
      getData () {
        authFetch(process.env.VUE_APP_API_ROOT + `tasks/running/pairs/?id=${this.taskId}`)
          .then(async response => {
            const data = await response.json()
            this.data = data
          })
          .catch((error) => {
            console.log(error)
            if (error.status === 404) {
              this.error = 'Task not found'
            } else {
              this.error = `Server returned a ${error.status} status code`
            }
          })
          .finally(() => {
            this.loading = false
          })
      },
    },
  }
</script>

<template>
  <div>
    <v-container>
      <v-card>
        <v-stepper v-model="formStepsCurrent" :vertical="false">
          <v-stepper-header>
            <v-stepper-step :step="1">
              Destination Selection
            </v-stepper-step>
            <v-divider />
            <v-stepper-step :step="formStepsSource">
              Source Selection
            </v-stepper-step>
            <v-divider />
            <v-stepper-step :step="formStepsTaskOptions">
              Task Options
            </v-stepper-step>
            <template v-if="taskType === 'finalized'">
              <v-divider />
              <v-stepper-step :step="formStepsSchedule">
                Schedule
              </v-stepper-step>
            </template>
          </v-stepper-header>
          <v-stepper-items>

            <!-- START DESTINATION SELECTION -->
            <v-stepper-content step="1">
              <v-form
                ref="formDesination"
                v-model="formValid"
                @submit.prevent="validateAndGetDestinations"
              >
                <v-card-text>
                  <v-container class="m-3">
                    <v-row>
                      <v-col cols="3">
                        <v-select
                          v-model="destinationType"
                          :items="destinationTypes"
                          label="Destination Selection Method"
                        />
                      </v-col>
                    </v-row>
                    <!-- START WHITELIST -->
                    <v-row v-if="destinationType === 'whitelist'">
                      <v-col cols="2">
                        <v-select
                          v-model="formData.destinationLookbackType"
                          :items="destinationLookbackTypes"
                          label="CDR Lookback Method"
                        />
                      </v-col>
                      <template v-if="formData.destinationLookbackType === 'time'">
                        <v-col cols="1">
                          <v-text-field
                            v-model="destinationLookbackTimeNum"
                            reverse
                          />
                        </v-col>
                        <v-col cols="2">
                          <v-select
                            v-model="destinationLookbackTimePeriod"
                            :items="destinationLookbackPeriods"
                          />
                        </v-col>
                      </template>
                      <template v-if="formData.destinationLookbackType === 'rows'">
                        <v-col cols="3">
                          <v-text-field
                            v-model="formData.destinationLookbackData"
                            :rules="[rules.required, rules.positiveNumber]"
                            type="number"
                            label="Amount"
                          />
                        </v-col>
                      </template>
                      <v-col cols="12">
                        <v-btn color="primary" @click="addWhitelist()">Add Filter</v-btn>
                      </v-col>
                      <v-col cols="12">
                        <template v-for="(child, index) in whitelists">
                          <component
                            :is="child.component"
                            :key="index"
                            v-model="formData.whitelists[index]"
                            v-bind="{
                              index: index,
                              errors: child.errors,
                              lookbackType: formData.destinationLookbackType,
                              lookbackData: formData.destinationLookbackData
                            }"
                            @removeWhitelist="removeWhitelist"
                          />
                        </template>
                      </v-col>
                    </v-row>
                    <!-- END WHITELIST -->
                    <!-- START HLR - Not currently used -->
                    <v-row v-else-if="destinationType === 'hlr'">
                      <v-col cols="3">
                        <div class="mt-5 text-end">HLR Minimum Updated Time</div>
                      </v-col>
                      <v-col cols="1">
                        <v-text-field
                          v-model="destinationLookbackTimeNum"
                          reverse
                        />
                      </v-col>
                      <v-col cols="2">
                        <v-select
                          v-model="destinationLookbackTimePeriod"
                          :items="destinationLookbackPeriods"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-btn color="primary" @click="addHLR()">Add Filter</v-btn>
                      </v-col>
                      <template v-for="(child, index) in hlrWhitelists">
                        <component
                          :is="child.component"
                          :key="index"
                          v-model="hlrWhitelistFilters[index]"
                          v-bind="{
                            index: index,
                            errors: child.errors,
                            lookbackType: formData.destinationLookbackType,
                            lookbackData: formData.destinationLookbackData
                          }"
                          @removeWhitelist="removeHLR"
                        />
                      </template>
                    </v-row>
                    <!-- END HLR -->
                    <!-- START CSV -->
                    <v-row v-if="formData.destinationType === 'custom'">
                      <v-col cols="6">
                        <v-file-input
                          accepts=".csv,text/csv"
                          label="CSV Number and Rate File"
                          required
                          :error="csvError.length > 0"
                          :error-messages="csvError"
                          @change="onFileChange"
                        />
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          v-model="csvNumberColumn"
                          :rules="[rules.required, rules.numberGT0]"
                          required
                          label="Number Column"
                          type="number"
                        />
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          v-model="csvRate"
                          :rules="[rules.required, rules.positiveFloat]"
                          required
                          label="Rate"
                          type="number"
                        />
                      </v-col>
                    </v-row>
                    <!-- END CSV -->
                  </v-container>
                </v-card-text>
                <task-stepper-actions
                  :page="formStepsCurrent"
                  :total-pages="formStepsTotal"
                />
              </v-form>
            </v-stepper-content>
            <!-- END DESTINATION SELECTION -->

            <!-- START SOURCE SELECTION -->
            <v-stepper-content :step="formStepsSource">
              <v-form
                ref="formSource"
                v-model="formValid"
                @submit.prevent="validateAndGetSources"
              >
                <v-card-text>
                  <v-container class="m-3">
                    <v-row>
                      <v-col cols="3">
                        <v-select
                          v-model="sourceType"
                          :items="sourceTypeItems"
                          label="Source Selection Method"
                        />
                      </v-col>
                      <template v-if="formData.sourceType === 'auto-prefix'">
                        <v-col cols="2" offset="1">
                          <v-text-field
                            v-model="formData.sourceLookbackTime"
                            reverse
                            label="Popularity Lookback"
                            type="number"
                          />
                        </v-col>
                        <v-col cols="2">
                          <v-select
                            v-model="formData.sourceLookbackTimePeriod"
                            :items="manualLookbackPeriods"
                          />
                        </v-col>
                      </template>
                      <template v-else-if="formData.sourceType === 'auto'">
                        <v-col cols="2" offset="1">
                          <v-text-field
                            v-model="formData.sourceLookbackTime"
                            reverse
                            label="Popularity Lookback"
                            type="number"
                          />
                        </v-col>
                        <v-col cols="2">
                          <v-select
                            v-model="formData.sourceLookbackTimePeriod"
                            :items="manualLookbackPeriods"
                          />
                        </v-col>
                        <v-col>
                          <div class="text-center">
                            <v-switch
                              v-model="formData.sourceAutoBan"
                              label="Auto Ban"
                              class="switch-center"
                            />
                          </div>
                        </v-col>
                        <v-col cols="2">
                          <div class="text-center">
                            <v-switch
                              v-model="formData.sourceAutoRefine"
                              label="Auto Refine"
                              class="switch-center"
                            />
                          </div>
                        </v-col>
                        <v-col cols="2" offset="4">
                          <v-select
                            v-model="formData.sourceAutoWhitelistType"
                            label="Filter Type"
                            :items="autoWhitelistTypes"
                          />
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            v-model="formData.sourceAutoWhitelist"
                            :rules="[rules.commaSepString]"
                            :label="'Carrier/Prefix ' + whiteOrBlacklist"
                            hint="Optional, pipe (|) separated"
                            persistent-hint=""
                          />
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            v-model="formData.sourceMinimumPopularity"
                            :rules="[rules.positiveNumber]"
                            label="Popularity Required"
                            hint="Optional"
                            persistent-hint
                            type="number"
                          />
                        </v-col>
                      </template>
                      <template v-else-if="formData.sourceType === 'manual'">
                        <v-col cols="3">
                          <v-text-field
                            v-model="formData.sourceLookbackTime"
                            reverse
                            label="Popularity Lookback"
                            type="number"
                          />
                        </v-col>
                        <v-col cols="2">
                          <v-select
                            v-model="formData.sourceLookbackTimePeriod"
                            :items="manualLookbackPeriods"
                          />
                        </v-col>
                        <v-col cols="2">
                          <v-btn
                            color="primary"
                            :loading="manualLookbackLoading"
                            :disabled="manualLookbackLoading"
                            @click="loadPopularity"
                          >
                            Lookup Sources</v-btn>
                        </v-col>
                        <template v-if="sources.length > 0 || manualRemoved.length > 0">
                          <v-col cols="6">
                            <v-card elevation="0">
                              <v-card-title class="subheading">
                                Active Numbers
                                <v-spacer />
                                <v-text-field
                                  v-model="manualSearch"
                                  append-icon="filter_list"
                                  label="Filter"
                                  single-line
                                  hide-details
                                  clearable
                                />
                              </v-card-title>
                              <v-data-table
                                ref="manualTable"
                                :items="sources"
                                :headers="manualHeaders"
                                :search="manualSearch"
                                :sort-by.sync="manualTableOptions.sortBy"
                                :sort-desc.sync="manualTableOptions.descending"
                                :footer-props="{ itemsPerPageOptions: [10,50,{'text':'All',value:-1}] }"
                              >
                                <template v-slot:item="props">
                                  <tr @click="removeManualNumber(props.item)">
                                    <td>{{ props.item.number }}</td>
                                    <td>{{ props.item.popularity }}</td>
                                    <td class="text-right">
                                      <v-btn
                                        color="primary"
                                        small
                                        fab
                                      >
                                        <v-icon>remove</v-icon>
                                      </v-btn>
                                    </td>
                                  </tr>
                                </template>
                                <template v-slot:footer>
                                  <td :colspan="headers.length">
                                    <v-btn
                                      color="primary"
                                      @click="removeAllManualNumbers()"
                                    >Remove All</v-btn>
                                  </td>
                                </template>
                                <template v-slot:no-results>
                                  <v-alert :value="true" color="error" icon="warning">
                                    Your search for "{{ manualSearch }}" found no results.
                                  </v-alert>
                                </template>
                              </v-data-table>
                            </v-card>
                          </v-col>
                          <v-col cols="6">
                            <v-card elevation="0">
                              <v-card-title class="subheading">
                                Removed Numbers
                                <v-spacer />
                                <v-text-field
                                  v-model="manualRemovedSearch"
                                  append-icon="filter_list"
                                  label="Filter"
                                  single-line
                                  hide-details
                                  clearable
                                />
                              </v-card-title>
                              <v-data-table
                                ref="manualRemovedTable"
                                :items="manualRemoved"
                                :headers="manualHeaders"
                                :search="manualRemovedSearch"
                                :sort-by.sync="manualTableOptions.sortBy"
                                :sort-desc.sync="manualTableOptions.descending"
                                :footer-props="{ itemsPerPageOptions: [10,50,{'text':'All',value:-1}] }"
                                no-data-text="No numbers"
                              >
                                <template v-slot:item="props">
                                  <tr @click="addManualNumber(props.item)">
                                    <td>{{ props.item.number }}</td>
                                    <td>{{ props.item.popularity }}</td>
                                    <td class="text-right">
                                      <v-btn color="primary" small fab>
                                        <v-icon>add</v-icon>
                                      </v-btn>
                                    </td>
                                  </tr>
                                </template>
                                <template v-slot:footer>
                                  <td :colspan="headers.length">
                                    <v-btn color="primary" @click="addAllManualNumbers()">
                                      Add All
                                    </v-btn>
                                  </td>
                                </template>
                                <template v-slot:no-results>
                                  <v-alert :value="true" color="error" icon="warning">
                                    Your search for "{{ manualRemovedSearch }}" found no results.
                                  </v-alert>
                                </template>
                              </v-data-table>
                            </v-card>
                          </v-col>
                        </template>
                      </template>
                      <template v-else-if="formData.sourceType !== 'auto-prefix'">
                        <v-col cols="7" offset="2">
                          <v-radio-group v-model="formData.sourceCount" row>
                            <v-radio
                              v-for="(item, key) in sourceCountItems"
                              :key="key"
                              :label="item.text"
                              :value="item.value"
                            />
                            <v-tooltip bottom max-width="400">
                              <template v-slot:activator="{ on }">
                                <v-icon v-on="on">help</v-icon>
                              </template>
                              Selecting to use 12 numbers will test 3 of each numbers that
                              you add from the IPRN source list. This can be helpful in
                              speeding up the finding of new access. If the task is a
                              production/semi production task, you should select to use
                              All Numbers.
                            </v-tooltip>
                          </v-radio-group>
                        </v-col>
                        <v-col cols="6">
                          <v-card elevation="0">
                            <v-card-title class="subheading">
                              Available Sources
                              <v-spacer />
                              <v-text-field
                                v-model="sourceSearch"
                                append-icon="filter_list"
                                label="Filter"
                                single-line
                                hide-details
                                clearable
                              />
                            </v-card-title>
                            <v-data-table
                              ref="sourceTable"
                              :items="sourceItems"
                              :headers="headers"
                              :loading="sourceLoading"
                              :footer-props="{ itemsPerPageOptions: [10,50,{'text':'All',value:-1}] }"
                              :search="sourceSearch"
                              hide-default-header
                            >
                              <template v-slot:item="props">
                                <tr @click="addSource(props.item)">
                                  <td>{{ props.item.name }}</td>
                                  <td>{{ props.item.count }}
                                  </td><td class="text-right">
                                    <v-btn color="primary" small fab>
                                      <v-icon>add</v-icon>
                                    </v-btn>
                                  </td>
                                </tr>
                              </template>
                              <template v-slot:footer>
                                <td :colspan="headers.length">
                                  <v-btn color="primary" @click="addAllSources()">
                                    Add All
                                  </v-btn>
                                </td>
                              </template>
                              <template v-slot:no-data>
                                <v-alert v-if="!sourceLoading" :value="true" color="error" icon="warning">
                                  No numbers avaialable
                                </v-alert>
                                <div v-if="sourceLoading" class="text-center">
                                  <v-progress-circular indeterminate />
                                </div>
                              </template>
                              <template v-slot:no-results>
                                <v-alert :value="true" color="error" icon="warning">
                                  Your search for "{{ sourceSearch }}" found no results.
                                </v-alert>
                              </template>
                            </v-data-table>
                          </v-card>
                        </v-col>
                        <v-col cols="6">
                          <v-card elevation="0">
                            <v-card-title class="subheading">
                              Selected Sources ({{ formData.sources.length }})
                              <v-spacer />
                              <v-text-field
                                v-model="sourceSelectedSearch"
                                append-icon="filter_list"
                                label="Filter"
                                single-line
                                hide-details
                                clearable
                              />
                            </v-card-title>
                            <v-data-table
                              ref="sourceSelectedTable"
                              :items="sources"
                              :headers="headers"
                              :footer-props="{ itemsPerPageOptions: [10,50,{'text':'All',value:-1}] }"
                              :search="sourceSelectedSearch"
                              :loading="sourceLoading"
                              hide-default-header
                              no-data-text="No numbers added"
                            >
                              <template v-slot:item="props">
                                <tr @click="removeSource(props.item.name)">
                                  <td>{{ props.item.name }}</td>
                                  <td>{{ props.item.count }}</td>
                                  <td class="text-right">
                                    <v-btn color="primary" small fab>
                                      <v-icon>remove</v-icon>
                                    </v-btn>
                                  </td>
                                </tr>
                              </template>
                              <template v-slot:footer>
                                <td :colspan="headers.length">
                                  <v-btn color="primary" @click="removeAllSources()">
                                    Remove All
                                  </v-btn>
                                </td>
                              </template>
                              <template v-slot:no-results>
                                <v-alert :value="true" color="error" icon="warning">
                                  Your search for "{{ sourceSelectedSearch }}" found no results.
                                </v-alert>
                              </template>
                            </v-data-table>
                          </v-card>
                        </v-col>
                      </template>
                    </v-row>
                    <v-row>
                      <v-col cols="2">
                        <v-text-field
                          v-model="formData.sourceCliPrefix"
                          label="CLI Prefix"
                        >
                          <template v-slot:append>
                            <v-tooltip bottom max-width="400">
                              <template v-slot:activator="{ on }">
                                <v-icon v-on="on">help</v-icon>
                              </template>
                              Add a prefix to the CallerID when the call is delivered.
                            </v-tooltip>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col offset="1">
                        <v-radio-group
                          v-model="formData.sourceLastSeenCheck"
                          label="Source Filtering"
                        >
                          <v-radio label="None" value="none" />
                          <v-radio label="Verified Only" value="verified" />
                          <v-radio label="Exploration" value="exploration" />
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <task-stepper-actions
                  :page="formStepsCurrent"
                  :total-pages="formStepsTotal"
                  @prev="formStepsCurrent--"
                />
              </v-form>
            </v-stepper-content>
            <!-- END SOURCE SELECTION -->

            <!-- START TASK OPTIONS -->
            <v-stepper-content :step="formStepsTaskOptions">
              <v-form
                ref="formTaskOptions"
                v-model="formValid"
                @submit.prevent="validateTaskOptions"
              >
                <v-card-text>
                  <v-container class="m-3">
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="formData.taskName"
                          :rules="[rules.required]"
                          required
                          label="Task Name"
                        />
                      </v-col>
                      <v-col v-if="routeGroupsLoading === true" class="text-center">
                        <v-progress-circular
                          :size="35"
                          :width="3"
                          indeterminate
                        />
                      </v-col>
                      <v-col v-else>
                        <v-select
                          v-model="formData.routeGroup"
                          label="Route Group"
                          :items="routeGroupItems"
                          :rules="[rules.required]"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                  <v-container v-if="routePDDs.length > 0">
                    <component
                      :is="r.component"
                      v-for="r in routePDDs"
                      ref="routePDDs"
                      :key="`routePDD_${r.route.id}`"
                      v-model="formData.pdds[r.route.id]"
                      :route="r.route"
                    />
                  </v-container>
                  <v-container>
                    <v-row v-if="taskType !== 'finalized'">
                      <v-col cols="4">
                        <v-switch
                          v-model="useMaxAttempts"
                          label="Limit total attempts"
                        />
                      </v-col>
                      <v-col v-if="useMaxAttempts === false" cols="3">
                        <v-text-field
                          v-model="formData.taskDuration"
                          :rules="[rules.required, rules.numberGT0]"
                          required
                          label="Task Duration (minutes)"
                          type="number"
                        />
                      </v-col>
                      <v-col v-else cols="3">
                        <v-text-field
                          v-model="formData.taskMaxAttempts"
                          :rules="[rules.required, rules.numberGT0]"
                          required
                          label="Max Outgoing Attempts"
                          type="number"
                        />
                      </v-col>
                    </v-row>
                    <v-row v-if="showAccuracy">
                      <v-col cols="3">
                        <v-switch
                          v-model="accuracy"
                          label="High Accuracy Mode"
                        />
                      </v-col>
                      <template v-if="accuracy === true">
                        <v-col cols="2">
                          <v-text-field
                            ref="accuracyStrip"
                            v-model="accuracyStrip"
                            label="Digits to strip"
                            type="number"
                            :rules="[rules.required, rules.numberGT0]"
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-btn
                            :disabled="accuracyLoading"
                            :loading="accuracyLoading"
                            color="primary"
                            @click="getFoundNumbers()"
                          >
                            Check Available Destinations
                          </v-btn>
                        </v-col>
                        <v-col>
                          <template v-if="accuracyResults !== null">
                            <strong>Base Numbers:</strong> {{ accuracyResults.baseNumbers }}<br>
                            <strong>Prefixes:</strong> {{ accuracyResults.prefixes }}<br>
                            <strong>Total:</strong> {{ accuracyResults.total }}
                          </template>
                          <template v-if="accuracyError !== null">
                            <span>{{ accuracyError }}</span>
                          </template>
                        </v-col>
                      </template>
                    </v-row>
                    <v-row v-if="showDialingOptions">
                      <v-col cols="4">
                        <v-switch
                          v-model="alternateACD"
                          label="Alternate ACD"
                        />
                      </v-col>
                      <template v-if="alternateACD === true">
                        <v-col cols="3">
                          <v-text-field
                            v-model="formData.taskAltACD"
                            :rules="[rules.required, rules.positiveRange]"
                            required
                            label="Alternate ACD"
                            hint="Range"
                          />
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            v-model="formData.taskAltACDPercent"
                            :rules="[rules.required, rules.positiveNumber]"
                            required
                            label="Percent chance"
                            type="number"
                            suffix="%"
                          />
                        </v-col>
                      </template>
                    </v-row>
                  </v-container>
                </v-card-text>
                <task-stepper-actions
                  :page="formStepsCurrent"
                  :total-pages="formStepsTotal"
                  :loading="submitting"
                  @prev="formStepsCurrent--"
                />
              </v-form>
            </v-stepper-content>
            <!-- END TASK OPTIONS -->

            <!-- START SCHEDULE -->
            <v-stepper-content
              v-if="taskType === 'finalized'"
              :step="formStepsSchedule"
            >
              <v-form
                ref="formSchedule"
                v-model="formValid"
                @submit.prevent="validateAndGetSchedule"
              >
                <v-card-text>
                  <v-card-title>All Times are in GMT</v-card-title>
                  <v-container class="mx-3">
                    <v-row>
                      <v-col cols="12">
                        <v-btn color="primary" @click="addSchedule">
                          Add Time Range
                        </v-btn>
                      </v-col>
                      <template v-for="(child, index) in schedules">
                        <component
                          :is="child.component"
                          :key="index"
                          v-model="formData.schedule[index]"
                          v-bind="{
                            index: index,
                            errors: child.errors
                          }"
                          @removeSchedule="removeSchedule"
                        />
                      </template>
                    </v-row>
                  </v-container>
                  <v-container class="m-3">
                    <v-row>
                      <v-col cols="4">
                        <v-text-field
                          v-model="formData.scheduleMinimumRoI"
                          :rules="[rules.required, rules.positiveNumber]"
                          required
                          label="Return on Investment Required (percentage)"
                          type="number"
                        />
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          v-model="formData.scheduleMaximumTimeBelow"
                          :rules="[rules.required, rules.positiveNumber]"
                          validate-on-blur
                          required
                          label="Maximum Time Below RoI (minutes)"
                          type="number"
                        />
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          v-model="formData.scheduleGrace"
                          :rules="[rules.required, rules.positiveNumber]"
                          required
                          label="Grace Period (minutes)"
                          type="number"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <task-stepper-actions
                  :page="formStepsCurrent"
                  :total-pages="formStepsTotal"
                  :loading="submitting"
                  @prev="formStepsCurrent--"
                />
              </v-form>
            </v-stepper-content>
            <!-- END SCHEDULE -->
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-container>
    <v-dialog v-model="errorDialog" max-width="500">
      <v-card>
        <v-card-title class="headline" color="error">Validation Error</v-card-title>
        <v-card-text>
          <p>You made mistakes on the form that must be fixed.</p>
          <p v-for="(error, key) in errorDialogText" :key="key">{{ error }}</p>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="errorDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="lookbackWarning" persistent max-width="500">
      <v-card>
        <v-card-title class="headline">CDR Lookback Warning</v-card-title>
        <v-card-text>
          <p>You have chosen less than 30 days for CDR lookback. This can be dangerous and cause
            unexpected and unpredicatble behaviour</p>
          <p>Are you sure you want to continue?</p>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="error" @click="lookbackWarning = false">Cancel</v-btn>
          <v-btn color="primary" @click="validateAndGetDestinations(true)">I Understand</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { authFetch } from '@/plugins/authFetch'
  import WhitelistRow from '@/components/WhitelistRow'
  import HLRRow from '@/components/HLRRow'
  import ScheduleRow from '@/components/ScheduleRow'
  import * as rules from '@/plugins/rules'
  import {
    commaSeparatedNumbersToList,
    pipeSeparatedStringsToList,
    sourceGroupItems,
    numberRangeToList,
  } from '@/plugins/common'
  import TaskStepperActions from '@/components/TaskStepperActions'
  import RoutePDD from '@/components/RoutePDD'

  export default {
    components: { TaskStepperActions, RoutePDD },
    props: {
      taskType: { type: String, default: 'exploration' },
    },
    data () {
      return {
        lookbackWarning: false,
        sourceLoading: true,
        sourceCountItems: [
          { text: 'Use 12 Numbers', value: 'one' },
          { text: 'Use All Numbers', value: 'all' },
        ],
        sourceSearch: '',
        sourceSelectedSearch: '',
        sourcePrefixItems: [],
        sourceCarrierItems: [],
        headers: [
          { text: 'Name', value: 'name' },
          { text: 'Actions', value: 'name' },
        ],
        whitelists: {},
        whitelistKey: 0,
        schedules: {},
        schedulesKey: 0,
        submitting: false,
        errorDialog: false,
        errorDialogText: [],
        rules: rules,
        formValid: true,
        destinationLookbackTypes: [
          { text: 'Time', value: 'time' },
          { text: 'Rows', value: 'rows' },
        ],
        destinationLookbackPeriods: [
          { text: 'Hours', value: 'hour' },
          { text: 'Days', value: 'day' },
          { text: 'Months', value: 'month' },
          { text: 'Years', value: 'year' },
        ],
        manualLookbackPeriods: [
          { text: 'Minutes', value: 'minute' },
          { text: 'Hours', value: 'hour' },
          { text: 'Days', value: 'day' },
          { text: 'Months', value: 'month' },
          { text: 'Years', value: 'year' },
        ],
        formData: {
          sources: [],
          sourceCount: 'one',
          sourceType: 'carrier',
          sourceAutoWhitelist: '',
          sourceAutoWhitelistType: 'white',
          sourceAutoBan: false,
          sourceAutoRefine: false,
          sourceMinimumPopularity: '',
          sourceLookbackTime: 1,
          sourceLookbackTimePeriod: 'day',
          sourceGroup: null,
          sourceCliPrefix: '',
          destinationType: 'whitelist',
          destinationLookbackType: 'time',
          destinationLookbackData: '3 month',
          destinationCSV: null,
          whitelists: {},
          taskName: '',
          taskDuration: '',
          schedule: {},
          scheduleMinimumRoI: null,
          scheduleMaximumTimeBelow: null,
          scheduleGrace: null,
          sourceLastSeenCheck: 'none',
          accuracy: false,
          accuracyStrip: null,
          taskMaxAttempts: null,
          routeGroup: null,
          pdds: {},
          taskAltACD: '',
          taskAltACDPercent: '',
        },
        sources: [],
        manualLookbackLoading: false,
        manualRemoved: [],
        manualSearch: '',
        manualRemovedSearch: '',
        manualHeaders: [
          { text: 'Number', value: 'number' },
          { text: 'Pop', value: 'popularity' },
        ],
        manualTableOptions: { sortBy: 'popularity', descending: true },
        test: ['a', 'b', 'c'],
        autoWhitelistTypes: [
          { text: 'Whitelist', value: 'white' },
          { text: 'Blacklist', value: 'black' },
        ],
        sourceGroupItems: sourceGroupItems,
        routeGroups: {},
        routeGroupItems: [],
        routeGroupsLoading: false,
        hlrWhitelists: {},
        hlrWhitelistKey: 0,
        hlrWhitelistFilters: {},
        csvError: [],
        csvNumberColumn: null,
        csvRate: null,
        csvFile: false,
        destinationType: 'whitelist',
        showDestinationsOverride: false,
        formStepsCurrent: 1,
        accuracy: false,
        accuracyStrip: null,
        accuracyLoading: false,
        accuracyResults: null,
        accuracyError: null,
        useMaxAttempts: false,
        routePDDs: [],
        alternateACD: false,
      }
    },
    computed: {
      formStepsTotal () {
        if (this.taskType === 'finalized') {
          return this.formStepsSchedule
        }
        return this.formStepsTaskOptions
      },
      formStepsSource () {
        return 2
      },
      formStepsTaskOptions () {
        return this.formStepsSource + 1
      },
      formStepsSchedule () {
        return this.formStepsTaskOptions + 1
      },
      destinationTypes () {
        if (this.$store.state.permission & 4) {
          return [
            { text: 'Whitelist', value: 'whitelist' },
            { text: 'CSV File', value: 'custom' },
          ]
        }
        return [
          { text: 'Whitelist', value: 'whitelist' },
          { text: 'HLR', value: 'hlr' },
          { text: 'CSV File', value: 'custom' },
        ]
      },
      sourceTypeItems () {
        if (this.formData.destinationType === 'none') {
          return [
            { text: 'Exploration', value: 'carrier' },
          ]
        }
        return [
          { text: 'Exploration', value: 'carrier' },
          { text: 'Final Production', value: 'auto' },
          { text: 'Historical Access', value: 'auto-prefix' },
        ]
      },
      showDestinationFilters () {
        return this.formData.destinationType === 'whitelist'
      },
      sourceItems () {
        if (this.formData.sourceType === 'prefix') {
          if (this.sourcePrefixItems.length === 0) {
            this.loadSource(this.formData.sourceType)
          }
          return this.sourcePrefixItems
        } else if (this.formData.sourceType === 'carrier') {
          if (this.sourceCarrierItems.length === 0) {
            this.loadSource(this.formData.sourceType)
          }
          return this.sourceCarrierItems
        }
        return []
      },
      destinationLookbackTimeNum: {
        get () {
          if (typeof this.formData.destinationLookbackData === 'undefined') {
            return 1
          }
          const parts = this.formData.destinationLookbackData.split(' ')
          if (isNaN(parts[0])) {
            return 1
          }
          return parseInt(parts[0], 10)
        },
        set (value) {
          this.formData.destinationLookbackData = `${value} ${this.destinationLookbackTimePeriod}`
        },
      },
      destinationLookbackTimePeriod: {
        get () {
          if (typeof this.formData.destinationLookbackData === 'undefined') {
            return 'day'
          }
          const parts = this.formData.destinationLookbackData.split(' ')
          if (parts.length < 2) {
            return 'day'
          }
          return parts[1]
        },
        set (value) {
          this.formData.destinationLookbackData = `${this.destinationLookbackTimeNum} ${value}`
        },
      },
      formDataLookbackData () {
        return this.formData.destinationLookbackData
      },
      formDataLookbackType () {
        return this.formData.destinationLookbackType
      },
      sourceType: {
        get () {
          return this.formData.sourceType
        },
        set (val) {
          this.sources = []
          this.formData.sources = []
          this.formData.sourceType = val
        },
      },
      whiteOrBlacklist () {
        return this.formData.sourceAutoWhitelistType === 'white' ? 'Whitelist' : 'Blacklist'
      },
      showAccuracy () {
        if (this.destinationType !== 'whitelist') {
          return false
        }
        if (this.sourceType !== 'carrier') {
          return false
        }
        return true
      },
    },
    watch: {
      'formData.routeGroup' () {
        this.formData.pdds = {}
        this.routePDDs = []
        if (this.formData.routeGroup === null ||
          Object.prototype.hasOwnProperty.call(this.routeGroups, this.formData.routeGroup) === false) {
          this.routePDDs = []
        }
        this.routeGroups[this.formData.routeGroup].routes.forEach(route => {
          this.formData.pdds[route.id] = route.pdds
          this.routePDDs.push({
            route: route,
            component: RoutePDD,
          })
        })
      },
      destinationType () {
        this.$set(this.formData, 'destinationType', this.destinationType)
        if (this.destinationType === 'none') {
          this.formData.sourceType = 'carrier'
        }
      },
      formDataLookbackData () {
        if (this.formData.destinationLookbackType === 'time') {
          const parts = this.formData.destinationLookbackData.split(' ')
          if (parts.length < 2) {
            return
          }
          this.destinationLookbackTimeNum = parts[0]
          this.destinationLookbackTimePeriod = parts[1]
        }
      },
      formDataLookbackType () {
        if (this.formData.destinationLookbackType === 'time') {
          this.formData.destinationLookbackData = '1 day'
        } else {
          this.formData.destinationLookbackData = '1000'
        }
      },
    },
    mounted () {
      if (Object.keys(this.formData.whitelists).length === 0) {
        this.addWhitelist()
        this.addHLR()
      } else {
        if (Array.isArray(this.formData.whitelists) === true) {
          this.formData.whitelists = { ...this.formData.whitelists }
        }
        Object.keys(this.formData.whitelists).forEach((value, key) => {
          if (this.formData.destinationType === 'whitelist') {
            this.addWhitelist()
            this.addHLR()
          } else if (this.formData.destinationType === 'hlr') {
            this.hlrWhitelistFilters[key] = this.formData.whitelists[value]
            this.addWhitelist()
            this.addHLR(key)
          }
        })
      }
      if (typeof this.formData.schedule === 'undefined' || Object.keys(this.formData.schedule).length === 0) {
        this.addSchedule()
      } else {
        Object.keys(this.formData.schedule).forEach((value, key) => {
          this.addSchedule(key)
        })
      }
      if (this.formData.sourceType === 'manual') {
        this.loadPopularity(this.formData.sources)
      }
      if (Array.isArray(this.formData.taskACD)) {
        this.formData.taskACD = this.formData.taskACD.join('-')
      }
      if (Array.isArray(this.formData.taskMaxCallsPerInterval)) {
        this.formData.taskMaxCallsPerInterval = this.formData.taskMaxCallsPerInterval.join('-')
      }
      if (Array.isArray(this.formData.taskMaxCallsInterval)) {
        this.formData.taskMaxCallsInterval = this.formData.taskMaxCallsInterval.join('-')
      }
      if (Object.keys(this.formData.whitelists).length > 0 &&
        Object.prototype.hasOwnProperty.call(this.formData.whitelists[0], 'strip') === true &&
        this.formData.whitelists[0].strip !== null) {
        this.accuracy = true
        this.accuracyStrip = this.formData.whitelists[0].strip
      }
      if (this.formData.taskMaxAttempts !== null) {
        this.useMaxAttempts = true
      }
      this.loadRouteGroups()
    },
    methods: {
      formVerifyValidity (form, valid) {
        if (this.errorDialogText.length > 0) {
          this.errorDialog = true
          valid = false
        }
        if (form.validate() === false || valid === false) {
          this.submitting = false
          return false
        }
        return true
      },
      validateAndGetDestinations (ignoreLookback = false, clearDialog = true) {
        let valid = true
        if (clearDialog === true) {
          this.errorDialogText = []
        }
        this.lookbackWarning = false
        this.csvError = []
        this.destinationFilters = []

        // Check all destination filters and build
        let filters = []
        // Check for empty destination filters
        if (this.formData.destinationType === 'whitelist') {
          if (Object.keys(this.formData.whitelists).length < 1) {
            this.errorDialogText.push('Must include at least one destination filter')
          } else {
            filters = this.validateAndGetFilters()
            if (filters === false) {
              valid = false
            }
          }
        }
        if (this.formData.destinationType === 'hlr') {
          if (Object.keys(this.hlrWhitelistFilters).length < 1) {
            this.errorDialogText.push('Must include at least one destination filter')
          } else {
            filters = this.validateAndGetHLRFilters()
            if (filters === false) {
              valid = false
            }
          }
        }

        // CSV stuff
        if (this.formData.destinationType === 'custom') {
          if (this.csvFile === false) {
            this.csvError.push('File is required')
            valid = false
          }
        }

        if (this.formVerifyValidity(this.$refs.formDesination, valid) === false) {
          return false
        }

        if ((this.formData.destinationType === 'whitelist') &&
          this.formData.destinationLookbackType === 'time' &&
          this.destinationLookbackTimePeriod === 'day' &&
          this.destinationLookbackTimeNum <= 30 &&
          ignoreLookback === false) {
          this.lookbackWarning = true
          return false
        }

        if (this.formStepsCurrent < this.formStepsTotal) {
          this.formStepsCurrent++
        }
        return filters
      },
      validateAndGetSources (clearDialog = true) {
        const valid = true
        if (clearDialog === true) {
          this.errorDialogText = []
        }

        // Check for invalid sourceType
        if (!this.sourceTypeItems.some(type => type.value === this.formData.sourceType)) {
          this.errorDialogText.push('Invalid selection for source type')
        }
        // Make sure we have something if needed
        let sources = []
        if (this.formData.sourceType === 'carrier' || this.formData.sourceType === 'prefix') {
          // Check for invalid sourceCount
          if (!this.sourceCountItems.some(type => type.value === this.formData.sourceCount)) {
            this.errorDialogText.push('Invalid selection for source count')
          }
        }
        if (this.formData.sourceType === 'manual') {
          sources = this.sources.map(source => source.number)
        } else if (this.formData.sourceType !== 'auto') {
          sources = this.sources.map(source => source.submit)
        }
        if (this.formData.sourceType !== 'auto' &&
          this.formData.sourceType !== 'auto-prefix' &&
          sources.length < 1) {
          this.errorDialogText.push('You must add at least one source')
        }

        if (this.formVerifyValidity(this.$refs.formSource, valid) === false) {
          return false
        }

        if (this.formStepsCurrent < this.formStepsTotal) {
          this.formStepsCurrent++
        }
        return sources
      },
      validateTaskOptions () {
        this.errorDialogText = []
        let valid = true
        if (this.formVerifyValidity(this.$refs.formTaskOptions, true) === false) {
          valid = false
        }
        if (Object.keys(this.formData.pdds).length === 0) {
          valid = false
        }
        if (Object.prototype.hasOwnProperty.call(this.$refs, 'routePDDs') === true) {
          this.$refs.routePDDs.forEach(cmp => {
            if (cmp.validate() === false) {
              valid = false
            }
          })
        } else {
          valid = false
        }

        if (valid !== true) {
          return false
        }

        if (this.formStepsCurrent === this.formStepsTotal) {
          return this.submit()
        } else {
          this.formStepsCurrent++
        }
      },
      validateAndGetSchedule (preventSubmit = false, clearDialog = true) {
        if (clearDialog === true) {
          this.errorDialogText = []
        }
        let valid = true
        const schedule = []

        let showScheduleDialog = true
        for (const [key, range] of Object.entries(this.formData.schedule)) {
          let hasKeys = true
          if (range[0] === null) {
            this.schedules[key].errors.startError = 'Required'
            valid = false
            hasKeys = false
            showScheduleDialog = false
          }
          if (range[1] === null) {
            this.schedules[key].errors.endError = 'Required'
            valid = false
            hasKeys = false
            showScheduleDialog = false
          }
          if (hasKeys && range[0] >= range[1]) {
            this.schedules[key].errors.startError = 'Must be before end time'
            valid = false
            showScheduleDialog = false
          }
          if (valid) {
            schedule.push([range[0], range[1]])
          }
        }
        if (schedule.length < 1 && showScheduleDialog) {
          this.errorDialogText.push('Must include at least one valid schedule entry')
        }
        if (this.formVerifyValidity(this.$refs.formSchedule, valid) === false) {
          return false
        }

        if (preventSubmit === false) {
          this.submit()
        }
        return schedule
      },
      onFileChange (f) {
        this.csvFile = f
        if (!this.csvFile) {
          this.csvFile = false
        }
      },
      toBase64 (file) {
        if (this.formData.destinationType === 'custom') {
          return new Promise((resolve, reject) => {
            var reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
          })
        } else {
          return new Promise((resolve, reject) => {
            resolve('')
          })
        }
      },
      loadRouteGroups () {
        this.routeGroupsLoading = true
        authFetch(`${process.env.VUE_APP_API_ROOT}route-groups/`)
          .then(async response => {
            const items = await response.json()
            this.routeGroups = {}
            this.routeGroupItems = []
            items.forEach(r => {
              this.routeGroupItems.push({
                text: r.name,
                value: r.id,
              })
              this.routeGroups[r.id] = r
            })
          })
          .finally(() => {
            this.routeGroupsLoading = false
          })
      },
      loadSource (type) {
        this.sourceLoading = true
        authFetch(process.env.VUE_APP_API_ROOT + `tasks/sources/${type}/`)
          .then(async response => {
            const items = await response.json()
            if (type === 'prefix') {
              items.forEach(row => {
                const src = {
                  name: `${row.prefix} - ${row.carrier}`,
                  count: row.count,
                  submit: row.prefix,
                }
                this.sourcePrefixItems.push(src)

                if (this.formData.sources.indexOf(row.prefix) !== -1) {
                  this.addSource(src)
                }
              })
            } else {
              items.forEach(row => {
                const src = {
                  name: row.carrier,
                  count: row.count,
                  submit: row.carrier,
                }
                this.sourceCarrierItems.push(src)
                if (this.formData.sources.indexOf(row.carrier) !== -1) {
                  this.addSource(src)
                }
              })
            }
          })
          .catch((error) => {
            console.log(error)
            this.items = []
          })
          .finally(() => {
            this.sourceLoading = false
          })
      },
      loadPopularity (initialSources) {
        this.errorDialogText = []
        this.sources = []
        this.manualRemoved = []
        let filters = []
        // Check for empty destination filters
        if (this.formData.destinationType === 'whitelist') {
          if (Object.keys(this.formData.whitelists).length < 1) {
            this.errorDialogText.push('Must include at least one destination filter')
            this.errorDialog = true
            return false
          }
          // Check all destination filters and build
          filters = this.validateAndGetFilters()
          if (filters === false) {
            return false
          }
        } else if (this.formData.destinationType === 'hlr') {
          if (Object.keys(this.hlrWhitelistFilters).length < 1) {
            this.errorDialogText.push('Must include at least one destination filter')
            this.errorDialog = true
            return false
          }
          // Check all destination filters and build
          filters = this.validateAndGetHLRFilters()
          if (filters === false) {
            return false
          }
        }

        this.manualLookbackLoading = true
        authFetch(process.env.VUE_APP_API_ROOT + 'tasks/cdrs/popularity/', {
          method: 'POST',
          body: JSON.stringify({
            filters: filters,
            lookbackTime: this.formData.sourceLookbackTime,
            lookbackTimePeriod: this.formData.sourceLookbackTimePeriod,
          }),
        })
          .then(async response => {
            const items = await response.json()
            if (!Array.isArray(items) || items.length < 1) {
              this.errorDialogText.push('No numbers found')
              this.errorDialog = true
              return
            }
            if (initialSources === undefined || initialSources.length < 1) {
              items.forEach(value => {
                this.sources.push({
                  number: value[0],
                  popularity: value[1],
                })
              })
            } else {
              items.forEach(value => {
                if (initialSources.indexOf(value[0]) !== -1) {
                  this.sources.push({
                    number: value[0],
                    popularity: value[1],
                  })
                } else {
                  this.manualRemoved.push({
                    number: value[0],
                    popularity: value[1],
                  })
                }
              })
            }
          })
          .catch((error) => {
            console.log(error)
            this.manualRemoved = []
            this.sources = []
          })
          .finally(() => {
            this.manualLookbackLoading = false
          })
      },
      addSource (source) {
        for (let i = 0; i < this.sources.length; i++) {
          if (this.sources[i].name === source.name) {
            return false
          }
        }
        this.sources.push(source)
        return true
      },
      removeSource (source) {
        let index = null
        this.sources.some((value, key) => {
          if (value.name === source) {
            index = key
            return true
          }
          return false
        })
        if (index !== null) {
          this.sources.splice(index, 1)
        }
      },
      addAllSources () {
        this.sourceItems.forEach(v => {
          if (this.sourceSearch === null || this.sourceSearch === '' ||
            this.$refs.sourceTable.customFilter(v.name, this.sourceSearch)) {
            this.addSource(v)
          }
        })
      },
      removeAllSources () {
        this.sources.slice(0).forEach(v => {
          if (this.sourceSelectedSearch === null || this.sourceSelectedSearch === '' ||
            this.$refs.sourceSelectedTable.customFilter(v.name, this.sourceSelectedSearch)) {
            this.removeSource(v.name)
          }
        })
      },
      addManualNumber (number) {
        let index = null
        this.manualRemoved.some((value, key) => {
          if (value.number === number.number) {
            index = key
            return true
          }
          return false
        })
        if (index !== null) {
          this.sources.push(number)
          this.manualRemoved.splice(index, 1)
        }
      },
      removeManualNumber (number) {
        let index = null
        this.sources.some((value, key) => {
          if (value.number === number.number) {
            index = key
            return true
          }
          return false
        })
        if (index !== null) {
          this.manualRemoved.push(number)
          this.sources.splice(index, 1)
        }
      },
      addAllManualNumbers () {
        this.manualRemoved.slice(0).forEach(v => {
          if (this.manualRemovedSearch === null || this.manualRemovedSearch === '' ||
            this.$refs.manualRemovedTable.customFilter(v.number, this.manualRemovedSearch)) {
            this.addManualNumber(v)
          }
        })
      },
      removeAllManualNumbers () {
        this.sources.slice(0).forEach(v => {
          if (this.manualSearch === null || this.manualSearch === '' ||
            this.$refs.manualTable.customFilter(v.number, this.manualSearch)) {
            this.removeManualNumber(v)
          }
        })
      },
      addWhitelist (key) {
        if (key !== undefined) {
          this.whitelistKey = key
        }
        this.$set(this.whitelists, this.whitelistKey, {
          component: WhitelistRow,
          errors: {
            stringError: '',
            lengthsError: '',
            randomDigitsError: '',
            rateError: '',
          },
        })
        this.whitelistKey++
      },
      removeWhitelist (index) {
        if (Object.prototype.hasOwnProperty.call(this.whitelists, index)) {
          this.$delete(this.whitelists, index)
        }
        if (Object.prototype.hasOwnProperty.call(this.formData.whitelists, index)) {
          this.$delete(this.formData.whitelists, index)
        }
      },
      addHLR (key) {
        if (key !== undefined) {
          this.hlrWhitelistKey = key
        }
        this.$set(this.hlrWhitelists, this.hlrWhitelistKey, {
          component: HLRRow,
          errors: {
            stringError: '',
            rateError: '',
          },
        })
        this.hlrWhitelistKey++
      },
      removeHLR (index) {
        if (Object.prototype.hasOwnProperty.call(this.hlrWhitelists, index)) {
          this.$delete(this.hlrWhitelists, index)
        }
        if (Object.prototype.hasOwnProperty.call(this.hlrWhitelistFilters, index)) {
          this.$delete(this.hlrWhitelistFilters, index)
        }
      },
      addSchedule (key) {
        if (key !== undefined) {
          this.schedulesKey = key
        }
        this.$set(this.schedules, this.schedulesKey, {
          component: ScheduleRow,
          errors: {
            startError: '',
            endError: '',
          },
        })
        this.schedulesKey++
      },
      removeSchedule (index) {
        if (Object.prototype.hasOwnProperty.call(this.schedules, index)) {
          this.$delete(this.schedules, index)
        }
        if (Object.prototype.hasOwnProperty.call(this.formData.schedule, index)) {
          this.$delete(this.formData.schedule, index)
        }
      },
      validateAndGetFilters () {
        const filters = []
        let valid = true
        for (const [key, filter] of Object.entries(this.formData.whitelists)) {
          if (!filter.string) {
            this.whitelists[key].errors.stringError = 'Required'
            valid = false
          }
          if (!filter.lengths) {
            this.whitelists[key].errors.lengthsError = 'Required'
            valid = false
          }
          if (!filter.rate) {
            this.whitelists[key].errors.rateError = 'Required'
            valid = false
          }
          let string = filter.string
          if (typeof string === 'string') {
            string = [string]
          }
          if (valid) {
            filters.push({
              type: filter.type,
              string: string,
              lengths: commaSeparatedNumbersToList(filter.lengths),
              randomDigits: commaSeparatedNumbersToList(filter.randomDigits),
              rate: filter.rate,
            })
          } else {
            return false
          }
        }
        return filters
      },
      validateAndGetHLRFilters () {
        const filters = []
        let valid = true
        for (const [key, filter] of Object.entries(this.hlrWhitelistFilters)) {
          if (!filter.string) {
            this.hlrWhitelists[key].errors.stringError = 'Required'
            valid = false
          }
          if (!filter.rate) {
            this.hlrWhitelists[key].errors.rateError = 'Required'
            valid = false
          }
          if (valid) {
            filters.push({
              type: filter.type,
              string: filter.string,
              rate: filter.rate,
            })
          } else {
            return false
          }
        }
        return filters
      },
      submit () {
        this.lookbackWarning = false
        this.errorDialogText = []
        let valid = true

        const sources = this.validateAndGetSources(false)
        if (sources === false) {
          valid = false
        }
        const filters = this.validateAndGetDestinations(true, false)
        if (filters === false) {
          valid = false
        }

        // Schedule!
        let schedule = []
        if (this.taskType === 'finalized') {
          schedule = this.validateAndGetSchedule(true, false)
          if (schedule === false) {
            valid = false
          }
        }

        // CSV stuff
        let csvNumberColumn = 0
        let csvRate = 0
        if (this.formData.destinationType === 'custom') {
          csvNumberColumn = this.csvNumberColumn
          csvRate = this.csvRate
        }

        if (this.useMaxAttempts === true) {
          this.formData.taskDuration = 600
        } else {
          this.formData.taskMaxAttempts = null
        }

        // Show dialog if needed
        if (this.errorDialogText.length > 0) {
          this.errorDialog = true
          valid = false
        }

        if (valid === false) {
          this.submitting = false
          return false
        }

        let destinationType = this.formData.destinationType
        if (this.accuracy === true && this.showAccuracy === true) {
          destinationType = 'accurate'
          filters.forEach((v, i, f) => {
            f[i].strip = this.accuracyStrip
          })
        }

        let altACD = null
        let altACDPercent = null
        if (this.alternateACD) {
          altACD = numberRangeToList(this.formData.taskAltACD)
          altACDPercent = parseInt(this.formData.taskAltACDPercent)
        }

        const postData = {
          taskType: this.taskType,
          sources: sources,
          sourceType: this.formData.sourceType,
          sourceCount: this.formData.sourceCount,
          sourceLookbackTime: this.formData.sourceLookbackTime,
          sourceLookbackTimePeriod: this.formData.sourceLookbackTimePeriod,
          sourceAutoWhitelist: pipeSeparatedStringsToList(this.formData.sourceAutoWhitelist),
          sourceAutoWhitelistType: this.formData.sourceAutoWhitelistType,
          sourceAutoBan: this.formData.sourceAutoBan,
          sourceAutoRefine: this.formData.sourceAutoRefine,
          sourceMinimumPopularity: parseInt(this.formData.sourceMinimumPopularity),
          sourceGroup: this.formData.sourceGroup,
          sourceLastSeenCheck: this.formData.sourceLastSeenCheck,
          sourceCliPrefix: this.formData.sourceCliPrefix,
          destinationType: destinationType,
          destinationLookbackType: this.formData.destinationLookbackType,
          destinationLookbackData: this.formData.destinationLookbackData,
          destinationFilters: filters,
          destinationCSVNumberColumn: csvNumberColumn,
          destinationCSVRate: parseFloat(csvRate),
          taskName: this.formData.taskName,
          taskDuration: parseInt(this.formData.taskDuration, 10),
          routeGroup: this.formData.routeGroup,
          taskMaxAttempts: this.formData.taskMaxAttempts,
          routePDDs: this.formData.pdds,
          taskAltACD: altACD,
          taskAltACDPercent: altACDPercent,
        }

        if (this.taskType === 'finalized') {
          postData.schedule = schedule
          postData.scheduleMinimumRoI = this.formData.scheduleMinimumRoI
          postData.scheduleMaximumTimeBelow = this.formData.scheduleMaximumTimeBelow
          postData.scheduleGrace = this.formData.scheduleGrace
        }

        this.submitting = true
        this.toBase64(this.csvFile).then(async b64File => {
          postData.destinationCSV = b64File
          await authFetch(`${process.env.VUE_APP_API_ROOT}tasks/groups/`, {
            method: 'POST',
            body: JSON.stringify(postData),
          })
            .then(async response => {
              const data = await response.json()
              if (Object.prototype.hasOwnProperty.call(data, 'result') && data.result === 'success') {
                this.$router.push({ name: 'tasks' })
              } else {
                this.errorDialogText = ['Received an unexpected response from the server. Not sure what to do']
                this.valid = true
              }
            })
            .catch(async error => {
              console.log(error)
              const data = await error.json()
              // Correct error response?
              if (typeof data === 'object' && Object.prototype.hasOwnProperty.call(data, 'message')) {
                // Is it an object we can loop through for feedback?
                if (typeof data.message === 'object') {
                  this.errorDialogText = data.message
                // String returned, setup dialog
                } else {
                  this.errorDialogText = [data.message]
                }
              // Generic error if we didn't get a proper response
              } else {
                this.errorDialogText = [`Server returned a ${error.status} status code`]
              }
              if (this.errorDialogText) {
                this.errorDialog = true
              }
            })
            .finally(() => {
              this.submitting = false
            })
        })
      },
      getFoundNumbers () {
        this.accuracyResults = null
        if (this.$refs.accuracyStrip.validate() === false) {
          if (this.accuracyStrip === null) {
            this.$refs.accuracyStrip.focus()
            this.$refs.accuracyStrip.blur()
          }
          return false
        }
        this.accuracyLoading = true
        authFetch(`${process.env.VUE_APP_API_ROOT}tasks/cdrs/found-numbers/`, {
          method: 'POST',
          body: JSON.stringify({
            retails: this.validateAndGetSources(),
            destinations: this.validateAndGetDestinations(true),
            strip: this.accuracyStrip,
            lookbackData: this.formData.destinationLookbackData,
            lookbackType: this.formData.destinationLookbackType,
          }),
        })
          .then(async response => {
            const data = await response.json()
            this.accuracyResults = data
          })
          .catch(async error => {
            console.log(error)
            const data = await error.json()
            console.log(data)
          })
          .finally(() => {
            this.accuracyLoading = false
          })
      },
    },
  }
</script>

<style>
.v-datatable__actions .v-datatable__actions__select .v-select__selections input {
  display: none
}
.switch-center {
  display: flex;
  justify-content: center;
}
</style>

<template>
  <layout-panel>
    <v-container>
      <v-card>
        <v-card-text class="display-2 text-center mt-5 primary white--text">
          HLR Batches
        </v-card-text>
      </v-card>
    </v-container>
    <v-container>
      <v-card>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="items"
            :loading="reloading"
            item-key="id"
            :sort-by.sync="tableOptions.sortBy"
            :sort-desc.sync="tableOptions.descending"
            hide-default-footer
            :items-per-page="-1"
            :expanded.sync="expanded"
            :single-expand="false"
            show-expand
          >
            <v-progress-linear v-slot:progress color="blue" indeterminate />
            <template v-slot:item.progress="row">
              <td>
                <v-progress-circular
                  v-if="row.item.progress !== '-'"
                  :value="row.item.progress"
                >
                  {{ row.item.progress }}
                </v-progress-circular>
                <div v-else>-</div>
              </td>
            </template>
            <template v-slot:expanded-item="row">
              <td :colspan="row.headers.length">
                <v-data-table
                  :headers="headersExpanded"
                  :items="row.item.counts"
                  hide-default-footer
                  :items-per-page="-1"
                  dense
                  class="grey darken-4"
                />
              </td>
            </template>
            <template v-slot:no-data>
              <div v-if="!loading && !error" class="text-center">
                You have no batches to display
              </div>
              <v-alert v-if="!loading && error" :value="true" color="error" icon="warning">
                Error loading batches
              </v-alert>
              <div v-if="initialLoad" class="text-center">
                <v-progress-circular indeterminate />
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </layout-panel>
</template>

<script>
  import { authFetch } from '@/plugins/authFetch'

  export default {
    data () {
      return {
        headers: [
          { text: 'ID', value: 'id' },
          { text: 'Batch ID', value: 'batchId' },
          { text: 'Added', value: 'added' },
          { text: 'Completed', value: 'completed' },
          { text: 'Progress', value: 'progress' },
        ],
        headersExpanded: [
          { text: 'Carrier', value: 'carrier' },
          { text: 'Numbers Checked', value: 'out' },
          { text: 'Numbers Verified', value: 'in' },
        ],
        items: [],
        initialLoad: true,
        loading: true,
        reloadTimer: null,
        reloading: false,
        error: false,
        tableOptions: {
          sortBy: 'id',
          descending: true,
          rowsPerPage: -1,
        },
        expanded: [],
      }
    },
    created () {
      this.getData()
      this.reloadTimer = setInterval(() => {
        this.getData()
      }, 60000)
    },
    beforeDestroy () {
      clearInterval(this.reloadTimer)
    },
    methods: {
      showProgress (item) {
        return item.taskStatus === 'running' && item.taskType !== 'finalized'
      },
      getData () {
        if (!this.initialLoad) {
          this.reloading = true
        }
        this.loading = true
        authFetch(process.env.VUE_APP_API_ROOT + 'hlr/')
          .then(async response => {
            this.items = await response.json()
          })
          .catch(error => {
            console.log(error)
            this.error = true
          })
          .finally(() => {
            this.initialLoad = false
            this.reloading = false
            this.loading = false
          })
      },
    },
  }
</script>

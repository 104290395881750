<template>
  <div>
    <v-container>
      <v-card>
        <v-card-text class="display-2 text-center mt-5 primary white--text">
          {{ pageHeader }}
          <div v-if="pageDescription !== ''" class="grey--text body-1">
            {{ pageDescription }}
          </div>
        </v-card-text>
      </v-card>
    </v-container>

    <v-container>
      <v-card>
        <v-stepper v-model="formStepsCurrent" :vertical="false">
          <v-stepper-header>
            <v-stepper-step :step="1">
              Destination Selection
            </v-stepper-step>
            <v-divider />
            <v-stepper-step :step="2">
              Source Selection
            </v-stepper-step>
            <v-divider />
            <v-stepper-step :step="3">
              Route Options
            </v-stepper-step>
            <v-divider />
            <v-stepper-step :step="4">
              Task Options
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>

            <!-- DESTINATION SELECTION -->
            <v-stepper-content step="1">
              <!-- BYPASS IF CLONING USING A CSV -->
              <v-card v-if="formData.destinationOverride !== 0">
                <v-card-text>
                  The task you are cloning was created with a CSV file. This task will use the same
                  rates and destinations loaded when that task was created.
                </v-card-text>
                <task-stepper-actions
                  :page="formStepsCurrent"
                  :total-pages="formStepsTotal"
                  @next="formStepsCurrent++"
                />
              </v-card>
              <!-- REGULAR FORM -->
              <v-form
                v-else
                ref="formDesination"
                v-model="formValid"
                @submit.prevent="validateAndGetDestinations()"
              >
                <v-card-text>
                  <v-container class="m-3">
                    <v-row>
                      <v-col cols="3">
                        <v-select
                          v-model="formData.destinationType"
                          :items="destinationTypes"
                          label="Destination Selection Method"
                        />
                      </v-col>
                    </v-row>
                    <v-row v-if="formData.destinationType === 'csv'">
                      <v-col cols="12">
                        <v-file-input
                          accepts=".csv,text/csv"
                          label="CSV Rate File"
                          required
                          :error="csvError.length > 0"
                          :error-messages="csvError"
                          @change="onFileChange"
                        />
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          v-model="formData.csvPrefixColumn"
                          :rules="[rules.required, rules.numberGT0]"
                          required
                          label="Prefix Column Number"
                          type="number"
                        />
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          v-model="formData.csvRateColumn"
                          :rules="[rules.required, rules.numberGT0]"
                          required
                          label="Rate Column Number"
                          type="number"
                        />
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          v-model="formData.csvNameColumn"
                          label="Carrier Name Column Number (optional)"
                          type="number"
                        />
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          v-model="formData.csvMaximumRate"
                          :rules="[rules.required]"
                          required
                          label="Maximum Rate"
                        >
                          <template v-slot:append>
                            <v-tooltip bottom max-width="400">
                              <template v-slot:activator="{ on }">
                                <v-icon v-on="on">help</v-icon>
                              </template>
                              Select the MAX rate from the rate sheet uploaded of any
                              destination that will be tested.
                            </v-tooltip>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row v-if="formData.destinationType === 'whitelist'">
                      <v-col cols="2">
                        <v-select
                          v-model="formData.destinationLookbackType"
                          :items="destinationLookbackTypes"
                          label="CDR Lookback Method"
                        />
                      </v-col>
                      <template v-if="formData.destinationLookbackType === 'time'">
                        <v-col cols="1">
                          <v-text-field
                            v-model="destinationLookbackTimeNum"
                            reverse
                          />
                        </v-col>
                        <v-col cols="2">
                          <v-select
                            v-model="destinationLookbackTimePeriod"
                            :items="destinationLookbackPeriods"
                          />
                        </v-col>
                      </template>
                      <template v-if="formData.destinationLookbackType === 'rows'">
                        <v-col cols="3">
                          <v-text-field
                            v-model="formData.destinationLookbackData"
                            :rules="[rules.required, rules.positiveNumber]"
                            type="number"
                            label="Amount"
                          />
                        </v-col>
                      </template>
                      <v-col cols="12">
                        <v-btn color="primary" @click="addWhitelist()">Add Filter</v-btn>
                      </v-col>
                      <template v-for="(child, index) in whitelists">
                        <component
                          :is="child.component"
                          :key="index"
                          v-model="formData.whitelists[index]"
                          v-bind="{
                            index: index,
                            errors: child.errors,
                            lookbackType: formData.destinationLookbackType,
                            lookbackData: formData.destinationLookbackData
                          }"
                          @removeWhitelist="removeWhitelist"
                        />
                      </template>
                    </v-row>
                    <v-row v-if="formData.destinationType === 'hlr'">
                      <v-col cols="3">
                        <div class="mt-5 text-end">HLR Minimum Updated Time</div>
                      </v-col>
                      <v-col cols="1">
                        <v-text-field
                          v-model="destinationLookbackTimeNum"
                          reverse
                        />
                      </v-col>
                      <v-col cols="2">
                        <v-select
                          v-model="destinationLookbackTimePeriod"
                          :items="destinationLookbackPeriods"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-btn color="primary" @click="addHLR()">Add Filter</v-btn>
                      </v-col>
                      <template v-for="(child, index) in hlrWhitelists">
                        <component
                          :is="child.component"
                          :key="index"
                          v-model="hlrWhitelistFilters[index]"
                          v-bind="{
                            index: index,
                            errors: child.errors,
                            lookbackType: formData.destinationLookbackType,
                            lookbackData: formData.destinationLookbackData
                          }"
                          @removeWhitelist="removeHLR"
                        />
                      </template>
                    </v-row>
                  </v-container>
                </v-card-text>
                <task-stepper-actions
                  :page="formStepsCurrent"
                  :total-pages="formStepsTotal"
                />
              </v-form>
            </v-stepper-content>
            <!-- END DESTINATION SELECTION -->

            <!-- START SOURCE SELECTION -->
            <v-stepper-content step="2">
              <v-form
                ref="formSources"
                v-model="formValid"
                @submit.prevent="validateSources()"
              >
                <v-card-text>
                  <v-container class="m-3">
                    <v-row>
                      <template v-if="taskType === 'auto-smart'">
                        <v-col cols="3">
                          <v-text-field
                            v-model="formData.sourceLookbackTime"
                            reverse
                            label="Popularity Lookback"
                            type="number"
                          />
                        </v-col>
                        <v-col cols="3">
                          <v-select
                            v-model="formData.sourceLookbackTimePeriod"
                            :items="manualLookbackPeriods"
                          />
                        </v-col>
                      </template>
                      <v-col cols="3">
                        <v-text-field
                          v-model="formData.sourceMinimumProfit"
                          :rules="[rules.positiveFloat]"
                          required
                          label="Minimum Source Value"
                          type="number"
                          step="0.001"
                          hint="Optional"
                          persistent-hint
                        >
                          <template v-slot:append>
                            <v-tooltip bottom max-width="400">
                              <template v-slot:activator="{ on }">
                                <v-icon v-on="on">help</v-icon>
                              </template>
                              Select the MINIMUM rate of the IPRN numbers that will be
                              tested.
                            </v-tooltip>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <v-select
                          v-model="formData.sourceGroup"
                          :items="sourceGroupItems"
                          label="Source Number Group"
                        />
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          v-model="formData.sourceCliPrefix"
                          label="CLI Prefix"
                        >
                          <template v-slot:append>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-icon v-on="on">help</v-icon>
                              </template>
                              Add a prefix to the CallerID when the call is delivered.
                            </v-tooltip>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col>
                        <v-radio-group
                          v-model="formData.sourceLastSeenCheck"
                          label="Source Filtering"
                        >
                          <v-radio label="None" value="none" />
                          <v-radio label="Verified Only" value="verified" />
                          <v-radio label="Exploration" value="exploration" />
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <task-stepper-actions
                  :page="formStepsCurrent"
                  :total-pages="formStepsTotal"
                  @prev="formStepsCurrent--"
                />
              </v-form>
            </v-stepper-content>
            <!-- END SOURCE SELECTION -->

            <!-- START ROUTE OPTIONS -->
            <v-stepper-content step="3">
              <v-form
                ref="formRouteOptions"
                v-model="formValid"
                @submit.prevent="validateRouteOptions()"
              >
                <v-card-text>
                  <v-container class="m-3">
                    <v-row>
                      <template v-if="routesLoading === false">
                        <v-col cols="4">
                          <v-select
                            v-model="formData.routeId"
                            label="Route"
                            :items="routesItems"
                            :rules="[rules.required, rules.positiveNumber]"
                          />
                        </v-col>
                        <v-col cols="2">
                          <v-btn color="primary" @click="routeForm = true">
                            New Route
                          </v-btn>
                        </v-col>
                      </template>
                      <v-col v-else cols="6" style="text-align: center">
                        <v-progress-circular
                          :size="35"
                          :width="3"
                          indeterminate
                        />
                      </v-col>
                      <v-col cols="4" offset="1">
                        <v-select
                          v-model="formData.routeDialServer"
                          :items="dialServerItems"
                          label="Outgoing Dial Server"
                        >
                          <template v-slot:append-outer>
                            <v-tooltip bottom max-width="400">
                              <template v-slot:activator="{ on }">
                                <v-icon v-on="on">help</v-icon>
                              </template>
                              Select the dial server you want to use for this task.
                              Dial servers support a maximum of 500 outgoing calls
                              per server and a maximum of 100 calls per second. If
                              you go over this limit per server, tasks will fail to
                              start.
                            </v-tooltip>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <task-stepper-actions
                  :page="formStepsCurrent"
                  :total-pages="formStepsTotal"
                  @prev="formStepsCurrent--"
                />
              </v-form>
            </v-stepper-content>
            <!-- END ROUTE OPTIONS -->

            <!-- START TASK OPTIONS -->
            <v-stepper-content step="4">
              <v-form
                ref="formTaskOptions"
                v-model="formValid"
                @submit.prevent="validateTaskOptions()"
              >
                <v-card-text>
                  <v-container class="m-3">
                    <v-row>
                      <v-col cols="4">
                        <v-text-field
                          v-model="formData.taskName"
                          :rules="[rules.required]"
                          required
                          label="Task Name"
                        />
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          v-model="formData.taskDuration"
                          :rules="[rules.required, rules.numberGT0]"
                          required
                          label="Task Duration (minutes)"
                          type="number"
                        />
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          v-model="formData.taskACD"
                          :rules="[rules.required, rules.positiveRange]"
                          required
                          label="ACD Required"
                          hint="Range"
                        >
                          <template v-slot:append>
                            <v-tooltip bottom max-width="400">
                              <template v-slot:activator="{ on }">
                                <v-icon v-on="on">help</v-icon>
                              </template>
                              Setting the ACD is setting the average duration of the
                              outgoing calls, this is a requirement that will change
                              between routes.
                            </v-tooltip>
                          </template>
                        </v-text-field>
                      </v-col>
                      <!-- <v-col cols="4">
                        <v-switch
                          v-model="easyMode"
                          label="Easy Mode"
                        />
                      </v-col> -->
                    </v-row>
                    <v-row>
                      <v-col cols="4">
                        <v-text-field
                          v-model="formData.taskMaxSimultaneousCalls"
                          :rules="[rules.required, rules.positiveNumber]"
                          required
                          label="Max Channels"
                          type="number"
                          :hint="channelsHint"
                          :persistent-hint="cpsChannelsDisabled"
                          min="1"
                          :max="channelsMax"
                          :disabled="cpsChannelsDisabled"
                        />
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          v-model="formData.taskMaxCallsPerInterval"
                          :rules="[rules.required, rules.positiveRange]"
                          required
                          label="Max Calls per Interval"
                          :hint="cpsHint"
                          :persistent-hint="cpsChannelsDisabled"
                          :disabled="cpsChannelsDisabled"
                        />
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          v-model="formData.taskMaxCallsInterval"
                          :rules="[rules.required, rules.positiveRange]"
                          required
                          label="Max Calls Interval (Seconds)"
                          hint="Range"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="4">
                        <v-switch
                          v-model="autoPDD"
                          label="Automatic PDD Adjust"
                        >
                          <template v-slot:append>
                            <v-tooltip bottom max-width="400">
                              <template v-slot:activator="{ on }">
                                <v-icon v-on="on">help</v-icon>
                              </template>
                              Enter the minimum and maximum ASR that you want
                              to run on this route and the platform will try
                              its best to acheive a comfortable number between
                              the entered values. If it cannot reach a number
                              between the values entered, the task will eventually
                              be automatically stopped.
                            </v-tooltip>
                          </template>
                        </v-switch>
                      </v-col>
                      <template v-if="autoPDD === true">
                        <v-col cols="3">
                          <v-text-field
                            v-model="formData.taskMinASR"
                            :rules="[rules.required, rules.positiveNumber]"
                            required
                            label="Min ASR"
                            type="number"
                          />
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            v-model="formData.taskMaxASR"
                            :rules="[rules.required, rules.positiveNumber]"
                            required
                            label="Max ASR"
                            type="number"
                          />
                        </v-col>
                      </template>
                      <template v-else>
                        <v-col cols="3">
                          <v-text-field
                            v-model="formData.taskPDDs"
                            :rules="[rules.required, rules.positiveCommaSepNumbers]"
                            validate-on-blur
                            required
                            label="PDDs (comma separated)"
                          />
                        </v-col>
                      </template>
                    </v-row>
                    <v-row v-if="showDialingOptions">
                      <v-col cols="4">
                        <v-switch
                          v-model="alternateACD"
                          label="Alternate ACD"
                        >
                        </v-switch>
                      </v-col>
                      <template v-if="alternateACD === true">
                        <v-col cols="3">
                          <v-text-field
                            v-model="formData.taskAltACD"
                            :rules="[rules.required, rules.positiveRange]"
                            required
                            label="Alternate ACD"
                            hint="Range"
                          />
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            v-model="formData.taskAltACDPercent"
                            :rules="[rules.required, rules.positiveNumber]"
                            required
                            label="Percent chance"
                            type="number"
                            suffix="%"
                          />
                        </v-col>
                      </template>
                    </v-row>
                  </v-container>
                </v-card-text>
                <task-stepper-actions
                  :page="formStepsCurrent"
                  :total-pages="formStepsTotal"
                  :loading="submitting"
                  @prev="formStepsCurrent--"
                />
              </v-form>
            </v-stepper-content>
            <!-- END TASK OPTIONS -->
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-container>

    <v-dialog v-model="errorDialog" max-width="500">
      <v-card>
        <v-card-title class="headline" color="error">Validation Error</v-card-title>
        <v-card-text>
          <p>You made mistakes on the form that must be fixed.</p>
          <p v-for="(error, key) in errorDialogText" :key="key">{{ error }}</p>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="errorDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="lookbackWarning" persistent max-width="500">
      <v-card>
        <v-card-title class="headline">CDR Lookback Warning</v-card-title>
        <v-card-text>
          <p>You have chosen less than 30 days for CDR lookback. This can be dangerous and cause
            unexpected and unpredicatble behaviour</p>
          <p>Are you sure you want to continue?</p>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="error" @click="lookbackWarning = false">Cancel</v-btn>
          <v-btn color="primary" @click="submit">Start Task</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="routeForm" max-width="500">
      <new-route-form @completed="handleNewRoute" @cancel="routeForm = false" />
    </v-dialog>
  </div>
</template>

<script>
  import { authFetch } from '@/plugins/authFetch'
  import * as rules from '@/plugins/rules'
  import {
    numberRangeToList,
    commaSeparatedNumbersToList,
    defaultDialServerMaximums,
    sourceGroupItems,
  } from '@/plugins/common'
  import { Promise } from 'q'
  import WhitelistRow from '@/components/WhitelistRow'
  import HLRRow from '@/components/HLRRow'
  import TaskStepperActions from '@/components/TaskStepperActions'
  import NewRouteForm from '@/components/ModalRouteForm.vue'

  export default {
    components: { TaskStepperActions, NewRouteForm },
    props: {
      taskType: { type: String, default: 'auto-exploration' },
      taskParent: { type: Number, default: 0 },
      taskId: { type: Number, default: 0 },
      previousFormData: {
        type: Object,
        default () {
          return {
            destinationType: 'csv',
            destinationLookbackType: 'time',
            destinationLookbackData: '3 year',
            whitelists: {},
            taskName: '',
            taskDuration: '',
            taskACD: '',
            taskMaxSimultaneousCalls: '',
            taskMaxCallsPerInterval: '',
            taskMaxCallsInterval: '',
            taskPDDs: '',
            taskMaxASR: '',
            taskMinASR: '',
            taskPDDLock: false,
            routeId: '',
            routeDialServer: null,
            csvPrefixColumn: null,
            csvRateColumn: null,
            csvNameColumn: null,
            csvMaximumRate: null,
            sourceMinimumProfit: '',
            sourceGroup: null,
            sourceLookbackTime: 2,
            sourceLookbackTimePeriod: 'day',
            sourceLastSeenCheck: 'none',
            sourceCliPrefix: '',
            destinationOverride: 0,
            taskAltACD: '',
            taskAltACDPercent: '',
          }
        },
      },
      pageHeader: { type: String, default: 'New Auto Exploration Task' },
      pageDescription: { type: String, default: '' },
    },
    data () {
      return {
        lookbackWarning: false,
        submitting: false,
        errorDialog: false,
        errorDialogText: '',
        rules: rules,
        formValid: true,
        formData: this.previousFormData,
        csvFile: false,
        csvError: [],
        sourceGroupItems: sourceGroupItems,
        dialServerItems: [],
        dialServerData: {},
        dialServerDefault: null,
        destinationLookbackTypes: [
          { text: 'Time', value: 'time' },
          { text: 'Rows', value: 'rows' },
        ],
        destinationLookbackPeriods: [
          { text: 'Hours', value: 'hour' },
          { text: 'Days', value: 'day' },
          { text: 'Months', value: 'month' },
          { text: 'Years', value: 'year' },
        ],
        manualLookbackPeriods: [
          { text: 'Minutes', value: 'minute' },
          { text: 'Hours', value: 'hour' },
          { text: 'Days', value: 'day' },
          { text: 'Months', value: 'month' },
          { text: 'Years', value: 'year' },
        ],
        whitelists: {},
        whitelistKey: 0,
        easyMode: false,
        routesItems: [],
        routesLoading: true,
        routeForm: false,
        hlrWhitelists: {},
        hlrWhitelistKey: 0,
        hlrWhitelistFilters: {},
        formStepsCurrent: 1,
        formStepsTotal: 4,
        alternateACD: false,
      }
    },
    computed: {
      cpsHint () {
        if (this.cpsChannelsDisabled === true) {
          return 'Must select Outgoing Dial Server first'
        }
        return `Maximum ${this.dialServer.maxCPS}`
      },
      cpsMax () {
        if (this.cpsChannelsDisabled === true) {
          return 0
        }
        return this.dialServer.maxCPS
      },
      channelsHint () {
        if (this.cpsChannelsDisabled === true) {
          return 'Must select Outgoing Dial Server first'
        }
        return `Maximum ${this.dialServer.maxChannels}`
      },
      channelsMax () {
        if (this.cpsChannelsDisabled === true) {
          return 0
        }
        return this.dialServer.maxChannels
      },
      cpsChannelsDisabled () {
        return this.dialServer === null
      },
      dialServer () {
        if (this.formData.routeDialServer === 'auto') {
          return {
            ip: 'auto',
            maxCPS: defaultDialServerMaximums.cps,
            maxChannels: defaultDialServerMaximums.channels,
          }
        }
        let ip = this.formData.routeDialServer
        if (ip === null && this.dialServerDefault !== null) {
          ip = this.dialServerDefault
        }
        if (Object.prototype.hasOwnProperty.call(this.dialServerData, ip) === false) {
          return null
        }
        return this.dialServerData[ip]
      },
      destinationTypes () {
        if (this.$store.state.permission & 4) {
          return [
            { text: 'Rate File', value: 'csv' },
            { text: 'Whitelist', value: 'whitelist' },
          ]
        }
        return [
          { text: 'Rate File', value: 'csv' },
          { text: 'Whitelist', value: 'whitelist' },
          { text: 'HLR', value: 'hlr' },
        ]
      },
      autoPDD: {
        get () {
          return !this.formData.taskPDDLock
        },
        set (value) {
          this.formData.taskPDDLock = !value
        },
      },
      destinationLookbackTimeNum: {
        get () {
          if (typeof this.formData.destinationLookbackData === 'undefined') {
            return 1
          }
          const parts = this.formData.destinationLookbackData.split(' ')
          if (isNaN(parts[0])) {
            return 1
          }
          return parseInt(parts[0], 10)
        },
        set (value) {
          this.formData.destinationLookbackData = `${value} ${this.destinationLookbackTimePeriod}`
        },
      },
      destinationLookbackTimePeriod: {
        get () {
          if (typeof this.formData.destinationLookbackData === 'undefined') {
            return 'day'
          }
          const parts = this.formData.destinationLookbackData.split(' ')
          if (parts.length < 2) {
            return 'day'
          }
          return parts[1]
        },
        set (value) {
          this.formData.destinationLookbackData = `${this.destinationLookbackTimeNum} ${value}`
        },
      },
      forceRandoms () {
        if (this.easyMode === true) {
          return '0'
        } else {
          return '0,3'
        }
      },
    },
    watch: {
      formDataLookbackData () {
        if (this.formData.destinationLookbackType === 'time') {
          const parts = this.formData.destinationLookbackData.split(' ')
          if (parts.length < 2) {
            return
          }
          this.destinationLookbackTimeNum = parts[0]
          this.destinationLookbackTimePeriod = parts[1]
        }
      },
      formDataLookbackType () {
        if (this.formData.destinationLookbackType === 'time') {
          this.formData.destinationLookbackData = '1 day'
        } else {
          this.formData.destinationLookbackData = '1000'
        }
      },
      easyMode () {
        if (this.easyMode === true) {
          this.formData.destinationLookbackData = '7 day'
        } else {
          this.formData.destinationLookbackData = '3 year'
        }
      },
    },
    mounted () {
      if (Object.keys(this.formData.whitelists).length === 0) {
        this.addWhitelist()
        this.addHLR()
      } else {
        if (Array.isArray(this.formData.whitelists) === true) {
          this.formData.whitelists = { ...this.formData.whitelists }
        }
        Object.keys(this.formData.whitelists).forEach((value, key) => {
          if (this.formData.destinationType === 'whitelist') {
            this.addWhitelist()
            this.addHLR()
          } else if (this.formData.destinationType === 'hlr') {
            this.hlrWhitelistFilters[key] = this.formData.whitelists[value]
            this.addWhitelist()
            this.addHLR(key)
          }
        })
      }

      if (this.taskId < 1) {
        this.formData.taskName = ''
      }
      authFetch(`${process.env.VUE_APP_API_ROOT}servers/`)
        .then(async response => {
          const items = await response.json()
          if (typeof items === 'object' && Object.prototype.hasOwnProperty.call(items, 'servers')) {
            if (items.servers.length > 0) {
              this.dialServerItems.push({
                text: 'Automatic',
                value: 'auto',
              })
            }
            if (Object.prototype.hasOwnProperty.call(items, 'default') && items.default !== null) {
              this.dialServerItems.push({
                text: `Use Default (${items.default.ip} - ` +
                  `${items.default.maxCPS} CPS/${items.default.maxChannels} Ports)`,
                value: null,
              })
              this.dialServerDefault = items.default.ip
            }
            items.servers.forEach(server => {
              this.dialServerItems.push({
                text: `${server.ip} - ${server.maxCPS} CPS/` +
                  `${server.maxChannels} Ports`,
                value: server.ip,
              })
              this.$set(this.dialServerData, server.ip, server)
            })
            if (this.dialServerDefault === null && this.formData.routeDialServer === null &&
              this.dialServerItems.length > 0) {
              this.$set(this.formData, 'routeDialServer', this.dialServerItems[0].value)
            }
          }
        })
      this.loadRoutes(this.formData.routeId)

      if (Array.isArray(this.formData.taskACD)) {
        this.formData.taskACD = this.formData.taskACD.join('-')
      }
    },
    methods: {
      formVerifyValidity (form, valid) {
        if (this.errorDialogText.length > 0) {
          this.errorDialog = true
          valid = false
        }

        if (form.validate() === false || valid === false) {
          this.submitting = false
          return false
        }
        return true
      },
      validateAndGetDestinations (ignoreLookback = false) {
        let valid = true
        this.errorDialogText = []
        this.lookbackWarning = false
        this.csvError = []
        this.destinationFilters = []

        let filters = []
        if (this.formData.destinationType === 'whitelist') {
          if (Object.keys(this.formData.whitelists).length < 1) {
            this.errorDialogText.push('Must include at least one destination filter')
          } else {
            filters = this.validateAndGetFilters()
            if (filters === false) {
              valid = false
            }
          }
        }
        if (this.formData.destinationType === 'hlr') {
          if (Object.keys(this.hlrWhitelistFilters).length < 1) {
            this.errorDialogText.push('Must include at least one destination filter')
          } else {
            filters = this.validateAndGetHLRFilters()
            if (filters === false) {
              valid = false
            }
          }
        }

        if (this.formData.destinationType === 'csv' && this.csvFile === false) {
          this.csvError.push('File is required')
          valid = false
        }

        if (this.formVerifyValidity(this.$refs.formDesination, valid) === false) {
          return false
        }

        if ((this.formData.destinationType === 'whitelist') &&
          this.formData.destinationLookbackType === 'time' &&
          this.destinationLookbackTimePeriod === 'day' &&
          this.destinationLookbackTimeNum <= 30 &&
          ignoreLookback === false) {
          this.lookbackWarning = true
          return false
        }

        if (this.formStepsCurrent < this.formStepsTotal) {
          this.formStepsCurrent++
        }
        return filters
      },
      validateSources () {
        if (this.formVerifyValidity(this.$refs.formSources, true) === false) {
          return false
        }
        this.formStepsCurrent++
      },
      validateTaskOptions () {
        if (this.formVerifyValidity(this.$refs.formTaskOptions, true) === false) {
          return false
        }
        this.submit()
      },
      validateRouteOptions () {
        if (this.formVerifyValidity(this.$refs.formRouteOptions, true) === false) {
          return false
        }
        this.formStepsCurrent++
      },
      handleNewRoute (id) {
        this.loadRoutes(id)
        this.routeForm = false
      },
      loadRoutes (setTo) {
        this.routesLoading = true
        authFetch(process.env.VUE_APP_API_ROOT + 'routes/')
          .then(async response => {
            const items = await response.json()
            this.routesItems = []
            items.forEach(r => {
              let prefix = 'No Prefix'
              if (r.prefix !== '') {
                prefix = `Prefix: ${r.prefix}`
              }
              let labelText = ''
              if (r.label) {
                labelText = `${r.label} - ${r.ip}:${r.port} ${prefix}`
              } else {
                labelText = `${r.ip}:${r.port} ${prefix}`
              }
              this.routesItems.push({
                text: labelText,
                value: r.id,
              })
            })
          })
          .finally(() => {
            this.routesLoading = false
            if (typeof setTo !== 'undefined') {
              this.formData.routeId = setTo
            }
          })
      },
      onFileChange (f) {
        this.csvFile = f
        if (!this.csvFile) {
          this.csvFile = false
        }
      },
      toBase64 (file) {
        if (this.formData.destinationType === 'csv') {
          return new Promise((resolve, reject) => {
            var reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
          })
        } else {
          return new Promise((resolve, reject) => {
            resolve('')
          })
        }
      },
      addWhitelist (key) {
        if (key !== undefined) {
          this.whitelistKey = key
        }
        this.$set(this.whitelists, this.whitelistKey, {
          component: WhitelistRow,
          errors: {
            stringError: '',
            lengthsError: '',
            randomDigitsError: '',
            rateError: '',
          },
        })
        this.whitelistKey++
      },
      removeWhitelist (index) {
        if (Object.prototype.hasOwnProperty.call(this.whitelists, index)) {
          this.$delete(this.whitelists, index)
        }
        if (Object.prototype.hasOwnProperty.call(this.formData.whitelists, index)) {
          this.$delete(this.formData.whitelists, index)
        }
      },
      addHLR (key) {
        if (key !== undefined) {
          this.hlrWhitelistKey = key
        }
        this.$set(this.hlrWhitelists, this.hlrWhitelistKey, {
          component: HLRRow,
          errors: {
            stringError: '',
            rateError: '',
          },
        })
        this.hlrWhitelistKey++
      },
      removeHLR (index) {
        if (Object.prototype.hasOwnProperty.call(this.hlrWhitelists, index)) {
          this.$delete(this.hlrWhitelists, index)
        }
        if (Object.prototype.hasOwnProperty.call(this.hlrWhitelistFilters, index)) {
          this.$delete(this.hlrWhitelistFilters, index)
        }
      },
      validateAndGetFilters () {
        const filters = []
        let valid = true
        for (const [key, filter] of Object.entries(this.formData.whitelists)) {
          if (!filter.string) {
            this.whitelists[key].errors.stringError = 'Required'
            valid = false
          }
          if (!filter.lengths) {
            this.whitelists[key].errors.lengthsError = 'Required'
            valid = false
          }
          if (!filter.rate) {
            this.whitelists[key].errors.rateError = 'Required'
            valid = false
          }
          if (valid) {
            filters.push({
              type: filter.type,
              string: filter.string,
              lengths: commaSeparatedNumbersToList(filter.lengths),
              randomDigits: commaSeparatedNumbersToList(filter.randomDigits),
              rate: filter.rate,
            })
          } else {
            return false
          }
        }
        return filters
      },
      validateAndGetHLRFilters () {
        const filters = []
        let valid = true
        for (const [key, filter] of Object.entries(this.hlrWhitelistFilters)) {
          if (!filter.string) {
            this.hlrWhitelists[key].errors.stringError = 'Required'
            valid = false
          }
          if (!filter.rate) {
            this.hlrWhitelists[key].errors.rateError = 'Required'
            valid = false
          }
          if (valid) {
            filters.push({
              type: filter.type,
              string: filter.string,
              rate: filter.rate,
            })
          } else {
            return false
          }
        }
        return filters
      },
      submit () {
        this.lookbackWarning = false
        this.errorDialogText = []
        let valid = true
        this.csvError = []

        if (this.autoPDD === true) {
          this.formData.taskPDDs = '18,19,20'
        } else {
          this.formData.taskMinASR = '7'
          this.formData.taskMaxASR = '14'
        }

        let maxCPS = 0
        let maxChannels = 0
        if (this.formData.routeDialServer === null && this.dialServerDefault !== null) {
          maxCPS = this.dialServerData[this.dialServerDefault].maxCPS
          maxChannels = this.dialServerData[this.dialServerDefault].maxChannels
        } else if (this.dialServer !== null) {
          maxCPS = this.dialServer.maxCPS
          maxChannels = this.dialServer.maxChannels
        }

        let taskMaxSimultaneousCalls = parseInt(this.formData.taskMaxSimultaneousCalls, 10)
        if (taskMaxSimultaneousCalls > maxChannels) {
          taskMaxSimultaneousCalls = maxChannels
          this.formData.taskMaxSimultaneousCalls = maxChannels
        }
        const taskMaxCallsPerInterval = numberRangeToList(this.formData.taskMaxCallsPerInterval)
        let k = 0
        if (taskMaxCallsPerInterval.length > 1) {
          k = 1
        }
        if (taskMaxCallsPerInterval[k] > maxCPS) {
          taskMaxCallsPerInterval[k] = maxCPS
        }

        let filters = []
        if (this.formData.destinationOverride === 0) {
          filters = this.validateAndGetDestinations(true)
          if (filters === false) {
            valid = false
          }
        }

        if (!this.formData.sourceMinimumProfit) {
          this.formData.sourceMinimumProfit = 0
        }

        if (this.errorDialogText.length > 0) {
          this.errorDialog = true
          valid = false
        }

        if (valid === false) {
          this.submitting = false
          return false
        }

        if (Object.prototype.hasOwnProperty.call(this.formData, 'csvPrefixColumn') === false) {
          this.formData.csvPrefixColumn = 0
        }
        if (Object.prototype.hasOwnProperty.call(this.formData, 'csvRateColumn') === false) {
          this.formData.csvRateColumn = 0
        }
        if (Object.prototype.hasOwnProperty.call(this.formData, 'csvNameColumn') === false) {
          this.formData.csvNameColumn = 0
        }
        if (Object.prototype.hasOwnProperty.call(this.formData, 'csvMaximumRate') === false) {
          this.formData.csvMaximumRate = 0
        }
        if (Object.prototype.hasOwnProperty.call(this.formData, 'destinationOverride') === false) {
          this.formData.destinationOverride = 0
        }

        let altACD = null
        let altACDPercent = null
        if (this.alternateACD) {
          altACD = numberRangeToList(this.formData.taskAltACD)
          altACDPercent = parseInt(this.formData.taskAltACDPercent)
        }

        const postData = {
          taskType: this.taskType,
          taskParent: 0,
          taskName: this.formData.taskName,
          taskDuration: parseInt(this.formData.taskDuration, 10),
          taskMaxSimultaneousCalls: taskMaxSimultaneousCalls,
          taskMaxCallsPerInterval: taskMaxCallsPerInterval,
          taskMaxCallsInterval: numberRangeToList(this.formData.taskMaxCallsInterval),
          taskPDDs: commaSeparatedNumbersToList(this.formData.taskPDDs),
          taskMinASR: parseInt(this.formData.taskMinASR, 10),
          taskMaxASR: parseInt(this.formData.taskMaxASR, 10),
          taskACD: numberRangeToList(this.formData.taskACD),
          taskPDDLock: this.formData.taskPDDLock,
          routeId: parseInt(this.formData.routeId, 10),
          routeDialServer: this.formData.routeDialServer,
          csvPrefixColumn: this.formData.csvPrefixColumn,
          csvRateColumn: this.formData.csvRateColumn,
          csvNameColumn: this.formData.csvNameColumn,
          csvMaximumRate: this.formData.csvMaximumRate,
          sourceMinimumProfit: this.formData.sourceMinimumProfit,
          sourceGroup: this.formData.sourceGroup,
          sourceLookbackTime: this.formData.sourceLookbackTime,
          sourceLookbackTimePeriod: this.formData.sourceLookbackTimePeriod,
          sourceLastSeenCheck: this.formData.sourceLastSeenCheck,
          sourceCliPrefix: this.formData.sourceCliPrefix,
          destinationType: this.formData.destinationType,
          destinationLookbackType: this.formData.destinationLookbackType,
          destinationLookbackData: this.formData.destinationLookbackData,
          destinationFilters: filters,
          destinationNumbers: [],
          easyMode: this.easyMode,
          destinationOverride: this.formData.destinationOverride,
          taskAltACD: altACD,
          taskAltACDPercent: altACDPercent,
        }

        // if (this.taskId > 0) {
        //   postData['taskId'] = this.taskId
        // }

        this.submitting = true
        const method = 'POST'
        // if (this.taskId > 0) {
        //   method = 'PUT'
        //   // postData['id'] = this.taskId
        // } else {
        //   method = 'POST'
        // }

        this.toBase64(this.csvFile).then(async b64File => {
          postData.csvFile = b64File
          await authFetch(process.env.VUE_APP_API_ROOT + 'tasks/auto/', {
            method: method,
            body: JSON.stringify(postData),
          })
            .then(async response => {
              const data = await response.json()
              if (Object.prototype.hasOwnProperty.call(data, 'result') && data.result === 'success') {
                this.$router.push({ name: 'tasks' })
              } else {
                this.errorDialogText = ['Received an unexpected response from the server. Not sure what to do']
                this.valid = true
              }
            })
            .catch(async error => {
              console.log(error)
              const data = await error.json()
              // Correct error response?
              if (typeof data === 'object' && Object.prototype.hasOwnProperty.call(data, 'message')) {
                // Is it an object we can loop through for feedback?
                if (typeof data.message === 'object') {
                  this.errorDialogText = data.message
                // String returned, setup dialog
                } else {
                  this.errorDialogText = [data.message]
                }
              // Generic error if we didn't get a proper response
              } else {
                this.errorDialogText = [`Server returned a ${error.status} status code`]
              }
              if (this.errorDialogText) {
                this.errorDialog = true
              }
            })
            .finally(() => {
              this.submitting = false
            })
        })
      },
    },
  }
</script>

<template>
  <layout-panel>
    <v-container>
      <v-card>
        <v-card-text class="display-2 text-center mt-5 primary white--text">
          High Rate VoIP Payment Requests
        </v-card-text>
      </v-card>
    </v-container>
    <v-container>
      <v-card>
        <v-card-title class="pt-5">Pending Payments</v-card-title>
        <v-card-text>
          <v-data-table
            :items="pendingArray"
            :headers="pendingHeaders"
            :loading="pendingLoading"
            hide-default-footer
            :items-per-page="-1"
            :single-expand="false"
            :expanded.sync="pendingExpanded"
            show-expand
          >
            <template v-slot:item.amount="row">
              ${{ floorToTwo(row.item.amount) }}
            </template>
            <template v-slot:item.invoice="row">
              <v-btn
                icon
                :loading="row.item.downloading"
                :disabled="row.item.downloading"
                @click="downloadFile(row.item.id)"
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.actions="row">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="primary"
                    icon
                    small
                    :disabled="row.item.loading"
                    :loading="row.item.loading"
                    v-on="on"
                    @click.stop="submit(row.item.id)"
                  >
                    <v-icon>check</v-icon>
                  </v-btn>
                </template>
                <span>Mark as Paid</span>
              </v-tooltip>
            </template>
            <template v-slot:expanded-item="row">
              <td :colspan="row.headers.length">
                <v-container>
                  <v-row v-for="(v, k) in row.item.details" :key="`${k}`">
                    <v-col class="pre"><strong>{{ k }}</strong>: {{ v }}</v-col>
                  </v-row>
                </v-container>
              </td>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-title class="pt-5">Completed Payments</v-card-title>
        <v-card-text>
          <v-data-table
            :items="paid"
            :headers="paidHeaders"
            :footer-props="{
              itemsPerPageOptions: [10, 50, 100]
            }"
            :options.sync="paidOptions"
            :server-items-length="paidTotal"
            :sort-by="paidSortBy"
            :sort-desc="paidDescending"
          >
            <template v-slot:item.amount="row">
              ${{ floorToTwo(row.item.amount) }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </layout-panel>
</template>

<script>
  import { authFetch, authDownload, objectToQueryString } from '@/plugins/authFetch'
  import { floorToTwo } from '@/plugins/common'
  export default {
    data: () => ({
      paid: [],
      paidLoading: false,
      paidHeaders: [
        { text: 'User', value: 'user' },
        { text: 'Date Requested', value: 'added' },
        { text: 'Period Start', value: 'start' },
        { text: 'Period End', value: 'end' },
        { text: 'Amount Paid', value: 'amount' },
        { text: 'Date Paid', value: 'paid' },
      ],
      paidOptions: {},
      paidTotal: 0,
      paidSortBy: 'end',
      paidDescending: true,
      pending: {},
      pendingLoading: false,
      pendingHeaders: [
        { text: 'User', value: 'user' },
        { text: 'Date Requested', value: 'added' },
        { text: 'Period Start', value: 'start' },
        { text: 'Period End', value: 'end' },
        { text: 'Amount Due', value: 'amount' },
        { text: 'Invoice', value: 'invoice' },
        { text: 'Details', value: 'data-table-expand' },
        { text: 'Actions', value: 'actions' },
      ],
      nextPayment: false,
      submitting: false,
      balance: null,
      pendingExpanded: [],
    }),
    computed: {
      translatedBalance () {
        if (this.balance === null) {
          return 'Balance loading...'
        }
        return `Your standing balance for this period: $${floorToTwo(this.balance)}`
      },
      pendingArray () {
        const result = []
        Object.values(this.pending).forEach(r => {
          result.push(r)
        })
        return result
      },
    },
    watch: {
      paidOptions: {
        handler () {
          this.getPaid()
        },
        deep: true,
      },
    },
    mounted () {
      this.getPending()
    },
    methods: {
      floorToTwo (v) {
        return floorToTwo(v)
      },
      downloadFile (id) {
        this.pending[id].downloading = true
        authDownload(
          `${process.env.VUE_APP_API_ROOT}admin/hrv/payment-request/invoice/${id}/`,
          'application/pdf'
        )
          .finally(() => {
            this.pending[id].downloading = false
          })
      },
      getPending () {
        this.pendingLoading = true
        authFetch(process.env.VUE_APP_API_ROOT + 'admin/hrv/payment-requests/pending/')
          .then(async response => {
            const items = await response.json()
            items.forEach(r => {
              r.loading = false
              r.downloading = false
              this.$set(this.pending, r.id, r)
            })
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            this.pendingLoading = false
          })
      },
      getPaid () {
        this.paidLoading = true
        const query = objectToQueryString({
          itemsPerPage: this.paidOptions.itemsPerPage,
          page: this.paidOptions.page,
          sortBy:
            this.paidOptions.sortBy.length === 0
              ? this.paidSortBy
              : this.paidOptions.sortBy[0],
          sortDesc:
            this.paidOptions.sortDesc.length === 0
              ? this.paidDescending === true
                ? 1
                : 0
              : this.paidOptions.sortDesc[0] === true
                ? 1
                : 0,
        })
        authFetch(process.env.VUE_APP_API_ROOT + `admin/hrv/payment-requests/?${query}`)
          .then(async response => {
            const data = await response.json()
            this.paidTotal = data.total
            this.paid = data.items
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            this.paidLoading = false
          })
      },
      submit (id) {
        this.pending[id].loading = true
        const postData = {
          id: id,
        }
        authFetch(process.env.VUE_APP_API_ROOT + 'admin/hrv/payment-requests/', {
          method: 'PUT',
          body: JSON.stringify(postData),
        })
          .then(async response => {
            await response.json()
            this.$delete(this.pending, id)
            this.getPaid()
          })
          .catch(async error => {
            console.log(error)
            this.pending[id].loading = false
          })
      },
    },
  }
</script>

<style scoped>
.pre { white-space: pre-wrap; }
</style>

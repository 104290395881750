<template>
  <layout-panel>
    <v-container>
      <v-card>
        <v-card-text class="display-2 text-center mt-5 primary white--text">
          Pending Users
        </v-card-text>
      </v-card>
    </v-container>
    <v-container v-if="loading === true" class="text-center mt-5">
      <v-progress-circular
        :size="50"
        :width="5"
        indeterminate
      />
    </v-container>
    <v-container>
      <v-alert
        v-if="loading === false && items.length === 0"
        :value="true"
        color="error"
        icon="warning"
        class="mt-5"
      >
        No data avaialable
      </v-alert>
      <template v-else-if="loading === false">
        <v-data-table
          :items="items"
          :headers="headers"
          hide-default-footer
          :items-per-page="-1"
        />
      </template>
    </v-container>
  </layout-panel>
</template>

<script>
  import { authFetch } from '@/plugins/authFetch'
  import { floorToTwo } from '@/plugins/common'

  export default {
    data () {
      return {
        loading: true,
        items: [],
        headers: [
          { text: 'User', value: 'user' },
          { text: 'Name', value: 'name' },
          { text: 'E-Mail', value: 'email' },
          { text: 'WhatsApp', value: 'whatsapp' },
          { text: 'Sykpe', value: 'skype' },
          { text: 'Referrer', value: 'referrer' },
        ],
      }
    },
    created () {
      this.getData()
    },
    methods: {
      floorToTwo (f) {
        return floorToTwo(f)
      },
      getData () {
        authFetch(process.env.VUE_APP_API_ROOT + 'admin/users/pending/')
          .then(async response => {
            const items = await response.json()
            this.items = []
            items.forEach(r => {
              const row = {
                user: r.user,
                name: '',
                email: '',
                whatsapp: '',
                skype: '',
              }
              if (Object.prototype.hasOwnProperty.call(r.contactDetails, 'Name') === true) {
                row.name = r.contactDetails.Name
              }
              if (Object.prototype.hasOwnProperty.call(r.contactDetails, 'E-Mail') === true) {
                row.email = r.contactDetails['E-Mail']
              }
              if (Object.prototype.hasOwnProperty.call(r.contactDetails, 'WhatsApp') === true) {
                row.whatsapp = r.contactDetails.WhatsApp
              }
              if (Object.prototype.hasOwnProperty.call(r.contactDetails, 'Skype') === true) {
                row.skype = r.contactDetails.Skype
              }
              if (Object.prototype.hasOwnProperty.call(r.contactDetails, 'Referrer') === true) {
                row.referrer = r.contactDetails.Referrer
              }
              this.items.push(row)
              console.log(row)
            })
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.loading = false
          })
      },
    },
  }
</script>

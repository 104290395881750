<template>
  <layout-panel>
    <v-container>
      <v-card>
        <v-card-text class="display-2 text-center mt-5 primary white--text">
          IVR Uploads
        </v-card-text>
      </v-card>
    </v-container>
    <v-container>
      <v-card>
        <v-card-title>Pending IVR Uploads</v-card-title>
        <v-card-text>
          <v-alert type="info" outlined>
            <strong>Note!</strong> Once you Complete a file, it's contents will be
            deleted from the system
          </v-alert>
        </v-card-text>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="itemsArray"
            hide-default-footer
            :items-per-page="-1"
          >
            <template v-slot:item.countries="row">
              {{ arrayToString(row.item.countries) }}
            </template>
            <template v-slot:item.fileName="row">
              <a
                v-if="row.item.downloading === false"
                @click.stop.prevent="downloadFile(row.item.id)"
              >
                {{ row.item.fileName }}
              </a>
              <v-progress-circular v-else indeterminate size="15" width="2" />
            </template>
            <template v-slot:item.actions="row">
              <v-btn
                color="primary"
                :disabled="row.item.loading"
                :loading="row.item.loading"
                small
                @click="submit(row.item.id)"
              >Mark Complete</v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </layout-panel>
</template>

<script>
  import { authFetch, authDownload } from '@/plugins/authFetch'

  export default {
    data () {
      return {
        items: {},
        headers: [
          { text: 'Added', value: 'date' },
          { text: 'Username', value: 'user' },
          { text: 'Countries', value: 'countries' },
          { text: 'File', value: 'fileName' },
          { text: 'Actions', value: 'actions' },
        ],
        loading: true,
      }
    },
    computed: {
      itemsArray () {
        const result = []
        Object.values(this.items).forEach(r => {
          result.push(r)
        })
        return result
      },
    },
    created () {
      this.getData()
    },
    methods: {
      arrayToString (arr) {
        return arr.join(', ')
      },
      downloadFile (id) {
        this.items[id].downloading = true
        authDownload(`${process.env.VUE_APP_API_ROOT}admin/ivr/upload/${id}/`)
          .finally(() => {
            this.items[id].downloading = false
          })
      },
      getData () {
        this.loading = true
        authFetch(process.env.VUE_APP_API_ROOT + 'admin/ivr/upload/')
          .then(async response => {
            const items = await response.json()
            items.forEach(r => {
              r.loading = false
              r.downloading = false
              this.$set(this.items, r.id, r)
            })
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.loading = false
          })
      },
      submit (id) {
        this.items[id].loading = true
        const postData = {
          id: id,
        }
        authFetch(process.env.VUE_APP_API_ROOT + 'admin/ivr/upload/', {
          method: 'PUT',
          body: JSON.stringify(postData),
        })
          .then(async response => {
            await response.json()
            this.$delete(this.items, id)
          })
          .catch(async error => {
            console.log(error)
            this.items[id].loading = false
          })
      },
    },
  }
</script>

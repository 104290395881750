<template>
  <v-form ref="form" @submit.prevent="submit">
    <v-text-field
      ref="pdds"
      v-model="newPDDs"
      class="mt-3 pb-0"
      label="PDDs"
      outlined
      dense
      :disabled="loading"
      :loading="loading"
      :rules="[rules.required, rules.positiveCommaSepNumbers]"
      :error="errors.length > 0"
      :error-messages="errors.join(', ')"
      @blur="submit"
      @focus="$event.target.select()"
    />
  </v-form>
</template>

<script>
  import { authFetch } from '@/plugins/authFetch'
  import * as rules from '@/plugins/rules'
  import { commaSeparatedNumbersToList } from '@/plugins/common'

  export default {
    props: {
      taskId: { type: Number, required: true },
      value: { type: Array, required: true },
    },
    data () {
      return {
        rules: rules,
        newPDDs: '',
        errors: [],
        loading: false,
      }
    },
    created () {
      this.newPDDs = this.value.join(', ')
    },
    mounted () {
      this.$nextTick(() => {
        this.$refs.pdds.focus()
      })
    },
    methods: {
      submit () {
        if (this.loading === true) {
          return false
        }
        this.errors = []
        if (!this.$refs.form.validate()) {
          return false
        }
        this.loading = true
        const pddArray = commaSeparatedNumbersToList(this.newPDDs)
        const postData = {
          pdds: pddArray,
        }
        authFetch(`${process.env.VUE_APP_API_ROOT}task/pdds/${this.taskId}/`, {
          method: 'PUT',
          body: JSON.stringify(postData),
        })
          .then(async response => {
            const data = await response.json()
            this.$emit('input', pddArray)
            this.$emit('completed', data)
          })
          .catch(async error => {
            console.log(error)
            try {
              const data = await error.json()
              if (typeof data === 'object' && Object.prototype.hasOwnProperty.call(data, 'message')) {
                // Is it an object we can loop through for feedback?
                if (typeof data.message === 'object') {
                  Object.values(data.message).forEach(msg => {
                    this.errors.push(msg)
                  })
                // String returned, setup dialog
                } else {
                  this.errors = [data.message]
                }
              // Generic error if we didn't get a proper response
              } else {
                this.errors = [`Server returned a ${error.status} status code`]
              }
            } catch (ex) {
              this.errors = ['Server error']
            }
          })
          .finally(() => {
            this.loading = false
          })
      },
    },
  }
</script>

<style>

</style>

<template>
  <layout-panel>
    <v-container>
      <v-card>
        <v-card-text class="display-2 text-center mt-5 primary white--text">
          Arbitrage with Rate Sheet
        </v-card-text>
      </v-card>
    </v-container>
    <v-form ref="form" v-model="formValid" @submit.prevent="submit">
      <v-container>
        <v-row>
          <v-col>
            <v-card>
              <v-container class="m-3">
                <v-row>
                  <v-col cols="12">
                    <span class="title">Rate File</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="8">
                    <v-file-input
                      accepts=".csv,text/csv"
                      label="CSV Rates File"
                      required
                      :error="csvError.length > 0"
                      :error-messages="csvError"
                      @change="onFileChange"
                    />
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      v-model="csvPrefixColumn"
                      :rules="[rules.required, rules.numberGT0]"
                      required
                      label="Prefix Column Number"
                      type="number"
                    />
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      v-model="csvRateColumn"
                      :rules="[rules.required, rules.numberGT0]"
                      required
                      label="Rate Column Number"
                      type="number"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-select
                      v-if="routesLoading === false"
                      v-model="routeId"
                      label="Route"
                      :items="routesItems"
                      :rules="[rules.required, rules.positiveNumber]"
                    />
                    <v-progress-circular
                      v-else
                      :size="35"
                      :width="3"
                      indeterminate
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                      v-model="countries"
                      label="Retail Countries"
                      :items="allCountries"
                      required
                      :rules="[rules.required]"
                      :loading="countriesLoading"
                      multiple
                      dense
                      validate-on-blur
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          close
                          small
                          @click:close="removeItem(data.item)"
                        >
                          {{ data.item }}
                        </v-chip>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="3">
                    <v-select
                      v-model="sourceGroup"
                      :items="sourceGroupItems"
                      label="Source Number Group"
                    />
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      v-model="cps"
                      :rules="[rules.required, rules.numberGT0]"
                      required
                      label="CPS"
                      type="number"
                    />
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      v-model="ports"
                      :rules="[rules.required, rules.numberGT0]"
                      required
                      label="Ports"
                      type="number"
                    />
                  </v-col>
                  <v-col cols="2">
                    <v-btn
                      color="primary"
                      :loading="loading"
                      :disabled="loading"
                      type="submit"
                    >Test
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-container>
      <v-card>
        <v-card-title>
          Previous Tasks
        </v-card-title>
        <v-card-text>
          <v-data-table
            :items="tasks"
            :headers="headers"
            :footer-props="{ itemsPerPageOptions: [50,100,{'text':'All',value:-1}] }"
            :items-per-page="50"
            :loading="tasksLoading"
            @click:row="goToTask"
          />
        </v-card-text>
      </v-card>
    </v-container>
    <v-dialog v-model="errorDialog" max-width="500">
      <v-card>
        <v-card-title class="headline" color="error">Validation Error</v-card-title>
        <v-card-text>
          <p>You made mistakes on the form that must be fixed.</p>
          <p v-for="(error, key) in errorDialogText" :key="key">{{ error }}</p>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="errorDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </layout-panel>
</template>

<script>
  import { authFetch } from '@/plugins/authFetch'
  import * as rules from '@/plugins/rules'
  import { Promise } from 'q'
  import { sourceGroupItems } from '@/plugins/common'

  export default {
    data () {
      return {
        submitted: false,
        loading: false,
        errorDialog: false,
        errorDialogText: '',
        rules: rules,
        csvError: [],
        headers: [
          { text: 'ID', value: 'id' },
          { text: 'Start', value: 'start' },
          { text: 'End', value: 'end' },
          { text: 'Route', value: 'route' },
          { text: 'Countries', value: 'countries' },
        ],
        csvFile: null,
        csvPrefixColumn: null,
        csvRateColumn: null,
        routeId: null,
        formValid: true,
        routesItems: [],
        routesLoading: true,
        allCountries: [],
        countries: [],
        countriesLoading: false,
        sourceGroup: null,
        sourceGroupItems: sourceGroupItems,
        cps: null,
        ports: null,
        tasks: [],
        tasksLoading: false,
        reloadTimer: null,
      }
    },
    mounted () {
      this.loadRoutes()
      this.getCountries()
      if (!this.reloadTimer) {
        this.getTasks()
        this.reloadTimer = setInterval(() => {
          this.getTasks()
        }, 10000)
      }
    },
    beforeRouteLeave (to, from, next) {
      if (this.reloadTimer) {
        clearInterval(this.reloadTimer)
      }
      next()
    },
    methods: {
      removeItem (item) {
        const index = this.countries.indexOf(item)
        if (index >= 0) this.countries.splice(index, 1)
      },
      getCountries (search) {
        this.countriesLoading = true
        authFetch(`${process.env.VUE_APP_API_ROOT}countries/`)
            .then(async response => {
              this.allCountries = [].concat(['All'], await response.json())
            })
            .catch((error) => {
              console.log(error)
            })
            .finally(() => {
              this.countriesLoading = false
            })
      },
      getTasks () {
        this.tasksLoading = true
        authFetch(`${process.env.VUE_APP_API_ROOT}arbitrage/tasks/`)
            .then(async response => {
              this.tasks = await response.json()
            })
            .catch((error) => {
              console.log(error)
            })
            .finally(() => {
              this.tasksLoading = false
            })
      },
      onFileChange (f) {
        this.csvFile = f
        if (!this.csvFile) {
          this.csvFile = false
        }
      },
      toBase64 (file) {
        return new Promise((resolve, reject) => {
          var reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = () => resolve(reader.result)
          reader.onerror = error => reject(error)
        })
      },
      loadRoutes () {
        this.routesLoading = true
        authFetch(process.env.VUE_APP_API_ROOT + 'routes/')
          .then(async response => {
            const items = await response.json()
            this.routesItems = []
            items.forEach(r => {
              let prefix = 'No Prefix'
              if (r.prefix !== '') {
                prefix = `Prefix: ${r.prefix}`
              }
              let labelText = ''
              if (r.label) {
                labelText = `${r.label} - ${r.ip}:${r.port} ${prefix}`
              } else {
                labelText = `${r.ip}:${r.port} ${prefix}`
              }
              this.routesItems.push({
                text: labelText,
                value: r.id,
              })
            })
          })
          .finally(() => {
            this.routesLoading = false
          })
      },
      submit () {
        this.errorDialogText = []
        let valid = true
        this.csvError = []

        if (this.csvFile === false) {
          this.csvError.push('File is required')
          valid = false
        }

        // Built-in validation
        if (!this.$refs.form.validate() || !valid) {
          this.submitting = false
          return false
        }

        const postData = {
          csvPrefixColumn: this.csvPrefixColumn,
          csvRateColumn: this.csvRateColumn,
          routeId: this.routeId,
          countries: this.countries,
          sourceGroup: this.sourceGroup,
          cps: this.cps,
          ports: this.ports,
        }

        this.loading = true
        this.submitted = true
        const method = 'POST'

        this.toBase64(this.csvFile).then(async b64File => {
          postData.csvFile = b64File
          await authFetch(process.env.VUE_APP_API_ROOT + 'arbitrage/compare/', {
            method: method,
            body: JSON.stringify(postData),
          })
            .then(async response => {
              const result = await response.json()
              this.$router.push({
                name: 'arbitrage-compare-result',
                params: { taskId: result.id },
              })
            })
            .catch(async error => {
              console.log(error)
              const data = await error.json()
              // Correct error response?
              if (typeof data === 'object' && Object.prototype.hasOwnProperty.call(data, 'message')) {
                // Is it an object we can loop through for feedback?
                if (typeof data.message === 'object') {
                  this.errorDialogText = data.message
                // String returned, setup dialog
                } else {
                  this.errorDialogText = [data.message]
                }
              // Generic error if we didn't get a proper response
              } else {
                this.errorDialogText = [`Server returned a ${error.status} status code`]
              }
              if (this.errorDialogText) {
                this.errorDialog = true
              }
            })
            .finally(() => {
              this.loading = false
            })
        })
      },
      goToTask (task) {
        this.$router.push({
          name: 'arbitrage-compare-result',
          params: { taskId: task.id },
        })
      },
    },
  }
</script>

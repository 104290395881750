<template>
  <layout-panel>
    <v-container>
      <v-card>
        <v-card-text class="display-2 text-center mt-5 primary white--text">
          Arbitrage with Rate Sheet
        </v-card-text>
      </v-card>
    </v-container>
    <v-container v-if="step === 0">
      <v-row>
        <v-col class="text-center">
          <v-progress-circular
            :rotate="360"
            :size="100"
            :width="15"
            :value="step0Percent"
          >
            {{ step0Text }}
          </v-progress-circular>
          <p class="mt-5">{{ loadingMessage }}</p>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="step === 1">
      <v-data-table
        :items="items"
        :headers="headers"
        :footer-props="{ itemsPerPageOptions: [50,100,{'text':'All',value:-1}] }"
        :items-per-page="50"
      >
        <template v-slot:item.callback="row">
          {{ callbackToText(row.item.callback) }}
        </template>
      </v-data-table>
    </v-container>
  </layout-panel>
</template>

<script>
  import { authFetch } from '@/plugins/authFetch'

  export default {
    props: {
      taskId: { type: Number, required: true },
    },
    data () {
      return {
        headers: [
          { text: 'CSV Prefix', value: 'prefix' },
          { text: 'CSV Rate', value: 'cost' },
          { text: 'Retail Revenue', value: 'revenue' },
          { text: 'Difference', value: 'profit' },
          { text: 'Country', value: 'country' },
          { text: 'Number Tested', value: 'retail' },
          { text: 'In CDR', value: 'callback' },
          { text: 'Exec Result', value: 'result' },
        ],
        items: [],
        step: null,
        reloadTimer: null,
        loadingError: false,
        loadingPercent: 0,
        loadingMessage: 'Waiting for server to respond',
        countdownTimerShow: false,
        countdownTimerStart: 120,
        countdownTimerCurrent: 0,
        countdownTimer: null,
      }
    },
    computed: {
      step0Percent () {
        if (this.countdownTimerShow) {
          return Math.floor(
            ((this.countdownTimerStart - this.countdownTimerCurrent) /
              this.countdownTimerStart) * 100
          )
        } else {
          return this.loadingPercent
        }
      },
      step0Text () {
        if (this.countdownTimerShow) {
          const minutes = parseInt(this.countdownTimerCurrent / 60, 10)
          let seconds = parseInt(this.countdownTimerCurrent % 60, 10)
          seconds = seconds < 10 ? '0' + seconds : seconds
          return minutes + ':' + seconds
        } else {
          return this.loadingPercent + '%'
        }
      },
    },
    watch: {
      step () {
        if (this.step === 0) {
          if (this.reloadTimer === null) {
            this.reloadTimer = setInterval(() => {
              this.getData()
            }, 5000)
            this.getData()
          }
        } else {
          if (this.reloadTimer !== null) {
            clearInterval(this.reloadTimer)
            this.reloadTimer = null
          }
          if (this.countdownTimer !== null) {
            clearInterval(this.countdownTimer)
            this.countdownTimer = null
          }
        }
      },
      loadingPercent () {
        if (this.step === 0 && this.loadingPercent === 100 && this.countdownTimer === null) {
          this.countdownTimerCurrent = this.countdownTimerStart
          this.countdownTimerShow = true
          this.countdownTimer = setInterval(() => {
            this.countdownTimerCurrent -= 1
          }, 1000)
        }
      },
    },
    mounted () {
      this.step = 0
    },
    beforeDestroy () {
      if (this.reloadTimer !== null) {
        clearInterval(this.reloadTimer)
      }
      if (this.countdownTimer !== null) {
        clearInterval(this.countdownTimer)
      }
    },
    methods: {
      callbackToText (v) {
        if (v === true) {
          return 'Yes'
        }
        return 'No'
      },
      getData () {
        authFetch(process.env.VUE_APP_API_ROOT + `arbitrage/compare/${this.taskId}/`)
          .then(async response => {
            const items = await response.json()
            if (Array.isArray(items)) {
              this.items = []
              items.forEach(item => {
                item.profit = (item.revenue - item.cost).toFixed(4)
                this.items.push(item)
              })
              this.step = 1
            } else {
              this.loadingPercent = Math.floor(items.percent)
              this.loadingMessage = items.message
            }
          })
          .catch(error => {
            console.log(error)
            this.loadingError = true
          })
      },
    },
  }
</script>

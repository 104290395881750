<template>
  <layout-panel>
    <v-container>
      <v-card>
        <v-card-text class="display-2 text-center mt-5 primary white--text">
          Route Groups
        </v-card-text>
      </v-card>
    </v-container>

    <route-group-form @completed="getGroups" />

    <v-container>
      <v-card>
        <v-card-title class="title">Route Groups</v-card-title>
        <v-card-text
          v-if="routeGroupsLoading === false && routeGroups.length === 0"
          class="text-center"
          style="font-style: italic;"
        >
          No groups to show
        </v-card-text>
        <v-card-text
          v-else-if="routeGroupsLoading === true"
          class="text-center"
        >
          <v-progress-circular
            :size="35"
            :width="3"
            indeterminate
          />
        </v-card-text>
        <v-data-table
          v-else
          :items="routeGroups"
          :headers="headers"
          :footer-props="{ itemsPerPageOptions: [50,100,{'text':'All',value:-1}] }"
          :items-per-page="50"
        >
          <template v-slot:item.routes="row">
            <v-chip v-for="v in row.item.routes" :key="`routes_${row.item.id}-${v.id}`" class="ma-1">
              {{ translateName(v) }}
            </v-chip>
          </template>
          <template v-slot:item.actions="row">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  class="mr-2"
                  v-on="on"
                  @click="editGroup(row.item)"
                >mdi-pencil
                </v-icon>
              </template>
              <span>Edit</span>
            </v-tooltip>
            <template v-if="isDeleting(row.item)">
              <v-progress-circular
                :size="20"
                :width="2"
                indeterminate
              />
            </template>
            <v-tooltip v-else bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  @click="deleteGroup(row.item)"
                >mdi-delete
                </v-icon>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </layout-panel>
</template>

<script>
  import { authFetch } from '@/plugins/authFetch'
  import RouteGroupForm from '@/components/RouteGroupForm'
  import { translateRoute } from '@/plugins/common'

  export default {
    components: { RouteGroupForm },
    data () {
      return {
        headers: [
          { text: 'Name', value: 'name' },
          { text: 'Routes', value: 'routes', sortable: false },
          { text: 'Actions', value: 'actions', sortable: false, width: 125 },
        ],
        routeGroups: [],
        routeGroupsLoading: false,
        routeGroupDeleting: {},
      }
    },
    created () {
      this.getGroups()
    },
    methods: {
      isDeleting (item) {
        return Object.prototype.hasOwnProperty.call(this.routeGroupDeleting, item.id) === true &&
          this.routeGroupDeleting[item.id] === true
      },
      translateName: translateRoute,
      getGroups () {
        this.routeGroups = []
        this.routeGroupsLoading = true
        authFetch(`${process.env.VUE_APP_API_ROOT}route-groups/`)
          .then(async response => {
            this.routeGroups = await response.json()
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.routeGroupsLoading = false
          })
      },
      deleteGroup (item) {
        this.$set(this.routeGroupDeleting, item.id, true)
        authFetch(`${process.env.VUE_APP_API_ROOT}route-group/${item.id}/`, {
          method: 'DELETE',
        })
          .then(async response => {
            await response.json()
            const k = this.routeGroups.indexOf(item)
            if (k !== -1) {
              this.routeGroups.splice(k, 1)
            }
          })
          .catch(async error => {
            console.log(error)
          })
          .finally(() => {
            this.$set(this.routeGroupDeleting, item.id, false)
          })
      },
      editGroup (item) {
        this.$router.push({ name: 'route-group', params: { id: item.id } })
      },
    },
  }
</script>

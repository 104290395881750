<template>
  <layout-panel>
    <v-container>
      <v-card>
        <v-card-text class="display-2 text-center mt-5 primary white--text">
          Running Tasks
        </v-card-text>
      </v-card>
    </v-container>
    <v-container>
      <v-card>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="items"
            :loading="loading"
            item-key="id"
            :sort-by.sync="tableOptions.sortBy"
            :sort-desc.sync="tableOptions.descending"
            hide-default-footer
            :items-per-page="-1"
          >
            <template v-slot:item.taskType="row">
              {{ translateType(row.item.taskType) }}
            </template>
            <template v-slot:item.dialServer="row">
              {{ translateDialServer(row.item.dialServer) }}
            </template>
            <template v-slot:item.progressPercent="row">
              <template v-if="showProgress(row.item)">
                <v-progress-circular :value="row.item.progressPercent">
                  {{ row.item.progressPercent }}
                </v-progress-circular>
              </template>
              <template v-else>n/a</template>
            </template>
            <template v-slot:item.taskDuration="row">
              <template v-if="showDuration(row.item)">
                {{ row.item.taskDuration }}
              </template>
              <template v-else>n/a</template>
            </template>
            <template v-slot:item.actions="row">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    @click.stop="sendAction('cancel', row)"
                  >clear</v-icon>
                </template>
                <span>Cancel - Will work when user popup finished</span>
              </v-tooltip>
            </template>
            <template v-slot:item.cost="row">
              {{ floorToTwo(row.item.cost) }}
            </template>
            <template v-slot:row.item.rates="row">
              {{ prettyRates(row.item.rates) }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </layout-panel>
</template>

<script>
  import { authFetch } from '@/plugins/authFetch'
  import { statusMap, typeMap, floorToTwo } from '@/plugins/common'

  export default {
    data () {
      return {
        headers: [
          { text: 'Created', value: 'taskCreated' },
          { text: 'User', value: 'user' },
          { text: 'Name', value: 'taskName' },
          { text: 'Type', value: 'taskType' },
          { text: 'Dial Server', value: 'dialServer' },
          { text: 'Route IP', value: 'routeIp' },
          { text: 'Duration (Mins)', value: 'taskDuration' },
          { text: 'Progress', value: 'progressPercent' },
          { text: 'ASR', value: 'asr' },
          { text: 'RoI', value: 'roi' },
          { text: 'Cost', value: 'cost' },
          { text: 'Rates', value: 'rates' },
          { text: 'Actions', value: 'actions' },
        ],
        items: [],
        loading: false,
        reloadTimer: null,
        reloading: false,
        tableOptions: {
          sortBy: 'taskCreated',
          descending: true,
          rowsPerPage: -1,
        },
      }
    },
    created () {
      this.getData()
      this.reloadTimer = setInterval(() => {
        this.getData()
      }, 10000)
    },
    beforeDestroy () {
      clearInterval(this.reloadTimer)
    },
    methods: {
      showProgress (item) {
        return item.taskType !== 'finalized'
      },
      showDuration (item) {
        return item.taskType !== 'finalized'
      },
      translateStatus (key) {
        if (Object.prototype.hasOwnProperty.call(statusMap, key)) {
          return statusMap[key]
        }
        return `Unknown (${key})`
      },
      translateDialServer (value) {
        if (value === null) {
          return 'Default'
        }
        return value
      },
      translateType (key) {
        if (Object.prototype.hasOwnProperty.call(typeMap, key)) {
          return typeMap[key]
        }
        return `Unknown (${key})`
      },
      getData () {
        if (this.loading === true) {
          return
        }
        this.loading = true
        authFetch(process.env.VUE_APP_API_ROOT + 'admin/tasks/running/')
          .then(async response => {
            const items = await response.json()
            this.items = []
            items.forEach(item => {
              if (item.cost === 0) {
                item.roi = 'n/a'
              } else {
                item.roi = floorToTwo(((item.revenue - item.cost) / item.cost) * 100)
              }
              this.items.push(item)
            })
          })
          .catch(error => {
            console.log(error)
            this.error = true
          })
          .finally(() => {
            this.loading = false
          })
      },
      sendAction () {

      },
      floorToTwo (float) {
        return floorToTwo(float)
      },
      prettyRates (rates) {
        if (rates.length === 0) {
          return 'n/a'
        }
        return rates.join(', ')
      },
    },
  }
</script>

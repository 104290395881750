<template>
  <layout-panel>
    <v-container>
      <v-card>
        <v-card-text class="display-2 text-center mt-5 primary white--text">
          Historic Popular Tasks
        </v-card-text>
      </v-card>
    </v-container>
    <v-container>
      <v-card>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="items"
            :loading="loading"
            item-key="id"
            :footer-props="{
              itemsPerPageOptions: [50, 100, 500]
            }"
            :options.sync="options"
            :server-items-length="total"
            :sort-by="sortBy"
            :sort-desc="descending"
            :single-expand="false"
            :expanded.sync="expanded"
            show-expand
          >
            <template v-slot:header.quickLaunch="props">
              <v-tooltip bottom max-width="300">
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    {{ props.header.text }}
                    <v-icon size="16" class="pl-1" v-on="on">help</v-icon>
                  </span>
                </template>
                Quick Launch - Launch the selected tasks using the "Default Task
                Settings" defined in User Settings
              </v-tooltip>
            </template>
            <template v-slot:item.destinations="row">
              {{ translateCarriers(row.item.destinations, true) }}
            </template>
            <template v-slot:item.sources="row">
              {{ translateCarriers(row.item.sources, true) }}
            </template>
            <template v-slot:item.actions="row">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    @click.stop="sendAction(row)"
                  >file_copy</v-icon>
                </template>
                <span>Create task using pairs</span>
              </v-tooltip>
            </template>
            <template v-slot:item.quickLaunch="row">
              <v-checkbox
                v-model="quickLaunch"
                :value="quickLaunchItems[row.item.qlId]"
                class="short"
              />
            </template>
            <template v-slot:expanded-item="row">
              <td :colspan="row.headers.length">
                <v-card>
                  <v-card-title class="subtitle-2">Destinations</v-card-title>
                  <v-card-text>
                    <div style="max-height: 300px; overflow-y: auto;">
                      <v-chip
                        v-for="(c, k) in row.item.destinations"
                        :key="`dest_${row.item.id}_${k}`"
                        class="mx-2 my-1"
                      >{{ c }}
                      </v-chip>
                    </div>
                  </v-card-text>
                  <v-card-title class="subtitle-2">Retails</v-card-title>
                  <v-card-text>
                    <div style="max-height: 300px; overflow-y: auto;">
                      <v-chip
                        v-for="(c, k) in row.item.sources"
                        :key="`dest_${row.item.id}_${k}`"
                        class="mx-2 my-1"
                      >{{ c }}
                      </v-chip>
                    </div>
                  </v-card-text>
                </v-card>
              </td>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions v-if="quickLaunch.length > 0">
          <v-spacer />
          <v-btn
            color="primary"
            :disabled="quickLaunchSubmitting"
            :loading="quickLaunchSubmitting"
            @click="submitQuickLaunch()"
          >
            Quick Launch {{ quickLaunch.length }} Tasks
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>

    <v-dialog v-model="quickLaunchErrorDialog" max-width="500">
      <v-card>
        <v-card-title class="headline" color="error">Server Error</v-card-title>
        <v-card-text>
          <p>There was an error processing your request.</p>
          <p v-for="(err, key) in quickLaunchErrors" :key="key">{{ err }}</p>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="quickLaunchErrorDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </layout-panel>
</template>

<script>
  import { authFetch, objectToQueryString } from '@/plugins/authFetch'
  import { destinationsToArray } from '@/plugins/common'

  export default {
    props: {
      headerText: { type: String, default: 'Running Pairs' },
    },
    data () {
      return {
        headers: [
          { text: 'Started', value: 'added' },
          { text: 'Destinations', value: 'destinations', sortable: false },
          { text: 'Retails', value: 'sources', sortable: false },
          { text: 'View', value: 'data-table-expand', sortable: false },
          { text: 'Actions', value: 'actions', sortable: false },
          { text: 'QL', value: 'quickLaunch' },
        ],
        items: [],
        loading: false,
        expanded: [],
        total: 0,
        options: {},
        sortBy: 'added',
        descending: true,
        quickLaunch: [],
        quickLaunchItems: {},
        quickLaunchErrors: [],
        quickLaunchSubmitting: false,
        quickLaunchErrorDialog: false,
      }
    },
    watch: {
      options: {
        handler () {
          this.getData()
        },
        deep: true,
      },
    },
    beforeDestroy () {
      clearInterval(this.reloadTimer)
    },
    methods: {
      translateCarriers (arr, limit) {
        const str = arr.join(', ')
        if (str.length > 55 && limit) {
          return `${str.substring(0, 50)}...`
        }
        return str
      },
      getData () {
        this.loading = true
        const query = objectToQueryString({
          itemsPerPage: this.options.itemsPerPage,
          page: this.options.page,
          sortBy:
            this.options.sortBy.length === 0
              ? this.sortBy
              : this.options.sortBy[0],
          sortDesc:
            this.options.sortDesc.length === 0
              ? this.descending === true
                ? 1
                : 0
              : this.options.sortDesc[0] === true
                ? 1
                : 0,
        })
        authFetch(`${process.env.VUE_APP_API_ROOT}tasks/historic/pairs/?${query}`)
          .then(async response => {
            const r = await response.json()
            this.quickLaunch = []
            this.quickLaunchItems = {}
            let id = 0
            this.total = r.total
            this.items = []
            r.items.forEach(item => {
              item.qlId = id
              this.quickLaunchItems[id] = {
                destinations: item.destinations,
                sources: item.sources,
              }
              item.destinations = destinationsToArray(item.destinations)
              this.items.push(item)
              id++
            })
          })
          .catch(error => {
            console.log(error)
            this.error = true
          })
          .finally(() => {
            this.loading = false
          })
      },
      sendAction (row) {
        this.$router.push({ name: 'task-new-from-pairs', params: { taskId: row.item.id } })
      },
      submitQuickLaunch () {
        const postData = { tasks: this.quickLaunch }
        this.quickLaunchErrors = []
        this.quickLaunchSubmitting = true
        this.quickLaunchErrorDialog = false
        authFetch(`${process.env.VUE_APP_API_ROOT}tasks/quick/`, {
          method: 'POST',
          body: JSON.stringify(postData),
        })
          .then(async response => {
            await response.json()
            this.$router.push({ name: 'tasks' })
          })
          .catch(async error => {
            console.log(error)
            const data = await error.json()
            if (typeof data === 'object' && Object.prototype.hasOwnProperty.call(data, 'message')) {
              // Is it an object we can loop through for feedback?
              if (typeof data.message === 'object') {
                Object.values(data.message).forEach(msg => {
                  this.quickLaunchErrors.push(msg)
                })
                // String returned, setup dialog
              } else {
                this.quickLaunchErrors = [data.message]
              }
              // Generic error if we didn't get a proper response
            } else {
              this.quickLaunchErrors = [`Server returned a ${error.status} status code`]
            }
          })
          .finally(() => {
            this.quickLaunchSubmitting = false
            if (this.quickLaunchErrors.length > 0) {
              this.quickLaunchErrorDialog = true
            }
          })
      },
    },
  }
</script>

<style scoped>
::v-deep .short {
  margin-top: 5px;
}
::v-deep .short .v-input__control {
  height: 35px;
}
</style>

<template>
  <layout-panel>
    <v-container>
      <v-card>
        <v-card-text class="display-2 text-center mt-5 primary white--text">
          Upload IVR
        </v-card-text>
      </v-card>
    </v-container>
    <v-container>
      <v-card>
        <v-card-title>Upload IVR Audio for Approval</v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="formValid" @submit.prevent="submit">
            <v-container>
              <v-row>
                <v-col>
                  <v-file-input
                    label="IVR File"
                    required
                    :error="fileError.length > 0"
                    :error-messages="fileError"
                    show-size
                    hint="Max file size: 20MB"
                    persistent-hint
                    @change="onFileChange"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-autocomplete
                    v-model="countries"
                    label="Countries"
                    :items="allCountries"
                    required
                    :rules="[rules.required]"
                    :loading="countriesLoading"
                    multiple
                    dense
                    validate-on-blur
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        close
                        small
                        @click:close="removeItem(data.item)"
                      >
                        {{ data.item }}
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn
                    color="primary"
                    type="submit"
                    block
                    :disabled="submitting"
                    :loading="submitting"
                  >Submit</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>

      <v-card>
        <v-card-title>Most Recent Approvals</v-card-title>
        <v-card-text v-if="recentLoading === true">
          <v-progress-circular
            :size="25"
            :width="3"
            indeterminate
          />
        </v-card-text>
        <v-card-text v-else-if="recent.length === 0">
          <em>Nothing to display</em>
        </v-card-text>
        <v-card-text v-else>
          <v-list-item v-for="(i, k) in recent" :key="`recent_${k}`">
            {{ i.date }} - {{ arrayToString(i.countries) }} : {{ i.fileName }}
          </v-list-item>
        </v-card-text>

        <v-card-title>Pending Requests</v-card-title>
        <v-card-text v-if="pendingLoading === true">
          <v-progress-circular
            :size="25"
            :width="3"
            indeterminate
          />
        </v-card-text>
        <v-card-text v-else-if="pending.length === 0">
          <em>Nothing to display</em>
        </v-card-text>
        <v-card-text v-else>
          <v-list-item v-for="(i, k) in pending" :key="`pending_${k}`">
            {{ i.date }} - {{ arrayToString(i.countries) }} : {{ i.fileName }}
          </v-list-item>
        </v-card-text>
      </v-card>
    </v-container>
  </layout-panel>
</template>

<script>
  import { authFetch } from '@/plugins/authFetch'
  import * as rules from '@/plugins/rules'

  export default {
    data () {
      return {
        rules: rules,
        formValid: false,
        recent: [],
        recentLoading: false,
        pending: [],
        pendingLoading: false,
        submitting: false,
        formErrors: [],
        file: false,
        fileError: [],
        allCountries: [],
        countries: [],
        countriesLoading: false,
      }
    },
    created () {
      this.getData()
      this.getCountries()
    },
    methods: {
      arrayToString (arr) {
        return arr.join(', ')
      },
      removeItem (item) {
        const index = this.countries.indexOf(item)
        if (index >= 0) this.countries.splice(index, 1)
      },
      onFileChange (f) {
        this.file = f
        if (!this.file) {
          this.file = false
          return
        }
        if (this.file !== false && this.file.size > 20000000) {
          this.fileError.push('File cannot be larger than 20MB')
          this.file = false
          return
        }
        this.fileError = []
      },
      toBase64 (file) {
        return new Promise((resolve, reject) => {
          var reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = () => resolve(reader.result)
          reader.onerror = error => reject(error)
        })
      },
      getCountries (search) {
        this.countriesLoading = true
        authFetch(`${process.env.VUE_APP_API_ROOT}countries/`)
          .then(async response => {
            this.allCountries = await response.json()
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            this.countriesLoading = false
          })
      },
      getData () {
        this.pendingLoading = true
        this.recentLoading = true
        authFetch(`${process.env.VUE_APP_API_ROOT}ivr/upload/`)
          .then(async response => {
            const items = await response.json()
            this.pending = items.pending
            this.recent = items.completed
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.pendingLoading = false
            this.recentLoading = false
          })
      },
      submit () {
        this.formErrors = []
        this.fileError = []
        if (!this.$refs.form.validate()) {
          this.formLoading = false
          return false
        }
        if (this.file === false) {
          return false
        }
        this.submitting = true
        const postData = {
          countries: this.countries,
          fileName: this.file.name,
        }
        this.toBase64(this.file).then(async b64file => {
          postData.file = b64file
          await authFetch(`${process.env.VUE_APP_API_ROOT}ivr/upload/`, {
            method: 'POST',
            body: JSON.stringify(postData),
          })
            .then(async response => {
              await response.json()
              this.file = false
              this.countries = []
              this.getData()
            })
            .catch(async error => {
              console.log(error)
              const data = await error.json()
              if (typeof data === 'object' && Object.prototype.hasOwnProperty.call(data, 'message')) {
                // Is it an object we can loop through for feedback?
                if (typeof data.message === 'object') {
                  Object.values(data.message).forEach(msg => {
                    this.formErrors.push(msg)
                  })
                // String returned, setup dialog
                } else {
                  this.formErrors = [data.message]
                }
              // Generic error if we didn't get a proper response
              } else {
                this.formErrors = [`Server returned a ${error.status} status code`]
              }
            })
            .finally(() => {
              this.submitting = false
            })
        })
      },
    },
  }
</script>

<template>
  <v-app>
    <router-view />
    <v-footer v-if="showFooter" app>
      <template v-if="showStats()">
        <template v-if="initialLoad === false">
          <span class="pr-1">{{ serverTime }}</span>
          <span class="px-1">Today's Stats:</span>
          <span class="px-1">Minutes: {{ duration }}</span>
          <span v-if="showExtra()" class="px-1">
            Cost: {{ toMoney(cost) }}
          </span>
          <span class="px-1">
            Revenue: <span class="footer-revenue">{{ toMoney(revenue) }}</span>
          </span>
          <span v-if="showExtra()" class="px-1">
            Profit: <span :class="signToColor(profit)">{{ toMoney(profit) }}</span>
          </span>
          <span v-if="isAdmin()" class="px-1">
            User Profit: <span :class="signToColor(profit)">{{ toMoney(userProfit) }}</span>
          </span>
          <span v-if="showExtra()" class="px-1">
            RoI: <span :class="signToColor(profit)">{{ percent }}%</span>
          </span>
          <span v-if="isAdmin()" class="px-1">
            Debt: <span :class="signToColor(debt)">{{ toMoney(debt) }}</span>
          </span>
          <span class="pl-1">Live Calls: {{ active }}</span>
        </template>
        <v-progress-circular
          v-else
          indeterminate
          size="15"
          width="2"
        />
        <v-spacer />

        <v-badge
          color="red"
          overlap
        >
          <template v-slot:badge>
            <span v-if="chatBadge">&nbsp;</span>
          </template>
          <v-btn
            small
            color="primary"
            @click="toggleChat()"
          >Chat</v-btn>
        </v-badge>

        <div
          v-if="chat"
          tabindex="-1"
          style="z-index: 202; position: fixed; bottom: 50px; right: 0; width: 800px;"
        >
          <v-card outlined>
            <v-toolbar dense color="primary" dark>
              Member's Chat
              <v-spacer />
              <v-toolbar-items>
                <v-btn icon @click="toggleChat()"><v-icon>clear</v-icon></v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-card-text
              id="chat-content"
              class="my-0 py-0"
              style="max-height: 300px; overflow: auto;"
              @scroll="chatOnScroll"
            >
              <v-data-table
                class="mt-1"
                :headers="chatHeaders"
                hide-default-header
                hide-default-footer
                :items="chatItems"
                :items-per-page="-1"
                dense
                :sort-by.sync="chatOptions.sortBy"
              >
                <template v-slot:item="row">
                  <tr>
                    <td colspan="4">
                      <span class="overline grey--text text--darken-1">{{ row.item.sent }} - </span>
                      <span class="overline grey--text text--lighten-1">{{ row.item.user }}</span>
                      <p>{{ row.item.text }}</p>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-text class="my-0 py-0">
              <v-container class="ma-0 pa-0">
                <v-row>
                  <v-col cols="10">
                    <v-textarea
                      id="chat-input"
                      v-model="chatText"
                      auto-grow
                      outlined
                      :rows="1"
                      single-line=""
                      :counter="1000"
                      :disabled="chatSending"
                      @keydown.enter.exact.prevent
                      @keyup.enter.exact="sendChat()"
                      @keydown.enter.shift.exact="newLineChat()"
                    />
                  </v-col>
                  <v-col cols="2">
                    <v-btn
                      color="primary"
                      fab
                      :disabled="chatSending"
                      @click="sendChat()"
                    >
                      <v-icon>send</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </div>
      </template>
      <template v-else>
        Rubix Platform
        <v-spacer />
        <v-icon class="pr-2 green--text">mdi-check-bold</v-icon>
        <span class="pr-2">System Health</span>
        <span class="green--text">OK</span>
      </template>
    </v-footer>
    <v-dialog v-model="globalMessageDialog" max-width="500">
      <v-card>
        <v-card-title class="headline" color="error">Message from Admins</v-card-title>
        <v-card-text class="pre">{{ globalMessage }}</v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="globalMessageDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
  import { authFetch, objectToQueryString } from '@/plugins/authFetch'
  import { toMoney, floorToTwo } from '@/plugins/common'

  export default {
    data: () => ({
      reloadTimer: false,
      loading: false,
      initialLoad: true,
      cost: 0,
      revenue: 0,
      profit: 0,
      userProfit: 0,
      debt: 0,
      active: '0',
      percent: 'n/a',
      serverTime: 'n/a',
      duration: '0:00',
      chat: false,
      chatHeaders: [
        { text: 'ID', value: 'id' },
        { text: 'Sent', value: 'sent' },
        { text: 'User', value: 'user' },
        { text: 'Text', value: 'text' },
      ],
      chatOptions: {
        sortBy: 'id',
        descending: true,
      },
      chatTimer: false,
      chatLoaded: false,
      chatSending: false,
      chatItemsObject: {},
      chatLastId: false,
      chatText: '',
      chatBadge: false,
      chatFirstPush: true,
      globalMessageDialog: false,
      globalMessage: '',
    }),
    computed: {
      chatItems () {
        const result = []
        Object.values(this.chatItemsObject).forEach(v => {
          result.push(v)
        })
        return result
      },
      userPermission () {
        return this.$store.state.permission
      },
      isDark () {
        return this.$store.state.dark
      },
      showFooter () {
        if (this.$route.name === 'home') {
          return false
        }
        return true
      },
    },
    watch: {
      userPermission (v) {
        this.getChats()
      },
      isDark (v) {
        this.$vuetify.theme.dark = v
      },
    },
    created () {
      this.$vuetify.theme.dark = this.isDark
    },
    beforeDestroy () {
      if (this.reloadTimer) {
        clearInterval(this.reloadTimer)
      }
      if (this.chatTimer) {
        clearInterval(this.chatTimer)
      }
    },
    methods: {
      newLineChat () {
        this.chatText = `${this.chatText}\n`
      },
      pushChats (results) {
        let atBottom = false
        if (this.chat === true) {
          const container = this.$el.querySelector('#chat-content')
          if (container.scrollTop === (container.scrollHeight - container.offsetHeight)) {
            atBottom = true
          }
        }
        results.forEach(r => {
          if (!Object.prototype.hasOwnProperty.call(this.chatItemsObject, r.id)) {
            this.$set(this.chatItemsObject, r.id, r)
          }
          if (this.chatLastId === false || r.id > this.chatLastId) {
            this.chatLastId = r.id
            if (this.chatFirstPush === false && (atBottom === false || this.chat === false)) {
              this.chatBadge = true
            }
            this.chatFirstPush = false
          }
        })
        if (atBottom === true) {
          this.$nextTick(() => {
            const container = this.$el.querySelector('#chat-content')
            container.scrollTop = container.scrollHeight
          })
        }
      },
      toggleChat () {
        this.chat = !this.chat
        if (this.chat === true) {
          this.chatBadge = false
          this.$nextTick(() => {
            const container = this.$el.querySelector('#chat-content')
            container.scrollTop = container.scrollHeight
          })
        }
        if (this.chatTimer === false) {
          this.getChats()
        }
      },
      showStats () {
        if (this.$store.state.permission) {
          if (!this.reloadTimer) {
            this.getData()
            this.reloadTimer = setInterval(() => {
              this.getData()
            }, 10000)
          }
          return true
        }
        if (this.reloadTimer) {
          clearInterval(this.reloadTimer)
        }
        return false
      },
      showExtra () {
        return !(this.$store.state.permission & 8)
      },
      isAdmin () {
        return this.$store.state.permission & 1
      },
      toMoney (float) {
        return toMoney(float)
      },
      getData () {
        if (this.loading) {
          return
        }
        this.loading = true
        authFetch(process.env.VUE_APP_API_ROOT + 'today/')
          .then(async response => {
            const items = await response.json()
            this.cost = items.cost
            this.revenue = items.revenue
            this.profit = items.profit
            this.active = items.active
            this.serverTime = items.now
            this.debt = items.debt
            if (items.cost === 0) {
              this.percent = 'n/a'
            } else {
              this.percent = floorToTwo(((this.profit + this.userProfit) / this.cost) * 100)
            }
            this.duration = this.fancyTimeFormat(items.duration)
            if (items.message !== null) {
              this.globalMessage = items.message
              this.globalMessageDialog = true
            }
            if (Object.prototype.hasOwnProperty.call(items, 'userProfit')) {
              this.userProfit = items.userProfit
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.initialLoad = false
            this.loading = false
          })
      },
      getChats () {
        if (!this.$store.state.permission) {
          return
        }
        if (this.chatTimer !== false) {
          clearInterval(this.chatTimer)
        }

        let queryString = ''
        if (this.chatLastId !== false) {
          queryString = '?' + objectToQueryString({
            last_id: this.chatLastId,
          })
        }

        authFetch(process.env.VUE_APP_API_ROOT + `chat/${queryString}`)
          .then(async response => {
            const items = await response.json()
            this.pushChats(items)
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.chatTimer = setInterval(() => {
              this.getChats()
            }, 10000)
          })
      },
      sendChat () {
        if (!this.chatText) {
          return
        }
        this.chatSending = true
        authFetch(process.env.VUE_APP_API_ROOT + 'chat/', {
          method: 'POST',
          body: JSON.stringify({ text: this.chatText }),
        })
          .then(async response => {
            await response.json()
            this.chatText = ''
            this.getChats()
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.chatSending = false
            this.$nextTick(() => {
              this.$el.querySelector('#chat-input').focus()
            })
          })
      },
      chatOnScroll ({ target: { scrollTop, clientHeight, scrollHeight } }) {
        if (scrollTop + clientHeight >= scrollHeight) {
          this.chatBadge = false
        }
      },
      fancyTimeFormat (time) {
        var hrs = ~~(time / 3600)
        var mins = ~~((time % 3600) / 60)
        var secs = ~~time % 60
        var ret = ''

        if (hrs > 0) {
          ret += '' + hrs + ':' + (mins < 10 ? '0' : '')
        }

        ret += '' + mins + ':' + (secs < 10 ? '0' : '')
        ret += '' + secs
        return ret
      },
      signToColor (num) {
        if (num >= 0) {
          return 'green--text'
        }
        return 'red--text'
      },
    },
  }
</script>

<style>
  #chat-input { margin-top: 0 !important }
  .footer-revenue { color: var(--v-footerRevenue-base) }
  .pre { white-space: pre-wrap; }
</style>

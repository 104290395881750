<template>
  <layout-panel>
    <v-container>
      <v-card>
        <v-card-text class="display-2 text-center mt-5 primary white--text">
          Billing Statement
        </v-card-text>
      </v-card>
    </v-container>
    <v-container>
      <v-card>
        <v-container>
          <v-row>
            <v-col cols="3">
              <v-menu
                v-model="startMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="start"
                    label="Start"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="start"
                  no-title
                  scrollable=""
                  @input="startMenu = false"
                />
              </v-menu>
            </v-col>
            <v-col cols="3" offset="1">
              <v-menu
                v-model="endMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="end"
                    label="End"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="end"
                  no-title
                  scrollable=""
                  @input="endMenu = false"
                />
              </v-menu>
            </v-col>
            <v-col cols="4" offset="1">
              <v-btn color="primary" :loading="loading" @click="getData()">
                Get Records
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-container>
    <v-container>
      <v-alert
        v-if="initial === true && loading === false && items.length === 0"
        :value="true"
        color="error"
        icon="warning"
      >
        No data avaialable
      </v-alert>
    </v-container>
    <template v-if="items.length > 0">
      <v-container>
        <v-card>
          <v-card-title class="display-1">Overall</v-card-title>
          <v-container>
            <v-row>
              <v-col cols="2" class="text-end pr-2">Outgoing Cost:</v-col>
              <v-col cols="2">${{ floorToTwo(totals.outgoing) }}</v-col>
              <v-col cols="2" class="text-end pr-2">Incoming Revenue:</v-col>
              <v-col cols="2">${{ floorToTwo(totals.incoming) }}</v-col>
              <v-col cols="2" class="text-end pr-2">Total Profit:</v-col>
              <v-col cols="2">${{ floorToTwo(totals.overall) }}</v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-container>
      <v-container v-if="routeTotals.length > 1">
        <v-card>
          <v-card-title class="display-1">Route Totals</v-card-title>
          <v-data-table
            :items="routeTotals"
            :headers="headersRoutes"
          >
            <template v-slot:item.cost="row">
              ${{ floorToTwo(row.item.cost) }}
            </template>
          </v-data-table>
        </v-card>
      </v-container>
    </template>
    <v-container v-for="(row, key) in items" :key="key">
      <v-card>
        <v-card-title class="display-1">Task: {{ row.name }}</v-card-title>
        <v-container>
          <v-row>
            <v-col cols="2" class="text-end pr-2">Outgoing Cost:</v-col>
            <v-col cols="2">${{ floorToTwo(row.totals.outgoing) }}</v-col>
            <v-col cols="2" class="text-end pr-2">Incoming Revenue:</v-col>
            <v-col cols="2">${{ floorToTwo(row.totals.incoming) }}</v-col>
            <v-col cols="2" class="text-end pr-2">Total Profit:</v-col>
            <v-col cols="2">${{ floorToTwo(row.totals.overall) }}</v-col>
          </v-row>
        </v-container>
        <v-card-title class="title">Incoming</v-card-title>
        <v-data-table
          :items="row.incoming"
          :headers="headersIn"
          :footer-props="{ itemsPerPageOptions: [10,50,{'text':'All',value:-1}] }"
        >
          <template v-slot:item="row">
            <tr>
              <td>{{ row.item.country }}</td>
              <td>{{ row.item.carrier }}</td>
              <td>{{ row.item.dstCarrier }}</td>
              <td>{{ floorToTwo(row.item.revenue) }}</td>
              <td>{{ row.item.billsec }}</td>
            </tr>
          </template>
        </v-data-table>
        <template v-for="(out, route) in row.outgoing">
          <v-card-title :key="out + ' header'" class="title">Outgoing - {{ route }}</v-card-title>
          <v-data-table
            v-if="out.length > 0"
            :key="route"
            :items="out"
            :headers="headersOut"
            :footer-props="{ itemsPerPageOptions: [10,50,{'text':'All',value:-1}] }"
          >
            <template v-slot:item="row">
              <tr>
                <td>{{ row.item.country }}</td>
                <td>{{ row.item.carrier }}</td>
                <td>{{ row.item.dstCarrier }}</td>
                <td>{{ floorToTwo(row.item.cost) }}</td>
                <td>{{ row.item.billsec }}</td>
              </tr>
            </template>
          </v-data-table>
          <v-card-text
            v-else
            :key="out"
            class="text-center"
            style="font-style: italic;"
          >
            No results to show
          </v-card-text>
        </template>
      </v-card>
    </v-container>
  </layout-panel>
</template>

<script>
  import { authFetch, objectToQueryString } from '@/plugins/authFetch'
  import { validateDate } from '@/plugins/validators'
  import { floorToTwo } from '@/plugins/common'

  export default {
    props: {
      startProp: { type: String, required: true },
      endProp: { type: String, required: true },
    },
    data () {
      return {
        start: this.startProp,
        startMenu: false,
        end: this.endProp,
        endMenu: false,
        items: [],
        totals: {},
        routeTotals: [],
        loading: false,
        initial: false,
        headersIn: [
          { text: 'Country', value: 'country' },
          { text: 'Carrier', value: 'carrier' },
          { text: 'Destination Carrier', value: 'dstCarrier' },
          { text: 'Revenue', value: 'revenue' },
          { text: 'Duration', value: 'billsec' },
        ],
        headersOut: [
          { text: 'Country', value: 'country' },
          { text: 'Carrier', value: 'carrier' },
          { text: 'Destination Carrier', value: 'dstCarrier' },
          { text: 'Cost', value: 'Cost' },
          { text: 'Duration', value: 'billsec' },
        ],
        headersRoutes: [
          { text: 'Route Name', value: 'name' },
          { text: 'Cost', value: 'cost' },
          { text: 'Duration', value: 'billsec' },
          { text: 'Total Calls', value: 'calls' },
        ],
      }
    },
    created () {
      if (!validateDate(this.start)) {
        this.start = new Date().toISOString().substr(0, 10)
      }
      if (!validateDate(this.end)) {
        const tomorrow = new Date()
        tomorrow.setDate(tomorrow.getDate() + 1)
        this.end = tomorrow.toISOString().substr(0, 10)
      }
    },
    methods: {
      objectLength (obj) {
        if (typeof obj !== 'object') {
          return 0
        }
        return Object.keys(obj).length
      },
      floorToTwo (float) {
        return floorToTwo(float)
      },
      getData () {
        const queryString = objectToQueryString({
          start: this.start,
          end: this.end,
        })
        this.initial = true
        this.loading = true
        this.totals = {}
        this.items = []
        authFetch(process.env.VUE_APP_API_ROOT + `billing/?${queryString}`)
          .then(async response => {
            const items = await response.json()
            this.totals = items.overall
            this.items = items.tasks
            this.routeTotals = items.routeTotals
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.loading = false
          })
      },
    },
  }
</script>

<template>
  <layout-panel>
    <v-container><v-card>
      <v-card-text class="display-2 text-center mt-5 primary white--text">
        {{ translateType(type) }}
        <div class="grey--text text--lighten-1 body-1">
          {{ pageDescription }}
        </div>
      </v-card-text>
    </v-card></v-container>
    <task-form-steps
      :key="translateType(type)"
      :task-type="type"
      :external-task="external"
    />
  </layout-panel>
</template>

<script>
  import TaskFormSteps from '@/components/TaskFormSteps'
  import { typeMap } from '@/plugins/common'

  export default {
    components: { TaskFormSteps },
    props: {
      type: { type: String, default: 'exploration' },
      external: { type: Boolean, default: false },
    },
    computed: {
      pageDescription () {
        if (this.external === true) {
          return 'External use only. You can create a task just using the IPRN numbers, ' +
            'without generating any calls using the platform.'
        } else if (this.type === 'exploration') {
          return 'Configure the form below to start a generic access search. Select ' +
            'the destination, source numbers and your route and click Start Task to ' +
            'begin.'
        } else {
          return 'Configure the form below to start a production access task. Select ' +
            'the destination, source numbers and your route, additionally, you can ' +
            'choose a minimum ROI and time settings for this type of task, and then ' +
            'click Start Task to begin.'
        }
      },
    },
    methods: {
      translateType (key) {
        if (this.external === true) {
          return 'External Traffic Task'
        }
        if (Object.prototype.hasOwnProperty.call(typeMap, key)) {
          return typeMap[key]
        }
        return `Unknown (${key})`
      },
    },
  }
</script>

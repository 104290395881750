<template>
  <layout-panel>
    <v-container>
      <v-card>
        <v-card-text class="display-2 text-center mt-5 primary white--text">
          Revenue Boost Tasks
        </v-card-text>
      </v-card>
    </v-container>
    <v-container v-if="items.running.length > 0">
      <v-card>
        <v-card-title>Active Tasks</v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="items.running"
            :loading="reloading"
            item-key="id"
            :sort-by.sync="tableOptions.sortBy"
            :sort-desc.sync="tableOptions.descending"
            hide-default-footer
            :items-per-page="-1"
            @click:row="goToTask"
          >
            <v-progress-linear v-slot:progress color="blue" indeterminate />
            <template v-slot:item.taskStatus="row">
              <template v-if="row.item.taskStatus === 'error'">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      {{ translateStatus(row.item.taskStatus) }}
                    </span>
                  </template>
                  <span v-if="row.item.errorText !== ''">{{ row.item.errorText }}</span>
                  <span v-else>System error occurred</span>
                </v-tooltip>
              </template>
              <template v-else>
                {{ translateStatus(row.item.taskStatus) }}
              </template>
            </template>
            <template v-slot:item.actions="row">
              <template v-if="!row.loading">
                <v-tooltip v-if="getActions(row.item) === 'cancel'" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      @click.stop="sendAction('cancel', row)"
                    >clear</v-icon>
                  </template>
                  <span>Cancel</span>
                </v-tooltip>
                <v-tooltip v-if="getActions(row.item) === 'restart'" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      @click.stop="sendAction('restart', row)"
                    >replay</v-icon>
                  </template>
                  <span>Restart</span>
                </v-tooltip>
                <v-tooltip v-if="showHide(row.item)" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      class="pl-2"
                      v-on="on"
                      @click.stop="sendAction('hide', row)"
                    >visibility_off</v-icon>
                  </template>
                  <span>Hide</span>
                </v-tooltip>
              </template>
              <v-progress-circular
                v-else
                indeterminate
                size="15"
                width="2"
              />
              <template v-if="getActions(row.item) === false">&nbsp;</template>
            </template>
            <template v-slot:no-data>
              <div v-if="!loading && !error" class="text-center">
                You have no tasks to display
              </div>
              <v-alert v-if="!loading && error" :value="true" color="error" icon="warning">
                Error loading tasks
              </v-alert>
              <div v-if="initialLoad" class="text-center">
                <v-progress-circular indeterminate />
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
    <v-container>
      <v-card>
        <v-card-title>Inactive Tasks</v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headersOther"
            :items="items.other"
            :loading="reloading"
            item-key="id"
            :sort-by.sync="tableOptions.sortBy"
            :sort-desc.sync="tableOptions.descending"
            hide-default-footer
            :items-per-page="-1"
            @click:row="goToTask"
          >
            <v-progress-linear v-slot:progress color="blue" indeterminate />
            <template v-slot:item.taskStatus="row">
              <template v-if="row.item.taskStatus === 'error'">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      {{ translateStatus(row.item.taskStatus) }}
                    </span>
                  </template>
                  <span v-if="row.item.errorText !== ''">{{ row.item.errorText }}</span>
                  <span v-else>System error occurred</span>
                </v-tooltip>
              </template>
              <template v-else>
                {{ translateStatus(row.item.taskStatus) }}
              </template>
            </template>
            <template v-slot:item.actions="row">
              <template v-if="!row.loading">
                <v-tooltip v-if="getActions(row.item) === 'cancel'" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      @click.stop="sendAction('cancel', row)"
                    >clear</v-icon>
                  </template>
                  <span>Cancel</span>
                </v-tooltip>
                <v-tooltip v-if="getActions(row.item) === 'restart'" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      @click.stop="sendAction('restart', row)"
                    >replay</v-icon>
                  </template>
                  <span>Restart</span>
                </v-tooltip>
                <v-tooltip v-if="showHide(row.item)" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      class="pl-2"
                      v-on="on"
                      @click.stop="sendAction('hide', row)"
                    >visibility_off</v-icon>
                  </template>
                  <span>Hide</span>
                </v-tooltip>
              </template>
              <v-progress-circular
                v-else
                indeterminate
                size="15"
                width="2"
              />
              <template v-if="getActions(row.item) === false">&nbsp;</template>
            </template>
            <template v-slot:no-data>
              <div v-if="!loading && !error" class="text-center">
                You have no tasks to display
              </div>
              <v-alert v-if="!loading && error" :value="true" color="error" icon="warning">
                Error loading tasks
              </v-alert>
              <div v-if="initialLoad" class="text-center">
                <v-progress-circular indeterminate />
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </layout-panel>
</template>

<script>
  import { authFetch } from '@/plugins/authFetch'
  import { statusMap } from '@/plugins/common'

  export default {
    data () {
      return {
        headers: [
          { text: 'Created', value: 'taskCreated' },
          { text: 'Base Lookup', value: 'taskName' },
          { text: 'Status', value: 'taskStatus' },
          { text: 'Source Peer', value: 'source' },
          { text: 'Destination Peer', value: 'destination' },
          { text: 'Dial Server', value: 'dialServer' },
          { text: 'Percentage', value: 'percentage' },
          { text: 'Revenue', value: 'revenue' },
          { text: 'Actions', value: 'actions' },
        ],
        headersOther: [
          { text: 'Created', value: 'taskCreated' },
          { text: 'Base Lookup', value: 'taskName' },
          { text: 'Status', value: 'taskStatus' },
          { text: 'Source Peer', value: 'source' },
          { text: 'Destination Peer', value: 'destination' },
          { text: 'Percentage', value: 'percentage' },
          { text: 'Actions', value: 'actions' },
        ],
        headersRoute: [
          { text: 'Prefix', value: 'prefix' },
          { text: 'IP', value: 'ip' },
          { text: 'Port', value: 'port' },
          { text: 'Status', value: 'status' },
          { text: 'Day Prefixes', value: 'prefixes' },
          { text: 'Evening Prefixes', value: 'prefixesAlt' },
          { text: 'Max Ports', value: 'maxSimultaneousCalls' },
          { text: 'Actions', value: 'actions' },
        ],
        routeItems: [],
        items: {
          running: [],
          other: [],
        },
        initialLoad: true,
        loading: true,
        reloadTimer: null,
        reloading: false,
        error: false,
        tableOptions: {
          sortBy: 'taskCreated',
          descending: true,
          rowsPerPage: -1,
        },
      }
    },
    computed: {
      userHasRoutes () {
        return this.$store.state.permission & 6
      },
    },
    created () {
      this.getData()
      this.reloadTimer = setInterval(() => {
        this.getData()
      }, 10000)
    },
    beforeDestroy () {
      clearInterval(this.reloadTimer)
    },
    methods: {
      getData () {
        if (!this.initialLoad) {
          this.reloading = true
        }
        this.loading = true
        authFetch(`${process.env.VUE_APP_API_ROOT}tasks/rev-boost/`)
          .then(async response => {
            const items = await response.json()
            this.items.running = []
            this.items.other = []
            items.forEach(item => {
              if (item.taskStatus === 'running') {
                this.items.running.push(item)
              } else {
                this.items.other.push(item)
              }
            })
          })
          .catch(error => {
            console.log(error)
            this.error = true
          })
          .finally(() => {
            this.initialLoad = false
            this.reloading = false
            this.loading = false
          })
      },
      showHide (item) {
        const action = this.getActions(item)
        return action === 'resume' || action === 'restart'
      },
      goToTask (row) {
        this.$router.push({
          name: 'revboost-task',
          params: { taskId: row.id },
        })
      },
      translateStatus (key) {
        if (Object.prototype.hasOwnProperty.call(statusMap, key)) {
          return statusMap[key]
        }
        return `Unknown (${key})`
      },
      getActions (item) {
        if (item.taskStatus === 'running') {
          return 'cancel'
        }
        if (item.taskStatus === 'aborted' || item.taskStatus === 'error') {
          return 'restart'
        }
        return false
      },
      sendAction (action, row) {
        row.loading = true
        authFetch(`${process.env.VUE_APP_API_ROOT}task/action/${row.item.id}/`, {
          method: 'POST',
          body: JSON.stringify({ action: action }),
        })
          .then(async response => {
            const result = await response.json()
            row.item.taskStatus = result.result
            if (action === 'hide') {
              this.items.other.forEach((v, k) => {
                if (v.id === row.item.id) {
                  this.$delete(this.items.other, k)
                }
              })
            }
          })
          .catch(error => {
            console.log(error)
            this.error = true
          })
          .finally(() => {
            row.loading = false
          })
      },
    },
  }
</script>

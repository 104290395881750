<template>
  <v-row class="align-center">
    <v-col cols="6" class="text-right">
      {{ translateName(route) }}
    </v-col>
    <v-col cols="6" md="3" lg="2">
      <v-text-field
        ref="pdds"
        v-model="pdds"
        :rules="[rules.required, rules.positiveCommaSepNumbers]"
        required
        label="PDDs"
        @blur="push"
      />
    </v-col>
  </v-row>
</template>

<script>
  import { commaSeparatedNumbersToList, translateRoute } from '@/plugins/common'
  import * as rules from '@/plugins/rules'

  export default {
    props: {
      value: { type: Array },
      route: { type: Object },
    },
    data () {
      return {
        pdds: this.value,
        rules: rules,
      }
    },
    created () {
      if (!this.pdds) {
        this.pdds = this.route.pdds
      }
      this.pdds = this.pdds.join(', ')
      this.push()
    },
    methods: {
      translateName: translateRoute,
      push () {
        this.$emit('input', commaSeparatedNumbersToList(this.pdds))
      },
      validate () {
        return this.$refs.pdds.validate()
      },
    },
  }
</script>

<style>

</style>

<template>
  <layout-panel>
    <v-container>
      <v-card>
        <v-card-text class="display-2 text-center mt-5 primary white--text">
          My Number Rates
        </v-card-text>
      </v-card>
    </v-container>
    <v-card class="mt-5">
      <v-card-text>
        <v-card-title>Filters</v-card-title>
        <v-form ref="form" @submit.prevent="applyFilter">
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="filterNumberField"
                  label="Number Filter"
                  clearable
                  prepend-icon="filter_list"
                  :rules="[rules.moreThanOneCharacter]"
                  validate-on-blur
                />
              </v-col>
              <v-col>
                <v-text-field
                  v-model="filterCarrierField"
                  label="Carrier Filter"
                  clearable
                  prepend-icon="filter_list"
                  :rules="[rules.moreThanTwoCharacters]"
                  validate-on-blur
                />
              </v-col>
              <v-col>
                <v-text-field
                  v-model="filterCountryField"
                  label="Country Filter"
                  clearable
                  prepend-icon="filter_list"
                  :rules="[rules.moreThanTwoCharacters]"
                  validate-on-blur
                />
              </v-col>
              <v-col>
                <v-btn color="primary" type="submit">Apply</v-btn>
              </v-col>
              <v-col>
                <v-btn
                  color="primary"
                  :disabled="downloading"
                  :loading="downloading"
                  @click="downloadCSV()"
                >Download as CSV</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :footer-props="{
            itemsPerPageOptions: [50, 100, 500]
          }"
          :options.sync="options"
          :server-items-length="total"
          :sort-by="sortBy"
          :sort-desc="descending"
        >
          <template v-slot:no-data>
            <div v-if="!loading && !error" class="text-center">
              No IPRN Rates found
            </div>
            <v-alert
              v-if="!loading && error"
              :value="true"
              color="error"
              icon="warning"
            >
              Error loading numbers
            </v-alert>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </layout-panel>
</template>

<script>
  import { authFetch, objectToQueryString, authDownload } from '@/plugins/authFetch'
  import * as rules from '@/plugins/rules'
  export default {
    props: {
      taskId: { type: Number, default: 0 },
    },
    data () {
      return {
        headers: [
          { text: 'Number', value: 'number' },
          { text: 'Prefix', value: 'prefix' },
          { text: 'Carrier', value: 'carrier' },
          { text: 'Country', value: 'country' },
          { text: 'Revenue', value: 'revenue' },
        ],
        items: [],
        loading: false,
        error: false,
        total: 0,
        options: {},
        sortBy: 'number',
        descending: false,
        filterCarrierField: '',
        filterCountryField: '',
        filterNumberField: '',
        rules: rules,
        filterCarrier: '',
        filterCountry: '',
        filterNumber: '',
        downloading: false,
      }
    },
    watch: {
      options: {
        handler () {
          this.getData()
        },
        deep: true,
      },
    },
    methods: {
      downloadCSV () {
        this.downloading = true
        authDownload(`${process.env.VUE_APP_API_ROOT}user/numbers/download/`)
          .finally(() => {
            this.downloading = false
          })
      },
      applyFilter () {
        if (!this.$refs.form.validate()) {
          this.submitting = false
          return false
        }
        this.filterCarrier = this.filterCarrierField
        this.filterCountry = this.filterCountryField
        this.filterNumber = this.filterNumberField
        this.options.page = 1
        this.getData()
      },
      getData () {
        this.loading = true
        const query = objectToQueryString({
          itemsPerPage: this.options.itemsPerPage,
          page: this.options.page,
          sortBy:
            this.options.sortBy.length === 0
              ? this.sortBy
              : this.options.sortBy[0],
          sortDesc:
            this.options.sortDesc.length === 0
              ? this.descending === true
                ? 1
                : 0
              : this.options.sortDesc[0] === true
                ? 1
                : 0,
          filterCarrier: this.filterCarrier,
          filterCountry: this.filterCountry,
          filterNumber: this.filterNumber,
        })
        authFetch(process.env.VUE_APP_API_ROOT + `user/numbers/?${query}`)
          .then(async response => {
            const r = await response.json()
            this.total = r.total
            this.items = r.items
          })
          .catch(error => {
            console.log(error)
            this.error = true
          })
          .finally(() => {
            this.loading = false
          })
      },
    },
  }
</script>

export function validateIPV4 (string) {
  if (typeof string !== 'string') {
    return false
  }
  const blocks = string.split('.')
  if (blocks.length === 4) {
    const first = blocks.every(block => {
      return (
        !isNaN(block) && parseInt(block, 10) >= 0 && parseInt(block, 10) <= 255
      )
    })
    return first && parseInt(blocks[0], 10) > 0 && parseInt(blocks[3], 10) > 0
  }
  return false
}

export function validatePort (string) {
  return (
    !isNaN(string) && parseInt(string, 10) > 0 && parseInt(string, 10) <= 65535
  )
}

export function validateIPAndPort (string) {
  const parts = string.split(':')
  if (parts.length !== 2) {
    return false
  }
  return validateIPV4(parts[0]) && validatePort(parts[1])
}

export function validateCommaSeparatedPositiveNumbers (string) {
  if (typeof string !== 'string') {
    return false
  }
  if (string === '') {
    return true
  }
  const blocks = string.split(',')
  return blocks.every(block => {
    block = block.trim()
    return !isNaN(block) && parseInt(block, 10) >= 0
  })
}

export function validateCommaSeparatedString (string) {
  if (typeof string !== 'string') {
    return false
  }
  if (string === '') {
    return true
  }
  const blocks = string.split(',')
  return blocks.every(block => {
    block = block.trim()
    return block !== ''
  })
}

export function validatePositiveRange (s) {
  if (typeof s !== 'string') {
    return false
  }
  let blocks = s.split('-')
  blocks = blocks.map(block => block.trim())
  if (isNaN(blocks[0])) {
    return false
  }
  const left = parseInt(blocks[0], 10)
  if (blocks.length === 1) {
    return left >= 0
  }
  if (blocks.length === 2) {
    if (isNaN(blocks[1])) {
      return false
    }
    const right = parseInt(blocks[1], 10)
    return left >= 0 && right >= 0 && left <= right
  } else {
    return false
  }
}

export function validateDate (string) {
  return string.match(/\d{4}-\d{2}-\d{2}/)
}

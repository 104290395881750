<template>
  <layout-panel>
    <v-container>
      <v-card>
        <v-card-text class="display-2 text-center mt-5 primary white--text">
          CLI Verification Results
        </v-card-text>
      </v-card>
    </v-container>
    <v-container v-if="step === 0">
      <v-row>
        <v-col class="text-center">
          <v-progress-circular
            :rotate="360"
            :size="100"
            :width="15"
            :value="loadingPercent"
          >
            {{ step0Text }}
          </v-progress-circular>
          <p class="mt-5">{{ loadingMessage }}</p>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="step === 1">
      <v-data-table
        :items="items"
        :headers="headers"
        :footer-props="{ itemsPerPageOptions: [50,100,{'text':'All',value:-1}] }"
        :items-per-page="50"
      >
        <template v-slot:item.cli="row">
          {{ translateCLI(row.item.cli) }}
        </template>
      </v-data-table>
    </v-container>
  </layout-panel>
</template>

<script>
  import { authFetch } from '@/plugins/authFetch'

  export default {
    props: {
      taskId: { type: Number, required: true },
    },
    data () {
      return {
        headers: [
          { text: 'Retail', value: 'retail' },
          { text: 'Destination', value: 'destination' },
          { text: 'Destination Country', value: 'country' },
          { text: 'Duration', value: 'billsec' },
          { text: 'CLI Result', value: 'cli' },
        ],
        items: [],
        step: null,
        reloadTimer: null,
        loadingError: false,
        loadingPercent: 0,
        loadingMessage: 'Waiting for server to respond',
      }
    },
    computed: {
      step0Text () {
        return this.loadingPercent + '%'
      },
    },
    watch: {
      step () {
        if (this.step === 0) {
          if (this.reloadTimer === null) {
            this.reloadTimer = setInterval(() => {
              this.getData()
            }, 5000)
            this.getData()
          }
        } else {
          if (this.reloadTimer !== null) {
            clearInterval(this.reloadTimer)
            this.reloadTimer = null
          }
        }
      },
    },
    mounted () {
      this.step = 0
    },
    beforeDestroy () {
      if (this.reloadTimer !== null) {
        clearInterval(this.reloadTimer)
      }
    },
    methods: {
      translateCLI (val) {
        if (val === '') {
          return 'No CLI'
        }
        return val
      },
      getData () {
        authFetch(process.env.VUE_APP_API_ROOT + `cli/test/${this.taskId}/`)
          .then(async response => {
            const items = await response.json()
            if (Array.isArray(items)) {
              this.items = items
              this.step = 1
            } else {
              this.loadingPercent = Math.floor(items.percent)
              this.loadingMessage = items.message
            }
          })
          .catch(error => {
            console.log(error)
            this.loadingError = true
          })
      },
    },
  }
</script>

<template>
  <layout-panel>
    <v-container>
      <v-card>
        <v-card-text class="display-2 text-center mt-5 primary white--text">
          Submit Support Ticket
        </v-card-text>
      </v-card>
    </v-container>
    <v-container>
      <v-card v-if="submitComplete === false">
        <v-form ref="form" v-model="formValid" @submit.prevent="submit">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="formSubject"
                    label="Subject"
                    :rules="[rules.required]"
                    validate-on-blur
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea
                    v-model="formBody"
                    label="What do you need help with?"
                    :rules="[rules.required]"
                    validate-on-blur
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-title>How Can We Contact You?</v-card-title>
          <v-card-text>
            Fill at least one
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="formEmail"
                    label="E-Mail"
                    :error-messages="contactError"
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="formSkype"
                    label="Skype"
                    :error-messages="contactError"
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="formWhatsApp"
                    label="WhatsApp"
                    :error-messages="contactError"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn
                    color="primary"
                    type="submit"
                    block
                    :disabled="submitting"
                    :loading="submitting"
                  >Submit</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-form>
      </v-card>

      <v-card v-else>
        <v-card-title>Thank You!</v-card-title>
        <v-card-text>We will get back to you within 48 hours!</v-card-text>
      </v-card>
    </v-container>

  </layout-panel>
</template>

<script>
  import { authFetch } from '@/plugins/authFetch'
  import * as rules from '@/plugins/rules'

  export default {
    data () {
      return {
        rules: rules,
        formValid: false,
        pendingLoading: false,
        submitting: false,
        formErrors: [],
        formEmail: null,
        formSkype: null,
        formWhatsApp: null,
        formSubject: null,
        formBody: null,
        submitComplete: false,
        contactError: '',
      }
    },
    watch: {
      formEmail () { this.contactError = '' },
      formSkype () { this.contactError = '' },
      formWhatsApp () { this.contactError = '' },
    },
    methods: {
      submit () {
        this.submitting = true
        this.formErrors = []
        let error = false
        if (!this.$refs.form.validate()) {
          error = true
        }

        if (!this.formEmail && !this.formSkype && !this.formWhatsApp) {
          this.contactError = 'Must fill in at least one'
          error = true
        }

        if (error === true) {
          this.submitting = false
          return
        }

        const postData = {
          subject: this.formSubject,
          body: this.formBody,
          contactEmail: this.formEmail,
          contactSkype: this.formSkype,
          contactWhatsApp: this.formWhatsApp,
        }
        authFetch(process.env.VUE_APP_API_ROOT + 'support/ticket/', {
          method: 'POST',
          body: JSON.stringify(postData),
        })
          .then(async response => {
            await response.json()
            this.submitComplete = true
          })
          .catch(async error => {
            console.log(error)
          })
          .finally(() => {
            this.submitting = false
          })
      },
    },
  }
</script>

<template>
  <layout-panel>
    <v-container>
      <v-card>
        <v-card-text class="display-2 text-center mt-5 primary white--text">
          Automatic Access Sub-Tasks
        </v-card-text>
      </v-card>
    </v-container>
    <h2>{{ routeName }}</h2>
    <v-card class="mt-5">
      <v-card-title class="display-2">Running</v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="itemsRunning"
          hide-default-footer
          :items-per-page="-1"
          @click:row="goToTask"
        >
          <template v-slot:item.taskStatus="row">
            <template v-if="row.item.errorText !== ''">
              test
            </template>
            <template v-else>
              {{ translateStatus(row.item.taskStatus) }}
            </template>
          </template>
          <template v-slot:item.asr="row">
            {{ translateAsr(row.item.asr) }}
          </template>
          <template v-slot:item.roi="row">
            {{ translateAsr(row.item.roi) }}
          </template>
          <template v-slot:item.actions="row">
            <v-tooltip v-if="!row.loading" bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  @click.stop="sendAction('cancel', row)"
                >clear</v-icon>
              </template>
              <span>Cancel</span>
            </v-tooltip>
            <v-progress-circular
              v-else
              indeterminate
              size="15"
              width="2"
            />
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-card class="mt-5">
      <v-card-title class="display-2">
        Inactive
        <v-switch v-model="showOther" class="pl-5" />
      </v-card-title>
      <v-card-text>
        <template v-if="showOther">
          <v-container>
            <v-row>
              <v-col cols="3">
                <v-text-field
                  v-model="roiFilter"
                  label="Minimum RoI Filter"
                  append-icon="filter_list"
                  type="number"
                  clearable
                />
              </v-col>
            </v-row>
          </v-container>
          <v-data-table
            :headers="headersOther"
            :items="itemsOtherFiltered"
            hide-default-footer
            :items-per-page="-1"
            @click:row="goToTask"
          >
            <template v-slot:item.taskStatus="row">
              <template v-if="row.item.errorText !== ''">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      {{ translateStatus(row.item.taskStatus) }}
                    </span>
                  </template>
                  {{ row.item.errorText }}
                </v-tooltip>
              </template>
              <template v-else>
                {{ translateStatus(row.item.taskStatus) }}
              </template>
            </template>
            <template v-slot:item.asr="row">
              {{ translateAsr(row.item.asr) }}
            </template>
            <template v-slot:item.roi="row">
              {{ translateAsr(row.item.roi) }}
            </template>
            <template v-slot:item.actions="row">
              <v-tooltip v-if="!row.loading" bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    @click.stop="sendRouteAction('cancel', row)"
                  >clear</v-icon>
                </template>
                <span>Cancel</span>
              </v-tooltip>
              <v-progress-circular
                v-else
                indeterminate
                size="15"
                width="2"
              />
            </template>
          </v-data-table>
        </template>
      </v-card-text>
    </v-card>
  </layout-panel>
</template>

<script>
  import { authFetch } from '@/plugins/authFetch'
  import { statusMap, floorToTwo } from '@/plugins/common'

  export default {
    props: {
      taskId: { type: Number, default: 0 },
    },
    data () {
      return {
        headers: [
          { text: 'Created', value: 'taskCreated' },
          { text: 'Name', value: 'taskName' },
          { text: 'Status', value: 'taskStatus' },
          { text: 'Dial Server', value: 'dialServer' },
          { text: 'ASR', value: 'asr' },
          { text: 'RoI', value: 'roi' },
          { text: 'Actions', value: 'actions' },
        ],
        headersOther: [
          { text: 'Created', value: 'taskCreated' },
          { text: 'Name', value: 'taskName' },
          { text: 'Status', value: 'taskStatus' },
          { text: 'Dial Server', value: 'dialServer' },
          { text: 'ASR', value: 'asr' },
          { text: 'RoI', value: 'roi' },
        ],
        itemsRunning: [],
        itemsOther: [],
        routeInfo: null,
        showOther: false,
        initialLoad: true,
        loading: true,
        reloadTimer: null,
        reloading: false,
        error: false,
        tableOptions: {
          sortBy: 'taskCreated',
          descending: true,
          rowsPerPage: -1,
        },
        roiFilter: null,
      }
    },
    computed: {
      itemsOtherFiltered () {
        if (!this.roiFilter) {
          return this.itemsOther
        }
        const min = parseInt(this.roiFilter, 10)
        const result = []
        this.itemsOther.forEach(v => {
          if (v.roi >= min) {
            result.push(v)
          }
        })
        return result
      },
      routeName () {
        if (this.routeInfo === null) {
          return ''
        }
        let prefix = 'No Prefix'
        if (this.routeInfo.prefix) {
          prefix = `Prefix: ${this.routeInfo.prefix}`
        }
        return `${this.routeInfo.ip}:${this.routeInfo.port} ${prefix}`
      },
    },
    created () {
      this.getData()
      this.reloadTimer = setInterval(() => {
        this.getData()
      }, 10000)
    },
    beforeDestroy () {
      clearInterval(this.reloadTimer)
    },
    methods: {
      getData () {
        if (!this.initialLoad) {
          this.reloading = true
        }
        this.loading = true
        authFetch(process.env.VUE_APP_API_ROOT + `task/route/${this.taskId}/`)
          .then(async response => {
            const items = await response.json()
            this.itemsRunning = []
            this.itemsOther = []
            if (Object.prototype.hasOwnProperty.call(items, 'routeInfo')) {
              this.routeInfo = items.routeInfo
            }
            if (Object.prototype.hasOwnProperty.call(items, 'tasks')) {
              items.tasks.forEach(v => {
                if (v.cost === 0) {
                  v.roi = 'n/a'
                } else {
                  v.roi = floorToTwo(((v.revenue - v.cost) / v.cost) * 100)
                }
                if (v.taskStatus === 'running') {
                  this.itemsRunning.push(v)
                } else {
                  this.itemsOther.push(v)
                }
              })
            }
          })
          .catch(error => {
            console.log(error)
            this.error = true
          })
          .finally(() => {
            this.initialLoad = false
            this.reloading = false
            this.loading = false
          })
      },
      goToTask (row) {
        this.$router.push({
          name: 'task',
          params: { taskId: row.id },
        })
      },
      translateStatus (key) {
        if (Object.prototype.hasOwnProperty.call(statusMap, key)) {
          return statusMap[key]
        }
        return `Unknown (${key})`
      },
      translateAsr (value) {
        if (value === 'n/a') {
          return value
        }
        return value + '%'
      },
      sendAction (action, row) {
        row.loading = true
        authFetch(process.env.VUE_APP_API_ROOT + `task/action/${row.item.id}/`, {
          method: 'POST',
          body: JSON.stringify({ action: action }),
        })
          .then(async response => {
            const result = await response.json()
            row.item.taskStatus = result.result
          })
          .catch(error => {
            console.log(error)
            this.error = true
          })
          .finally(() => {
            row.loading = false
          })
      },
    },
  }
</script>

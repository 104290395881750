<template>
  <layout-panel>
    <v-container>
      <v-card>
        <v-card-text class="display-2 text-center mt-5 primary white--text">
          Countries
        </v-card-text>
      </v-card>
    </v-container>
    <v-container>
      <v-card>
        <v-list>
          <v-list-item v-for="(i, k) in items" :key="`target_${k}`">
            <v-list-item-content>{{ i }}</v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-container>
  </layout-panel>
</template>

<script>

  export default {
    data () {
      return {
        items: [
          'Argentina 25',
          'Armenia',
          'Australia',
          'Bermuda',
          'Bolivia',
          'Brazil -> Update Spanish',
          'British Virgin Islands',
          'Brunei',
          'Cayman Islands',
          'Chile',
          'China',
          'Colombia',
          'Cyprus',
          'Czech Republic',
          'Democratic Republic Of Congo',
          'Denmark',
          'Dominican Republic',
          'Ecuador',
          'El Salvador',
          'Equatorial Guinea',
          'Finland',
          'France',
          'Gibraltar',
          'Greece',
          'Guadaloupe',
          'Guatemala',
          'Guyana',
          'Haiti',
          'Hungary',
          'Iceland',
          'India 25',
          'Ireland',
          'Israel',
          'Italy',
          'Jamaica',
          'Kuwait',
          'Lebanon',
          'Lichtenstein',
          'Malaysia',
          'Martinique',
          'Mexico',
          'Morocco',
          'Netherlands',
          'New Zealand',
          'Nigeria',
          'Norway',
          'Paraguay',
          'Peru',
          'Philippines',
          'Poland',
          'Portugal',
          'Romania',
          'Saudi Arabia',
          'Singapore',
          'Slovakia',
          'South Korea',
          'Spain',
          'Sweden',
          'Switzerland',
          'Thailand',
          'Trinidad and Tobago',
          'Turkey 26',
          'UAE 38',
          'United Kingdom',
          'United States',
          'Venezuela',
          'Vietnam',
        ],
      }
    },
  }
</script>

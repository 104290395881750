<template>
  <layout-panel>
    <v-container>
      <v-card>
        <v-card-text class="display-2 text-center mt-5 primary white--text">
          Running Tasks
        </v-card-text>
      </v-card>
    </v-container>
    <v-container v-if="items.running.length > 0">
      <v-card>
        <v-card-title>Active Tasks</v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="items.running"
            :loading="reloading"
            item-key="id"
            :sort-by.sync="tableOptions.sortBy"
            :sort-desc.sync="tableOptions.descending"
            hide-default-footer
            :items-per-page="-1"
          >
            <v-progress-linear v-slot:progress color="blue" indeterminate />
            <template v-slot:header.roi="props">
              <v-tooltip bottom max-width="300">
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    {{ props.header.text }}
                    <v-icon size="16" class="pl-1" v-on="on">help</v-icon>
                  </span>
                </template>
                A task's RoI will only be calculated whilst the task is running. Calls received
                after the task has ended are not included in the RoI listed below
              </v-tooltip>
            </template>
            <template v-slot:header.asr="props">
              <v-tooltip bottom max-width="300">
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    {{ props.header.text }}
                    <v-icon size="16" class="pl-1" v-on="on">help</v-icon>
                  </span>
                </template>
                Average Success Ratio
              </v-tooltip>
            </template>
            <template v-slot:header.crp="props">
              <v-tooltip bottom max-width="300">
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    {{ props.header.text }}
                    <v-icon size="16" class="pl-1" v-on="on">help</v-icon>
                  </span>
                </template>
                Call Return Ratio - Percentage of returning calls in comparison to
                Attempts per task.
              </v-tooltip>
            </template>
            <template v-slot:item="row">
              <tr
                :key="row.item.id"
                @click="goToTask(row.item)"
              >
                <td>{{ row.item.taskCreated }}</td>
                <td>{{ row.item.taskName }}</td>
                <td>{{ translateType(row.item.taskType) }}</td>
                <td v-if="row.item.taskStatus === 'error'">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span v-on="on">
                        {{ translateStatus(row.item.taskStatus) }}
                      </span>
                    </template>
                    <span v-if="row.item.errorText !== ''">{{ row.item.errorText }}</span>
                    <span v-else>System error occurred</span>
                  </v-tooltip>
                </td>
                <td v-else>{{ translateStatus(row.item.taskStatus) }}</td>
                <td>
                  <span :class="getCLIClass(row.item.cliTest)">
                    {{ row.item.cliTest }}
                  </span>
                </td>
                <td>{{ translateDialServer(row.item.dialServer) }}</td>
                <td v-if="showDuration(row.item)">{{ getDuration(row.item) }}</td>
                <td v-else>n/a</td>
                <td v-if="showProgress(row.item)">
                  <v-progress-circular
                    :value="getProgress(row.item)"
                  >
                    {{ getProgress(row.item) }}
                  </v-progress-circular>
                </td>
                <td v-else>n/a</td>
                <td>
                  {{ row.item.asr }}<template v-if="row.item.asr !== 'n/a'">%</template>
                </td>
                <td>
                  {{ row.item.roi }}<template v-if="row.item.roi !== 'n/a'">%</template>
                  {{ roiLive(row.item) }}
                </td>
                <td>
                  {{ getCallReturnPercentage(row.item) }}
                </td>
                <td>
                  <template v-if="!row.item.loading">
                    <v-tooltip v-if="getActions(row.item) === 'pause'" bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          @click.stop="sendAction('pause', row)"
                        >pause</v-icon>
                      </template>
                      <span>Pause</span>
                    </v-tooltip>
                    <v-tooltip v-if="getActions(row.item) === 'cancel'" bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          @click.stop="sendAction('cancel', row)"
                        >clear</v-icon>
                      </template>
                      <span>Cancel</span>
                    </v-tooltip>
                    <v-tooltip v-if="getActions(row.item) === 'resume'" bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          @click.stop="sendAction('resume', row)"
                        >play_arrow</v-icon>
                      </template>
                      <span>Resume</span>
                    </v-tooltip>
                    <v-tooltip v-if="getActions(row.item) === 'restart'" bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          @click.stop="sendAction('restart', row)"
                        >replay</v-icon>
                      </template>
                      <span>Restart</span>
                    </v-tooltip>
                    <v-tooltip v-if="showClone(row.item)" bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          class="pl-2"
                          v-on="on"
                          @click.stop="goToClone(row.item)"
                        >file_copy</v-icon>
                      </template>
                      <span>Clone</span>
                    </v-tooltip>
                    <v-tooltip v-if="showHide(row.item)" bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          class="pl-2"
                          v-on="on"
                          @click.stop="sendAction('hide', row)"
                        >visibility_off</v-icon>
                      </template>
                      <span>Hide</span>
                    </v-tooltip>
                  </template>
                  <v-progress-circular
                    v-else
                    indeterminate
                    size="15"
                    width="2"
                  />
                </td>
                <td v-if="getActions(row.item) === false">&nbsp;</td>
              </tr>
            </template>
            <template v-slot:no-data>
              <div v-if="!loading && !error" class="text-center">
                You have no tasks to display
              </div>
              <v-alert v-if="!loading && error" :value="true" color="error" icon="warning">
                Error loading tasks
              </v-alert>
              <div v-if="initialLoad" class="text-center">
                <v-progress-circular indeterminate />
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>

    <v-container v-if="items.favourites.length > 0">
      <v-card>
        <v-card-title>Favourite Tasks</v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headersOther"
            :items="items.favourites"
            :loading="reloading"
            item-key="id"
            :sort-by.sync="tableOptions.sortBy"
            :sort-desc.sync="tableOptions.descending"
            hide-default-footer
            :items-per-page="-1"
            @click:row="goToTask"
          >
            <v-progress-linear v-slot:progress color="blue" indeterminate />
            <template v-slot:header.roi="props">
              <v-tooltip bottom max-width="300">
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    {{ props.header.text }}
                    <v-icon size="16" class="pl-1" v-on="on">help</v-icon>
                  </span>
                </template>
                A task's RoI will only be calculated whilst the task is running. Calls received
                after the task has ended are not included in the RoI listed below
              </v-tooltip>
            </template>
            <template v-slot:header.crp="props">
              <v-tooltip bottom max-width="300">
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    {{ props.header.text }}
                    <v-icon size="16" class="pl-1" v-on="on">help</v-icon>
                  </span>
                </template>
                Call Return Ratio - Percentage of returning calls in comparison to
                Attempts per task.
              </v-tooltip>
            </template>
            <template v-slot:item.taskType="row">
              {{ translateType(row.item.taskType) }}
            </template>
            <template v-slot:item.taskStatus="row">
              <template v-if="row.item.taskStatus === 'error'">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      {{ translateStatus(row.item.taskStatus) }}
                    </span>
                  </template>
                  <span v-if="row.item.errorText !== ''">{{ row.item.errorText }}</span>
                  <span v-else>System error occurred</span>
                </v-tooltip>
              </template>
              <template v-else>{{ translateStatus(row.item.taskStatus) }}</template>
            </template>
            <template v-slot:item.dialServer="row">
              {{ translateDialServer(row.item.dialServer) }}
            </template>
            <template v-slot:item.taskDuration="row">
              <template v-if="showDuration(row.item)">{{ getDuration(row.item) }}</template>
              <template v-else>n/a</template>
            </template>
            <template v-slot:item.asr="row">
              <div v-if="canUpdatePDDs(row.item)" class="can-pdd-edit" @click.stop="pddEditSetVisible(row.item)">
                <template v-if="pddEditIsVisible(row.item)">
                  <tasks-pdd-update
                    v-model="row.item.taskPDDs"
                    :task-id="row.item.id"
                    @completed="pddEditSetVisible(row.item, false)"
                  />
                </template>
                <template v-else>
                  {{ row.item.asr }}<template v-if="row.item.asr !== 'n/a'">%</template>
                </template>

              </div>
              <template v-else>
                {{ row.item.asr }}<template v-if="row.item.asr !== 'n/a'">%</template>
              </template>
            </template>
            <template v-slot:item.roi="row">
              {{ row.item.roi }}<template v-if="row.item.roi !== 'n/a'">%</template>
            </template>
            <template v-slot:item.crp="row">
              {{ getCallReturnPercentage(row.item) }}
            </template>
            <template v-slot:item.cliTest="row">
              <span :class="getCLIClass(row.item.cliTest)">
                {{ row.item.cliTest }}
              </span>
            </template>
            <template v-slot:item.actions="row">
              <template v-if="!row.item.loading">
                <v-tooltip v-if="getActions(row.item) === 'pause'" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      @click.stop="sendAction('pause', row)"
                    >pause</v-icon>
                  </template>
                  <span>Pause</span>
                </v-tooltip>
                <v-tooltip v-if="getActions(row.item) === 'cancel'" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      @click.stop="sendAction('cancel', row)"
                    >clear</v-icon>
                  </template>
                  <span>Cancel</span>
                </v-tooltip>
                <v-tooltip v-if="getActions(row.item) === 'resume'" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      @click.stop="sendAction('resume', row)"
                    >play_arrow</v-icon>
                  </template>
                  <span>Resume</span>
                </v-tooltip>
                <v-tooltip v-if="getActions(row.item) === 'restart'" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      @click.stop="sendAction('restart', row)"
                    >replay</v-icon>
                  </template>
                  <span>Restart</span>
                </v-tooltip>
                <v-tooltip v-if="getActions(row.item) === 'nuke'" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      @click.stop="sendAction('nuke', row)"
                    >mdi-nuke</v-icon>
                  </template>
                  <span>Force Kill</span>
                </v-tooltip>
                <v-tooltip v-if="showClone(row.item)" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      class="pl-2"
                      v-on="on"
                      @click.stop="goToClone(row.item)"
                    >file_copy</v-icon>
                  </template>
                  <span>Clone</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      class="pl-2"
                      v-on="on"
                      @click.stop="sendAction('favourite', row)"
                    >mdi-star-off</v-icon>
                  </template>
                  <span>Remove from Favourites</span>
                </v-tooltip>
              </template>
              <v-progress-circular
                v-else
                indeterminate
                size="15"
                width="2"
              />
            </template>
            <template v-slot:no-data>
              <div v-if="!loading && !error" class="text-center">
                You have no tasks to display
              </div>
              <v-alert v-if="!loading && error" :value="true" color="error" icon="warning">
                Error loading tasks
              </v-alert>
              <div v-if="initialLoad" class="text-center">
                <v-progress-circular indeterminate />
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>

    <v-container>
      <v-card>
        <v-card-title>Inactive Tasks</v-card-title>
        <v-card-text class="text-right">
          <v-btn
            :disabled="hideAllLoading"
            :loading="hideAllLoading"
            @click="sendHideAll"
          >
            <v-icon left>visibility_off</v-icon>
            Hide All
          </v-btn>
        </v-card-text>
        <v-card-text>
          <v-data-table
            :headers="headersOther"
            :items="items.other"
            :loading="reloading"
            item-key="id"
            :sort-by.sync="tableOptions.sortBy"
            :sort-desc.sync="tableOptions.descending"
            hide-default-footer
            :items-per-page="-1"
            @click:row="goToTask"
          >
            <v-progress-linear v-slot:progress color="blue" indeterminate />
            <template v-slot:header.roi="props">
              <v-tooltip bottom max-width="300">
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    {{ props.header.text }}
                    <v-icon size="16" class="pl-1" v-on="on">help</v-icon>
                  </span>
                </template>
                A task's RoI will only be calculated whilst the task is running. Calls received
                after the task has ended are not included in the RoI listed below
              </v-tooltip>
            </template>
            <template v-slot:header.crp="props">
              <v-tooltip bottom max-width="300">
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    {{ props.header.text }}
                    <v-icon size="16" class="pl-1" v-on="on">help</v-icon>
                  </span>
                </template>
                Call Return Ratio - Percentage of returning calls in comparison to
                Attempts per task.
              </v-tooltip>
            </template>
            <template v-slot:item.taskType="row">
              {{ translateType(row.item.taskType) }}
            </template>
            <template v-slot:item.taskStatus="row">
              <template v-if="row.item.taskStatus === 'error'">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      {{ translateStatus(row.item.taskStatus) }}
                    </span>
                  </template>
                  <span v-if="row.item.errorText !== ''">{{ row.item.errorText }}</span>
                  <span v-else>System error occurred</span>
                </v-tooltip>
              </template>
              <template v-else>{{ translateStatus(row.item.taskStatus) }}</template>
            </template>
            <template v-slot:item.dialServer="row">
              {{ translateDialServer(row.item.dialServer) }}
            </template>
            <template v-slot:item.taskDuration="row">
              <template v-if="showDuration(row.item)">{{ getDuration(row.item) }}</template>
              <template v-else>n/a</template>
            </template>
            <template v-slot:item.asr="row">
              <div v-if="canUpdatePDDs(row.item)" class="can-pdd-edit" @click.stop="pddEditSetVisible(row.item)">
                <template v-if="pddEditIsVisible(row.item)">
                  <tasks-pdd-update
                    v-model="row.item.taskPDDs"
                    :task-id="row.item.id"
                    @completed="pddEditSetVisible(row.item, false)"
                  />
                </template>
                <template v-else>
                  {{ row.item.asr }}<template v-if="row.item.asr !== 'n/a'">%</template>
                </template>

              </div>
              <template v-else>
                {{ row.item.asr }}<template v-if="row.item.asr !== 'n/a'">%</template>
              </template>
            </template>
            <template v-slot:item.roi="row">
              {{ row.item.roi }}<template v-if="row.item.roi !== 'n/a'">%</template>
            </template>
            <template v-slot:item.crp="row">
              {{ getCallReturnPercentage(row.item) }}
            </template>
            <template v-slot:item.cliTest="row">
              <span :class="getCLIClass(row.item.cliTest)">
                {{ row.item.cliTest }}
              </span>
            </template>
            <template v-slot:item.actions="row">
              <template v-if="!row.item.loading">
                <v-tooltip v-if="getActions(row.item) === 'pause'" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      @click.stop="sendAction('pause', row)"
                    >pause</v-icon>
                  </template>
                  <span>Pause</span>
                </v-tooltip>
                <v-tooltip v-if="getActions(row.item) === 'cancel'" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      @click.stop="sendAction('cancel', row)"
                    >clear</v-icon>
                  </template>
                  <span>Cancel</span>
                </v-tooltip>
                <v-tooltip v-if="getActions(row.item) === 'resume'" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      @click.stop="sendAction('resume', row)"
                    >play_arrow</v-icon>
                  </template>
                  <span>Resume</span>
                </v-tooltip>
                <v-tooltip v-if="getActions(row.item) === 'restart'" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      @click.stop="sendAction('restart', row)"
                    >replay</v-icon>
                  </template>
                  <span>Restart</span>
                </v-tooltip>
                <v-tooltip v-if="getActions(row.item) === 'nuke'" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      @click.stop="sendAction('nuke', row)"
                    >mdi-nuke</v-icon>
                  </template>
                  <span>Force Kill</span>
                </v-tooltip>
                <v-tooltip v-if="showClone(row.item)" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      class="pl-2"
                      v-on="on"
                      @click.stop="goToClone(row.item)"
                    >file_copy</v-icon>
                  </template>
                  <span>Clone</span>
                </v-tooltip>
                <v-tooltip v-if="showHide(row.item)" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      class="pl-2"
                      v-on="on"
                      @click.stop="sendAction('hide', row)"
                    >visibility_off</v-icon>
                  </template>
                  <span>Hide</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      class="pl-2"
                      v-on="on"
                      @click.stop="sendAction('favourite', row)"
                    >mdi-star</v-icon>
                  </template>
                  <span>Add to Favourites</span>
                </v-tooltip>
              </template>
              <v-progress-circular
                v-else
                indeterminate
                size="15"
                width="2"
              />
            </template>
            <template v-slot:no-data>
              <div v-if="!loading && !error" class="text-center">
                You have no tasks to display
              </div>
              <v-alert v-if="!loading && error" :value="true" color="error" icon="warning">
                Error loading tasks
              </v-alert>
              <div v-if="initialLoad" class="text-center">
                <v-progress-circular indeterminate />
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
    <v-container v-if="userHasRoutes">
      <v-card>
        <v-card-title>Automatic Access Tasks</v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headersRoute"
            :items="routeItems"
            hide-default-footer
            :items-per-page="-1"
            @click:row="goToRouteTask"
          >
            <template v-slot:item.status="row">
              {{ translateStatus(row.item.status) }}
            </template>
            <template v-slot:item.prefixes="row">
              {{ translatePrefixes(row.item.prefixes) }}
            </template>
            <template v-slot:item.prefixesAlt="row">
              {{ translatePrefixes(row.item.prefixesAlt) }}
            </template>
            <template v-slot:item.actions="row">
              <v-tooltip v-if="!row.item.loading" bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    @click.stop="sendRouteAction('cancel', row)"
                  >clear</v-icon>
                </template>
                <span>Cancel</span>
              </v-tooltip>
              <v-progress-circular
                v-else
                indeterminate
                size="15"
                width="2"
              />
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </layout-panel>
</template>

<script>
  import { authFetch } from '@/plugins/authFetch'
  import { statusMap, typeMap, floorToTwo } from '@/plugins/common'
  import TasksPddUpdate from '@/components/TasksPddUpdate'

  export default {
    components: { TasksPddUpdate },
    data () {
      return {
        headers: [
          { text: 'Created', value: 'taskCreated' },
          { text: 'Name', value: 'taskName' },
          { text: 'Type', value: 'taskType' },
          { text: 'Status', value: 'taskStatus' },
          { text: 'CLI', value: 'cliTest' },
          { text: 'Dial Server', value: 'dialServer' },
          { text: 'Dur', value: 'taskDuration' },
          { text: 'Progress', value: 'progressPercent' },
          { text: 'ASR', value: 'asr' },
          { text: 'RoI', value: 'roi' },
          { text: 'CR%', value: 'crp', width: '75px' },
          { text: 'Actions', value: 'action', width: 160 },
        ],
        headersOther: [
          { text: 'Created', value: 'taskCreated' },
          { text: 'Name', value: 'taskName' },
          { text: 'Type', value: 'taskType' },
          { text: 'Status', value: 'taskStatus' },
          { text: 'CLI', value: 'cliTest' },
          { text: 'Dial Server', value: 'dialServer' },
          { text: 'Dur', value: 'taskDuration' },
          { text: 'ASR', value: 'asr' },
          { text: 'RoI', value: 'roi' },
          { text: 'CR%', value: 'crp', width: '75px' },
          { text: 'Actions', value: 'actions', width: 160 },
        ],
        headersRoute: [
          { text: 'Prefix', value: 'prefix' },
          { text: 'IP', value: 'ip' },
          { text: 'Port', value: 'port' },
          { text: 'Status', value: 'status' },
          { text: 'Day Prefixes', value: 'prefixes' },
          { text: 'Evening Prefixes', value: 'prefixesAlt' },
          { text: 'Max Ports', value: 'maxSimultaneousCalls' },
          { text: 'Actions', value: 'actions' },
        ],
        routeItems: [],
        items: {
          running: [],
          other: [],
          favourites: [],
        },
        initialLoad: true,
        loading: true,
        reloadTimer: null,
        reloading: false,
        error: false,
        tableOptions: {
          sortBy: 'taskCreated',
          descending: true,
          rowsPerPage: -1,
        },
        pddEditing: {},
        hideAllLoading: false,
      }
    },
    computed: {
      userHasRoutes () {
        return this.$store.state.permission & 6
      },
    },
    created () {
      this.getData()
      this.getRouteData()
      this.reloadTimer = setInterval(() => {
        this.getData()
        this.getRouteData()
      }, 10000)
    },
    beforeDestroy () {
      clearInterval(this.reloadTimer)
    },
    methods: {
      canUpdatePDDs (task) {
        return ['aborted', 'killed', 'error'].includes(task.taskStatus) === true && task.taskPDDLock === true
      },
      pddEditIsVisible (task) {
        return Object.prototype.hasOwnProperty.call(this.pddEditing, task.id) && this.pddEditing[task.id] === true
      },
      pddEditSetVisible (task, visible = true) {
        this.$set(this.pddEditing, task.id, visible)
      },
      getData () {
        if (!this.initialLoad) {
          this.reloading = true
        }
        this.loading = true
        authFetch(process.env.VUE_APP_API_ROOT + 'tasks/')
          .then(async response => {
            const items = await response.json()
            this.items.running = []
            this.items.other = []
            this.items.favourites = []
            items.forEach(item => {
              item.loading = false
              if (item.cost === 0) {
                item.roi = 'n/a'
              } else {
                item.roi = floorToTwo(((item.revenue - item.cost) / item.cost) * 100)
              }
              if (item.taskStatus === 'running') {
                this.items.running.push(item)
              } else if (item.favourite === true) {
                this.items.favourites.push(item)
              } else {
                this.items.other.push(item)
              }
            })
          })
          .catch(error => {
            console.log(error)
            this.error = true
          })
          .finally(() => {
            this.initialLoad = false
            this.reloading = false
            this.loading = false
          })
      },
      getRouteData () {
        if (!this.userHasRoutes) {
          return
        }
        authFetch(process.env.VUE_APP_API_ROOT + 'tasks/route/')
          .then(async response => {
            this.routeItems = await response.json()
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
          })
      },
      showProgress (item) {
        return item.taskStatus === 'running' && item.taskType !== 'finalized'
      },
      getProgress (item) {
        if (item.taskMaxAttempts === null) {
          return item.progressPercent
        }
        return Math.floor((item.outgoingCurrent / item.taskMaxAttempts) * 100)
      },
      showDuration (item) {
        return item.taskType !== 'finalized'
      },
      getDuration (item) {
        if (item.taskMaxAttempts === null) {
          return item.taskDuration
        }
        return item.taskMaxAttempts
      },
      showHide (item) {
        const action = this.getActions(item)
        return (action === 'resume' || action === 'restart') && item.favourite === false
      },
      showClone (item) {
        return ['exploration', 'refinement', 'finalized', 'auto-exploration',
                'auto-smart'].includes(item.taskType)
      },
      showFavouriteOn (item) {
        return item.favourite === false
      },
      showFavouriteOff (item) {
        return item.favourite === true
      },
      goToTask (task) {
        this.$router.push({
          name: 'task',
          params: { taskId: task.id },
        })
      },
      goToClone (item) {
        if (item.taskType === 'auto-exploration') {
          this.$router.push({
            name: 'task-clone-auto',
            params: { taskId: item.id },
          })
        } else {
          this.$router.push({
            name: 'task-clone',
            params: { taskId: item.id },
          })
        }
      },
      goToRouteTask (row) {
        this.$router.push({
          name: 'task-route',
          params: { taskId: row.id },
        })
      },
      translateStatus (key) {
        if (Object.prototype.hasOwnProperty.call(statusMap, key)) {
          return statusMap[key]
        }
        return `Unknown (${key})`
      },
      translateType (key) {
        if (Object.prototype.hasOwnProperty.call(typeMap, key)) {
          return typeMap[key]
        }
        return `Unknown (${key})`
      },
      translateDialServer (value) {
        if (value === null) {
          if (this.$store.state.permission & 8) {
            return 'None'
          }
          return 'Default'
        }
        if (value === 'auto') {
          return 'Automatic'
        }
        return value
      },
      translatePrefixes (value) {
        if (value.length === 0) {
          return 'n/a'
        }
        return value.join(', ')
      },
      getActions (item) {
        console.log(item)
        if (item.taskStatus === 'running' || item.taskStatus === 'schedule') {
          if (item.taskType === 'finalized') {
            return 'pause'
          } else {
            return 'cancel'
          }
        } else if (item.taskStatus === 'paused') {
          return 'resume'
        } else if (
          item.taskStatus === 'completed' ||
          item.taskStatus === 'error' ||
          item.taskStatus === 'killed' ||
          item.taskStatus === 'aborted'
        ) {
          return 'restart'
        } else if (item.taskStatus === 'aborting' && item.nuked === false) {
          return 'nuke'
        }
        return false
      },
      sendHideAll () {
        const ids = []
        this.items.other.forEach(v => {
          ids.push(v.id)
        })
        if (ids.length === 0) {
          return
        }
        this.hideAllLoading = true
        authFetch(`${process.env.VUE_APP_API_ROOT}tasks/hide-all/`, {
          method: 'POST',
          body: JSON.stringify({ ids: ids }),
        })
          .then(async response => {
            await response.json()
            this.getData()
          })
          .catch(error => {
            console.log(error)
            this.error = true
          })
          .finally(() => {
            this.hideAllLoading = false
          })
      },
      sendAction (action, row) {
        row.item.loading = true
        authFetch(process.env.VUE_APP_API_ROOT + `task/action/${row.item.id}/`, {
          method: 'POST',
          body: JSON.stringify({ action: action }),
        })
          .then(async response => {
            const result = await response.json()
            row.item.taskStatus = result.result
            switch (action) {
              case 'hide':
                this.items.other.forEach((v, k) => {
                  if (v.id === row.item.id) {
                    this.$delete(this.items.other, k)
                  }
                })
                break
              case 'favourite':
                if (row.item.favourite === true) {
                  this.items.favourites.forEach((v, k) => {
                    if (v.id === row.item.id) {
                      this.$delete(this.items.favourites, k)
                      v.favourite = false
                      this.items.other.push(v)
                    }
                  })
                } else {
                  this.items.other.forEach((v, k) => {
                    if (v.id === row.item.id) {
                      this.$delete(this.items.other, k)
                      v.favourite = true
                      this.items.favourites.push(v)
                    }
                  })
                }
                break
              case 'nuke':
                row.item.nuked = true
                break
            }
          })
          .catch(error => {
            console.log(error)
            this.error = true
          })
          .finally(() => {
            row.item.loading = false
          })
      },
      sendRouteAction (action, row) {
        row.item.loading = true
        authFetch(process.env.VUE_APP_API_ROOT + `task/route/action/${row.item.id}/`, {
          method: 'POST',
          body: JSON.stringify({ action: action }),
        })
          .then(async response => {
            const result = await response.json()
            row.item.status = result.result
          })
          .catch(error => {
            console.log(error)
            this.error = true
          })
          .finally(() => {
            row.item.loading = false
          })
      },
      getCallReturnPercentage (item) {
        if (item.outgoing === 0) {
          return 'n/a'
        }
        return floorToTwo((item.incoming / item.outgoing) * 100).toString() + '%'
      },
      roiLive (item) {
        if (Object.prototype.hasOwnProperty.call(item, 'revenueLive') === false) {
          return ''
        }
        if (item.revenueLive === 0 || item.cost === 0) {
          return ''
        }
        const live = floorToTwo((((item.revenue + item.revenueLive) - item.cost) / item.cost) * 100)
        return `(${live}%)`
      },
      getCLIClass (cli) {
        if (cli === 'err') {
          return ['red--text']
        }
        if (cli === 'n/a' || cli.includes('/') === false) {
          return []
        }
        const parts = cli.split('/')
        if (parts[0] === '0') {
          return ['red--text']
        }
        if (parts[0] === parts[1]) {
          return ['green--text']
        }
        return ['yellow--text']
      },
    },
  }
</script>

<style scoped>
.can-pdd-edit {
    cursor: pointer;
}
</style>

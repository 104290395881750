import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors'
import 'vuetify/src/styles/main.sass'

Vue.use(Vuetify)
export default new Vuetify({
  icons: { iconfont: 'mdiSvg' },
  theme: {
    themes: {
      light: {
        primary: colors.indigo.darken1,
        footerRevenue: colors.yellow.darken3,
        headerBackground: colors.indigo.darken4,
        navigationHeaderBackground: colors.indigo.darken1,
      },
      dark: {
        primary: colors.indigo.darken1,
        footerRevenue: colors.yellow,
        headerBackground: colors.grey.darken4,
        navigationHeaderBackground: colors.grey.darken4,
      },
    },
    options: {
      customProperties: true,
    },
  },
})

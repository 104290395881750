<template>
  <layout-panel>
    <v-container>
      <v-card>
        <v-card-text class="display-2 text-center mt-5 primary white--text">
          High Rate VoIP Routes
        </v-card-text>
      </v-card>
    </v-container>
    <v-container>
      <v-card>
        <v-card-text>
          <v-data-table
            :items="items"
            :headers="headers"
            :footer-props="{
              itemsPerPageOptions: [50, 100, 500]
            }"
            :options.sync="options"
            :server-items-length="total"
            :sort-by="sortBy"
            :sort-desc="descending"
            :loading="loading"
          >
            <template v-slot:item.ip="row">
              {{ row.item.ip }}:{{ row.item.port }}
            </template>
            <template v-slot:item.prefix="row">
              <em v-if="row.item.prefix === ''">No Prefix</em>
              {{ row.item.prefix }}
            </template>
            <template v-slot:item.destinations="row">
              {{ row.item.destinations.join(', ') }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </layout-panel>
</template>

<script>
  import { authFetch, objectToQueryString } from '@/plugins/authFetch'
  export default {
    data: () => ({
      items: [],
      loading: false,
      headers: [
        { text: 'IP:Port', value: 'ip' },
        { text: 'Prefix', value: 'prefix' },
        { text: 'Max Channels', value: 'channels' },
        { text: 'Max CPS', value: 'cps' },
        { text: 'Destinations', value: 'destinations', sortable: false },
        { text: 'Added', value: 'added' },
        { text: 'User', value: 'user' },
      ],
      options: {},
      total: 0,
      sortBy: 'added',
      descending: true,
    }),
    watch: {
      options: {
        handler () {
          this.getData()
        },
        deep: true,
      },
    },
    methods: {
      getData () {
        this.loading = true
        const query = objectToQueryString({
          itemsPerPage: this.options.itemsPerPage,
          page: this.options.page,
          sortBy:
            this.options.sortBy.length === 0
              ? this.sortBy
              : this.options.sortBy[0],
          sortDesc:
            this.options.sortDesc.length === 0
              ? this.descending === true
                ? 1
                : 0
              : this.options.sortDesc[0] === true
                ? 1
                : 0,
        })
        authFetch(`${process.env.VUE_APP_API_ROOT}admin/hrv/routes/?${query}`)
          .then(async response => {
            const data = await response.json()
            this.total = data.total
            this.items = data.items
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            this.loading = false
          })
      },
    },
  }
</script>

<style scoped>
.pre { white-space: pre-wrap; }
</style>

<template>
  <layout-panel>
    <v-container>
      <v-card>
        <v-card-text class="display-2 text-center mt-5 primary white--text">
          IVR Stats
        </v-card-text>
      </v-card>
    </v-container>
    <v-container v-if="loading === true" class="text-center mt-5">
      <v-progress-circular
        :size="50"
        :width="5"
        indeterminate
      />
    </v-container>
    <v-container>
      <v-alert
        v-if="loading === false && objHasLength(total) === false"
        :value="true"
        color="error"
        icon="warning"
        class="mt-5"
      >
        No data avaialable
      </v-alert>
      <template v-else-if="loading === false">
        <div class="mb-5">
          <span class="display-1 pr-5">Today</span>
          <v-card v-if="objHasLength(today)">
            <template v-for="(items, country) in today">
              <v-card-title
                :key="`tod_title_${country}`"
                class="title"
              >{{ country }}</v-card-title>
              <v-data-table
                :key="`tod_table_${country}`"
                :items="items"
                :headers="headers"
                hide-default-footer
                :items-per-page="-1"
                :sort-by.sync="tableOptions.sortBy"
                :sort-desc.sync="tableOptions.descending"
              >
                <template v-slot:item.acd="row">
                  {{ floorToTwo(row.item.acd) }}
                </template>
              </v-data-table>
            </template>
          </v-card>
          <v-card v-else>
            <v-card-text class="text-center"><em>No data to display</em></v-card-text>
          </v-card>
        </div>

        <div>
          <span class="display-1 pr-5">Total</span>
          <v-card>
            <template v-for="(items, country) in total">
              <v-card-title
                :key="`tot_title_${country}`"
                class="title"
              >{{ country }}</v-card-title>
              <v-data-table
                :key="`tot_table_${country}`"
                :items="items"
                :headers="headers"
                hide-default-footer
                :items-per-page="-1"
                :sort-by.sync="tableOptions.sortBy"
                :sort-desc.sync="tableOptions.descending"
              >
                <template v-slot:item.acd="row">
                  {{ floorToTwo(row.item.acd) }}
                </template>
              </v-data-table>
            </template>
          </v-card>
        </div>
      </template>
    </v-container>
  </layout-panel>
</template>

<script>
  import { authFetch } from '@/plugins/authFetch'
  import { floorToTwo } from '@/plugins/common'

  export default {
    data () {
      return {
        loading: true,
        today: {},
        total: {},
        headers: [
          { text: 'Filename', value: 'filename' },
          { text: 'Calls', value: 'calls' },
          { text: 'ACD', value: 'acd' },
        ],
        tableOptions: {
          sortBy: 'acd',
          descending: true,
          rowsPerPage: -1,
        },
      }
    },
    created () {
      this.getData()
    },
    methods: {
      floorToTwo (f) {
        return floorToTwo(f)
      },
      objHasLength (obj) {
        return Object.keys(obj).length !== 0
      },
      getData () {
        authFetch(process.env.VUE_APP_API_ROOT + 'admin/stats/ivr/')
          .then(async response => {
            const items = await response.json()
            this.today = items.today
            this.total = items.total
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.loading = false
          })
      },
    },
  }
</script>
